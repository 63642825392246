import { useToasts } from "components/Toast";
import { useConfirm } from "components/Confirm";
import { useCallback } from "react";
import { namedOperations } from "generated/operations";
import { useDeleteCommissionConclusionMutation } from "generated/hooks";

type TProps = {
  onSuccess?: () => void;
};

export const useAppealViewRemoveConsolutionCommission = ({
  onSuccess,
}: TProps = {}): {
  removeConclusion: (id: number) => void;
  removing: boolean;
} => {
  const { addOkConfirm } = useConfirm();
  const { addSuccessToast, addErrorsToast } = useToasts();
  const [deleteConclusionMutation, { loading: removing }] =
    useDeleteCommissionConclusionMutation({
      refetchQueries: [namedOperations.Query.ConclusionAppeal],
    });

  const removeConclusion = useCallback(
    (id: number) => {
      addOkConfirm({
        text: "Удалить",
        cancelText: "Отменить",
        onClickOk: async () => {
          const removeData = await deleteConclusionMutation({
            variables: {
              id: { id },
            },
          });
          const errors =
            removeData?.data?.deleteCommissionHeadConclusion.errors;
          if (errors) {
            addErrorsToast(errors);
            return;
          }
          addSuccessToast("Удаление прошло успешно");
          onSuccess?.();
        },
      });
    },
    [
      deleteConclusionMutation,
      addErrorsToast,
      addSuccessToast,
      addOkConfirm,
      onSuccess,
    ]
  );
  return { removeConclusion, removing };
};
