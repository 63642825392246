import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import {
  PatientItemFragment,
  PatientsQuery,
  PatientsQueryVariables,
} from "generated/operations";
import { usePatientsQuery } from "generated/hooks";
import { sortingTransformer } from "utils/sortingTransformer";
import { whereTransformer } from "utils/whereTransformer";
import { dataTransformer } from "./utils/dataTransformer";
import { columnTransformer } from "./utils/columnTransformer";

export type TSelectUsersDataGridProps = Partial<
  UseGqlDataGridParams<
    PatientsQuery,
    PatientItemFragment,
    PatientsQueryVariables
  >
>;

export type TSelectUsersDataGridResult = UseGqlDataGridResult<
  PatientsQuery,
  PatientItemFragment,
  PatientsQueryVariables
>;

export const useSelectUsersDataGrid = (
  props?: TSelectUsersDataGridProps
): TSelectUsersDataGridResult => {
  const gridName = "employingOrganizationPatients";
  const gridData = useGqlDataGrid<
    PatientsQuery,
    PatientItemFragment,
    PatientsQueryVariables
  >({
    type: gridName,
    queryHook: usePatientsQuery,
    dataTransformer,
    columnTransformer,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    selection: true,
    ...props,
  });
  return gridData;
};
