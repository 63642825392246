import { FC } from "react";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { SelectUsersForm } from "../SelectUsersForm/SelectUsersForm";
import { PatientItemFragment } from "generated/operations";
import { Outlet } from "react-router";

interface SelectUserDrawerProps<T> {
  onSelect: (rows: T[]) => void;
  onClose: () => void;
  selectedUsers: Pick<PatientItemFragment, "id" | "fio">[];
}

export const SelectUserDrawer: FC<
  SelectUserDrawerProps<Pick<PatientItemFragment, "id" | "fio">>
> = ({ onSelect, onClose, selectedUsers }) => {
  return (
    <>
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 1000,
          },
        }}
        open
        onClose={onClose}
      >
        <DrawerContent
          title={"Выбор пользователей"}
          onClose={onClose}
          isWithActions={false}
        >
          <SelectUsersForm onSelect={onSelect} selectedUsers={selectedUsers} />
        </DrawerContent>
      </Drawer>
      <Outlet />
    </>
  );
};
