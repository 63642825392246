import { locale } from "locale";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ColumnTransformer } from "components/DataGrid";
import { OrganizationFragment } from "generated/operations";
import { Link } from "@mui/material";
import { paths } from "router/paths";
import { TokenHelper } from "TokenHelper";
import { AccessPermissions } from "types/AccessPermissions";

const getHeaderName = (field: string): string => {
  return (
    (locale.employingOrganization as { [key: string]: string })[field] ?? field
  );
};

const fieldsOrder = ["organizationName", "address"].reverse();

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);

  switch (column.field) {
    case "organizationName":
      return {
        ...column,
        headerName,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, OrganizationFragment>) => {
          const employingOrganizationPath = TokenHelper.checkAccessPermission(
            AccessPermissions.EmployingOrganizationsUpdate
          )
            ? paths.employingOrganizationEdit(row.id)
            : paths.employingOrganization(row.id);
          return <Link href={employingOrganizationPath}>{value}</Link>;
        },
      };
    default:
      return {
        ...column,
        headerName,
      };
  }
};

export const columnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
