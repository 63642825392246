import { formatDate } from "utils/formatDate";
import { ClientCreateValue } from "../../types";
import { TokenHelper } from "TokenHelper";

export const getInputDataFromValues = (
  values: ClientCreateValue
): ClientCreateValue => {
  return {
    name: values.name,
    surname: values.surname,
    fathersName: values.fathersName ?? "",
    sex: values.sex,
    birthdate: values.birthdate ? formatDate(values.birthdate, "server") : "",
    snils: values.snils,
    phoneNumber: values.phoneNumber ?? "",
    email: values.email ?? "",
    employingOrganizationId:
      TokenHelper.getCurrentEmployingOrganizationId() ?? undefined,
  };
};
