import { ConclusionAppealQuery } from "generated/operations";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";
import { TConclusionDoctorCreateResult } from "pages/AppealPage/utils/types";

export const opoConclusionDoctorValidateCreate = (
  employeeId: string,
  isCorrectOrganizationDoctor: boolean,
  isPsychiatrist: boolean,
  isClinicalPsychologist: boolean,
  data: ConclusionAppealQuery | undefined,
  commissionSize: number
): TConclusionDoctorCreateResult => {
  if (!isCorrectOrganizationDoctor) {
    return {
      isClinicalPsychologistAvailable: false,
      isCreateConclusion: false,
      isNarcologyAvailable: false,
      isPsychiatryAvailable: false,
    };
  }

  const canCreateConclusion = (): boolean => {
    return isPsychiatrist || isClinicalPsychologist;
  };

  if (data === undefined || data.conclusionAppeals?.totalCount === 0) {
    return {
      isCreateConclusion: canCreateConclusion(),
      isNarcologyAvailable: false,
      isPsychiatryAvailable: isPsychiatrist,
      isClinicalPsychologistAvailable: isClinicalPsychologist,
    };
  }

  let psychiatristsCount: number = 0;
  let currentPsychiatristExists: boolean = false;
  let clinicalPsychologistExists: boolean = false;

  for (let conclusion of data.conclusionAppeals?.items!) {
    if (
      conclusion.name === ConclusionsName.Psychiatrist &&
      conclusion.doctorId === employeeId &&
      conclusion.isActual
    ) {
      currentPsychiatristExists = true;
    }
    if (
      conclusion.name === ConclusionsName.Psychiatrist &&
      conclusion.isActual
    ) {
      psychiatristsCount++;
      continue;
    }
    if (
      conclusion.name === ConclusionsName.ClinicalPsychologist &&
      conclusion.isActual
    ) {
      clinicalPsychologistExists = true;
    }
  }

  let psychiatristAvailable =
    isPsychiatrist &&
    psychiatristsCount < commissionSize &&
    !currentPsychiatristExists;

  let clinicalPsychologistAvailable =
    isClinicalPsychologist && !clinicalPsychologistExists;

  return {
    isCreateConclusion: psychiatristAvailable || clinicalPsychologistAvailable,
    isNarcologyAvailable: false,
    isPsychiatryAvailable: psychiatristAvailable,
    isClinicalPsychologistAvailable: clinicalPsychologistAvailable,
  };
};
