import { useMemo } from "react";
import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit";
import { useFileHistoriesDataGrid } from "pages/ChangesPage/hooks/useFileChangesDateGrid/useFileChangesDataGrid";
import { useFileChangesHeaderButtons } from "pages/ChangesPage/hooks/useFileChangesDateGrid/useFileChangesHeaderButtons";

export function FileChangesGrid() {
  const gridData = useFileHistoriesDataGrid();
  const { gridProps } = gridData;
  const columns = gridData.gridProps.columns.concat([]);
  const columnsMemo = useMemo(() => columns, [columns]);
  const headerButtons = useFileChangesHeaderButtons({ gridData });
  const headerButtonsMemo = useMemo(() => headerButtons, [headerButtons]);

  return (
    <DataGrid
      {...gridProps}
      headerButtons={headerButtonsMemo}
      getRowHeight={() => "auto"}
      columns={columnsMemo}
      rowsPerPageOptions={[10, 30, 50]}
      showOrderColumn
      showColumnSettings={false}
      gridKey="conclusionFileHistories"
    />
  );
}
