import { useConfirm } from "components/Confirm";
import { formCloseHandler } from "components/Form/formCloseHandler";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { Formik } from "formik";
import { DrawerForm } from "components/Form";
import { MultipleOpoAppealFromFileValues } from "../../types";
import { CreateMultipleOpoAppealFileUploadFormValidate } from "./createMultipleOpoAppealFileUploadFormValidate";
import { MultipleOpoAppealFileUploadForm } from "./MultipleOpoAppealFileUploadForm";
import React, { FC, useState } from "react";
import { SelectOrganization } from "../SelectOrganization/SelectOrganization";
import { paths } from "router/paths";
import { useNavigate } from "react-router";
import { useCreateMultipleAppealOposFromFileMutation } from "generated/hooks";
import { useToasts } from "components/Toast";
import { getFileInputDataFromValues } from "./getFileInputDataFromValues";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";

interface MultipleOpoAppealFileUploadDrawerProps {
  onCancel: () => void;
}

export const MultipleOpoAppealFileUploadDrawer: FC<
  MultipleOpoAppealFileUploadDrawerProps
> = ({ onCancel }) => {
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);
  const { addOkConfirm } = useConfirm();
  const { addSuccessToast, addErrorsToast, addErrorToast } = useToasts();
  const navigate = useNavigate();

  const initialValues: MultipleOpoAppealFromFileValues = {
    appealOpoFile: undefined,
    appealOpoFileText: undefined,
    organizationInfo: {},
  };

  const [createMultipleAppealOposFromFileMutation] =
    useCreateMultipleAppealOposFromFileMutation();

  const submitFormFile = async (values: MultipleOpoAppealFromFileValues) => {
    const input = getFileInputDataFromValues(values);
    try {
      const { data } = await createMultipleAppealOposFromFileMutation({
        variables: {
          input: input,
        },
      });
      if (
        data?.createMultipleAppealOposFromFile &&
        data.createMultipleAppealOposFromFile?.errors
      ) {
        addErrorsToast(data.createMultipleAppealOposFromFile.errors);
        return;
      }
      if (
        data?.createMultipleAppealOposFromFile &&
        data.createMultipleAppealOposFromFile.appealOpo
      ) {
        addSuccessToast(
          `Создано заявок: ${data.createMultipleAppealOposFromFile.appealOpo.length}`
        );
        navigate(paths.clients());
        return;
      }
      addErrorToast("Что-то пошло не так...", "Ошибка");
    } catch {
      addErrorToast("Что-то пошло не так...", "Ошибка");
    }
  };

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: submitFormFile,
  });

  const handlePickOrganization = () => {
    setIsOrganizationModalOpen(true);
  };

  const closeHandler = async (values: MultipleOpoAppealFromFileValues) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: MultipleOpoAppealFromFileValues;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open
      onClose={() => closeHandler(values)}
    >
      <Formik<MultipleOpoAppealFromFileValues>
        onSubmit={handlePickOrganization}
        initialValues={initialValues}
        validate={CreateMultipleOpoAppealFileUploadFormValidate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(props.values)}
                title="Загрузка данных в формате таблицы"
                confirmButtonProps={{
                  disabled: !(props.isValid && props.dirty),
                  children: "Отправить",
                }}
                cancelButtonProps={{
                  onClick: () => closeHandler(props.values),
                }}
              >
                <MultipleOpoAppealFileUploadForm />
                {isOrganizationModalOpen && (
                  <SelectOrganization
                    onClose={async () => {
                      await props.setFieldValue("organizationInfo", {});
                      setIsOrganizationModalOpen(false);
                    }}
                    onSubmit={() => submitWithCspHandler(props.values)}
                  />
                )}
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
