import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const CheckListIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "16", height: "16", viewBox: "0 0 16 16", ...props, sx: {
            width: 16,
            height: 16,
            ...props.sx,
        } },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.5 0C1.67157 0 1 0.671573 1 1.5V13.5C1 14.3284 1.67157 15 2.5 15H4.75C5.16421 15 5.5 14.6642 5.5 14.25C5.5 13.8358 5.16421 13.5 4.75 13.5H3C2.72386 13.5 2.5 13.2761 2.5 13V2C2.5 1.72386 2.72386 1.5 3 1.5H11C11.2761 1.5 11.5 1.72386 11.5 2V9.25C11.5 9.66421 11.8358 10 12.25 10C12.6642 10 13 9.66421 13 9.25V1.5C13 0.671573 12.3284 0 11.5 0H2.5ZM4 4.75C4 4.33579 4.33579 4 4.75 4H9.25C9.66421 4 10 4.33579 10 4.75C10 5.16421 9.66421 5.5 9.25 5.5H4.75C4.33579 5.5 4 5.16421 4 4.75ZM4.75 7C4.33579 7 4 7.33579 4 7.75C4 8.16421 4.33579 8.5 4.75 8.5H6.75C7.16421 8.5 7.5 8.16421 7.5 7.75C7.5 7.33579 7.16421 7 6.75 7H4.75ZM15.7866 10.226C16.076 10.5223 16.0703 10.9972 15.774 11.2866L11.2171 15.7366C10.9229 16.0239 10.4521 16.0207 10.1618 15.7294L8.21873 13.7794C7.92636 13.486 7.9272 13.0111 8.22061 12.7187C8.51402 12.4264 8.98889 12.4272 9.28127 12.7206L10.7003 14.1447L14.726 10.2134C15.0223 9.92402 15.4972 9.92965 15.7866 10.226Z" })));
};
