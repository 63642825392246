import { useUserProfileQuery } from "generated/hooks";
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  CircularProgress,
  Label,
} from "@genome-frontend/uikit/build/lib/uikit";
import { locale } from "locale";
import CspCertificate from "components/CspCertificate/CspCertificate";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { FormatDate } from "utils/formatDate";
import { getSexName } from "utils/getSexName";
import { useSubmitCertificate } from "pages/ProfilePage/utils/useSubmitCertificate";

export const EmployeeProfileForm = () => {
  const { data, loading } = useUserProfileQuery();

  const { saving, submitCertificate } = useSubmitCertificate();

  if (!loading && !data) {
    return null;
  }

  return (
    <Box>
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Grid margin={10}>
            <Typography sx={{ mb: 2 }} variant="h6">
              Личная информация
            </Typography>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.surname}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.surname ?? "Иванов"}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.name}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.name ?? "Иван"}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.fathersName}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.fathersName}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.sex}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{getSexName(data?.userProfile.sex)}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.birthdate}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>
                  <FormatDate date={data?.userProfile?.birthdate} />
                </Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.snils}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.snils}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.phoneNumber}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.phoneNumber}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.email}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.email}</Typography>
              </Grid>
            </Grid>

            <Typography sx={{ mb: 2, mt: 5 }} variant="h6">
              Сведения о работе
            </Typography>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.workplace}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.organizationName}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.position}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.position}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.profile}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.userProfile.profileName}</Typography>
              </Grid>
            </Grid>

            <Typography sx={{ mb: 2, mt: 5 }} variant="h6">
              Привязка ЭЦП к профилю
            </Typography>
            {saving ? (
              <CircularProgress />
            ) : (
              <Grid container item>
                <Grid xs={3} item>
                  <CspCertificate
                    onSubmit={submitCertificate}
                    buttonText={"Привязать сертификат ЭЦП к профилю"}
                    details={false}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};
