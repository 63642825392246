import { useMemo } from "react";
import { useNavigate, Outlet } from "react-router";
import {
  DataGrid,
  GridEventListener,
  GridEvents,
} from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useClientsDataGrid } from "pages/ClientsPage/hooks/useClientsDataGrid/useClientsDataGrid";
import { useClientsHeaderButtons } from "pages/ClientsPage/hooks/useClientsDataGrid/useClientsHeaderButtons";
import { paths } from "router/paths";
import { useClientsColumns } from "pages/ClientsPage/hooks/useClientsColumns";
import { useAuth } from "auth/useAuth";
import { AccessPermissions } from "types/AccessPermissions";

export function ClientsGrid() {
  const gridData = useClientsDataGrid();
  const { gridProps } = gridData;
  const columns = useClientsColumns(gridData);

  const { hasAccessPermission } = useAuth();

  const columnsWithSwitch = columns.map((col: any) => {
    return col;
  });

  const headerButtons = useClientsHeaderButtons(gridData);

  const navigate = useNavigate();

  const handleRowDoubleToViewClick: GridEventListener<GridEvents.rowClick> = ({
    row,
  }) => {
    navigate(paths.client(row.id));
  };

  const handleRowDoubleToEditClick: GridEventListener<GridEvents.rowClick> = ({
    row,
  }) => {
    navigate(paths.clientEdit(row.id));
  };

  const headerButtonsMemo = useMemo(() => headerButtons, [headerButtons]);

  return (
    <>
      <DataGrid
        {...gridProps}
        headerButtons={
          hasAccessPermission(AccessPermissions.ClientsCreate)
            ? headerButtonsMemo
            : undefined
        }
        onRowDoubleClick={
          hasAccessPermission(AccessPermissions.ClientsUpdate)
            ? handleRowDoubleToEditClick
            : handleRowDoubleToViewClick
        }
        columns={columnsWithSwitch}
        rowsPerPageOptions={[10, 30, 50]}
        showColumnSettings={false}
        showOrderColumn
        gridKey="clients"
      />
      <Outlet />
    </>
  );
}
