import * as yup from "yup";
import { locale } from "locale";

export const createMultipleOpoAppealDataGridFormValidationSchema = yup
  .object()
  .shape({
    values: yup.array().of(
      yup.object().shape({
        employeePosition: yup.string().required(`${locale.REQUIRED}`),
        department: yup.string().optional(),
        okved: yup.object().shape({
          id: yup.number().required(),
          name: yup.string().required(`${locale.REQUIRED}`),
        }),
        opoWorkType: yup.object().shape({
          id: yup.number().required(),
          name: yup.string().required(`${locale.REQUIRED}`),
        }),
        userId: yup
          .string()
          .required(`${locale.REQUIRED}`)
          .uuid(`${locale.REQUIRED}`),
        requestDate: yup.date().required(`${locale.REQUIRED}`),
      })
    ),
  });
