import { Modal, ModalContent } from "@genome-frontend/uikit/build/lib/uikit";
import { FC } from "react";
import { useFormikContext } from "formik";
import { MultipleOpoAppealValues } from "../../types";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { CreateMultipleOpoAppealDataGridFormValidate } from "../CreateMultipleAppealOpo/createMultipleOpoAppealDataGridFormValidate";
import { Box } from "@mui/material";
import { TextInputField } from "components/formFields/TextInputField";

export interface InputFileNameProps {
  onClose: () => void;
  onSubmit: () => void;
}

export const InputFileName: FC<InputFileNameProps> = ({
  onClose,
  onSubmit,
}) => {
  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
  } = useFormikContext<MultipleOpoAppealValues>();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: CreateMultipleOpoAppealDataGridFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalContent
        title="Введите название шаблона"
        confirmButtonProps={{
          children: "Сохранить",
          disabled: !values.fileName || !values.fileName.length,
          onClick: onSubmit,
        }}
        cancelButtonProps={{ onClick: onClose }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TextInputField name={`fileName`} required />
        </Box>
      </ModalContent>
    </Modal>
  );
};
