import { CreateAppealOpoValues } from "../../types";

export const getInitialValues = (): CreateAppealOpoValues => {
  return {
    employeePosition: "",
    department: undefined,
    okved: {
      id: 0,
      name: "",
    },
    opoWorkType: {
      id: 0,
      name: "",
    },
    organizationInfo: {
      id: 0,
      organizationName: "",
    },
    userId: "",
    requestDate: new Date(),
    deleted: false,
  };
};
