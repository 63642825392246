import { AppealCreate } from "pages/AppealPage/components/AppealCreate/AppealCreate";
import { TAppealsByAdminGridProps } from "pages/AppealPage/components/AppealsGridByAdmin/AppealsGridByAdmin";
import React, { ReactNode } from "react";

export const useAppealsGridByAdminHeaderButtons = ({
  clientId,
  employeeId,
}: TAppealsByAdminGridProps): ReactNode[] => {
  return [<AppealCreate clientId={clientId} employeeId={employeeId} />];
};
