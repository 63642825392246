import {
  Button,
  Grid,
  Modal,
  ModalContent,
} from "@genome-frontend/uikit/build/lib/uikit";
import { TRadioGroupOption } from "@genome-frontend/uikit/build/lib/uikit/RadioButton";
import "date-fns";
import { useConclusionAppealQuery } from "generated/hooks";
import {
  ConclusionAppealQuery,
  FinalAppealConclusionType,
  RestrictionType,
} from "generated/operations";
import { useState } from "react";

export const radioOptionsConclusion: TRadioGroupOption[] = [
  { id: FinalAppealConclusionType.GOODIN, caption: "ДА" },
  { id: FinalAppealConclusionType.UNFIT, caption: "НЕТ" },
];

export const radioOptionsRestriction: TRadioGroupOption[] = [
  { id: RestrictionType.NONE, caption: "Нет" },
  {
    id: RestrictionType.TEMPORARILY_WORK,
    caption: "Временно допущен к труду",
  },
  {
    id: RestrictionType.NOT_TEMPORARILY_WORK,
    caption: "Временно не допущен к труду",
  },
];

interface FinalConclusionProps {
  appealId: number;
  roleName: string;
  createConclusionButtonText: string;
  saveAllowed: boolean;
  validateCreate: (data: ConclusionAppealQuery | undefined) => boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

export const FinalConclusion: React.FC<FinalConclusionProps> = ({
  appealId,
  roleName,
  createConclusionButtonText,
  saveAllowed,
  children,
  validateCreate,
  onClose,
  onSubmit,
}) => {
  const conclusionData = useConclusionAppealQuery({
    variables: {
      id: appealId,
      roleName: roleName,
      skip: null,
      take: null,
      order: null,
      where: null,
    },
  });

  const createConclusionStatus = validateCreate(conclusionData.data);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const handleSubmit = async () => {
    await onSubmit();
    handleClose();
  };

  return (
    <>
      <Grid container sx={{ mt: 1 }}>
        <Grid xs={4} item>
          <Button
            variant="contained"
            onClick={handleOpen}
            disabled={!createConclusionStatus}
          >
            {createConclusionButtonText}
          </Button>
        </Grid>
      </Grid>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContent
          title="Создание заключения"
          confirmButtonProps={{
            children: "Подтвердить",
            onClick: handleSubmit,
            disabled: !saveAllowed,
          }}
          cancelButtonProps={{ onClick: handleClose }}
        >
          {children}
        </ModalContent>
      </Modal>
    </>
  );
};
