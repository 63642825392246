import { OrganizationValues } from "../types";
import { CreateOrganizationInput } from "generated/operations";
import { formatDate } from "utils/formatDate";

export const getCreateOrganizationInputDataFromValues = (
  values: OrganizationValues
): CreateOrganizationInput => {
  return {
    name: values.name ?? "",
    address: values.address ?? "",
    inn: values.inn ?? "",
    ogrn: values.ogrn ?? "",
    kpp: values.kpp ?? "",
    licenseNumber: values.licenseNumber ?? "",
    licenseDate: values.licenseDate
      ? formatDate(values.licenseDate, "server")
      : "",
    licenseAuthority: values.licenseAuthority ?? "",
    phone: values.phone ?? "",
    email: values.email ?? "",
    organizationCode: values.organizationCode ?? "",
    republicCode: values.republicCode ?? "",
    licenseFile: {
      file: values.licenseFile ?? "",
      fileName: values.licenseFileText ?? "",
    },
    printFile: {
      file: values.printFile ?? "",
      fileName: values.printFileText ?? "",
    },
    mainOkvedId: values.mainOkved?.id ?? 0,
  };
};
