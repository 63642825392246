import React, { FC } from "react";
import { Grid, Label } from "@genome-frontend/uikit/build/lib/uikit";
import { useFormikContext } from "formik";
import { locale } from "locale";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { SelectOkved } from "components/Selectors/SelectOkved";
import { CreateAppealOpoValues } from "../../types";
import { CreateAppealOpoFormValidate } from "./CreateAppealOpoFormValidate";
import { SelectOpoWorkType } from "components/Selectors/SelectOpoWorkType";
import { SelectOrganizationInfo } from "components/Selectors/SelectOrganizationInfo";

interface CreateAppealOpoFormProps {
  appealOpo?: CreateAppealOpoValues;
}

export const CreateAppealOpoForm: FC<CreateAppealOpoFormProps> = () => {
  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
  } = useFormikContext<CreateAppealOpoValues>();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: CreateAppealOpoFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <>
      <Grid alignItems="flex-start" spacing={2} container>
        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="department"
            label={locale.appealOpo.department}
            fullWidth
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="employeePosition"
            label={locale.appealOpo.employeePosition}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "223px",
              },
            }}
          />
        </Grid>

        <Grid mt={2} xs={12} item>
          <Label required>{locale.appealOpo.okved + ":"}</Label>
          <SelectOkved
            validationFieldContext={validationFieldContext}
            name="okved"
          />
        </Grid>

        <Grid mt={2} xs={12} item>
          <Label required>{locale.appealOpo.workType + ":"}</Label>
          <SelectOpoWorkType
            validationFieldContext={validationFieldContext}
            name="opoWorkType"
          />
        </Grid>

        <Grid mt={2} xs={12} item>
          <Label required>{locale.appealOpo.organizationInfo + ":"}</Label>
          <SelectOrganizationInfo
            validationFieldContext={validationFieldContext}
            name="organizationInfo"
          />
        </Grid>
      </Grid>
    </>
  );
};
