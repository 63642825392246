import React from "react";
import { useSetCommissionSizeMutation } from "generated/hooks";
import {
  Modal,
  ModalContent,
  Select,
} from "@genome-frontend/uikit/build/lib/uikit";
import { locale } from "locale";
import { useToasts } from "components/Toast";

interface SetCommissionSizeProps {
  appealId: number;
}

const validCommissionSizes: number[] = [3, 4, 5];

export const SetCommissionSizeModal: React.FC<SetCommissionSizeProps> = ({
  appealId,
}) => {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const [open, setOpen] = React.useState(true);
  const [size, setSize] = React.useState(validCommissionSizes[0]);
  const [mutate] = useSetCommissionSizeMutation();

  const setCommissionSize = async (commissionSize: number) => {
    const { data } = await mutate({
      variables: {
        input: {
          id: appealId,
          size: commissionSize,
        },
      },
    });
    const errors = data?.setCommissionSize.errors;

    if (errors) {
      return addErrorsToast(errors);
    }

    addSuccessToast(locale.appealOpo.commissionSizeSet);
    setOpen(false);
  };

  return (
    <Modal open={open}>
      <ModalContent
        title={locale.appealOpo.setCommissionSize}
        confirmButtonProps={{
          children: "Подтвердить",
          onClick: () => setCommissionSize(size),
        }}
        cancelButtonProps={{
          children: "Отмена",
          onClick: () => setOpen(false),
        }}
      >
        <Select
          value={size}
          options={validCommissionSizes.map((x) => ({ label: x, value: x }))}
          onChange={(e) => setSize(e.target.value as number)}
        />
      </ModalContent>
    </Modal>
  );
};
