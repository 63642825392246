import { GridRenderCellParams } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { ColumnTransformer } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { locale } from "locale";
import { renderCellWithTooltip } from "components/DataGrid/renderCellWithTooltip";

const fieldsOrder = [
  "dateChanged",
  "organizationName",
  "employeeFio",
  "clientFio",
  "prevValue",
  "currentValue",
].reverse();

const getHeaderName = (field: string): string => {
  return (locale.userChanges as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);

  switch (column.field) {
    case "dateChanged":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.trackedTime,
          tooltipTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.trackedTime,
        }),
        minWidth: 220,
      };
    case "organizationName":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        minWidth: 120,
        renderCell: renderCellWithTooltip(),
      };
    case "employeeFio":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        renderCell: renderCellWithTooltip(),
      };

    case "clientFio":
      return {
        ...column,
        headerName,
        sortable: true,
        renderCell: renderCellWithTooltip(),
      };
    case "currentValue":
      return {
        ...column,
        headerName,
        sortable: true,
        renderCell: renderCellWithTooltip(),
      };
    case "prevValue":
      return {
        ...column,
        headerName,
        sortable: true,
        renderCell: renderCellWithTooltip(),
      };
    default:
      return {
        ...column,
        headerName,
        renderCell: renderCellWithTooltip(),
      };
  }
};

export const userChangesColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
