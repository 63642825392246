import { TokenHelper } from "TokenHelper";

export const AuthorizationOpened = {
  getOpenedToken(roleName: string) {
    const authenticationURL =
      process.env.REACT_APP_AUTHORIZATION_PROXY_ENDPOINT_URL;

    if (!authenticationURL) {
      throw Error("REACT_APP_AUTHORIZATION_PROXY_ENDPOINT_URL empty");
    }

    const input = { roleName: roleName };

    const token = TokenHelper.getCurrentToken();

    return fetch(authenticationURL!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(input),
    });
  },

  async setAuthorizationOpened() {
    const response = await this.getOpenedToken(
      TokenHelper.getCurrentUserRole()!
    );

    if (response.status == 200) {
      const body = await response.json();
      const data = body as string;
      TokenHelper.setOpenedToken(data);
    }
  },
};
