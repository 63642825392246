import { Autocomplete } from "@genome-frontend/uikit/build/lib/uikit";
import { LazyQueryHookOptions } from "@apollo/client";
import { QueryResult } from "@apollo/client/react/types/types";
import { AutocompleteProps } from "@genome-frontend/uikit/build/lib/uikit/Autocomplete";
import { useCallback, useState } from "react";
import { debounce } from "lodash";

export type GqlAutocompleteOption = {
  id: number | undefined;
  caption: string | undefined;
};

export type DataTransformer<TData> = (data: TData) => GqlAutocompleteOption[];
export type VariablesTransformer<TVariables> = (search: string) => TVariables;

const getOptionLabel = (option: GqlAutocompleteOption) => option.caption ?? "";

export type GqlAutocompleteProps<
  TData,
  TVariables,
  Multiple extends boolean | undefined
> = {
  queryHook: (
    options?: Partial<LazyQueryHookOptions<TData, TVariables>>
  ) => Promise<QueryResult<TData, TVariables>>;
  dataTransformer: DataTransformer<TData>;
  variablesTransformer: VariablesTransformer<TVariables>;
} & Omit<
  AutocompleteProps<GqlAutocompleteOption, Multiple, false, false>,
  "options"
>;

export function GqlAutocomplete<
  TData,
  TVariables,
  Multiple extends boolean | undefined = false
>({
  queryHook,
  dataTransformer,
  variablesTransformer,
  ...props
}: GqlAutocompleteProps<TData, TVariables, Multiple>) {
  const [options, setOptions] = useState<GqlAutocompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const inputChangeHandler = useCallback(
    async (newInputValue: string) => {
      const variables = variablesTransformer(newInputValue);

      const { data, loading } = await queryHook({
        variables,
      });
      setIsLoading(loading);

      setOptions(data ? dataTransformer(data) : []);
    },
    [variablesTransformer, queryHook, dataTransformer]
  );

  return (
    <Autocomplete<GqlAutocompleteOption, Multiple, false, false>
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      onOpen={() => {
        inputChangeHandler("");
      }}
      loading={isLoading}
      getOptionLabel={getOptionLabel}
      onInputChange={debounce((elem, val) => inputChangeHandler(val), 500)}
      {...props}
    />
  );
}
