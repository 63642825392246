import { Box } from "@mui/material";
import { Header } from "components/Header/Header";

export function HomePage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Header />
      <p>Closed part main page</p>
    </Box>
  );
}
