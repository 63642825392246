import { useMemo } from "react";
import { useReportsHeaderButtons } from "pages/ReportsPage/hooks/useReportsDataGrid/useReportsHeaderButtons";
import { useReportsDataGrid } from "pages/ReportsPage/hooks/useReportsDataGrid/useReportsDataGrid";
import { TreeDataGrid } from "@genome-frontend/uikit/build/lib/uikit/TreeDataGrid/TreeDataGrid";
import { TokenHelper } from "TokenHelper";

export function ReportsGrid() {
  const roleName = TokenHelper.getCurrentUserRole();
  const gridData = useReportsDataGrid({
    variablesTransformer: (variables) => {
      variables.roleName = roleName!;
      return variables;
    },
  });

  const { gridProps } = gridData;
  const columns = gridData.gridProps.columns.concat([]);
  const columnsMemo = useMemo(() => columns, [columns]);
  const headerButtons = useReportsHeaderButtons({ gridData });
  const headerButtonsMemo = useMemo(() => headerButtons, [headerButtons]);

  return (
    <TreeDataGrid
      {...gridProps}
      columns={columnsMemo}
      headerButtons={headerButtonsMemo}
      disableColumnFilter={false}
      rowsPerPageOptions={[10, 30, 50]}
      showOrderColumn
      showColumnSettings={false}
      checkboxSelection={false}
      // @ts-ignore
      childrenListName={"documents"}
      gridKey="reports"
    />
  );
}
