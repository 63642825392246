import { MultipleOpoAppealFromFileValues } from "../../types";
import { CreateMultipleAppealOposFromFileInput } from "generated/operations";

export const getFileInputDataFromValues = (
  values: MultipleOpoAppealFromFileValues
): CreateMultipleAppealOposFromFileInput => {
  return {
    organizationInfoId: values.organizationInfo.id!,
    multipleAppealOpoFile: {
      file: values.appealOpoFile ?? "",
      fileName: values.appealOpoFileText ?? "",
    },
  };
};
