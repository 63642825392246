import React, { FC } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  Typography,
  Label,
  Grid,
} from "@genome-frontend/uikit/build/lib/uikit";
import { PencilOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PencilOutlineIcon";
import { locale } from "locale";
import { FormatDate } from "utils/formatDate";
import { useOrganizationQuery } from "generated/hooks";
import { FileDownloadLinkField } from "components/formFields/FileDownloadLinkField";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";

interface OrganizationViewDrawerProps {
  id: number;
  onClose: () => void;
  onError: () => void;
  onEdit: (organization: any) => void;
}

export const OrganizationViewDrawer: FC<OrganizationViewDrawerProps> = ({
  id,
  onClose,
  onError,
  onEdit,
}) => {
  const { data, loading } = useOrganizationQuery({
    variables: {
      id,
    },
    onError: () => {
      onError();
    },
  });

  const closeHandler = () => {
    onClose();
  };

  const editHandler = () => {
    if (data?.organization) {
      onEdit(data.organization);
    }
  };

  if (!loading && !data) {
    return null;
  }

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 920,
        },
      }}
      open
      onClose={closeHandler}
    >
      <DrawerContent
        onClose={closeHandler}
        title="Просмотр организации"
        isWithActions={false}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 1,
            borderBottom: "1px solid #DFE3E6",
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ ml: 1 }}
              startIcon={<PencilOutlineIcon />}
              onClick={editHandler}
            >
              {locale.EDIT}
            </Button>
          </Box>
        </Box>
        {loading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Label>{locale.organization.name}:</Label>
                <Typography>{data?.organization.name}</Typography>
              </Grid>

              <Grid xs={3} item>
                <Label>{locale.organization.republicCode}:</Label>
                <Typography>{data?.organization.republicCode}</Typography>
              </Grid>

              <Grid xs={9} item>
                <Label>{locale.organization.organizationCode}:</Label>
                <Typography>{data?.organization.organizationCode}</Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.address}:</Label>
                <Typography>{data?.organization.address}</Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.inn}:</Label>
                <Typography>{data?.organization.inn}</Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.ogrn}:</Label>
                <Typography>{data?.organization.ogrn}</Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.kpp}:</Label>
                <Typography>{data?.organization.kpp}</Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.licenseNumber}:</Label>
                <Typography>{data?.organization.licenseNumber}</Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.licenseFile}:</Label>
                <FileDownloadLinkField
                  file={data?.organization.licenseFile?.file}
                  fileName={data?.organization.licenseFile?.fileName}
                />
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.licenseDate}:</Label>
                <Typography>
                  <FormatDate date={data?.organization.licenseDate} />
                </Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.licenseAuthority}:</Label>
                <Typography>{data?.organization.licenseAuthority}</Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.printFile}:</Label>
                <FileDownloadLinkField
                  file={data?.organization.printFile?.file}
                  fileName={data?.organization.printFile?.fileName}
                />
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.phone}:</Label>
                <Typography>{data?.organization.phone}</Typography>
              </Grid>

              <Grid xs={12} item>
                <Label>{locale.organization.email}:</Label>
                <Typography>{data?.organization.email}</Typography>
              </Grid>

              <Grid xs={12} container item>
                <Grid xs={12} item>
                  <Label>{locale.organization.mainOkved}:</Label>
                </Grid>
                <Grid xs={2} item>
                  <Typography>{data?.organization.mainOkved.code}</Typography>
                </Grid>
                <Grid xs={10}>
                  <Typography>{data?.organization.mainOkved.name}</Typography>
                </Grid>
              </Grid>

              {!!data?.organization.additionalOkveds?.length && (
                <Grid xs={12} container item>
                  <Grid xs={12} item>
                    <Label>{locale.organization.additionalOkveds}:</Label>
                  </Grid>
                  {data.organization.additionalOkveds.map((okv, index) => {
                    return (
                      <Grid container item key={index}>
                        <Grid xs={2} item>
                          <Typography>{okv.code}</Typography>
                        </Grid>
                        <Grid xs={10} item sx={{ alignSelf: "flex-end" }}>
                          <Typography>{okv.name}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};
