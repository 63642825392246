import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "../../../../components/DataGrid";
import {
  OrganizationFragment,
  OrganizationsQuery,
  OrganizationsQueryVariables,
} from "../../../../generated/operations";
import { useOrganizationsQuery } from "../../../../generated/hooks";
import { dataTransformer } from "./utils/dataTransformer";
import { columnTransformer } from "./utils/columnTransformer";
import { sortingTransformer } from "../../../../utils/sortingTransformer";
import { whereTransformer } from "../../../../utils/whereTransformer";
export type TOrganizationsDataGridProps = Partial<
  UseGqlDataGridParams<
    OrganizationsQuery,
    OrganizationFragment,
    OrganizationsQueryVariables
  >
>;

export type TOrganizationsDataGridResult = UseGqlDataGridResult<
  OrganizationsQuery,
  OrganizationFragment,
  OrganizationsQueryVariables
>;

export const useOrganizationsDataGrid = (
  props?: TOrganizationsDataGridProps
): TOrganizationsDataGridResult => {
  const gridName = "organizations";
  const gridData = useGqlDataGrid<
    OrganizationsQuery,
    OrganizationFragment,
    OrganizationsQueryVariables
  >({
    type: gridName,
    queryHook: useOrganizationsQuery,
    dataTransformer,
    columnTransformer,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    selection: true,
    ...props,
  });
  return gridData;
};
