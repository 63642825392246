import { ClientQuery } from "generated/operations";
import { DateNull } from "types/NullableTypes";

const checkDateInRange = (
  beginRange: Date,
  endRange: Date,
  check: Date
): boolean => {
  return check >= beginRange && check <= endRange;
};

const checkDateRangeInRange = (
  beginRange: Date,
  endRange: Date,
  beginCheck: Date,
  endCheck: DateNull
): boolean => {
  if (endCheck === null) {
    return true;
  } else {
    // Целиком в диапазоне
    const all: boolean =
      checkDateInRange(beginRange, endRange, beginCheck) &&
      checkDateInRange(beginRange, endRange, endCheck!);

    // Пересечение начальной даты
    const begin: boolean =
      beginCheck <= beginRange &&
      checkDateInRange(beginRange, endRange, endCheck!);

    // Пересечение конечной даты
    const end: boolean =
      checkDateInRange(beginRange, endRange, beginCheck) &&
      endCheck! >= endRange;

    return all || begin || end;
  }
};

export const appealCreateValidate = (
  data: ClientQuery | undefined
): boolean => {
  if (
    data === undefined ||
    data.client.addresses === null ||
    data.client.addresses.length === 0
  )
    return false;

  const endRange: Date = new Date();
  const beginRange: Date = new Date();
  beginRange.setFullYear(beginRange.getFullYear() - 5);

  const addresses = data.client.addresses!;
  for (let index = 0; index < addresses.length; index++) {
    const address = addresses[index];

    const status: boolean = checkDateRangeInRange(
      beginRange,
      endRange,
      new Date(address.startDate),
      address.endDate !== null ? new Date(address.endDate) : null
    );

    if (status) {
      return true;
    }
  }

  return false;
};
