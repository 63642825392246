import { FC, useState } from "react";
import { Formik, FormikProps } from "formik";
import { getInputDataFromValues } from "./getInputDataFromValues";
import { useToasts } from "components/Toast";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { MultipleOpoAppealDataGridForm } from "./MultipleOpoAppealDataGridForm";
import { MultipleOpoAppealGridRow, MultipleOpoAppealValues } from "../../types";
import {
  useCreateMultipleAppealOposMutation,
  useCreateTemplateForMultipleAppealOposMutation,
} from "generated/hooks";
import { CreateMultipleOpoAppealDataGridFormValidate } from "./createMultipleOpoAppealDataGridFormValidate";
import { DrawerForm } from "components/Form";
import { DrawerContent } from "@genome-frontend/uikit/build/lib/uikit/Drawer/DrawerContent";
import { useNavigate } from "react-router";
import { paths } from "router/paths";
import { SelectOrganization } from "../SelectOrganization/SelectOrganization";
import { Box, Button } from "@genome-frontend/uikit/build/lib/uikit";
import { Typography } from "@mui/material";
import { InputFileName } from "../InputFileName/InputFileName";
import { getInputDataForTemplateFromValues } from "./getInputDataForTemplateFromValues";

interface MultipleOpoAppealDataGridDrawerProps {
  dataRows: MultipleOpoAppealGridRow[];
  onRowFieldChanged: (
    rowIndex: number,
    fieldName: string,
    fieldValue: any
  ) => void;
  onRowDeleted: (rowId: string) => void;
  onSubmitted: () => void;
}

const mapDataRowsToValues = (
  dataRows: MultipleOpoAppealGridRow[]
): MultipleOpoAppealValues => {
  return {
    values: dataRows.map((r) => {
      return {
        employeePosition: r.employeePosition,
        department: r.department,
        okved: r.okved,
        opoWorkType: r.opoWorkType,
        organizationInfo: {},
        userId: r.userId,
        requestDate: r.requestDate,
        deleted: false,
      };
    }),
    organizationInfo: {},
    fileName: "",
  };
};

export const MultipleOpoAppealDataGridDrawer: FC<
  MultipleOpoAppealDataGridDrawerProps
> = ({ dataRows, onRowFieldChanged, onRowDeleted, onSubmitted }) => {
  const initialValues = mapDataRowsToValues(dataRows);
  const { addSuccessToast, addErrorsToast } = useToasts();
  const navigate = useNavigate();
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);
  const [isFileNameModalOpen, setIsFileNameModalOpen] = useState(false);

  const [createMultipleAppealOpoMutation] =
    useCreateMultipleAppealOposMutation();

  const [createTemplateForMultipleAppealOposMutation] =
    useCreateTemplateForMultipleAppealOposMutation();

  const createMultipleAppealOpo = async (values: MultipleOpoAppealValues) => {
    const input = getInputDataFromValues(values);
    const data = await createMultipleAppealOpoMutation({
      variables: {
        input: input,
      },
    });

    if (data?.data?.createMultipleAppealOpos?.appealOpo) {
      addSuccessToast("Заявка создана");
      onSubmitted();
      navigate(paths.clients());
    } else {
      addErrorsToast(data?.data?.createMultipleAppealOpos?.errors);
    }
  };

  const createTemplateForMultipleAppealOpos = async (
    props: FormikProps<MultipleOpoAppealValues>
  ) => {
    const input = getInputDataForTemplateFromValues(props.values);
    const data = await createTemplateForMultipleAppealOposMutation({
      variables: {
        input: input,
      },
    });

    if (data?.data?.createTemplateForMultipleAppealOpos?.uUID) {
      addSuccessToast("Шаблон сохранен");
      await props.setFieldValue("fileName", "");
      setIsFileNameModalOpen(false);
    } else {
      addErrorsToast(data?.data?.createTemplateForMultipleAppealOpos?.errors);
    }
  };

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: createMultipleAppealOpo,
  });

  const handlePickOrganization = () => {
    setIsOrganizationModalOpen(true);
  };

  const handleSaveAsTemplate = () => {
    setIsFileNameModalOpen(true);
  };

  return (
    <>
      <Formik<MultipleOpoAppealValues>
        onSubmit={handlePickOrganization}
        initialValues={initialValues}
        validate={CreateMultipleOpoAppealDataGridFormValidate}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {(props) => {
          let isDisabled = !(
            props.isValid &&
            props.values.values.some((e) => !e.deleted) &&
            Object.keys(
              CreateMultipleOpoAppealDataGridFormValidate(props.values)
            ).length == 0
          );
          return (
            <DrawerForm onSubmit={props.handleSubmit}>
              <DrawerContent isWithActions={false}>
                <MultipleOpoAppealDataGridForm
                  rows={dataRows}
                  onRowFieldChanged={onRowFieldChanged}
                  onRowDeleted={onRowDeleted}
                />
                {isOrganizationModalOpen && (
                  <SelectOrganization
                    onClose={async () => {
                      await props.setFieldValue("organizationInfo", {});
                      setIsOrganizationModalOpen(false);
                    }}
                    onSubmit={() => submitWithCspHandler(props.values)}
                  />
                )}
                {isFileNameModalOpen && (
                  <InputFileName
                    onClose={async () => {
                      await props.setFieldValue("fileName", "");
                      setIsFileNameModalOpen(false);
                    }}
                    onSubmit={() => createTemplateForMultipleAppealOpos(props)}
                  />
                )}
              </DrawerContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #DFE3E6",
                  paddingY: 2,
                  marginX: 3,
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "#fff",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  onClick={handleSaveAsTemplate}
                  disabled={isDisabled}
                >
                  Сохранить список как шаблон
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {isDisabled && (
                    <Typography
                      sx={{
                        color: "#ff6666",
                      }}
                    >
                      * Необходимо заполнить все поля
                    </Typography>
                  )}
                  {!isDisabled && (
                    <Typography
                      sx={{
                        color: "#52c41a",
                      }}
                    >
                      * Все поля заполнены
                    </Typography>
                  )}
                  <Button
                    sx={{ ml: (theme) => theme.spacing(2) }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isDisabled}
                  >
                    Отправить
                  </Button>
                </Box>
              </Box>
            </DrawerForm>
          );
        }}
      </Formik>
    </>
  );
};
