import { FC, useEffect, useState } from "react";
import { Modal, ModalContent } from "@genome-frontend/uikit/build/lib/uikit";
import { useToasts } from "components/Toast";
import {
  useAppealOpoAgreementQuery,
  useSignAppealOpoAgreementMutation,
} from "generated/hooks";
import { namedOperations } from "generated/operations";

interface AppealAgreementModalProps {
  appealId: number;
}

export const AppealAgreementModal: FC<AppealAgreementModalProps> = ({
  appealId,
}) => {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const [open, setOpen] = useState(false);
  const [signAppealOpoAgreementMutation] = useSignAppealOpoAgreementMutation();
  const appealOpoAgreement = useAppealOpoAgreementQuery({
    variables: {
      id: appealId,
    },
  });

  useEffect(() => {
    if (
      appealOpoAgreement.data &&
      !appealOpoAgreement.loading &&
      !appealOpoAgreement.error
    ) {
      setOpen(!appealOpoAgreement.data.appealOpoById.agreement);
    }
  }, [appealOpoAgreement]);

  const handleIdsModalClose = () => {
    setOpen(false);
  };

  const signIdsHandler = async () => {
    try {
      const { data } = await signAppealOpoAgreementMutation({
        variables: {
          input: {
            appealOpoId: appealId,
          },
        },
        refetchQueries: [namedOperations.Query.AppealOpoAgreement],
      });

      if (data?.signAppealOpoAgreement?.int) {
        addSuccessToast("ИДС успешно подписан");
      } else {
        addErrorsToast(data?.signAppealOpoAgreement?.errors);
      }
    } catch (e: any) {
      addErrorsToast([{ message: e.message }]);
    } finally {
      handleIdsModalClose();
    }
  };

  return (
    <Modal open={open} onClose={handleIdsModalClose}>
      <ModalContent
        title="ИДС"
        confirmButtonProps={{
          children: "Подписать",
          onClick: signIdsHandler,
        }}
        cancelButtonProps={{ onClick: handleIdsModalClose }}
      >
        У пользователя не подписано индивидуальное добровольное согласие
      </ModalContent>
    </Modal>
  );
};
