import { EmployingOrganizationProfileViewFragment } from "generated/operations";
import React, { FC, useState } from "react";
import { Grid, Label } from "@genome-frontend/uikit/build/lib/uikit";
import {
  Okved,
  EmployingOrganizationValues,
} from "pages/OrganizationsPage/types";
import { FieldArray, useFormikContext } from "formik";
import { locale } from "locale";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { MaskValidationTextInput } from "components/formFields/MaskValidationTextInput";
import { MaskExpress } from "utils/maskExpression";
import { SelectOkved } from "components/Selectors/SelectOkved";
import { EmployingOrganizationEditFormValidate } from "./EmployingOrganizationProfileEditFormValidate";
import { Button, Typography } from "@mui/material";
import { useToasts } from "components/Toast";
import { TokenHelper } from "TokenHelper";
import { EmployingOrganizationProfileEditDrawer } from "../EmployingOrganizationProfileEditDrawer/EmployingOrganizationProfileEditDrawer";

const emptyOkved: Okved = {
  id: undefined,
  name: "",
};

const maxAdditionalOkvedsNumber = 10;

interface EmployingOrganizationEditFormProps {
  employingOrganization?: EmployingOrganizationProfileViewFragment;
  isSubmitting?: boolean;
}

export const EmployingOrganizationProfileEditForm: FC<
  EmployingOrganizationEditFormProps
> = ({ employingOrganization, isSubmitting }) => {
  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
  } = useFormikContext<EmployingOrganizationValues>();
  const [
    isOpenEmployingOrganizationProfileEditDrawer,
    setIsOpenEmployingOrganizationProfileEditDrawer,
  ] = useState(false);

  const { addErrorsToast } = useToasts();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: EmployingOrganizationEditFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <>
      <Grid alignItems="flex-start" spacing={2} container>
        <Grid xs={12} item>
          <Typography mt={2}>
            {locale.employingOrganization.delegateInfo}
          </Typography>
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="delagateName"
            label={locale.employingOrganization.surname}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="delagateSurname"
            label={locale.employingOrganization.name}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="delagateFathersName"
            label={locale.employingOrganization.fathersName}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="email"
            label={locale.employingOrganization.email}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "223px",
              },
            }}
          />
        </Grid>

        <Grid xs={12} item>
          <MaskValidationTextInput
            mask={MaskExpress.phone}
            required
            label={locale.employingOrganization.phoneNumber}
            name="phoneNumber"
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "223px",
              },
            }}
            {...validationFieldContext}
          />
        </Grid>

        <Grid xs={12} item>
          <Typography mt={2}>
            {locale.employingOrganization.organizationInfo}
          </Typography>
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="organizationName"
            label={locale.employingOrganization.organizationName}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="address"
            label={locale.employingOrganization.address}
            fullWidth
            required
          />
        </Grid>

        <Grid container item>
          <Grid xs={4} item>
            <Label>{locale.employingOrganization.profile}</Label>
          </Grid>
          <Grid xs={4} item>
            <Typography>{TokenHelper.getCurrentUserRole()}</Typography>
          </Grid>
        </Grid>

        <Grid xs={12} item>
          <Label required>{locale.employingOrganization.okved}</Label>
          <SelectOkved
            validationFieldContext={validationFieldContext}
            name="mainOkved"
          />
        </Grid>

        <Grid xs={12} item>
          <Label>{locale.employingOrganization.additionalOkveds}</Label>
        </Grid>
        <FieldArray name="additionalOkveds" validateOnChange>
          {
            // @ts-ignore
            ({ push, remove }) => {
              const onAddOkvedButtonClick = () => {
                if (
                  values.additionalOkveds!.length >= maxAdditionalOkvedsNumber
                ) {
                  addErrorsToast(
                    [
                      {
                        message: `Указано максимальное количество дополнительных ОКВЭД'ов: ${maxAdditionalOkvedsNumber}`,
                      },
                    ],
                    ""
                  );
                  return;
                }

                push(emptyOkved);
              };

              return (
                <React.Fragment>
                  {values.additionalOkveds!.map((okv, index) => (
                    <Grid container item key={index} spacing={2}>
                      <Grid item xs={10}>
                        <SelectOkved
                          validationFieldContext={validationFieldContext}
                          name={`additionalOkveds[${index}]`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button fullWidth onClick={() => remove(index)}>
                          Удалить
                        </Button>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid item>
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      onClick={onAddOkvedButtonClick}
                    >
                      Добавить ОКВЭД
                    </Button>
                  </Grid>
                </React.Fragment>
              );
            }
          }
        </FieldArray>

        <Grid container item>
          <Grid xs={4} item>
            <Label>{locale.employingOrganization.ogrn}</Label>
          </Grid>
          <Grid xs={4} item>
            <Typography>{values?.ogrn}</Typography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid xs={4} item>
            <Label>{locale.employingOrganization.inn}</Label>
          </Grid>
          <Grid xs={4} item>
            <Typography>{values?.inn}</Typography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid xs={4} item>
            <Label>{locale.employingOrganization.kpp}</Label>
          </Grid>
          <Grid xs={4} item>
            <Typography>{values?.kpp}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {isOpenEmployingOrganizationProfileEditDrawer && (
        <EmployingOrganizationProfileEditDrawer
          onSuccess={() => {
            setIsOpenEmployingOrganizationProfileEditDrawer(false);
          }}
          onCancel={() => {
            setIsOpenEmployingOrganizationProfileEditDrawer(false);
          }}
        />
      )}
    </>
  );
};
