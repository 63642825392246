import { DataTransformer } from "components/DataGrid";
import { RoleViewFragment, RolesQuery } from "generated/operations";

export const RoleDataTransformer: DataTransformer<
  RolesQuery,
  RoleViewFragment
> = (data) => {
  return {
    rows: (data?.employeeRoles?.items as RoleViewFragment[]) || [],
    rowCount: data?.employeeRoles?.totalCount || 0,
  };
};
