import { ColumnTransformer } from "components/DataGrid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { locale } from "locale";
import { AppealByAdminViewFragment } from "generated/operations";
import { FormatDate } from "utils/formatDate";
import { Label } from "@genome-frontend/uikit/build/lib/uikit";
import { LinkDownloadFile } from "components/LinkDownloadFile/LinkDownloadFile";

const fieldsOrder = ["name", "responseDate", "number", "isActual"].reverse();

const getHeaderName = (field: string): string => {
  return (locale.appealOpo as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);
  column.filterable = false;
  column.sortable = false;
  column.headerName = headerName;

  switch (column.field) {
    case "isActual":
      return {
        ...column,
        sortable: false,
        renderCell: ({
          value,
        }: GridRenderCellParams<string, AppealByAdminViewFragment>) => {
          if (Boolean(value)) return <img src="/onCheckbox.ico" />;
          return <img src="/offCheckbox.ico" />;
        },
      };
    case "name":
      return {
        ...column,
        flex: 2,
        renderCell: ({ value, row }: GridRenderCellParams<string>) => {
          if (row.id < 0) {
            if (row.conclusionFile !== null) {
              return (
                <Label>
                  <LinkDownloadFile
                    fileData={row.conclusionFile?.file!}
                    fileName={row.conclusionFile?.fileName!}
                    title={value!}
                  />
                </Label>
              );
            } else {
              return <Label>{value}</Label>;
            }
          } else {
            return <Label>{value}</Label>;
          }
        },
      };
    case "responseDate":
      return {
        ...column,
        renderCell: ({ value }) =>
          value !== undefined ? <FormatDate date={value} /> : value,
      };
    default:
      return {
        ...column,
      };
  }
};

export const appealOposGridByEmployingOrganizationColumnTransformer: ColumnTransformer =
  (columns) => {
    columns.push(
      {
        field: "number",
        flex: 1,
        headerName: "number",
        minWidth: 150,
      },
      {
        field: "name",
        flex: 1,
        headerName: "name",
        minWidth: 150,
      },
      {
        field: "responseDate",
        flex: 1,
        headerName: "responseDate",
        minWidth: 150,
      },
      {
        field: "isActual",
        flex: 1,
        headerName: "isActual",
        minWidth: 150,
      }
    );

    return columns
      .filter((column) => fieldsOrder.includes(column.field))
      .sort((left, right) => {
        return (
          fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field)
        );
      })
      .map((column) => transformColumn(column));
  };
