import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";

import { usePatientsQuery } from "generated/hooks";
import {
  PatientItemFragment,
  PatientsQuery,
  PatientsQueryVariables,
} from "generated/operations";
import { sortingTransformer } from "utils/sortingTransformer";
import { whereTransformer } from "utils/whereTransformer";
import { clientsColumnTransformer } from "./utils/clientsColumnTransformer";

export type TClientsDataGridProps = Partial<
  UseGqlDataGridParams<
    PatientsQuery,
    PatientItemFragment,
    PatientsQueryVariables
  >
>;

export type TClientsDataGridResult = UseGqlDataGridResult<
  PatientsQuery,
  PatientItemFragment,
  PatientsQueryVariables
>;

export const useEmployingOrganizationClientsDataGrid = (
  props?: TClientsDataGridProps
): TClientsDataGridResult => {
  const gridName = "employingOrganizationPatients";
  return useGqlDataGrid<
    PatientsQuery,
    PatientItemFragment,
    PatientsQueryVariables
  >({
    type: gridName,
    queryHook: usePatientsQuery,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    columnTransformer: clientsColumnTransformer,
    ...props,
  });
};
