import { DataTransformer } from "components/DataGrid";
import { OrganizationFragment, OrganizationsQuery } from "generated/operations";

export const dataTransformer: DataTransformer<
  OrganizationsQuery,
  OrganizationFragment
> = (data) => {
  return {
    rows: data?.organizations?.items ?? [],
    rowCount: data?.organizations?.totalCount ?? 0,
  };
};
