import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import {
  AppealOposQuery,
  AppealOposQueryVariables,
  AppealOpoViewFragment,
} from "generated/operations";
import { useAppealOposQuery } from "generated/hooks";
import { appealOposGridColumnTransformer } from "./utils/appealOposGridColumnTransformer";
import { GridSortModel } from "@mui/x-data-grid";
import { DeleteAppealOpoValues } from "pages/ClientsPage/types";

const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "desc" }];

export type TAppealOposDataGridProps = Partial<
  UseGqlDataGridParams<
    AppealOposQuery,
    AppealOpoViewFragment,
    AppealOposQueryVariables
  >
> & {
  deleteAppealOpo: (values: DeleteAppealOpoValues) => Promise<void>;
};

export type TAppealOposDataGridResult = UseGqlDataGridResult<
  AppealOposQuery,
  AppealOpoViewFragment,
  AppealOposQueryVariables
>;

export const useAppealOposDataGrid = (
  props?: TAppealOposDataGridProps
): TAppealOposDataGridResult => {
  const {
    deleteAppealOpo = async (values: DeleteAppealOpoValues) =>
      Promise.resolve(),
    ...restProps
  } = props || {};
  const gridData = useGqlDataGrid<
    AppealOposQuery,
    AppealOpoViewFragment,
    AppealOposQueryVariables
  >({
    type: "appealOpos",
    queryHook: useAppealOposQuery,
    columnTransformer: (columns) =>
      appealOposGridColumnTransformer(columns, deleteAppealOpo),
    selection: true,
    defaultSort: DEFAULT_SORT,
    ...restProps,
  });

  return gridData;
};
