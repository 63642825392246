import getColumnActions from "components/DataGrid/getColumnActions";
import { locale } from "locale";
import { TUsersDataGridResult } from "pages/UsersPage/hooks/useUsersDataGrid/useUsersDataGrid";
import { useNavigate } from "react-router";
import { paths } from "router/paths";
import { UserRole } from "types/UserRole";
import { useUserRemove } from "./useUserRemove";

export const useUsersColumns = (gridData: TUsersDataGridResult) => {
  const navigate = useNavigate();

  const { removeUser } = useUserRemove();

  const columnActions = getColumnActions({
    columnActions: [
      {
        label: locale.EDIT,
        onClick: (params) => {
          if (params.row.roleName == UserRole.User) {
            navigate(paths.clientEdit(params.row.id));
          } else {
            navigate(paths.userEdit(params.row.id));
          }
        },
      },
      {
        label: locale.REMOVE,
        onClick: (params) => {
          removeUser(params.row.id);
        },
      },
    ],
  });

  return gridData.gridProps.columns;
};
