import { MultipleOpoAppealValues } from "../../types";
import { CreateMultipleAppealOposInput } from "generated/operations";
import { getInputDataFromValues as getInputSingle } from "pages/ClientsPage/components/CreateAppealOpo/getInputDataFromValues";

export const getInputDataFromValues = (
  data: MultipleOpoAppealValues
): CreateMultipleAppealOposInput => {
  return {
    inputs: data.values
      .filter((e) => !e.deleted)
      .map((v) => {
        let input = getInputSingle(v, v.userId);
        input.organizationInfoId = data.organizationInfo.id!;
        return input;
      }),
  };
};
