import React, { FC } from "react";
import { Grid, Label } from "@genome-frontend/uikit/build/lib/uikit";
import { ClientViewFragment } from "generated/operations";
import { ClientUpdateValue } from "pages/ClientsPage/types";
import { useFormikContext } from "formik";
import { locale } from "locale";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { ValidationDatePickerField } from "components/formFields/ValidationDatePickerField";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { MaskValidationTextInput } from "components/formFields/MaskValidationTextInput";
import { ClientCreateFromValidate } from "../ClientCreateDrawer/ClientCreateFromValidate";
import { MaskExpress } from "utils/maskExpression";
import { ValidationSelectField } from "components/formFields/ValidationSelectedField";
import { sexOptions } from "constants/selectFieldConsts";

interface ClientFormProps {
  user?: ClientViewFragment;
}

export const ClientCreateForm: FC<ClientFormProps> = ({ user }) => {
  const { setFieldValue, touched, errors, setFieldError, setFieldTouched } =
    useFormikContext<ClientUpdateValue>();

  const validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: ClientCreateFromValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <Grid alignItems="flex-start" container spacing={2}>
      <Grid container item>
        <Grid xs={4} item>
          <Label>
            <h3>Персональные данные</h3>
          </Label>
        </Grid>
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.surname}
          name="surname"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.name}
          name="name"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.fathersName}
          name="fathersName"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationSelectField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.sex}
          options={sexOptions}
          placeholder="Выберите значение"
          name="sex"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationDatePickerField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.birthdate}
          name="birthdate"
        />
      </Grid>
      <Grid xs={4} item>
        <MaskValidationTextInput
          mask={MaskExpress.snils}
          required
          fullWidth
          label={locale.user.snils}
          name="snils"
          {...validationFieldContext}
        />
      </Grid>
      <Grid xs={4} item>
        <MaskValidationTextInput
          mask={MaskExpress.phone}
          required
          fullWidth
          label={locale.user.phoneNumber}
          name="phoneNumber"
          {...validationFieldContext}
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label="Email"
          name="email"
        />
      </Grid>
    </Grid>
  );
};
