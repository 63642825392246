import { TOrganizationsDataGridResult } from "./useOrganizationsDataGrid/useOrganizationsDataGrid";
import { ReactNode } from "react";
import { useNavigate } from "react-router";
import { Button } from "@genome-frontend/uikit/build/lib/uikit";
import { PlusOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PlusOutlineIcon";
import { paths } from "router/paths";

export const useOrganizationsHeaderButtons = ({
  ids,
  setIds,
}: TOrganizationsDataGridResult): ReactNode[] => {
  const navigate = useNavigate();

  return [
    ...(ids.length > 0
      ? [
          <Button
            key="showAll"
            onClick={() => {
              setIds([]);
            }}
          >
            Показать всё
          </Button>,
        ]
      : []),
    ...[],
    <Button
      key="add"
      startIcon={<PlusOutlineIcon />}
      onClick={() => {
        navigate(paths.organizationNew());
      }}
      variant="contained"
    >
      Добавить организацию
    </Button>,
  ];
};
