import { GridFilterItem } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import {
  BooleanOperationFilterInput,
  ComparableDateOnlyOperationFilterInput,
  ComparableInt32OperationFilterInput,
  ListBooleanOperationFilterInput,
  ListComparableDateOnlyOperationFilterInput,
  StringOperationFilterInput,
} from "generated/operations";
import { FieldInfo, FilterInputValue } from "./types";
import { formatDate } from "utils/formatDate";
import { FilterListType } from "types/filterGraphQLTypes";

export const getNumberFilterValue = ({
  operatorValue,
  value,
}: GridFilterItem): ComparableInt32OperationFilterInput | null => {
  if (value === "" || value === undefined) {
    return null;
  }
  const formattedValue = Number(value);
  switch (operatorValue) {
    case "=":
      return {
        eq: formattedValue,
      };
    case "!=":
      return {
        nin: [formattedValue],
      };
    case ">":
      return {
        gt: formattedValue,
      };
    case "<":
      return {
        lt: formattedValue,
      };
    case ">=":
      return {
        gte: formattedValue,
      };
    case "<=":
      return {
        lte: formattedValue,
      };
    default:
      return {
        eq: formattedValue,
      };
  }
};

export const getBooleanFilterValue = ({
  operatorValue,
  value,
}: GridFilterItem): BooleanOperationFilterInput | null => {
  if (!value && value !== false) {
    return null;
  }

  if (operatorValue === "equals") {
    return {
      eq: value,
    };
  } else {
    return {
      neq: value,
    };
  }
};

export const getStringFilterValue = ({
  operatorValue,
  value,
}: GridFilterItem): StringOperationFilterInput | null => {
  if (!value) {
    return null;
  }
  switch (operatorValue) {
    case "contains":
      return {
        contains: value,
      };
    case "equals":
      return {
        eq: value,
      };
    case "startsWith":
      return {
        startsWith: value,
      };
    case "endsWith":
      return {
        endsWith: value,
      };
    case "in":
      return {
        in: value,
      };
    default:
      return {
        contains: value,
      };
  }
};

export const getDateTimeFilterValue = ({
  operatorValue,
  value,
}: GridFilterItem): ComparableDateOnlyOperationFilterInput | null => {
  if (!value) {
    return null;
  }

  const formattedValue = formatDate(value, "server");

  switch (operatorValue) {
    case "is":
      return {
        eq: formattedValue,
      };
    case "not":
      return {
        nin: [formattedValue],
      };
    case "after":
      return {
        gt: formattedValue,
      };
    case "onOrAfter":
      return {
        gte: formattedValue,
      };
    case "before":
      return {
        lt: formattedValue,
      };
    case "onOrBefore":
      return {
        lte: formattedValue,
      };
    default:
      return {
        eq: formattedValue,
      };
  }
};

export const getListDateTimeFilterValue = ({
  operatorValue,
  value,
}: GridFilterItem): ListComparableDateOnlyOperationFilterInput | null => {
  if (!value) {
    return null;
  }

  const result: ListComparableDateOnlyOperationFilterInput = {};

  switch (operatorValue) {
    case FilterListType.Any: {
      return (result.any = value);
    }
    case FilterListType.All: {
      result.all = {
        eq: value,
      };
      return result;
    }
    case FilterListType.Some: {
      result.some = {
        eq: value,
      };
      return result;
    }
    case FilterListType.None: {
      result.none = {
        eq: value,
      };
      return result;
    }
  }

  return result;
};

export const getListBooleanFilterValue = ({
  operatorValue,
  value,
}: GridFilterItem): ListBooleanOperationFilterInput | null => {
  if (value === null || value === undefined) {
    return null;
  }

  const result: ListBooleanOperationFilterInput = {};

  switch (operatorValue) {
    case FilterListType.Any: {
      return (result.any = value);
    }
    case FilterListType.All: {
      result.all = {
        eq: value,
      };
      return result;
    }
    case FilterListType.Some: {
      result.some = {
        eq: value,
      };
      return result;
    }
    case FilterListType.None: {
      result.none = {
        eq: value,
      };
      return result;
    }
  }

  return result;
};

export const getFilterValueByColumn = (
  filter: GridFilterItem,
  fieldInfo: FieldInfo
): FilterInputValue | undefined => {
  switch (fieldInfo.filterType) {
    case "ComparableInt32OperationFilterInput":
      return getNumberFilterValue(filter);
    case "ComparableDateOnlyOperationFilterInput":
    case "ComparableDateTimeOperationFilterInput":
    case "ComparableNullableOfDateOnlyOperationFilterInput":
      return getDateTimeFilterValue(filter);
    case "BooleanOperationFilterInput":
      return getBooleanFilterValue(filter);

    case "ListComparableDateOnlyOperationFilterInput":
      return getListDateTimeFilterValue(filter);

    case "ListBooleanOperationFilterInput":
      return getListBooleanFilterValue(filter);

    case "StringOperationFilterInput":
    default:
      return getStringFilterValue(filter);
  }
};

export const getDynamicFilterValue = (
  filter: GridFilterItem
): FilterInputValue | undefined => {
  return getStringFilterValue(filter);
};
