import * as yup from "yup";
import { RegExpress } from "utils/regularExpression";
import { locale } from "locale";
import { ValidationError } from "yup";

export const editValidationSchema = (isOrganizationAdmin: boolean) =>
  yup.object().shape({
    name: yup.string().required(`${locale.REQUIRED}`),
    surname: yup.string().required(`${locale.REQUIRED}`),
    fathersName: yup.string().required(`${locale.REQUIRED}`),
    email: yup.string().required(`${locale.REQUIRED}`).email(`${locale.EMAIL}`),
    birthdate: yup
      .date()
      .required(`${locale.REQUIRED}`)
      .min(new Date(1900, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
      .max(new Date(2100, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
      .typeError(`${locale.INVALID_DATE_FORMAT}`),
    phoneNumber: yup
      .string()
      .required(`${locale.REQUIRED}`)
      .matches(RegExpress.phone, `${locale.PHONE}`),
    snils: yup
      .string()
      .required(`${locale.REQUIRED}`)
      .matches(RegExpress.shils, `${locale.SNILS}`),

    profileIds: yup.lazy((val) =>
      Array.isArray(val)
        ? yup
            .array()
            .nullable(true)
            .min(1, locale.REQUIRED)
            .required(`${locale.REQUIRED}`)
        : yup.number().min(1).required(`${locale.REQUIRED}`)
    ),

    sex: yup.string().required(`${locale.REQUIRED}`),
    roleName: yup.string().required(`${locale.REQUIRED}`),
    position: yup.string().required(`${locale.REQUIRED}`),
    ...(!isOrganizationAdmin
      ? {
          password: yup.string().required(`${locale.REQUIRED}`),
        }
      : {}),
    organization: yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(`${locale.REQUIRED}`),
    }),
  });
