import { Dispatch, useContext } from "react";
import {
  doFriendlyCustomCertsList,
  EXPIRED_CSP_CERTIFICATE,
  signBase64string,
} from "utils/certificates";
import { expired } from "types/cspCertificateInfo";
import { UtcDateNow } from "utils/timeExtensions";
import { AuthHelper } from "AuthHelper";
import { OK } from "constants/responseCodes";
import { SuccessAuthResponse } from "types/SuccessAuthResponse";
import { TokenHelper } from "TokenHelper";
import { AuthContext, AuthStateEnum } from "auth/AuthContextProvider";
import { AuthorizationOpened } from "AuthorizationOpened";
import { paths } from "router/paths";
import { useNavigate } from "react-router";

type TSubmitWithCspProps = {
  displayCspErrorModal: (type: string) => void;
};

type TSubmitWithCspResult = {
  submitWithCsp: (
    thumbprint: string,
    setSubmitLoading: Dispatch<boolean>
  ) => void;
};

export const useSubmitWithCsp = ({
  displayCspErrorModal,
}: TSubmitWithCspProps): TSubmitWithCspResult => {
  const { setState } = useContext(AuthContext);
  const navigate = useNavigate();

  const submitWithCsp = (
    thumbprint: string,
    setSubmitLoading: Dispatch<boolean>
  ): void => {
    setSubmitLoading(true);
    doFriendlyCustomCertsList()
      .then(async (x) => {
        const certificateInfo = x.find((c) => c.thumbprint === thumbprint);
        if (!expired(certificateInfo)) {
          const cspSignature = await signBase64string(
            certificateInfo!.thumbprint,
            UtcDateNow()
          );
          localStorage.setItem("thumbprint", thumbprint);

          try {
            const response = await AuthHelper.sendFetchRequest(
              new URLSearchParams({
                scope: "offline_access",
                grant_type: "csp",
                csp_signature: cspSignature,
              })
            );
            if (response.status === OK) {
              const jsonData = await response.json();
              const userInfo = jsonData as SuccessAuthResponse;
              if (userInfo.access_token && userInfo.refresh_token) {
                TokenHelper.setTokenExpirationOffset(
                  Math.floor(userInfo.expires_in / 3)
                );
                TokenHelper.setAuthorizationToken(userInfo.access_token);
                TokenHelper.setRefreshToken(userInfo.refresh_token);
                setState(AuthStateEnum.AUTHORIZED);
                AuthorizationOpened.setAuthorizationOpened();
                navigate(paths.profile());
              }
              setSubmitLoading(false);
            } else {
              setState(AuthStateEnum.UNAUTHORIZED);
              displayCspErrorModal(await response.text());
              setSubmitLoading(false);
            }
          } catch (e) {
            setState(AuthStateEnum.UNAUTHORIZED);
            displayCspErrorModal("");
            setSubmitLoading(false);
          }
        } else {
          setState(AuthStateEnum.UNAUTHORIZED);
          displayCspErrorModal(EXPIRED_CSP_CERTIFICATE);
          setSubmitLoading(false);
        }
      })
      .catch(() => {
        setSubmitLoading(false);
      });
  };

  return {
    submitWithCsp: submitWithCsp,
  };
};
