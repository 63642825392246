import { EmployingOrganizationValues } from "../types";

export const getEmployingOrganizationInitialValues =
  (): EmployingOrganizationValues => {
    return {
      id: "",
      organizationName: "",
      address: "",
      delagateName: "",
      delagateSurname: "",
      delagateFathersName: "",
      inn: "",
      ogrn: "",
      kpp: "",
      phoneNumber: "",
      email: "",
      mainOkved: { id: undefined, name: "" },
      additionalOkveds: [],
    };
  };
