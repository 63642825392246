import { Outlet } from "react-router";
import React, { FC, useMemo } from "react";
import { TreeDataGrid } from "@genome-frontend/uikit/build/lib/uikit/TreeDataGrid/TreeDataGrid";
import { useAppealsByAdminDataGrid } from "pages/AppealPage/hooks/useAppealsGridByAdmin/useAppealsGridByAdmin";
import { useAppealsGridByAdminHeaderButtons } from "pages/AppealPage/hooks/useAppealsGridByAdmin/useAppealsGridByAdminHeaderButtons";

export interface TAppealsByAdminGridProps {
  clientId: string;
  employeeId: string;
}

export const AppealsGridByAdmin: FC<TAppealsByAdminGridProps> = ({
  clientId,
  employeeId,
}) => {
  const gridData = useAppealsByAdminDataGrid({
    variablesTransformer: (variables) => {
      variables.id = clientId;
      return variables;
    },
  });
  const columns = gridData.gridProps.columns.concat([]);
  const columnsMemo = useMemo(() => columns, [columns]);

  const headerButtons = useAppealsGridByAdminHeaderButtons({
    clientId,
    employeeId,
  });
  const headerButtonsMemo = useMemo(() => headerButtons, [headerButtons]);

  const { gridProps } = gridData;

  return (
    <>
      <TreeDataGrid
        {...gridProps}
        headerButtons={headerButtonsMemo}
        columns={columnsMemo}
        disableColumnFilter={true}
        rowsPerPageOptions={[10, 30, 50]}
        showOrderColumn
        showColumnSettings={false}
        checkboxSelection={false}
        // @ts-ignore
        childrenListName={"documents"}
      />
      <Outlet />
    </>
  );
};
