import { ColumnTransformer } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { locale } from "locale";

const fieldsOrder = ["name"];

const getHeaderName = (field: string): string => {
  return (
    (locale.multipleAppealOpoTemplate as { [key: string]: string })[field] ??
    field
  );
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);
  switch (column.field) {
    default:
      return {
        ...column,
        headerName,
      };
  }
};

export const multipleAppealOpoTemplatesColumnTransformer: ColumnTransformer = (
  columns
) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
