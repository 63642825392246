import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { MultipleOpoAppealValues } from "../../types";
import { createMultipleOpoAppealDataGridFormValidationSchema } from "../../utils/createMultipleOpoAppealDataGridFormValidationSchema";

export const CreateMultipleOpoAppealDataGridFormValidate = (
  values: MultipleOpoAppealValues
): FormikErrors<MultipleOpoAppealValues> => {
  let errors: FormikErrors<MultipleOpoAppealValues> = {};

  try {
    validateYupSchema(
      values,
      createMultipleOpoAppealDataGridFormValidationSchema,
      true,
      values
    );
  } catch (err) {
    errors = yupToFormErrors(err);
    return errors;
  }

  return errors;
};
