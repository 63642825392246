import { FC } from "react";
import { Box, Grid } from "@genome-frontend/uikit/build/lib/uikit";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import { ConclusionDoctorCreate } from "../ConclusionDoctor";
import { ConclusionTherapistCreate } from "../ConclusionTherapist";
import { AppealViewGrid } from "../AppealViewGrid/AppealViewGrid";
import { useAppealViewDataGrid } from "pages/AppealPage/hooks/useAppealViewDataGrid/useAppealViewDataGrid";
import { ClinicalPsychologistAppointmentButton } from "../ClinicalPsychologistAppointment/ClinicalPsychologistAppointmentButton";
import { AppealsName } from "../../utils/AppealsName";
import { OpoConclusionDoctorDrawer } from "../OpoConclusionDoctor/OpoConclusionDoctorDrawer";
import {
  useAppealOpoClinicalPsychologistAppointmentQuery,
  useAppealOpoCommissionSizeQuery,
  useAppealOpoQuery,
} from "generated/hooks";
import { CommissionConclusion } from "../CommissionConclusion/CommissionConclusion";

interface AppealViewProps {
  clientId: string;
  appealId: number;
  appealName: string;
}

const roleName = TokenHelper.getCurrentUserRole();
const employeeId = TokenHelper.getCurrentUserId();
const organizationId = TokenHelper.getOrganizationId();
const isDoctor = roleName === UserRole.Doctor;
const isTherapist = roleName === UserRole.Therapist;
const isPsychiatrist = TokenHelper.checkIsPsychiatristProfile();
const isComissionHead = TokenHelper.checkIsComissionHeadProfile();

export const AppealView: FC<AppealViewProps> = ({
  clientId,
  appealId,
  appealName,
}) => {
  const gridData = useAppealViewDataGrid({
    variablesTransformer: (variables) => {
      variables.id = appealId;
      variables.roleName = roleName!;
      return variables;
    },
  });

  const appealTypeIsOpo = appealName === AppealsName.Opo;

  const appealOpoData = useAppealOpoQuery({
    variables: { id: appealId },
    skip: !appealTypeIsOpo,
  });

  const commissionSize = useAppealOpoCommissionSizeQuery({
    variables: { id: appealId },
    skip: !appealTypeIsOpo,
  });

  const {
    data: appointmentData,
    loading: appointmentLoading,
    refetch,
  } = useAppealOpoClinicalPsychologistAppointmentQuery({
    variables: { id: appealId },
    skip: !appealTypeIsOpo,
  });

  return (
    <>
      <Box className={"box_header"}>
        <Box className={"box_center"}></Box>
        <Box className={"box_center"}></Box>
      </Box>
      <Grid alignItems="flex-start" container spacing={2}>
        <Grid xs={12} item>
          {isDoctor && !appealTypeIsOpo && (
            <ConclusionDoctorCreate
              employeeId={employeeId!}
              organizationId={organizationId}
              appealId={appealId}
              roleName={roleName}
            />
          )}
          {isDoctor && appealTypeIsOpo && (
            <OpoConclusionDoctorDrawer
              employeeId={employeeId!}
              organizationId={organizationId}
              appealId={appealId}
              roleName={roleName}
              employeePosition={
                appealOpoData.data?.appealOpoById.employeePosition ?? ""
              }
              appealOrganizationId={
                appealOpoData.data?.appealOpoById.organizationId ?? -1
              }
              clinicalPsychologistAppointment={
                appointmentData?.appealOpoById
                  .clinicalPsychologistAppointment ?? false
              }
              showCommissionSizeModal={
                !commissionSize.loading &&
                !commissionSize.data?.appealOpoById.commissionSize &&
                !commissionSize?.error
              }
              commissionSize={
                commissionSize.data?.appealOpoById.commissionSize ?? -1
              }
            />
          )}
          {isTherapist && (
            <ConclusionTherapistCreate
              organizationId={organizationId}
              employeeId={employeeId!}
              appealId={appealId}
            />
          )}
          {isPsychiatrist && isComissionHead && appealName == AppealsName.Opo && (
            <>
              <CommissionConclusion
                appealId={appealId}
                organizationId={organizationId}
                employeeId={employeeId!}
                employeePosition={
                  appealOpoData.data?.appealOpoById.employeePosition ?? ""
                }
                appealOrganizationId={
                  appealOpoData.data?.appealOpoById.organizationId ?? -1
                }
                commissionSize={
                  commissionSize.data?.appealOpoById.commissionSize
                }
                clinicalPsychologistAppointment={
                  appointmentData?.appealOpoById
                    .clinicalPsychologistAppointment ?? false
                }
              />
              <ClinicalPsychologistAppointmentButton
                appealId={appealId}
                appealData={appointmentData}
                loading={appointmentLoading}
                organizationId={organizationId}
                appealOrganizationId={
                  appealOpoData.data?.appealOpoById.organizationId ?? -1
                }
                refetch={refetch}
              />
            </>
          )}
        </Grid>
      </Grid>
      <AppealViewGrid {...{ gridData }} />
    </>
  );
};
