import { MainLayout } from "../../components/MainLayout";
import { OrganizationsGrid } from "./Components/OrganizationsGrid/OrganizationsGrid";
import { EmployingOrganizationGrid } from "./Components/EmployingOrganizationsGrid/EmployingOrganizationGrid";

export function OrganizationsPage() {
  return (
    <MainLayout title="Организации">
      <OrganizationsGrid />
      <EmployingOrganizationGrid />
    </MainLayout>
  );
}
