import { useLoadConclusionMutation } from "generated/hooks";
import { Button, Tooltip } from "@mui/material";
import { ConclusionFileExt } from "constants/fileExtension";
import { namedOperations } from "generated/operations";
import { useToasts } from "components/Toast";
import "./LoadConclusionFile.css";
import { locale } from "locale";

export interface LoadConclusionFileProps {
  id: number;
  icon: React.ReactElement;
  title: string;
  message: string;
}

export function LoadConclusionFile({
  id,
  icon,
  title,
  message,
}: LoadConclusionFileProps) {
  const [loadConclusionMutation] = useLoadConclusionMutation({
    refetchQueries: [namedOperations.Query.ConclusionAppeal],
  });

  const { addSuccessToast, addErrorToast } = useToasts();

  const handlerLoadConclusionFile = async ({ target }: any) => {
    const file = target.files[0];
    if (file !== undefined) {
      var extension = file.type;
      if (extension !== "application/pdf") {
        addErrorToast(locale.conclusion.conclusionFileFormatLoad);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
          await loadConclusionMutation({
            variables: {
              input: {
                id: Math.abs(id),
                file: { file: reader.result?.toString(), fileName: file.name },
              },
            },
          });
          addSuccessToast(message);
        };
      }
    }
  };

  const styleInputClass: string = "input-conclusion";

  return (
    <Tooltip title={title}>
      <label>
        <input
          name={"conclusion"}
          accept={ConclusionFileExt}
          type="file"
          className={styleInputClass}
          onChange={handlerLoadConclusionFile}
        />
        {icon}
      </label>
    </Tooltip>
  );
}
