import { MaskExpress } from "utils/maskExpression";
import { ConfirmParams } from "../Confirm";

type TFormValues<TValues> = {
  values: TValues;
  initialValues: TValues | null;
  onCancel: () => void;
  addOkConfirm: (params: ConfirmParams) => void;
};

const maskPhone = (newVal: any) => {
  if ("phone" in newVal)
    newVal.phone = MaskExpress.ToPhoneNumber(newVal.phone!);
  if ("phoneNumber" in newVal)
    newVal.phoneNumber = MaskExpress.ToPhoneNumber(newVal.phoneNumber!);
};

export const formCloseHandler = <TValues>({
  values,
  initialValues,
  onCancel,
  addOkConfirm,
}: TFormValues<TValues>): void => {
  let newVal = { ...values };
  maskPhone(newVal);
  const oldValText = JSON.stringify(initialValues);
  const newValText = JSON.stringify(newVal);
  if (initialValues && oldValText === newValText) onCancel();
  else
    addOkConfirm({
      title: "Предупреждение",
      text: "Вы уверены, что не хотите сохранить изменения?",
      onClickOk: onCancel,
    });
};
