import { AutocompleteField } from "components/formFields/AutocompleteField";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { GqlAutocompleteOption } from "components/GqlAutocomplete";
import { useOrganizationsLazyQuery } from "generated/hooks";
import {
  OrganizationQuery,
  OrganizationsQuery,
  SortEnumType,
} from "generated/operations";

export type OrganizationSelectProps = {
  validationFieldContext: TValidationFieldContext;
  name: string;
};

export const getOrganizationCaption = (org: {
  id: string;
  name: string;
}): string => {
  return org ? org.name : "";
};

export const OrganizationSelect = ({
  validationFieldContext,
  name,
}: OrganizationSelectProps) => {
  const [organizationQuery] = useOrganizationsLazyQuery();

  const dataTransformer = (data: OrganizationsQuery) => {
    return (
      data?.organizations?.items?.map((org) => ({
        id: org.id,
        caption: org.name,
      })) ?? []
    );
  };

  const variablesTransformer = (search: string) => {
    return {
      skip: null,
      take: 10,
      order: {
        id: SortEnumType.DESC,
      },
      where: {
        name: {
          contains: search,
        },
      },
    };
  };

  const optionTransformer = (option: GqlAutocompleteOption | null) => {
    return {
      id: option?.id,
      name: option?.caption,
    };
  };

  return (
    <>
      <AutocompleteField
        queryHook={organizationQuery}
        dataTransformer={dataTransformer}
        selectedCaptionTransformer={getOrganizationCaption}
        variablesTransformer={variablesTransformer}
        name={name}
        validationFieldContext={validationFieldContext}
        optionTransformer={optionTransformer}
      />
    </>
  );
};
