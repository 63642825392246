import { TValidationFieldContext } from "./base/ValidationFieldsContext";
import { useState } from "react";
import { TTextInputFieldProps } from "./TextInputField";
import { locale } from "locale";
import { FileValidation } from "../FileValidation";

export type TValidationFileField = {
  validationFieldContext: TValidationFieldContext;
  uploadButtonText: string;
  textFieldName: string;
  fileFieldName: string;
  label: string;
  acceptableExtensions: string;
  acceptableMimeTypes: string[];
  maxSize: number;
} & TTextInputFieldProps;

export const ValidationFileField = ({
  validationFieldContext,
  uploadButtonText,
  textFieldName,
  fileFieldName,
  label,
  acceptableExtensions,
  acceptableMimeTypes,
  maxSize,
  ...props
}: TValidationFileField) => {
  const [, setSelectedFile] = useState<File>();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const validateSingleField = (value?: string) => {
    const error = validationFieldContext.formValidationFunc({
      [textFieldName]: value,
    }) as any;
    validationFieldContext.setFieldError(textFieldName, error[textFieldName]);
  };

  const validateFileExtension = (extension: string): boolean => {
    if (!acceptableMimeTypes.includes(extension)) {
      validationFieldContext.setFieldError(
        textFieldName,
        `${locale.FILE_EXTENSION} ${acceptableExtensions}`
      );

      return false;
    }

    return true;
  };

  const validateFileSize = (value: number): boolean => {
    if (value >= maxSize * 1024 * 1024) {
      validationFieldContext.setFieldError(
        textFieldName,
        locale.FILE_SIZE + maxSize + "Mb."
      );

      return false;
    }
    return true;
  };

  const handleCapture = ({ target }: any) => {
    let file = target.files[0];
    if (
      file !== undefined &&
      validateFileSize(file.size) &&
      validateFileExtension(file.type)
    ) {
      setButtonDisabled(true);
      setSelectedFile(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        validationFieldContext.setFieldValue(fileFieldName, reader.result);
        validationFieldContext.setFieldValue(textFieldName, file.name);
        validateSingleField(file.name);
        validationFieldContext.setFieldTouched(textFieldName, true);
      };
    }
  };

  const setFieldValuesToNull = () => {
    validationFieldContext.setFieldValue(fileFieldName, null);
    validationFieldContext.setFieldValue(textFieldName, "");
    validateSingleField(undefined);
    setButtonDisabled(false);
  };

  const onClearClick = () => {
    setFieldValuesToNull();
    validationFieldContext.setFieldTouched(textFieldName, true);
  };

  const onFileInputClick = ({ target }: any) => {
    target.value = null;
    setFieldValuesToNull();
  };

  return (
    <FileValidation
      {...props}
      onClearClick={onClearClick}
      onFileInputClick={onFileInputClick}
      handleCapture={handleCapture}
      uploadButtonText={uploadButtonText}
      label={label}
      error={!!validationFieldContext.errors[textFieldName]}
      helperText={
        validationFieldContext.touched[textFieldName] &&
        validationFieldContext.errors[textFieldName]
      }
      fileFieldName={fileFieldName}
      textFieldName={textFieldName}
      acceptableExtensions={acceptableExtensions}
      isButtonDisabled={isButtonDisabled}
    />
  );
};
