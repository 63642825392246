import { Modal, ModalContent } from "@genome-frontend/uikit/build/lib/uikit";
import React, { FC } from "react";
import { SelectOrganizationInfo } from "components/Selectors/SelectOrganizationInfo";
import { useFormikContext } from "formik";
import { MultipleOpoAppealValues } from "../../types";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { CreateMultipleOpoAppealDataGridFormValidate } from "../CreateMultipleAppealOpo/createMultipleOpoAppealDataGridFormValidate";

export interface SelectOrganizationProps {
  onClose: () => void;
  onSubmit: () => void;
}

export const SelectOrganization: FC<SelectOrganizationProps> = ({
  onClose,
  onSubmit,
}) => {
  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
  } = useFormikContext<MultipleOpoAppealValues>();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: CreateMultipleOpoAppealDataGridFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalContent
        title="Выберите медицинскую организацию"
        confirmButtonProps={{
          children: "Выбрать",
          disabled:
            !values.organizationInfo ||
            !values.organizationInfo.id ||
            values.organizationInfo.id === 0,
          onClick: onSubmit,
        }}
        cancelButtonProps={{ onClick: onClose }}
      >
        <SelectOrganizationInfo
          name={"organizationInfo"}
          validationFieldContext={validationFieldContext}
        />
      </ModalContent>
    </Modal>
  );
};
