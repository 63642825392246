import { TokenHelper } from "../TokenHelper";
import { useToasts } from "../components/Toast";
import { ApiResult } from "../types/ApiResut";
import { useApolloClient } from "@apollo/client";

export interface ClosedServerPostRequestParams<TDataOutput> {
  endPointPath: string;
  successToast: string;
  failureToast: string;
  onSuccess: (response: TDataOutput) => void;
  refetchQueryName: string | undefined;
}

export interface UseSendPostRequestToClosedServerWithCsp<
  TDataInput,
  TDataOutput
> {
  sendPostRequestToClosedServer: (values: TDataInput) => Promise<TDataOutput>;
}

export function useSendPostRequestToClosedServer<TDataInput, TDataOutput>(
  params: ClosedServerPostRequestParams<TDataOutput>
): UseSendPostRequestToClosedServerWithCsp<TDataInput, TDataOutput> {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const apolloClient = useApolloClient();
  const baseApi = process.env.REACT_APP_CLOSED_LOCATION;
  const query = `/api/ClosedServer/SendPostRequestToClosedServer?closedEndPointPath=${params.endPointPath}`;
  const endApi = baseApi + query;

  const sendRequest = (data: TDataInput) =>
    fetch(endApi!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TokenHelper.getCurrentToken()}`,
        "X-OpenedToken": TokenHelper.getOpenedToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json() as Promise<ApiResult<TDataOutput>>;
      })
      .then(async (responseData) => {
        responseData =
          typeof responseData === "string"
            ? (JSON.parse(responseData) as ApiResult<TDataOutput>)
            : responseData;
        if (responseData.successful) {
          addSuccessToast(params.successToast);
          if (!!params.refetchQueryName) {
            await apolloClient.refetchQueries({
              include: [params.refetchQueryName],
            });
          }
          params.onSuccess(responseData.data);
        } else {
          addErrorsToast([{ message: responseData.errorMessage }]);
        }
        return responseData.data;
      })
      .catch((error) => {
        addErrorsToast([{ message: params.failureToast }]);
        return error;
      });

  return {
    sendPostRequestToClosedServer: sendRequest,
  };
}
