import { useNavigate } from "react-router";
import React, { FC } from "react";
import { paths } from "router/paths";
import { EmployingOrganizationProfileEditDrawer } from "./EmployingOrganizationProfileEditDrawer";

export const EmployingOrganizationProfileEditDrawerPage: FC = () => {
  const navigate = useNavigate();
  const closeHandler = () => {
    navigate(paths.profile());
  };

  const onSuccess = () => {
    navigate(paths.profile());
  };

  return (
    <EmployingOrganizationProfileEditDrawer
      onSuccess={onSuccess}
      onCancel={closeHandler}
    />
  );
};
