import { useLoadCommissionConclusionMutation } from "generated/hooks";
import { Tooltip } from "@mui/material";
import { ConclusionFileExt } from "constants/fileExtension";
import { namedOperations } from "generated/operations";
import { ReactElement } from "react";
import "./LoadConclusionFileCommission.css";
import { useToasts } from "components/Toast";
import { locale } from "locale";

export interface LoadConclusionFileProps {
  id: number;
  icon: ReactElement;
  title: string;
  message: string;
}

export function LoadConclusionFileCommission({
  id,
  icon,
  title,
  message,
}: LoadConclusionFileProps) {
  const [loadConclusionMutation] = useLoadCommissionConclusionMutation({
    refetchQueries: [namedOperations.Query.ConclusionAppeal],
  });

  const { addSuccessToast, addErrorToast, addErrorsToast } = useToasts();

  const handlerLoadConclusionFile = async ({ target }: any) => {
    const file = target.files[0];
    if (file !== undefined) {
      var extension = file.type;
      if (extension !== "application/pdf") {
        addErrorToast(locale.conclusion.conclusionFileFormatLoad);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
          const res = await loadConclusionMutation({
            variables: {
              input: {
                id: Math.abs(id),
                file: { file: reader.result?.toString(), fileName: file.name },
              },
            },
          });
          if (res.errors) {
            addErrorsToast(
              res.errors.map((e) => {
                return { message: e.message };
              })
            );
          } else if (res.data?.loadCommissionHeadConclusion.errors) {
            addErrorsToast(res.data.loadCommissionHeadConclusion.errors);
          } else {
            addSuccessToast(message);
          }
        };
      }
    }
  };

  const styleInputClass: string = "input-conclusion";

  return (
    <Tooltip title={title}>
      <label>
        <input
          name={"conclusion"}
          accept={ConclusionFileExt}
          type="file"
          className={styleInputClass}
          onChange={handlerLoadConclusionFile}
        />
        {icon}
      </label>
    </Tooltip>
  );
}
