import { expired } from "../types/cspCertificateInfo";
import { LazyQueryResult, QueryLazyOptions } from "@apollo/client";
import {
  CheckCspCertQuery,
  Exact,
  GetUserCspQuery,
} from "../generated/operations";
import {
  Certificate,
  createAttachedSignature,
  getCertificate,
  getSystemInfo,
  getUserCertificates,
} from "crypto-pro";
import { UtcDateNow } from "./timeExtensions";

export const NULL_CSP_CERTIFICATE = '"NULL_CSP_CERTIFICATE"';
export const EXPIRED_CSP_CERTIFICATE = '"EXPIRED_CSP_CERTIFICATE"';
export const CERTIFICATE_FAILED_VERIFICATION =
  '"CERTIFICATE_FAILED_VERIFICATION"';
export const CERTIFICATE_USER_NOT_FOUND = '"CERTIFICATE_USER_NOT_FOUND"';
export const CERTIFICATE_ACCESS_DENIED = '"CERTIFICATE_ACCESS_DENIED"';
export const DUPLICATE_CSP_CERTIFICATE = "DUPLICATE_CSP_CERTIFICATE";
export const PLUGIN_DOWNLOAD_LINK =
  "https://www.cryptopro.ru/products/cades/plugin/get_2_0";
export const CSP_DOWNLOAD_LINK =
  "https://www.cryptopro.ru/user?destination=node%2F148";
export const EXPIRED_CSP_CERTIFICATE_DESCRIPTION = (
  <div className={"CspModal"}>
    <p>
      У Вашего сертификата закончился срок действия, привяжите новый сертификат
      в личном кабинете.
    </p>
    <p>В дальнейшем Вы сможете осуществлять вход на сайт через ЭЦП.</p>
  </div>
);

export const CERTIFICATE_FAILED_VERIFICATION_DESCRIPTION = (
  <div className={"CspModal"}>
    <p>Произошла ошибка при проверке подписи сертификата.</p>
    <p>Проверьте корректность выбранного сертификата.</p>
  </div>
);

export const CERTIFICATE_USER_NOT_FOUND_DESCRIPTION = (
  <div className={"CspModal"}>
    <p>Не удалось авторизоваться.</p>
    <p>Не найден пользователь с указанной ЭЦП.</p>
  </div>
);

export const CERTIFICATE_ACCESS_DENIED_DESCRIPTION = (
  <div className={"CspModal"}>
    <p>Не удалось авторизоваться.</p>
    <p>Пользователь заблокирован.</p>
  </div>
);

export interface IBooleanResult {
  result: boolean;
  error?: string;
}

export const EXPIRED_CSP_CERTIFICATE_NOTIFICATION = {
  type: "error",
  message: "Срок действия данного сертификата истёк.",
  description: "Выберите актуальный сертификат и повторите попытку",
};

export const CLIENT_NULL_CSP_CERTIFICATE_DESCRIPTION = {
  type: "error",
  message: "Сертификат не привязан к аккаунту.",
  description:
    "Пожалуйста, авторизируйтесь через логин/пароль и привяжите сертификат на странице профиля.",
};

export const NULL_CSP_CERTIFICATE_DESCRIPTION = (
  <div className={"CspModal"}>
    <p>
      Пожалуйста, авторизируйтесь через логин/пароль и добавьте сертификат в
      профиле.
    </p>
    <p>В дальнейшем Вы сможете осуществлять вход на сайт через ЭЦП.</p>
  </div>
);

//для фильтрации через асинхронные функции
export const asyncFilter = async (arr: any, callback: any): Promise<any> => {
  const fail = Symbol();
  return (
    await Promise.all(
      arr.map(async (item: any) => ((await callback(item)) ? item : fail))
    )
  ).filter((i) => i !== fail);
};

//проверяет, что выбранный сертификат совместим с Crypto Pro
export const isCertificateValid = async (thumbprint: string): Promise<any> => {
  const certificate = await getCertificate(thumbprint);
  return certificate.isValid();
};

//возвращает список доступных сертификатов совместимых с Crypto Pro
export const doFriendlyCustomCertsList = async (): Promise<Certificate[]> => {
  try {
    let certsList = await getUserCertificates();
    certsList = await asyncFilter(certsList, async (check: any) => {
      return isCertificateValid(check.thumbprint);
    });

    return certsList;
  } catch (e: any) {
    return [];
  }
};

//возвращает версию приложения Crypto Pro CSP
export const getCspVersion = async (): Promise<string | null> => {
  try {
    const about = await getSystemInfo();
    const versions = about.cspVersion;
    return `${versions}`;
  } catch (e: any) {
    return null;
  }
};

//возвращает подпись строки выбранным сертификатом
export const signBase64string = async (
  thumbprint: string,
  message: string
): Promise<string> => {
  return createAttachedSignature(thumbprint, message);
};

export const fetchToUserCsp = async (
  loadUserCsp: (
    options?: QueryLazyOptions<Exact<{ [key: string]: never }>> | undefined
  ) => Promise<
    LazyQueryResult<GetUserCspQuery, Exact<{ [key: string]: never }>>
  >
) => {
  const cspData = await loadUserCsp();
  if (!cspData || !cspData.data || !cspData.data.actualUserCsp.thumbprint) {
    return null;
  }
  localStorage.setItem(
    "thumbprint",
    cspData.data.actualUserCsp.thumbprint.value
  );
  return cspData.data.actualUserCsp.thumbprint.value;
};

export const checkCspByPassword = async (
  thumbprint: string | null,
  checkCsp: (
    options?: QueryLazyOptions<Exact<{ cspSignature: string }>> | undefined
  ) => Promise<
    LazyQueryResult<CheckCspCertQuery, Exact<{ cspSignature: string }>>
  >
): Promise<IBooleanResult> => {
  try {
    const listCert = await doFriendlyCustomCertsList();
    const certificateInfo = listCert.find((c) => c.thumbprint === thumbprint);
    if (!expired(certificateInfo)) {
      const cspSignature = await signBase64string(
        certificateInfo!.thumbprint,
        UtcDateNow()
      );
      const result = await checkCsp({
        variables: { cspSignature: cspSignature },
      });
      if (!result.data) {
        return {
          result: false,
          error: "Ошибка проверки ЭЦП",
        };
      }
      return {
        result: result.data.checkCspCert,
        error: CLIENT_NULL_CSP_CERTIFICATE_DESCRIPTION.message,
      };
    } else {
      return {
        result: false,
        error: EXPIRED_CSP_CERTIFICATE_NOTIFICATION.message,
      };
    }
  } catch (e) {
    return {
      result: false,
      error: "Ошибка подтверждения по ЭЦП",
    };
  }
};

//возвращает версию расширения CryptoPro для браузера
export const getPluginVersion = async (): Promise<string | null> => {
  try {
    const about = await getSystemInfo();
    return about.cadesVersion;
  } catch (e: any) {
    return null;
  }
};
