import { locale } from "locale";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ColumnTransformer } from "components/DataGrid";
import { OrganizationFragment } from "generated/operations";
import { Link } from "@mui/material";
import { paths } from "router/paths";
import { TokenHelper } from "TokenHelper";
import { AccessPermissions } from "types/AccessPermissions";

const getHeaderName = (field: string): string => {
  return (locale.organization as { [key: string]: string })[field] ?? field;
};

const fieldsOrder = ["name", "address"].reverse();

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);

  switch (column.field) {
    case "name":
      return {
        ...column,
        headerName,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, OrganizationFragment>) => {
          const organizationPath = TokenHelper.checkAccessPermission(
            AccessPermissions.OrganizationsUpdate
          )
            ? paths.organizationEdit(row.id)
            : paths.organization(row.id);
          return <Link href={organizationPath}>{value}</Link>;
        },
      };
    default:
      return {
        ...column,
        headerName,
      };
  }
};

export const columnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
