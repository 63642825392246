import { useToasts } from "components/Toast";
import { useCallback } from "react";
import { namedOperations } from "generated/operations";
import { useUpdateTemplateForMultipleAppealOposMutation } from "generated/hooks";
import { locale } from "locale";

type TProps = {
  onSuccess?: () => void;
};

export const useRenameMultipleAppealOpoTemplate = ({
  onSuccess,
}: TProps = {}): {
  renameTemplate: (id: number, newName: string) => void;
} => {
  const { addErrorsToast } = useToasts();
  const [renameTemplateMutation] =
    useUpdateTemplateForMultipleAppealOposMutation({
      refetchQueries: [namedOperations.Query.MultipleAppealOpoTemplates],
    });

  const renameTemplate = useCallback(
    async (id: number, newName: string) => {
      const renameData = await renameTemplateMutation({
        variables: {
          input: { input: { id: id, newName: newName } },
        },
      });
      const errors = renameData.data?.updateMultipleAppealOposTemplate.errors;
      if (errors) {
        addErrorsToast(errors);
        return;
      }
      onSuccess?.();
    },
    [renameTemplateMutation, addErrorsToast, onSuccess]
  );
  return { renameTemplate: renameTemplate };
};
