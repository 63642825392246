import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";

import { useGetClientsHistoryAsyncQuery } from "generated/hooks";
import {
  GetClientsHistoryAsyncQuery,
  GetClientsHistoryAsyncQueryVariables,
  HistoryItemFragment,
} from "generated/operations";
import { sortingTransformer } from "utils/sortingTransformer";
import { whereTransformer } from "utils/whereTransformer";
import { userChangesColumnTransformer } from "./utils/userChangesColumnTransformer";
import { GridSortModel } from "@mui/x-data-grid";

const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "desc" }];

export type THistoriesDataGridProps = Partial<
  UseGqlDataGridParams<
    GetClientsHistoryAsyncQuery,
    HistoryItemFragment,
    GetClientsHistoryAsyncQueryVariables
  >
>;

export type THistoriesDataGridResult = UseGqlDataGridResult<
  GetClientsHistoryAsyncQuery,
  HistoryItemFragment,
  GetClientsHistoryAsyncQueryVariables
>;

export const useUserChangesDataGrid = (
  props?: THistoriesDataGridProps
): THistoriesDataGridResult => {
  const dynamicColumns = new Array<string>();
  const gridName = "clientsHistory";

  const result = useGqlDataGrid<
    GetClientsHistoryAsyncQuery,
    HistoryItemFragment,
    GetClientsHistoryAsyncQueryVariables
  >({
    type: gridName,
    queryHook: useGetClientsHistoryAsyncQuery,
    defaultSort: DEFAULT_SORT,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    columnTransformer: userChangesColumnTransformer,
    dynamicColumns,
    ...props,
  });

  return result;
};
