import React, { FC } from "react";
import {
  Button,
  Checkbox,
  Modal,
  ModalContent,
} from "@genome-frontend/uikit/build/lib/uikit";
import {
  useActiveAppealTypesQuery,
  useAppealsQuery,
  useAppealTypesQuery,
  useClientQuery,
} from "generated/hooks";
import { appealCreateValidate } from "./AppealCreateValidate";
import { useCreateAppealWithCsp } from "../../hooks/useCreateAppealWithCsp";

interface AppealCreateProps {
  clientId: string;
  employeeId: string;
}

export const AppealCreate: FC<AppealCreateProps> = ({
  clientId,
  employeeId,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const appealTypes =
    useActiveAppealTypesQuery().data?.activeAppealTypes?.items ?? [];
  const [checkedTypes, setType] = React.useState<number[]>([]);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setType([]);
  };

  const clientData = useClientQuery({
    variables: {
      id: clientId,
    },
  });

  const isCreateAppeal: boolean = appealCreateValidate(clientData.data);

  const { createAppealWithCsp } = useCreateAppealWithCsp({
    checkedTypes,
    clientId,
    employeeId,
  });

  const AppealCreateHelperFunction = () => {
    createAppealWithCsp(null);
    handleClose();
  };

  return (
    <>
      <Button onClick={handleClickOpen}>{"Заявка на услугу"}</Button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContent
          title="Выберите услугу"
          confirmButtonProps={{
            children: "Создать",
            disabled: !checkedTypes.length || !isCreateAppeal,
            onClick: AppealCreateHelperFunction,
          }}
          cancelButtonProps={{ onClick: handleClose }}
        >
          {appealTypes.map((type, index) => (
            <Checkbox
              key={index}
              label={type.name}
              onChange={(event) => {
                if (event.target.checked) {
                  setType([...checkedTypes, type.id]);
                } else {
                  setType(checkedTypes.filter((t) => t != type.id));
                }
              }}
            />
          ))}
        </ModalContent>
      </Modal>
    </>
  );
};
