import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { createMultipleOpoAppealFromFileFormValidationSchema } from "../../utils/createMultipleOpoAppealFromFileFormValidationSchema";
import { MultipleOpoAppealFromFileValues } from "../../types";

export const CreateMultipleOpoAppealFileUploadFormValidate = (
  values: MultipleOpoAppealFromFileValues
): FormikErrors<MultipleOpoAppealFromFileValues> => {
  let errors: FormikErrors<MultipleOpoAppealFromFileValues> = {};

  try {
    validateYupSchema(
      values,
      createMultipleOpoAppealFromFileFormValidationSchema,
      true,
      values
    );
  } catch (err) {
    errors = yupToFormErrors(err);
  }

  return errors;
};
