export const organization: { [key: string]: string } = {
  name: "Название",
  address: "Адрес",
  inn: "ИНН",
  ogrn: "ОГРН",
  kpp: "КПП",
  licenseNumber: "№ Лицензии",
  licenseDate: "Дата получения лицензии",
  licenseAuthority: "Орган, выдавший лицензию",
  phone: "Телефон",
  email: "E-mail",
  republicCode: "Код республики",
  organizationCode: "Код организации",
  licenseFile: "Файл лицензии",
  printFile: "Файл печати",
  mainOkved: "Основной ОКВЭД",
  additionalOkveds: "Дополнительные ОКВЭД'ы",
};
