import { MainLayout } from "components/MainLayout";
import { useEffect, useState } from "react";
import { Box, Button } from "@genome-frontend/uikit/build/lib/uikit";
import { SelectUserDrawer } from "./components/SelectUsersDrawer/SelectUsersDrawer";
import {
  MultipleAppealOpoTemplateFragment,
  PatientItemFragment,
} from "generated/operations";
import { MultipleOpoAppealDataGridDrawer } from "./components/CreateMultipleAppealOpo/MultipleOpoAppealDataGridDrawer";
import { Outlet } from "react-router";
import { useSearchParams } from "react-router-dom";
import { MultipleOpoAppealFileUploadDrawer } from "./components/MultipleOpoAppealFileUpload/MultipleOpoAppealFileUploadDrawer";
import { MultipleOpoAppealGridRow } from "./types";
import { TokenHelper } from "TokenHelper";
import download from "downloadjs";
import { MultipleAppealOpoTemplateDrawer } from "./components/MultipleAppealOpoTemplate/MultipleAppealOpoTemplateDrawer";
import { useMultipleAppealOpoFromTemplateLazyQuery } from "../../generated/hooks";
import { useToasts } from "../../components/Toast";
import { OK } from "constants/responseCodes";

const pageKey = "MultipleAppealOpoPage";

const emptyOkved = {};
const emptyOpoWorkType = {};

const mapGridRowToPartialFragment = (
  gridRow: MultipleOpoAppealGridRow
): Pick<PatientItemFragment, "id" | "fio"> => {
  return {
    id: gridRow.userId,
    fio: gridRow.fio,
  };
};

const mapPartialPatientItemFragmentToGridRow = (
  fragment: Pick<PatientItemFragment, "id" | "fio">
): MultipleOpoAppealGridRow => {
  return {
    fio: fragment.fio,
    department: "",
    employeePosition: "",
    okved: emptyOkved,
    opoWorkType: emptyOpoWorkType,
    requestDate: new Date(),
    userId: fragment.id,
  };
};

const getMultipleOpoAppealGridInitialRows = (): MultipleOpoAppealGridRow[] => {
  const savedMultipleAppealGridRows = sessionStorage.getItem(pageKey);

  return savedMultipleAppealGridRows
    ? JSON.parse(savedMultipleAppealGridRows)
    : [];
};

export function MultipleAppealOpoPage() {
  const [isUserSelectorOpen, setIsUserSelectorOpen] = useState<boolean>(false);
  const [isFileUploaderOpen, setIsFileUploaderOpen] = useState<boolean>(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [multipleAppealGridRows, setMultipleAppealGridRows] = useState<
    MultipleOpoAppealGridRow[]
  >(getMultipleOpoAppealGridInitialRows());
  const [multipleAppealOpoFromTemplate] =
    useMultipleAppealOpoFromTemplateLazyQuery();
  const { addSuccessToast, addErrorsToast, addErrorToast } = useToasts();

  useEffect(() => {
    // нужно для того, чтобы после применения фильтров (страница в этом случае перезагружается)
    // форма с выбором пользователей открывалась автоматически
    const selectEmployeeFormStatus =
      searchParams.get("selectEmployeeFormOpened")?.toLowerCase() === "true";
    setIsUserSelectorOpen(selectEmployeeFormStatus);

    const selectMultipleOpoAppealTemplateModalStatus =
      searchParams
        .get("multipleOpoAppealTemplateModalOpened")
        ?.toLowerCase() === "true";
    setIsTemplateModalOpen(selectMultipleOpoAppealTemplateModalStatus);
  }, [searchParams]);

  const handleClickOpenPatientsSelector = () => {
    setSearchParams({ selectEmployeeFormOpened: "true" });
    setIsUserSelectorOpen(true);
  };

  const handleClickUploadAppealAsFile = () => {
    setIsFileUploaderOpen(true);
  };

  const handleClickDownloadXlsx = async () => {
    const baseApi = process.env.REACT_APP_CLOSED_LOCATION;
    const query = `/api/AppealOpoTemplate/DownloadAppealOpoTemplate`;
    const endApi = baseApi + query;

    const token = TokenHelper.getCurrentToken();
    const tokenOpened = TokenHelper.getOpenedToken();

    const response = await fetch(endApi!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "X-OpenedToken": tokenOpened,
      },
    });

    if (response.status !== OK) {
      return addErrorToast("Не удалось скачать файл");
    }

    const blob = await response.blob();
    download(blob, "Шаблон для массовой заявки на ОПО.xlsx");
  };

  const handleClickSelect = (
    items: Pick<PatientItemFragment, "id" | "fio">[]
  ) => {
    setIsUserSelectorOpen(false);
    setSearchParams();

    const mappedRows = items.map((item) =>
      mapPartialPatientItemFragmentToGridRow(item)
    );

    for (let i = 0; i < mappedRows.length; i++) {
      const oldRowIndex = multipleAppealGridRows.findIndex(
        (e) => e.userId == mappedRows[i].userId
      );
      if (oldRowIndex != -1) {
        mappedRows[i] = multipleAppealGridRows[oldRowIndex];
      }
    }

    setMultipleAppealGridRows(mappedRows);
    sessionStorage.setItem(pageKey, JSON.stringify(mappedRows));
  };

  const handleClickClose = () => {
    setIsUserSelectorOpen(false);
    setSearchParams();
  };

  const handleRowFieldChanged = (
    rowIndex: number,
    fieldName: string,
    fieldValue: any
  ) => {
    setMultipleAppealGridRows((oldRows) => {
      const changedRow = oldRows[rowIndex];
      const parsedFieldName = fieldName.split(".").pop();
      changedRow[parsedFieldName as keyof MultipleOpoAppealGridRow] =
        fieldValue;

      sessionStorage.setItem(pageKey, JSON.stringify(oldRows));

      return oldRows;
    });
  };

  const handleRowDeleted = (rowId: string) => {
    setMultipleAppealGridRows((oldRows) => {
      const newRows = oldRows.filter((r) => r.userId != rowId);

      sessionStorage.setItem(pageKey, JSON.stringify(newRows));

      return newRows;
    });
  };

  const handleSubmitted = () => {
    setMultipleAppealGridRows([]);
    sessionStorage.removeItem(pageKey);
  };

  const handleTemplateModalOpened = () => {
    setSearchParams({ multipleOpoAppealTemplateModalOpened: "true" });
    setIsTemplateModalOpen(true);
  };

  const handleTemplateModalClosed = () => {
    setSearchParams();
    setIsTemplateModalOpen(false);
  };

  const handleModalConfirmed = async (
    template: MultipleAppealOpoTemplateFragment
  ) => {
    const multipleAppealOpoResult = await multipleAppealOpoFromTemplate({
      variables: { templateFileId: template.templateFileId },
    });
    const multipleAppealOpoItems =
      multipleAppealOpoResult.data?.multipleAppealOposFromTemplate?.items;
    if (!multipleAppealOpoItems) {
      addErrorsToast([{ message: "Не удалось загрузить данные из шаблона" }]);
    } else {
      const gridRows = multipleAppealOpoItems.map((item) => {
        return {
          fio: item.fio,
          department: item.department,
          employeePosition: item.employeePosition,
          okved: item.okved,
          opoWorkType: item.opoWorkType,
          requestDate: new Date(),
          userId: item.userId,
        };
      });

      setMultipleAppealGridRows((oldRows) => {
        sessionStorage.setItem(pageKey, JSON.stringify(gridRows));

        return gridRows;
      });
    }
  };

  return (
    <MainLayout title="Массовая заявка на ОПО">
      <Box
        sx={{ paddingY: 3, display: "flex", justifyContent: "space-between" }}
      >
        <Box>
          <Button onClick={handleClickUploadAppealAsFile} variant={"contained"}>
            Загрузить список в формате таблицы
          </Button>
          <pre />
          <Button
            onClick={handleClickOpenPatientsSelector}
            variant={"contained"}
          >
            Добавить пользователей
          </Button>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Button
            sx={{ textTransform: "unset" }}
            onClick={handleClickDownloadXlsx}
            variant={"outlined"}
          >
            Скачать xlsx шаблон для списка
          </Button>
          <pre />
          <MultipleAppealOpoTemplateDrawer
            isModalOpen={isTemplateModalOpen}
            onModalOpened={handleTemplateModalOpened}
            onModalClosed={handleTemplateModalClosed}
            onModalConfirmed={handleModalConfirmed}
            sx={{ display: "inline-block" }}
          ></MultipleAppealOpoTemplateDrawer>
        </Box>
      </Box>
      {isUserSelectorOpen && (
        <SelectUserDrawer
          onSelect={handleClickSelect}
          onClose={handleClickClose}
          selectedUsers={multipleAppealGridRows.map((r) =>
            mapGridRowToPartialFragment(r)
          )}
        />
      )}
      {isFileUploaderOpen && (
        <MultipleOpoAppealFileUploadDrawer
          onCancel={() => setIsFileUploaderOpen(false)}
        />
      )}
      <MultipleOpoAppealDataGridDrawer
        dataRows={multipleAppealGridRows}
        onRowFieldChanged={handleRowFieldChanged}
        onRowDeleted={handleRowDeleted}
        onSubmitted={handleSubmitted}
      />
      <Outlet />
    </MainLayout>
  );
}
