import {
  Box,
  Grid,
  Drawer,
  DrawerContent,
} from "@genome-frontend/uikit/build/lib/uikit";
import { Breadcrumbs, Link } from "@mui/material";
import React, { FC } from "react";
import { paths } from "router/paths";
import { AppealOposGridByEmployingOrganization } from "../AppealOposGridByEmployingOrganization/AppealOposGridByEmployingOrganization";
import { useNavigate, useParams } from "react-router";
import { useClientQuery } from "generated/hooks";
import { getFio } from "utils/getFio";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { Outlet } from "react-router";
import { formatDate } from "utils/formatDate";

export const AppealOpoByEmployingOrganization: FC = () => {
  const params = useParams();

  const appealId = Number(params.appealId);
  const userId = String(params.id);
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate(paths.client(userId));
  };

  const { data, loading } = useClientQuery({
    variables: {
      id: userId,
    },
    onError: closeHandler,
  });
  const getUserLabel = (user: {
    name: string;
    surname: string;
    fathersName: string | null | undefined;
    birthdate: any;
    snils: string;
  }) => {
    return `${getFio(user, true)}, ${formatDate(user.birthdate)}, ${
      user.snils
    }`;
  };

  if (!loading && !data) {
    return null;
  }

  return (
    <>
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 1000,
          },
        }}
        open
        onClose={closeHandler}
      >
        <DrawerContent
          onClose={closeHandler}
          title={data?.client ? getFio(data.client, true) : ""}
          isWithActions={false}
        >
          {loading ? (
            <CenteredCircularProgress />
          ) : (
            <>
              <Grid xs={12} item>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" href={paths.clients()}>
                    К списку пользователей
                  </Link>
                  <Link underline="hover" href={paths.client(userId)}>
                    {data?.client && getUserLabel(data?.client)}
                  </Link>
                </Breadcrumbs>
              </Grid>

              <Box className={"container"}>
                <AppealOposGridByEmployingOrganization appealId={appealId} />
              </Box>
            </>
          )}
        </DrawerContent>
      </Drawer>
      <Outlet />
    </>
  );
};
