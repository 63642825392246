import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "../../../../components/DataGrid";
import {
  EmployingOrganizationFragment,
  EmployingOrganizationsQuery,
  EmployingOrganizationsQueryVariables,
} from "../../../../generated/operations";
import { useEmployingOrganizationsQuery } from "../../../../generated/hooks";
import { sortingTransformer } from "../../../../utils/sortingTransformer";
import { whereTransformer } from "../../../../utils/whereTransformer";
import { dataTransformer } from "./utils/dataTransformer";
import { columnTransformer } from "./utils/columnTransformer";

export type TEmployingOrganizationsDataGridProps = Partial<
  UseGqlDataGridParams<
    EmployingOrganizationsQuery,
    EmployingOrganizationFragment,
    EmployingOrganizationsQueryVariables
  >
>;

export type TEmployingOrganizationsDataGridResult = UseGqlDataGridResult<
  EmployingOrganizationsQuery,
  EmployingOrganizationFragment,
  EmployingOrganizationsQueryVariables
>;

export const useEmployingOrganizationsDataGrid = (
  props?: TEmployingOrganizationsDataGridProps
): TEmployingOrganizationsDataGridResult => {
  const gridName = "employingOrganizationsList";
  const gridData = useGqlDataGrid<
    EmployingOrganizationsQuery,
    EmployingOrganizationFragment,
    EmployingOrganizationsQueryVariables
  >({
    type: gridName,
    queryHook: useEmployingOrganizationsQuery,
    dataTransformer,
    columnTransformer,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    selection: true,
    ...props,
  });
  return gridData;
};
