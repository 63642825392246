import React, { FC } from "react";
import { Grid, Label } from "@genome-frontend/uikit/build/lib/uikit";
import { ClientViewFragment } from "generated/operations";
import { Address, ClientUpdateValue } from "pages/ClientsPage/types";
import { FieldArray, useFormikContext } from "formik";
import { Button } from "@mui/material";
import { locale } from "locale";
import DadataSuggestions from "react-dadata-suggestions";
import "react-dadata-suggestions/dist/styles.css";
import "./ClientEditForm.css";
import { MaskExpress } from "utils/maskExpression";
import { sexOptions } from "constants/selectFieldConsts";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { ClientEditFormValidate } from "../ClientEditDrawer/ClientEditFormValidate";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { ValidationSelectField } from "components/formFields/ValidationSelectedField";
import { ValidationDatePickerField } from "components/formFields/ValidationDatePickerField";
import { MaskValidationTextInput } from "components/formFields/MaskValidationTextInput";

interface ClientFormProps {
  user?: ClientViewFragment;
}

const emptyAddress: Address = {
  fullAddress: "",
  startDate: "",
  endDate: null,
};

export const ClientEditForm: FC<ClientFormProps> = ({ user }) => {
  const tokenAPI = process.env.REACT_APP_TOKEN_API_SUGGESTIONS;

  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
    isSubmitting,
  } = useFormikContext<ClientUpdateValue>();

  const validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: ClientEditFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <Grid alignItems="flex-start" container spacing={2}>
      <Grid container item>
        <Grid xs={4} item>
          <Label>
            <h3>Персональные данные</h3>
          </Label>
        </Grid>
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.surname}
          name="surname"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.name}
          name="name"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.fathersName}
          name="fathersName"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationSelectField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.sex}
          options={sexOptions}
          placeholder="Выберите значение"
          name="sex"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationDatePickerField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.birthdate}
          name="birthdate"
        />
      </Grid>
      <Grid xs={4} item>
        <MaskValidationTextInput
          mask={MaskExpress.snils}
          required
          fullWidth
          label={locale.user.snils}
          name="snils"
          {...validationFieldContext}
        />
      </Grid>
      <Grid xs={4} item>
        <MaskValidationTextInput
          mask={MaskExpress.phone}
          required
          fullWidth
          label={locale.user.phoneNumber}
          name="phoneNumber"
          {...validationFieldContext}
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label="Email"
          name="email"
        />
      </Grid>

      <Grid container item>
        <Grid xs={4} item>
          <Label>
            <h3>Регистрация пользователя</h3>
          </Label>
        </Grid>
      </Grid>
      <FieldArray name="addresses" validateOnChange>
        {
          // @ts-ignore
          ({ push, remove }) => {
            return (
              <React.Fragment>
                {values.addresses!.map((adr, index) => {
                  const fullAddress = `addresses[${index}].fullAddress`;
                  const startDate = `addresses[${index}].startDate`;
                  const endDate = `addresses[${index}].endDate`;

                  return (
                    <Grid container item key={index} spacing={2}>
                      <Grid xs={12} item>
                        <DadataSuggestions
                          name={fullAddress}
                          minChars="3"
                          deferRequestBy="100"
                          onChange={(event: any) => {
                            setFieldValue(fullAddress, event);
                            setFieldTouched(fullAddress, true);
                          }}
                          token={tokenAPI}
                          onSelect={(suggestion: any) => {
                            setFieldValue(fullAddress, suggestion.value);
                            setFieldTouched(fullAddress, true);
                          }}
                          query={values.addresses![index].fullAddress}
                          receivePropsBehaveLikeOnChange={true}
                          onBlur={() => {
                            setFieldTouched(fullAddress, true);
                          }}
                        />
                        {touched &&
                          touched.addresses &&
                          (touched.addresses as unknown as Address[])[index] &&
                          (
                            (touched.addresses as unknown as Address[])[
                              index
                            ] as unknown as Address
                          ).fullAddress &&
                          errors &&
                          errors.addresses &&
                          errors.addresses[index] && (
                            <div className={"error-array"}>
                              {
                                (errors.addresses[index] as unknown as Address)
                                  .fullAddress
                              }
                            </div>
                          )}
                      </Grid>
                      <Grid item xs={4}>
                        <ValidationDatePickerField
                          validationFieldContext={validationFieldContext}
                          required
                          fullWidth
                          label="Дата регистрации"
                          name={startDate}
                          maxDate={new Date()}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ValidationDatePickerField
                          validationFieldContext={validationFieldContext}
                          required={index !== values.addresses!.length - 1}
                          fullWidth
                          label="Дата снятия с учета"
                          maxDate={new Date()}
                          name={endDate}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button onClick={() => remove(index)}>Удалить</Button>
                      </Grid>
                    </Grid>
                  );
                })}

                <Grid item>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    onClick={() => push(emptyAddress)}
                  >
                    Добавить адрес
                  </Button>
                </Grid>
              </React.Fragment>
            );
          }
        }
      </FieldArray>
    </Grid>
  );
};
