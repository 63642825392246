import { FC, useMemo } from "react";
import { useToasts } from "components/Toast";
import { useConfirm } from "components/Confirm";
import {
  useEmployingOrganizationQuery,
  useUpdateEmployingOrganizationMutation,
} from "generated/hooks";
import {
  namedOperations,
  UpdateEmployingOrganizationMutation,
} from "generated/operations";
import { EmployingOrganizationValues } from "../../types";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { Formik } from "formik";
import { DrawerForm } from "components/Form";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { formCloseHandler } from "components/Form/formCloseHandler";
import { getUpdateEmployingOrganizationInputDataFromValues } from "pages/OrganizationsPage/utils/getUpdateEmployingOrganizationInputDataFromValues";
import { getInitialEmployingOrganizationValuesFromData } from "pages/OrganizationsPage/utils/getInitialEmployingOrganizationValuesFromData";
import { EmployingOrganizationUpdateFormValidate } from "../EmployingOrganizationForm/EmployingOrganizationUpdateFormValidate";
import { EmployingOrganizationUpdateForm } from "../EmployingOrganizationForm/EmployingOrganizationUpdateForm";

interface EmployingOrganizationEditDrawerProps {
  id: string;
  onSuccess: (payload: UpdateEmployingOrganizationMutation) => void;
  onCancel: () => void;
}

export const EmployingOrganizationEditDrawer: FC<
  EmployingOrganizationEditDrawerProps
> = ({ id, onSuccess, onCancel }) => {
  const { addErrorsToast } = useToasts();
  const { addOkConfirm } = useConfirm();

  const { data, loading } = useEmployingOrganizationQuery({
    variables: {
      id,
    },
    onError: () => {
      onCancel();
    },
  });

  const [updateEmployingOrganizationMutation, { loading: saving }] =
    useUpdateEmployingOrganizationMutation({
      onCompleted: (payload) => {
        onSuccess(payload);
      },
      onError: () => {
        addErrorsToast([
          { message: "Ошибка при обновлении организации-работодателя" },
        ]);
      },
      refetchQueries: [
        namedOperations.Query.EmployingOrganization,
        namedOperations.Query.EmployingOrganization,
      ],
    });

  const updateOrganization = async (values: EmployingOrganizationValues) => {
    const input = getUpdateEmployingOrganizationInputDataFromValues(values);
    await updateEmployingOrganizationMutation({
      variables: {
        input,
      },
    });
  };

  const initialValues = useMemo(() => {
    if (!data?.employingOrganizationById) {
      return null;
    }
    return getInitialEmployingOrganizationValuesFromData(
      data?.employingOrganizationById
    );
  }, [data]);

  const submitHandler = (values: EmployingOrganizationValues) => {
    updateOrganization(values);
  };

  if (!loading && !data?.employingOrganizationById) {
    return null;
  }

  if (!initialValues || loading) {
    return (
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 1000,
          },
        }}
        open
        onClose={onCancel}
      >
        <DrawerContent onClose={onCancel} title="Редактирование">
          <CenteredCircularProgress />
        </DrawerContent>
      </Drawer>
    );
  }

  const closeHandler = async (values: EmployingOrganizationValues) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: EmployingOrganizationValues;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open
      onClose={() => closeHandler(values)}
    >
      <Formik<EmployingOrganizationValues>
        validate={EmployingOrganizationUpdateFormValidate}
        onSubmit={submitHandler}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(props.values)}
                title="Редактирование"
                confirmButtonProps={{
                  disabled: saving || !props.isValid,
                }}
                cancelButtonProps={{
                  onClick: () => closeHandler(props.values),
                }}
              >
                {data?.employingOrganizationById && (
                  <EmployingOrganizationUpdateForm
                    employingOrganization={data.employingOrganizationById}
                    isSubmitting={false}
                  />
                )}
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
