import { EmployingOrganizationValues } from "pages/OrganizationsPage/types";

export const getInitialValuesFromData = (
  values: any
): EmployingOrganizationValues => {
  return {
    delagateName: values.delagateName ?? "",
    delagateSurname: values.delagateSurname ?? "",
    delagateFathersName: values.delagateFathersName ?? "",
    organizationName: values.organizationName ?? "",
    address: values.address ?? "",
    email: values.email ?? "",
    id: values.id!,
    inn: values.inn ?? "",
    kpp: values.kpp ?? "",
    ogrn: values.ogrn ?? "",
    phoneNumber: values.phoneNumber ?? "",
    mainOkved: values.mainOkved ?? { id: undefined, name: "" },
    additionalOkveds: values.additionalOkveds ?? [],
  };
};
