import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { FC } from "react";
import { Tooltip } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import React from "react";
import { locale } from "../../../../locale";
import { TValidationFieldContext } from "../../../../components/formFields/base/ValidationFieldsContext";
import "./DeleteUserFromMultipleAppealButton.css";

export interface DeleteUserFromMultipleAppealButtonProps {
  editCellParams: GridRenderEditCellParams;
  validationFieldContext: TValidationFieldContext;
  onDeleted: (rowId: string) => void;
}

export const DeleteUserFromMultipleAppealButton: FC<
  DeleteUserFromMultipleAppealButtonProps
> = ({ editCellParams, validationFieldContext, onDeleted }) => {
  return (
    <Tooltip title={locale.multipleAppealOpo.deleteUserFromMultipleAppeal}>
      <label className={"deleteUserFromMultipleAppealLabel"}>
        <input
          name={"deleteUserFromMultipleAppeal"}
          type="button"
          className={"deleteUserFromMultipleAppealInput"}
          onClick={async () => {
            onDeleted(editCellParams.id.toString());
          }}
        />
        {React.createElement(DeleteOutlined, { cursor: "pointer" })}
      </label>
    </Tooltip>
  );
};
