import { RestrictionType } from "generated/operations";
import {
  RestrictionTypeNull,
  FinalAppealConclusionTypeNull,
} from "pages/AppealPage/utils/types";
import { DateNull } from "types/NullableTypes";
import { isValidDate } from "utils/isValidDate";

export const conclusionTherapistValidateSave = (
  conclusion: FinalAppealConclusionTypeNull,
  restriction: RestrictionTypeNull,
  restrictionDateEnd: DateNull
): boolean => {
  return (
    restriction !== null &&
    (restriction === RestrictionType.NONE || isValidDate(restrictionDateEnd)) &&
    conclusion !== null
  );
};
