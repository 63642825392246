import React, { FC, memo } from "react";
import { FormHelperText, Button } from "@mui/material";
import { Label } from "@genome-frontend/uikit/build/lib/uikit/Label/Label";
import { TextInputField } from "../formFields/TextInputField";
import { Grid } from "@genome-frontend/uikit/build/lib/uikit";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";

export interface FileValidationProps extends BaseTextFieldProps {
  onClearClick: () => void;
  onFileInputClick: (target: any) => void;
  handleCapture: (target: any) => void;
  uploadButtonText: string;
  label: string;
  error: boolean;
  helperText: string;
  fileFieldName: string;
  textFieldName: string;
  acceptableExtensions: string;
  isButtonDisabled: boolean;
}
export const FileValidation: FC<FileValidationProps> = memo(
  ({
    required,
    label,
    error,
    helperText,
    onClearClick,
    handleCapture,
    onFileInputClick,
    fileFieldName,
    textFieldName,
    acceptableExtensions,
    isButtonDisabled,
    uploadButtonText,
  }) => {
    return (
      <div>
        {label && <Label required={required}>{label}</Label>}
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid xs={3} item>
            <TextInputField name={textFieldName} disabled={true} />
          </Grid>
          <Grid xs={9} item>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid xs={2} item>
                <Button
                  variant="contained"
                  component="span"
                  onClick={onClearClick}
                >
                  Очистить
                </Button>
              </Grid>
              <Grid xs={10} item>
                <label>
                  <input
                    name={fileFieldName}
                    accept={acceptableExtensions}
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleCapture}
                    onClick={onFileInputClick}
                    disabled={isButtonDisabled}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    disabled={isButtonDisabled}
                  >
                    {uploadButtonText}
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </div>
    );
  }
);
