import React, { useEffect } from "react";
import { KeyboardArrowDownIcon } from "../../icons/KeyboardArrowDownIcon";
import { IconButton } from "../IconButton";
import { KeyboardArrowUpIcon } from "../../icons/KeyboardArrowUpIcon";
export const ExpandableItem = ({ childrenListName, row, onToggleExpandableItem, triggerUnToggle }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    useEffect(() => {
        setIsExpanded(false);
    }, [triggerUnToggle]);
    const needToHide = row[childrenListName] === undefined;
    const needToEnable = !needToHide && row[childrenListName].length > 0;
    const onClick = () => {
        setIsExpanded(!isExpanded);
        onToggleExpandableItem(row.id, !isExpanded);
    };
    return (needToHide
        ? React.createElement(React.Fragment, null)
        : React.createElement(IconButton, { disabled: !needToEnable, "aria-label": "expand row", size: "small", onClick: () => onClick() }, isExpanded ? React.createElement(KeyboardArrowDownIcon, null) : React.createElement(KeyboardArrowUpIcon, null)));
};
