export const user: { [key: string]: string } = {
  name: "Имя",
  surname: "Фамилия",
  fathersName: "Отчество",
  fio: "ФИО пользователя",
  sex: "Пол",
  sexMale: "Мужской",
  sexFemale: "Женский",
  birthdate: "Дата рождения",
  snils: "Номер СНИЛС",
  phoneNumber: "Телефон",
  email: "Email",
  roleName: "Роль",
  position: "Должность",
  organization: "Организация",
  profile: "Доступ к профилю",
  login: "Логин",
  password: "Пароль",
  hasLoginPassAccess: "Доступ по логину/паролю",
  isCommissionHead: "Является председателем ВК?",
  isAccessBlocked: "Блокировка доступа в систему",
};
