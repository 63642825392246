import { Location, useLocation } from "react-router";

import { AuthBoundaryContainer } from "../auth/AuthBoundaryContainer";

import { paths } from "./paths";
import { Redirect } from "./Redirect";

type TProps = {
  children: React.ReactElement;
};

export const GuestElement = ({ children }: TProps) => {
  const location = useLocation();
  const state = location.state as { from: Location };
  const prevPageUrl = state ? state.from.pathname : paths.homePage();

  return (
    <AuthBoundaryContainer
      isShouldBeAuthorized={false}
      fallback={<Redirect to={prevPageUrl} replace />}
    >
      {children}
    </AuthBoundaryContainer>
  );
};
