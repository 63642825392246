import * as yup from "yup";
import { locale } from "locale";
import { RegExpress } from "utils/regularExpression";
import { ValidationError } from "yup";

export const editOrganizationValidationSchema = yup.object().shape({
  name: yup.string().required(`${locale.REQUIRED}`),
  address: yup.string().required(`${locale.REQUIRED}`),
  inn: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .test(
      "len",
      "Поле должно содержать 10 или 12 символов",
      (val) => val?.length === 10 || val?.length === 12
    ),
  ogrn: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .test(
      "len",
      "Поле должно содержать 13 или 15 символов",
      (val) => val?.length === 13 || val?.length === 15
    ),
  kpp: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .test(
      "len",
      "Поле должно содержать 9 символов",
      (val) => val?.length === 9
    ),
  licenseNumber: yup.string().required(`${locale.REQUIRED}`),
  licenseDate: yup
    .date()
    .required(`${locale.REQUIRED}`)
    .min(new Date(1900, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
    .max(new Date(2100, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
    .typeError(`${locale.INVALID_DATE_FORMAT}`),
  licenseAuthority: yup.string().required(`${locale.REQUIRED}`),
  phone: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .matches(RegExpress.phone, `${locale.PHONE}`),
  email: yup.string().required(`${locale.REQUIRED}`).email(`${locale.EMAIL}`),
  republicCode: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .test("len", "Поле должно содержать 2 символа", (val) => val?.length === 2),
  organizationCode: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .test("len", "Поле должно содержать 2 символа", (val) => val?.length === 2),
  licenseFileText: yup.string().required(`${locale.REQUIRED}`),
  printFileText: yup.string().required(`${locale.REQUIRED}`),
  mainOkved: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(`${locale.REQUIRED}`),
  }),
  additionalOkveds: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(`${locale.REQUIRED}`),
      })
    )
    .test(function (additionalOkveds) {
      const errors: ValidationError[] = [];
      const uniqueOkveds = new Set();
      const { mainOkved } = this.parent;

      for (let index = 0; index < additionalOkveds?.length!; index++) {
        const element = additionalOkveds![index];

        if (uniqueOkveds.has(element.id))
          errors.push(
            new ValidationError(
              "Этот ОКВЭД уже указан в списке дополнительных ОКВЭД'ов. ОКВЭД'ы должны быть уникальны",
              element.name,
              `additionalOkveds[${index}].name`,
              "ОКВЭД'ы должны быть уникальны"
            )
          );
        else if (element.id == mainOkved.id) {
          errors.push(
            new ValidationError(
              "Этот ОКВЭД уже используется как основной. ОКВЭД'ы должны быть уникальны",
              element.name,
              `additionalOkveds[${index}].name`,
              "ОКВЭД'ы должны быть уникальны"
            )
          );
        } else if (element.id) uniqueOkveds.add(element.id);
      }

      return errors.length ? new ValidationError(errors) : true;
    }),
});
