import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { paths } from "router/paths";
import { EmployingOrganizationViewDrawer } from "../Components/EmployingOrganizationViewDrawer/EmployingOrganizationViewDrawer";

export const EmployingOrganizationViewDrawerPage: FC = () => {
  const navigate = useNavigate();

  const params = useParams();

  const id = params.id!;
  const closeHandler = () => {
    navigate(paths.organizations());
  };

  const editHandler = (emplOrganization: any) => {
    navigate(paths.employingOrganizationEdit(emplOrganization.id));
  };

  return (
    <EmployingOrganizationViewDrawer
      id={id}
      onClose={closeHandler}
      onError={closeHandler}
      onEdit={editHandler}
    />
  );
};
