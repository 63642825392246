import { useNavigate } from "react-router";
import { FC } from "react";
import { paths } from "router/paths";
import { OrganizationCreateDrawer } from "../Components/OrganizationCreateDrawer/OrganizationCreateDrawer";

export const OrganizationCreateDrawerPage: FC = () => {
  const navigate = useNavigate();
  const closeHandler = () => {
    navigate(paths.organizations());
  };

  const onSuccess = (data?: number) => {
    navigate(paths.organization(data));
  };

  return (
    <OrganizationCreateDrawer onSuccess={onSuccess} onCancel={closeHandler} />
  );
};
