import React from 'react';
import { Box, Typography } from '@mui/material';
import { CancelIconS } from '../../icons/CancelIconS';
import { IconButton } from '../IconButton/IconButton';
import { Button } from '../Button';
export const DrawerContent = ({ children, title, onClose, cancelButtonProps, confirmButtonProps, isWithActions = true, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: {
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #DFE3E6',
                p: (theme) => theme.spacing(2),
            } },
            React.createElement(Typography, { variant: "h6" }, title),
            onClose && (React.createElement(IconButton, { sx: { marginLeft: 'auto' }, onClick: () => onClose === null || onClose === void 0 ? void 0 : onClose({}, 'escapeKeyDown') },
                React.createElement(CancelIconS, null)))),
        React.createElement(Box, { sx: { flexGrow: 1, overflow: 'auto', p: 2 } }, children),
        isWithActions && (React.createElement(Box, { sx: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                borderTop: '1px solid #DFE3E6',
                p: 2,
                marginTop: 'auto',
            } },
            React.createElement(Button, { color: "primary", variant: "outlined", ...cancelButtonProps }, (cancelButtonProps === null || cancelButtonProps === void 0 ? void 0 : cancelButtonProps.children) || 'Отмена'),
            React.createElement(Button, { sx: { ml: (theme) => theme.spacing(2) }, color: "primary", variant: "contained", type: "submit", ...confirmButtonProps }, (confirmButtonProps === null || confirmButtonProps === void 0 ? void 0 : confirmButtonProps.children) || 'Сохранить')))));
};
