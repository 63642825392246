import { ReactNode } from "react";
import { useNavigate } from "react-router";
import { paths } from "router/paths";

import { CheckListIcon } from "@genome-frontend/uikit/build/lib/icons/CheckListIcon";
import { PlusOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PlusOutlineIcon";
import { PrintExcelIcon } from "@genome-frontend/uikit/build/lib/icons/PrintExcelIcon";
import { TrashIcon } from "@genome-frontend/uikit/build/lib/icons/TrashIcon";
import { Button } from "@genome-frontend/uikit/build/lib/uikit";

import {
  TOrganizationAdminUsersDataGridResult,
  TUsersDataGridResult,
} from "./useUsersDataGrid";

export const useUsersHeaderButtons = ({
  selectionModel,
  ids,
  setIds,
}:
  | TUsersDataGridResult
  | TOrganizationAdminUsersDataGridResult): ReactNode[] => {
  const navigate = useNavigate();

  const groupButtons = [
    <Button
      key="goToSelected"
      onClick={() => {
        const selectedIds = selectionModel.map((id) => Number(id));
        setIds(selectedIds);
      }}
      startIcon={<CheckListIcon />}
    >
      Перейти к выделенному
    </Button>,
    <Button key="remove" onClick={() => {}} startIcon={<TrashIcon />}>
      Удалить
    </Button>,
  ];

  return [
    ...(ids.length > 0
      ? [
          <Button
            key="showAll"
            onClick={() => {
              setIds([]);
            }}
          >
            Показать всё
          </Button>,
        ]
      : []),
    ...(selectionModel.length ? groupButtons : []),
    <Button
      key="add"
      startIcon={<PlusOutlineIcon />}
      onClick={() => {
        navigate(paths.userCreate());
      }}
      variant="contained"
    >
      Регистрация сотрудника
    </Button>,
  ];
};
