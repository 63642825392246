type TGetFioProps = {
  name: string | null | undefined;
  surname: string | null | undefined;
  fathersName: string | null | undefined;
};

export const getFio = (
  { name, surname, fathersName }: TGetFioProps,
  full = false
): string => {
  if (full) {
    return [surname, name, fathersName].filter(Boolean).join(" ");
  }
  return [
    surname,
    name && `${name.toUpperCase()[0]}.`,
    fathersName && `${fathersName.toUpperCase()[0]}.`,
  ]
    .filter(Boolean)
    .join(" ");
};
