import { CreateEmployingOrganizationInput } from "generated/operations";
import React, { FC, useState } from "react";
import { Grid, Label } from "@genome-frontend/uikit/build/lib/uikit";
import { EmployingOrganizationValues, Okved } from "../../types";
import { FieldArray, useFormikContext } from "formik";
import { locale } from "locale";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { MaskValidationTextInput } from "components/formFields/MaskValidationTextInput";
import { MaskExpress } from "utils/maskExpression";
import { EmployingOrganizationCreateFormValidate } from "./EmployingOrganizationCreateFromValidate";
import { SelectOkved } from "components/Selectors/SelectOkved";
import { Button, Typography } from "@mui/material";
import { EmployingOrganizationCreateDrawer } from "../EmployingOrganizationCreateDrawer/EmployingOrganizationCreateDrawer";
import { useToasts } from "components/Toast";

interface EmployingOrganizationCreateFormProps {
  employingOrganization?: CreateEmployingOrganizationInput;
}

const emptyOkved: Okved = {
  id: undefined,
  name: "",
};

const maxAdditionalOkvedsNumber = 10;

export const EmployingOrganizationCreateForm: FC<
  EmployingOrganizationCreateFormProps
> = () => {
  const [isOpenOrganizationCreateDrawer, setIsOpenOrganizationCreateDrawer] =
    useState(false);

  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
  } = useFormikContext<EmployingOrganizationValues>();

  const { addErrorsToast } = useToasts();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: EmployingOrganizationCreateFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <>
      <Grid alignItems="flex-start" spacing={2} container>
        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="organizationName"
            label={locale.employingOrganization.organizationName}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="email"
            label={locale.employingOrganization.email}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "223px",
              },
            }}
          />
        </Grid>

        <Grid xs={12} item>
          <MaskValidationTextInput
            mask={MaskExpress.phone}
            required
            label={locale.employingOrganization.phoneNumber}
            name="phoneNumber"
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "223px",
              },
            }}
            {...validationFieldContext}
          />
        </Grid>

        <Grid xs={12} item>
          <Typography mt={2}>
            {locale.employingOrganization.delegate}
          </Typography>
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="delagateName"
            label={locale.employingOrganization.surname}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="delagateSurname"
            label={locale.employingOrganization.name}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="delagateFathersName"
            label={locale.employingOrganization.fathersName}
            fullWidth
            required
          />
        </Grid>

        <Grid mt={2} xs={12} item>
          <Label required>{locale.employingOrganization.mainOkved + ":"}</Label>
          <SelectOkved
            validationFieldContext={validationFieldContext}
            name="mainOkved"
          />
        </Grid>

        <Grid xs={12} item>
          <Label>{locale.organization.additionalOkveds + ":"}</Label>
        </Grid>
        <FieldArray name="additionalOkveds" validateOnChange>
          {
            // @ts-ignore
            ({ push, remove }) => {
              const onAddOkvedButtonClick = () => {
                if (
                  values.additionalOkveds!.length >= maxAdditionalOkvedsNumber
                ) {
                  addErrorsToast(
                    [
                      {
                        message: `Указано максимальное количество дополнительных ОКВЭД'ов: ${maxAdditionalOkvedsNumber}`,
                      },
                    ],
                    ""
                  );
                  return;
                }

                push(emptyOkved);
              };

              return (
                <React.Fragment>
                  {values.additionalOkveds!.map((okv, index) => (
                    <Grid container item key={index} spacing={2}>
                      <Grid item xs={10}>
                        <SelectOkved
                          validationFieldContext={validationFieldContext}
                          name={`additionalOkveds[${index}]`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button fullWidth onClick={() => remove(index)}>
                          Удалить
                        </Button>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid item>
                    <Button variant="contained" onClick={onAddOkvedButtonClick}>
                      Добавить ОКВЭД
                    </Button>
                  </Grid>
                </React.Fragment>
              );
            }
          }
        </FieldArray>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="address"
            label={locale.employingOrganization.address}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="ogrn"
            label={locale.employingOrganization.ogrn}
            required
            type="number"
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="inn"
            label={locale.employingOrganization.inn}
            required
            type="number"
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="kpp"
            label={locale.employingOrganization.kpp}
            required
            type="number"
          />
        </Grid>
      </Grid>
      {isOpenOrganizationCreateDrawer && (
        <EmployingOrganizationCreateDrawer
          onSuccess={() => {
            setIsOpenOrganizationCreateDrawer(false);
          }}
          onCancel={() => {
            setIsOpenOrganizationCreateDrawer(false);
          }}
        />
      )}
    </>
  );
};
