import { GridSortModel } from "@mui/x-data-grid";
import { SortingTransformer, SortInput } from "../components/DataGrid";

export const sortingTransformer: (gridKey: string) => SortingTransformer =
  (gridKey: string) => (sortInputs: any) => {
    const storedSorting = localStorage.getItem(
      "X_SORT_" + gridKey?.toUpperCase()
    );

    if (storedSorting === null) {
      return sortInputs;
    } else {
      const gridModel: GridSortModel = JSON.parse(
        localStorage.getItem("X_SORT_" + gridKey?.toUpperCase())!
      ) as GridSortModel;

      if (gridModel?.length) {
        const sortInputsStorage = gridModel.reduce<SortInput>(
          (prev, { sort, field }) => {
            if (sort && field) {
              (prev as any)[field] = sort?.toUpperCase();
            }
            return prev;
          },
          {}
        );

        return sortInputsStorage;
      } else {
        return sortInputs;
      }
    }
  };
