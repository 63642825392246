import { EmployingOrganizationValues, OrganizationValues } from "../types";

export const getInitialEmployingOrganizationValuesFromData = (
  values: any
): EmployingOrganizationValues => {
  return {
    id: values.id ?? "",
    organizationName: values.organizationName ?? "",
    address: values.address ?? "",
    delagateName: values.delagateName ?? "",
    delagateSurname: values.delagateSurname ?? "",
    delagateFathersName: values.delagateFathersName ?? "",
    inn: values.inn ?? "",
    ogrn: values.ogrn ?? "",
    kpp: values.kpp ?? "",
    phoneNumber: values.phoneNumber ?? "",
    email: values.email ?? "",
    mainOkved: values.mainOkved ?? undefined,
    additionalOkveds: values.additionalOkveds ?? [],
    hasLoginPassAccess: values.hasLoginPassAccess ?? false,
    isAccessBlocked: values.isAccessBlocked ?? false,
    login: values.login ?? "",
    password: values.password ?? "",
  };
};
