import { namedOperations } from "generated/operations";
import { useSendPostRequestToClosedServer } from "utils/sendPostRequestToClosedServer";
import {
  AssignClientToEmployingOrganizationValue,
  ClientOutput,
} from "../types";

type TCreateClientProps = {
  onSuccess: (user: ClientOutput) => void;
};

export interface UseAssignClientToEmployingOrganization {
  assignClient: (
    clientAssignValue: AssignClientToEmployingOrganizationValue
  ) => Promise<void>;
}

export function useAssignClientToEmployingOrganization({
  onSuccess,
}: TCreateClientProps): UseAssignClientToEmployingOrganization {
  const { sendPostRequestToClosedServer } = useSendPostRequestToClosedServer<
    AssignClientToEmployingOrganizationValue,
    ClientOutput
  >({
    endPointPath: "/api/Patient/AssignPatientToEmployingOrganization",
    successToast: "Пользователь успешно добавлен!",
    failureToast: "Не удалось добавить пользователя!",
    onSuccess: onSuccess,
    refetchQueryName: namedOperations.Query.Clients,
  });

  const handleSubmit = async (
    input: AssignClientToEmployingOrganizationValue
  ) => {
    await sendPostRequestToClosedServer(input);
  };

  return {
    assignClient: handleSubmit,
  };
}
