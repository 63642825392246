import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Outlet,
  Routes,
} from "react-router-dom";
import { ApolloHelper } from "./ApolloHelper";
import { ApolloProvider } from "@apollo/client";
import { UIKitProvider } from "@genome-frontend/uikit/build/lib/uikit/UIKitProvider";

import RefreshTokenUpdater from "./RefreshTokenUpdater";

import { LoginPage } from "pages/LoginPage/LoginPage";
import { HomePage } from "pages/HomePage/HomePage";

import { paths } from "router/paths";
import { GuestElement } from "router/GuestElement";
import { PrivateElement } from "router/PrivateElement";
import { AuthContextProvider } from "auth/AuthContextProvider";
import { ToastsContextProvider } from "components/Toast/ToastsContextProvider";

import { ConfirmContextProvider } from "./components/Confirm";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";
import { ReportsPage } from "./pages/ReportsPage/ReportsPage";
import { OrganizationsPage } from "./pages/OrganizationsPage/OrganizationsPage";
import { ChangesPage } from "./pages/ChangesPage/ChangesPage";

import { UsersPage } from "./pages/UsersPage/UsersPage";
import { UserCreatePage } from "pages/UsersPage/outlet/UserCreatePage";
import { UserEditDrawerPage } from "pages/UsersPage/outlet/UserEditDrawerPage";

import { ClientsPage } from "./pages/ClientsPage/ClientsPage";
import { ClientCreatePage } from "pages/ClientsPage/outlet/ClientCreatePage";
import { ClientViewDrawerPage } from "pages/ClientsPage/outlet/ClientViewDrawerPage";
import { ClientEditDrawerPage } from "pages/ClientsPage/outlet/ClientEditDrawerPage";

import { OrganizationViewDrawerPage } from "./pages/OrganizationsPage/outlet/OrganizationViewDrawerPage";
import { OrganizationCreateDrawerPage } from "./pages/OrganizationsPage/outlet/OrganizationCreateDrawerPage";
import { AccessPermissions } from "./types/AccessPermissions";
import { PermissionElement } from "./router/PermissionElement";
import { OrganizationEditDrawerPage } from "./pages/OrganizationsPage/outlet/OrganizationEditDrawerPage";
import { UserViewDrawerPage } from "./pages/UsersPage/outlet/UserViewDrawerPage";
import { EmployingOrganizationCreateDrawerPage } from "./pages/OrganizationsPage/outlet/EmployingOrganizationCreateDrawerPage";
import { EmployingOrganizationEditDrawerPage } from "pages/OrganizationsPage/outlet/EmployingOrganizationEditDrawerPage";
import { EmployingOrganizationViewDrawerPage } from "pages/OrganizationsPage/outlet/EmployingOrganizationViewDrawerPage";
import { EmployingOrganizationProfileEditDrawerPage } from "./pages/ProfilePage/components/EmployingOrganizationProfile/EmployingOrganizationProfileEditDrawer/EmployingOrganizationProfileEditDrawerPage";
import { EmployingOrganizationClientViewDrawerPage } from "./pages/ClientsPage/outlet/EmployingOrganizationClientViewDrawerPage";
import { AppealOpoByEmployingOrganization } from "./pages/AppealPage/components/AppealOpoByEmployingOrganization/AppealOpoByEmployingOrganization";
import { MultipleAppealOpoPage } from "./pages/MultipleOpoAppealPage/MultipleAppealOpoPage";

function App() {
  return (
    <UIKitProvider>
      <AuthContextProvider>
        <ConfirmContextProvider>
          <ToastsContextProvider>
            <ApolloProvider client={ApolloHelper.configureClient()}>
              <Router>
                <RefreshTokenUpdater />
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route
                      path={paths.login()}
                      element={
                        <GuestElement>
                          <LoginPage />
                        </GuestElement>
                      }
                    />

                    <Route
                      element={
                        <PrivateElement>
                          <Outlet />
                        </PrivateElement>
                      }
                    >
                      <Route path={paths.homePage()} element={<HomePage />} />

                      <Route
                        path={paths.reports()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.Reports}
                          >
                            <ReportsPage />
                          </PermissionElement>
                        }
                      />

                      <Route
                        path={paths.organizations()}
                        element={
                          <PermissionElement
                            permission={
                              AccessPermissions.OrganizationNavigation
                            }
                          >
                            <OrganizationsPage />
                          </PermissionElement>
                        }
                      >
                        <Route
                          path={paths.organization()}
                          element={
                            <PermissionElement
                              permission={
                                AccessPermissions.OrganizationNavigation
                              }
                            >
                              <OrganizationViewDrawerPage />
                            </PermissionElement>
                          }
                        />
                        <Route
                          path={paths.organizationNewSubPath()}
                          element={
                            <PermissionElement
                              permission={AccessPermissions.OrganizationsUpdate}
                            >
                              <OrganizationCreateDrawerPage />
                            </PermissionElement>
                          }
                        />
                        <Route
                          path={paths.organizationEdit()}
                          element={
                            <PermissionElement
                              permission={AccessPermissions.OrganizationsUpdate}
                            >
                              <OrganizationEditDrawerPage />
                            </PermissionElement>
                          }
                        />
                        <Route
                          path={paths.employingOrganizationNewSubPath()}
                          element={
                            <PermissionElement
                              permission={
                                AccessPermissions.EmployingOrganizationsCreate
                              }
                            >
                              <EmployingOrganizationCreateDrawerPage />
                            </PermissionElement>
                          }
                        />
                        <Route
                          path={paths.employingOrganization()}
                          element={
                            <PermissionElement
                              permission={
                                AccessPermissions.EmployingOrganizationsRead
                              }
                            >
                              <EmployingOrganizationViewDrawerPage />
                            </PermissionElement>
                          }
                        />
                        <Route
                          path={paths.employingOrganizationEdit()}
                          element={
                            <PermissionElement
                              permission={
                                AccessPermissions.EmployingOrganizationsUpdate
                              }
                            >
                              <EmployingOrganizationEditDrawerPage />
                            </PermissionElement>
                          }
                        />
                      </Route>
                    </Route>

                    <Route
                      path={paths.changes()}
                      element={
                        <PermissionElement
                          permission={AccessPermissions.HistoryChangeNavigation}
                        >
                          <ChangesPage />
                        </PermissionElement>
                      }
                    />

                    <Route
                      path={paths.users()}
                      element={
                        <PermissionElement
                          permission={AccessPermissions.EmployeesNavigation}
                        >
                          <UsersPage />
                        </PermissionElement>
                      }
                    >
                      <Route
                        path={paths.userCreateSubPath()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.EmployeesCreate}
                          >
                            <UserCreatePage />
                          </PermissionElement>
                        }
                      />
                      <Route
                        path={paths.userSubPath()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.EmployeesNavigation}
                          >
                            <UserViewDrawerPage />
                          </PermissionElement>
                        }
                      />
                      <Route
                        path={paths.userEdit()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.EmployeesUpdate}
                          >
                            <UserEditDrawerPage />
                          </PermissionElement>
                        }
                      />
                    </Route>

                    <Route
                      path={paths.clients()}
                      element={
                        <PermissionElement
                          permission={AccessPermissions.ClientsNavigation}
                        >
                          <ClientsPage />
                        </PermissionElement>
                      }
                    >
                      <Route
                        path={paths.clientCreateSubPath()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.ClientsCreate}
                          >
                            <ClientCreatePage />
                          </PermissionElement>
                        }
                      >
                        <Route
                          path={paths.clientFromEmployingOrganizationCreate()}
                          element={
                            <PermissionElement
                              permission={AccessPermissions.ClientsAssign}
                            >
                              <EmployingOrganizationClientViewDrawerPage />
                            </PermissionElement>
                          }
                        />
                      </Route>
                      <Route
                        path={paths.clientSubPath()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.ClientsNavigation}
                          >
                            <ClientViewDrawerPage />
                          </PermissionElement>
                        }
                      />
                      <Route
                        path={paths.clientAppealView()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.ClientsNavigation}
                          >
                            <ClientViewDrawerPage />
                          </PermissionElement>
                        }
                      />
                      <Route
                        path={paths.clientAppealOpoView()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.ClientsNavigation}
                          >
                            <AppealOpoByEmployingOrganization />
                          </PermissionElement>
                        }
                      />
                      <Route
                        path={paths.clientEdit()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.ClientsUpdate}
                          >
                            <ClientEditDrawerPage />
                          </PermissionElement>
                        }
                      />
                    </Route>

                    <Route path={paths.profile()} element={<ProfilePage />}>
                      <Route
                        path={paths.editProfile()}
                        element={
                          <PermissionElement
                            permission={
                              AccessPermissions.EmployingOrganizationsProfileUpdate
                            }
                          >
                            <EmployingOrganizationProfileEditDrawerPage />
                          </PermissionElement>
                        }
                      />
                    </Route>

                    <Route
                      path={paths.multipleAppealOpo()}
                      element={
                        <PermissionElement
                          permission={AccessPermissions.AppealsOpoNavigation}
                        >
                          <MultipleAppealOpoPage />
                        </PermissionElement>
                      }
                    ></Route>
                  </Routes>
                </Suspense>
              </Router>
            </ApolloProvider>
          </ToastsContextProvider>
        </ConfirmContextProvider>
      </AuthContextProvider>
    </UIKitProvider>
  );
}

export default App;
