import * as yup from "yup";
import { locale } from "locale";
import { RegExpress } from "utils/regularExpression";

export const updateEmployingOrganizationValidationSchema = yup.object().shape({
  organizationName: yup.string().required(`${locale.REQUIRED}`),
  address: yup.string().required(`${locale.REQUIRED}`),
  delagateName: yup.string().required(`${locale.REQUIRED}`),
  delagateSurname: yup.string().required(`${locale.REQUIRED}`),
  delagateFathersName: yup.string().required(`${locale.REQUIRED}`),
  inn: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .test(
      "len",
      "Поле должно содержать 10 или 12 символов",
      (val) => val?.length === 10 || val?.length === 12
    ),
  ogrn: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .test(
      "len",
      "Поле должно содержать 13 или 15 символов",
      (val) => val?.length === 13 || val?.length === 15
    ),
  kpp: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .test(
      "len",
      "Поле должно содержать 9 символов",
      (val) => val?.length === 9
    ),
  phoneNumber: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .matches(RegExpress.phone, `${locale.PHONE}`),
  email: yup.string().required(`${locale.REQUIRED}`).email(`${locale.EMAIL}`),
  mainOkved: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(`${locale.REQUIRED}`),
  }),
});
