import { UserRole } from "types/UserRole";
import {
  FinalConclusion,
  radioOptionsConclusion,
  radioOptionsRestriction,
} from "../FinalConclusion/FinalConclusion";
import { conclusionCommissionValidateCreate } from "./conclussionCommissionValidateCreate";
import { conclusionCommissionValidateSave } from "./conclussionCommissionValidateSave";
import { locale } from "locale";
import { useCreateCommissionHeadConclusionMutation } from "generated/hooks";
import {
  DatePicker,
  Label,
  RadioGroup,
  Typography,
} from "@genome-frontend/uikit/build/lib/uikit";
import { useCreateFinalConclusionWithCsp } from "pages/AppealPage/hooks/useCreateFinalConclusionWithCsp";
import {
  CreateCommissionHeadConclusionInput,
  CreateCommissionHeadConclusionMutation,
} from "generated/operations";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";
import { useFinalConclusionHandlers } from "pages/AppealPage/hooks/useFinalConclusionHandlers";

interface CommissionConclusionProps {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
  employeePosition: string;
  appealOrganizationId: number;
  commissionSize: number | null | undefined;
  clinicalPsychologistAppointment: boolean;
}

export const CommissionConclusion: React.FC<CommissionConclusionProps> = ({
  appealId,
  employeeId,
  employeePosition,
  appealOrganizationId,
  organizationId,
  commissionSize,
  clinicalPsychologistAppointment,
}) => {
  const {
    conclusionTypeCurrent,
    restrictionTypeCurrent,
    restrictionDateEndCurrent,
    saveConclusionStatus,
    handlerConclusion,
    handlerRestriction,
    handlerRestrictionDateEnd,
    handleClose,
  } = useFinalConclusionHandlers(conclusionCommissionValidateSave);
  const isCorrectOrganizationDoctor = appealOrganizationId == organizationId;

  const [createCommissionHeadConclusionMutation] =
    useCreateCommissionHeadConclusionMutation();
  const { createFinalConclusionWithCsp } = useCreateFinalConclusionWithCsp<
    CreateCommissionHeadConclusionMutation,
    CreateCommissionHeadConclusionInput
  >({
    appealId,
    organizationId,
    employeeId,
    conclusionName: ConclusionsName.CommissionHead,
    conclusionCreatedMessage: locale.conclusion.createdCommissionHeadConclusion,
    restrictionTypeCurrent,
    conclusionTypeCurrent,
    restrictionDateEndCurrent,
    async createFinalConclusionMutation(options) {
      const { data } = await createCommissionHeadConclusionMutation(options);
      return { errors: data?.createCommissionHeadConclusion.errors };
    },
  });

  const submitConclusions = async () => {
    await createFinalConclusionWithCsp(null);
    handleClose();
  };

  return (
    <FinalConclusion
      appealId={appealId}
      roleName={UserRole.Doctor}
      createConclusionButtonText="Выдать заключение комиссии"
      saveAllowed={saveConclusionStatus}
      validateCreate={(data) =>
        conclusionCommissionValidateCreate(
          employeeId,
          data,
          commissionSize,
          clinicalPsychologistAppointment,
          isCorrectOrganizationDoctor
        )
      }
      onClose={handleClose}
      onSubmit={submitConclusions}
    >
      <Typography mb="0.5rem">
        {locale.conclusion.conclusionRole} "{employeePosition}"
      </Typography>
      <Label>Выдать разрешение</Label>
      <form>
        <RadioGroup
          options={radioOptionsConclusion}
          onChange={handlerConclusion}
        />
      </form>
      <br></br>
      <Label>Временное ограничение</Label>
      <form>
        <RadioGroup
          options={radioOptionsRestriction}
          onChange={handlerRestriction}
        />
      </form>
      <br></br>
      <Label>Выберите дату окончания ограничения</Label>
      <form>
        <DatePicker
          onChange={handlerRestrictionDateEnd}
          value={restrictionDateEndCurrent}
        />
      </form>
    </FinalConclusion>
  );
};
