import { GridSortModel } from "@mui/x-data-grid";
import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import { useRolesQuery } from "generated/hooks";
import {
  RoleViewFragment,
  RolesQuery,
  RolesQueryVariables,
} from "generated/operations";
import { RoleDataTransformer } from "./utils/roleDataTransformer";

const DEFAULT_SORT: GridSortModel = [{ field: "name", sort: "desc" }];

export type TRolesSelectProps = Partial<
  UseGqlDataGridParams<RolesQuery, RoleViewFragment, RolesQueryVariables>
>;

export type TRolesDataGridResult = UseGqlDataGridResult<
  RolesQuery,
  RoleViewFragment,
  RolesQueryVariables
>;

export const useRoleSelect = (
  props?: TRolesSelectProps
): TRolesDataGridResult => {
  const gridData = useGqlDataGrid<
    RolesQuery,
    RoleViewFragment,
    RolesQueryVariables
  >({
    type: "roles",
    queryHook: useRolesQuery,
    dataTransformer: RoleDataTransformer,
    ...props,
  });

  return gridData;
};
