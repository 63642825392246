export const employingOrganizationUpdate: { [key: string]: string } = {
  organizationName: "Название",
  address: "Адрес",
  surname: "Фамилия",
  name: "Имя",
  fathersName: "Отчество",
  inn: "ИНН",
  ogrn: "ОГРН",
  kpp: "КПП",
  phoneNumber: "Телефон",
  email: "Email",
  mainOkved: "Основной ОКВЭД",
  additionalOkved: "Дополнительный ОКВЭД",
  login: "Логин",
  password: "Пароль",
  hasLoginPassAccess: "Доступ по логину/паролю",
  isAccessBlocked: "Блокировка доступа в систему",
};
