import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import {
  AppealOpoByEmployingOrganizationQuery,
  AppealByAdminViewFragment,
  AppealOpoByEmployingOrganizationQueryVariables,
} from "generated/operations";
import { useAppealOpoByEmployingOrganizationQuery } from "generated/hooks";
import { GridSortModel } from "@mui/x-data-grid";
import { appealOposGridByEmployingOrganizationColumnTransformer } from "./utils/appealOposGridByEmployingOrganizationColumnTransformer";

const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "desc" }];

type AppealOpoByEmployingOrganizationType =
  AppealByAdminViewFragment["documents"][number];

export type TAppealOposByEmployingOrganizationDataGridProps = Partial<
  UseGqlDataGridParams<
    AppealOpoByEmployingOrganizationQuery,
    AppealOpoByEmployingOrganizationType,
    AppealOpoByEmployingOrganizationQueryVariables
  >
>;

export type TAppealOposByEmployingOrganizationDataGridResult =
  UseGqlDataGridResult<
    AppealOpoByEmployingOrganizationQuery,
    AppealOpoByEmployingOrganizationType,
    AppealOpoByEmployingOrganizationQueryVariables
  >;

export const useAppealOposByEmployingOrganizationDataGrid = (
  props?: TAppealOposByEmployingOrganizationDataGridProps
): TAppealOposByEmployingOrganizationDataGridResult => {
  return useGqlDataGrid<
    AppealOpoByEmployingOrganizationQuery,
    AppealOpoByEmployingOrganizationType,
    AppealOpoByEmployingOrganizationQueryVariables
  >({
    type: "appealOpoByEmployingOrganization",
    queryHook: useAppealOpoByEmployingOrganizationQuery,
    defaultSort: DEFAULT_SORT,
    columnTransformer: appealOposGridByEmployingOrganizationColumnTransformer,
    ...props,
  });
};
