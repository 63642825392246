import { Button } from "@mui/material";
import { TokenHelper } from "TokenHelper";

export const HangfireButton = () => {
  const hangfireLocation = `${
    process.env.REACT_APP_IDENTITY_LOCATION
  }/hangfire?token=${TokenHelper.getCurrentToken()}`;

  return (
    <Button variant="contained" href={hangfireLocation}>
      Менеджер задач
    </Button>
  );
};
