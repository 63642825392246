import { ClientOutput, ClientUpdateValue } from "../types";
import { useSendPostRequestToClosedServerWithCsp } from "../../../utils/sendPostRequestToClosedServerWithCsp";
import { getInputDataFromValues } from "../components/ClientEditDrawer/getInputDataFromValues";
import { MaskExpress } from "../../../utils/maskExpression";
import { namedOperations } from "../../../generated/operations";

type TUpdateClientProps = {
  onSuccess: (data?: number | undefined) => void;
  initialValues: ClientUpdateValue | null;
};

type TSubmitWithCspResult = {
  updateClientWithCsp: (values: ClientUpdateValue) => Promise<void>;
  cspLoading: boolean | undefined;
  saving: boolean;
};

export const useUpdateClientWithCsp = ({
  onSuccess,
  initialValues,
}: TUpdateClientProps): TSubmitWithCspResult => {
  const onUpdateSuccess = async (data: ClientOutput) => {
    onSuccess();
  };

  const isInitialValues = (values: ClientUpdateValue) => {
    const newValues = {
      ...values,
      phoneNumber: MaskExpress.ToPhoneNumber((values as any).phoneNumber!),
    };
    return JSON.stringify(newValues) === JSON.stringify(initialValues);
  };

  const { sendPostRequestToClosedServerWithCsp, cspLoading, saving } =
    useSendPostRequestToClosedServerWithCsp<ClientUpdateValue, ClientOutput>({
      endPointPath: "/api/Patient/UpdatePatient",
      refetchQueryNames: [
        namedOperations.Query.Client,
        namedOperations.Query.Clients,
      ],
      onSuccess: onUpdateSuccess,
      successToast: "Пользователь изменён",
      failureToast: "Невозможно изменить пользователя",
      isInitialValues,
    });

  const handleSubmit = async (input: ClientUpdateValue) => {
    const data = getInputDataFromValues(input);
    await sendPostRequestToClosedServerWithCsp(data);
  };

  return {
    updateClientWithCsp: handleSubmit,
    cspLoading,
    saving,
  };
};
