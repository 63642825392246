import { TokenHelper } from "TokenHelper";
import { MainLayout } from "../../components/MainLayout";
import { UsersGrid } from "./components/UsersGrid/UsersGrid";
import { UserRole } from "types/UserRole";
import { OrganizationAdminUsersGrid } from "./components/UsersGrid/OrganizationAdminUsersGrid";

export function UsersPage() {
  const isOrganizationAdmin =
    TokenHelper.getCurrentUserRole() === UserRole.OrganizationAdmin;

  return (
    <MainLayout title="Сотрудники">
      {isOrganizationAdmin ? <OrganizationAdminUsersGrid /> : <UsersGrid />}
    </MainLayout>
  );
}
