import { useField } from "formik";

import { TextInput } from "@genome-frontend/uikit/build/lib/uikit";
import { TTextInputProps } from "@genome-frontend/uikit/build/lib/uikit/TextInput";
import React, { useCallback } from "react";

export type TTextInputFieldProps = {
  name: string;
} & TTextInputProps;

export const TextInputField = ({ name, ...props }: TTextInputFieldProps) => {
  const [field, meta, { setValue }] = useField(name);

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue(evt.target.value);
    },
    [setValue]
  );

  return (
    <TextInput
      name={field.name}
      value={field.value ?? ""}
      onChange={handleChange}
      error={!!meta.error}
      {...props}
    />
  );
};
