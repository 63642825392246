import { ReactNode } from "react";
import { useNavigate } from "react-router";
import { Button } from "@genome-frontend/uikit/build/lib/uikit";
import { PlusOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PlusOutlineIcon";
import { TEmployingOrganizationsDataGridResult } from "./useEmployingOrganizationsDataGrid/useEmployingOrganizationsDataGrid";
import { paths } from "../../../router/paths";

export const useEmployingOrganizationsHeaderButtons = ({
  ids,
  setIds,
}: TEmployingOrganizationsDataGridResult): ReactNode[] => {
  const navigate = useNavigate();

  return [
    ...(ids.length > 0
      ? [
          <Button
            key="showAll"
            onClick={() => {
              setIds([]);
            }}
          >
            Показать всё
          </Button>,
        ]
      : []),
    ...[],
    <Button
      key="add"
      startIcon={<PlusOutlineIcon />}
      onClick={() => {
        navigate(paths.employingOrganizationNewSubPath());
      }}
      variant="contained"
    >
      Добавить организацию-работодателя
    </Button>,
  ];
};
