import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";

import { useGetConclusionFileHistoriesQuery } from "generated/hooks";
import {
  ConclusionFileHistoryItemFragment,
  GetConclusionFileHistoriesQuery,
  GetConclusionFileHistoriesQueryVariables,
} from "generated/operations";
import { sortingTransformer } from "utils/sortingTransformer";
import { whereTransformer } from "utils/whereTransformer";
import { fileChangesColumnTransformer } from "./utils/fileChangesColumnTransformer";
import { GridSortModel } from "@mui/x-data-grid";

const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "desc" }];

export type TFileHistoriesDataGridProps = Partial<
  UseGqlDataGridParams<
    GetConclusionFileHistoriesQuery,
    ConclusionFileHistoryItemFragment,
    GetConclusionFileHistoriesQueryVariables
  >
>;

export type TFileHistoriesDataGridResult = UseGqlDataGridResult<
  GetConclusionFileHistoriesQuery,
  ConclusionFileHistoryItemFragment,
  GetConclusionFileHistoriesQueryVariables
>;

export const useFileHistoriesDataGrid = (
  props?: TFileHistoriesDataGridProps
): TFileHistoriesDataGridResult => {
  const dynamicColumns = new Array<string>();
  const gridName = "conclusionFileHistories";

  return useGqlDataGrid<
    GetConclusionFileHistoriesQuery,
    ConclusionFileHistoryItemFragment,
    GetConclusionFileHistoriesQueryVariables
  >({
    type: gridName,
    defaultSort: DEFAULT_SORT,
    queryHook: useGetConclusionFileHistoriesQuery,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    columnTransformer: fileChangesColumnTransformer,
    dynamicColumns,
    ...props,
  });
};
