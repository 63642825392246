import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { useToasts } from "components/Toast";
import { paths } from "router/paths";
import { UpdateEmployingOrganizationMutation } from "generated/operations";
import { EmployingOrganizationEditDrawer } from "../Components/EmployingOrganizationEditDrawer/EmployingOrganizationEditDrawer";

export const EmployingOrganizationEditDrawerPage: FC = () => {
  const { addSuccessToast } = useToasts();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id!;

  const closeHandler = () => {
    navigate(paths.organizations());
  };

  const successHandler = (payload: UpdateEmployingOrganizationMutation) => {
    if (payload) {
      addSuccessToast("Организация изменена");
      navigate(
        paths.employingOrganization(
          payload.updateEmployingOrganization.employingOrganization?.id
        )
      );
    }
  };

  return (
    <EmployingOrganizationEditDrawer
      id={id}
      onSuccess={successHandler}
      onCancel={closeHandler}
    />
  );
};
