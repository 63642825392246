import { useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import { Switch } from "@genome-frontend/uikit/build/lib/uikit";

export type TSwitchField = {
  name: string;
  label?: string;
  required?: boolean;
  isOn?: boolean;
  shouldValidate?: boolean;
};

export const SwitchField = ({
  label,
  name,
  isOn,
  shouldValidate,
  ...props
}: TSwitchField) => {
  const [field, , { setValue }] = useField(name);
  const [checked, setChecked] = useState(isOn ?? false);

  useEffect(() => {
    setChecked(isOn ?? false);
    setValue(isOn ?? false, shouldValidate);
  }, [isOn, setValue, shouldValidate]);

  const onChange = useCallback(
    (evt: any) => {
      setChecked(evt.target.checked);
      setValue(evt.target.checked, shouldValidate);
    },
    [setChecked, setValue, shouldValidate]
  );

  return (
    <Switch
      key={label}
      onChange={onChange}
      checked={checked}
      label={label}
      name={name}
      {...props}
    />
  );
};
