import { ClientUpdateValue } from "pages/ClientsPage/types";
import { TokenHelper } from "TokenHelper";
import { formatDate } from "utils/formatDate";

export const getInputDataFromValues = (
  values: ClientUpdateValue
): ClientUpdateValue => {
  return {
    id: values.id,
    name: values.name,
    surname: values.surname,
    fathersName: values.fathersName ?? "",
    sex: values.sex,
    birthdate: values.birthdate ? formatDate(values.birthdate, "server") : "",
    snils: values.snils,
    phoneNumber: values.phoneNumber ?? "",
    email: values.email ?? "",
    employeeId: TokenHelper.getCurrentUserId()!,
    employeeFio: TokenHelper.getCurrentUserFio()!,
    organizationId: TokenHelper.getOrganizationId()!,
    organizationName: TokenHelper.getOrganizationName()!,
    addresses:
      values.addresses?.map((adr) => ({
        id: 0,
        fullAddress: adr.fullAddress,
        startDate: adr.startDate ? formatDate(adr.startDate, "server") : "",
        endDate: adr.endDate ? formatDate(adr.endDate, "server") : null,
      })) ?? [],
  };
};
