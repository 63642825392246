import { Outlet } from "react-router";
import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useMultipleAppealOpoTemplateGrid } from "../../hooks/useMultipleAppealOpoTemplateGrid/useMultipleAppealOpoTemplateGrid";
import { useMultipleAppealOpoTemplateGridColumns } from "../../hooks/useMultipleAppealOpoTemplateGrid/useMultipleAppealOpoTemplateGridColumns";
import { useState } from "react";
import { GridInputSelectionModel, GridRowId } from "@mui/x-data-grid";
import { MultipleAppealOpoTemplateFragment } from "../../../../generated/operations";

interface TMultipleAppealOpoTemplateGridProps {
  onTemplateSelected: (template: MultipleAppealOpoTemplateFragment) => void;
  onTemplateDeleteClicked: (templateId: number) => void;
  onTemplateRenameClicked: (
    template: MultipleAppealOpoTemplateFragment
  ) => void;
}

export function MultipleAppealOpoTemplateGrid(
  props: TMultipleAppealOpoTemplateGridProps
) {
  const gridData = useMultipleAppealOpoTemplateGrid();
  const { gridProps } = gridData;

  const actionsColumnProps = {
    onTemplateRenameClicked: (templateId: number) => {
      const template = gridProps.rows.find((e) => e.id == templateId);
      props.onTemplateRenameClicked(template);
    },
    onTemplateRemoveClicked: props.onTemplateDeleteClicked,
  };

  const columns = useMultipleAppealOpoTemplateGridColumns(
    gridData,
    actionsColumnProps
  );

  const [selectedRows, setSelectedRows] = useState<GridInputSelectionModel>([]);

  const handleRowSelected = (newSelectedRows: GridInputSelectionModel) => {
    const newSelectedRowIds = newSelectedRows as Array<GridRowId>;
    const oldSelectedRowIds = selectedRows as Array<GridRowId>;

    if (
      Math.abs(newSelectedRowIds?.length - oldSelectedRowIds?.length ?? 0) > 1
    ) {
      return;
    }

    const lastNewSelectedRowId =
      newSelectedRowIds.length > 0
        ? newSelectedRowIds[newSelectedRowIds.length - 1]
        : [];
    setSelectedRows(lastNewSelectedRowId);

    const template = gridData.rows.find((e) => e.id == lastNewSelectedRowId);
    props.onTemplateSelected(template!);
  };

  return (
    <>
      <DataGrid
        checkboxSelection={true}
        disableDensitySelector={true}
        {...gridProps}
        columns={columns}
        rowsPerPageOptions={[10, 30, 50]}
        showColumnSettings={false}
        showOrderColumn
        autoHeight={true}
        gridKey="multipleAppealOpoTemplates"
        selectionModel={selectedRows}
        onSelectionModelChange={handleRowSelected}
        hideFooterSelectedRowCount={true}
      />
      <Outlet />
    </>
  );
}
