import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";

import { useClientsQuery } from "generated/hooks";
import {
  ClientItemFragment,
  ClientsQuery,
  ClientsQueryVariables,
} from "generated/operations";
import { sortingTransformer } from "utils/sortingTransformer";
import { whereTransformer } from "utils/whereTransformer";
import { clientsColumnTransformer } from "./utils/clientsColumnTransformer";

export type TClientsDataGridProps = Partial<
  UseGqlDataGridParams<ClientsQuery, ClientItemFragment, ClientsQueryVariables>
>;

export type TClientsDataGridResult = UseGqlDataGridResult<
  ClientsQuery,
  ClientItemFragment,
  ClientsQueryVariables
>;

export const useClientsDataGrid = (
  props?: TClientsDataGridProps
): TClientsDataGridResult => {
  const gridName = "clients";
  return useGqlDataGrid<
    ClientsQuery,
    ClientItemFragment,
    ClientsQueryVariables
  >({
    type: gridName,
    queryHook: useClientsQuery,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    columnTransformer: clientsColumnTransformer,
    ...props,
  });
};
