export const appealOpo: { [key: string]: string } = {
  department: "Введите структурное подразделение (при наличии)",
  employeePosition: "Введите должность сотрудника",
  okved: "Выберите тип деятельности по ОКВЭД",
  workType: "Выберите вид деятельности из приказа 342н",
  organizationInfo: "Выберите медицинскую организацию",
  name: "Название услуги (обращения)",
  responseDate: "Дата получения",
  number: "Номер документа",
  isActual: "Действительно",
  isAbleToCancel: "Отмена заявки",
  setCommissionSize: "Укажите размер врачебной комиссии",
  commissionSizeSet: "Размер врачебной комиссии установлен",
  clinicalPsychologistAppointmentCreated:
    "Назначен осмотр клинического психолога",
};
