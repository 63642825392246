import { Formik } from "formik";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { namedOperations, UserItemFragment } from "generated/operations";
import { useCreateUserMutation } from "generated/hooks";
import { useToasts } from "components/Toast";
import { initialValues } from "./initialValues";
import { DrawerForm } from "components/Form";
import { UserCreateFromValidate } from "./UserCreateFromValidate";
import { UserCreateForm } from "../UserForm";
import { getInputDataFromValues } from "./getInputDataFromValues";
import { isAllFormValuesWasTouched } from "utils/isAllFormValuesWasTouched";
import { UserCreateValue } from "../../types";
import { useConfirm } from "components/Confirm";
import { formCloseHandler } from "components/Form/formCloseHandler";

type TProps = {
  onSuccess: (user: UserItemFragment) => void;
  onCancel: () => void;
};

export const UserCreateDrawer = ({ onSuccess, onCancel }: TProps) => {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const { addOkConfirm } = useConfirm();

  const [createUserMutation] = useCreateUserMutation({
    refetchQueries: [namedOperations.Query.Users],
  });

  const handleSubmit = async (clientValues: UserCreateValue) => {
    const clientInput = getInputDataFromValues(clientValues);

    try {
      const { data } = await createUserMutation({
        variables: {
          userInput: {
            ...clientInput,
          },
        },
      });

      if (data?.createUser && data?.createUser.errors) {
        addErrorsToast(data?.createUser.errors);
        return;
      }

      if (data?.createUser && "user" in data?.createUser) {
        addSuccessToast("Сотрудник создан");
        onSuccess(data?.createUser.user as UserItemFragment);
      }
    } catch (e) {
      addErrorsToast([{ message: "Невозможно создать сотрудника" }]);
      console.error(e);
    }
  };

  const closeHandler = async (values: UserCreateValue) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: UserCreateValue;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open={true}
      onClose={() => closeHandler(values)}
    >
      <Formik<UserCreateValue>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={UserCreateFromValidate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(props.values)}
                title="Регистрация сотрудника"
                confirmButtonProps={{
                  children: "Создать",
                  disabled: !(
                    props.isValid &&
                    props.dirty &&
                    isAllFormValuesWasTouched(props, getInputDataFromValues)
                  ),
                }}
                cancelButtonProps={{
                  onClick: () => closeHandler(props.values),
                }}
              >
                <UserCreateForm />
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
