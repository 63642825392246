import { ReactNode } from "react";
import {
  FileChangesExportExcelButton,
  TFileChangesExportExcelButton,
} from "pages/ChangesPage/components/ExportExcel/FileChangesExportExcelButton";

export const useFileChangesHeaderButtons = ({
  gridData,
}: TFileChangesExportExcelButton): ReactNode[] => {
  return [<FileChangesExportExcelButton gridData={gridData} />];
};
