import {
  FinalAppealConclusionType,
  RestrictionType,
} from "generated/operations";
import {
  FinalAppealConclusionTypeNull,
  RestrictionTypeNull,
} from "pages/AppealPage/utils/types";
import { DateNull } from "types/NullableTypes";
import { isValidDate } from "utils/isValidDate";

export const conclusionCommissionValidateSave = (
  conclusion: FinalAppealConclusionTypeNull,
  restriction: RestrictionTypeNull,
  restrictionDateEnd: DateNull
): boolean => {
  return (
    (conclusion === FinalAppealConclusionType.GOODIN &&
      restriction === RestrictionType.NONE &&
      restrictionDateEnd === null) ||
    (conclusion === FinalAppealConclusionType.UNFIT &&
      (restriction === RestrictionType.NOT_TEMPORARILY_WORK ||
        restriction === RestrictionType.TEMPORARILY_WORK) &&
      isValidDate(restrictionDateEnd))
  );
};
