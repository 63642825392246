import { ClientViewFragment } from "generated/operations";
import { ClientUpdateValue } from "pages/ClientsPage/types";
import { TokenHelper } from "TokenHelper";

export const getInitialValues = (
  data: ClientViewFragment
): ClientUpdateValue => {
  return {
    id: data.id,
    name: data.name,
    surname: data.surname,
    fathersName: data.fathersName ?? "",
    sex: data.sex,
    birthdate: data.birthdate,
    snils: data.snils,
    phoneNumber: data.phoneNumber ?? "",
    email: data.email ?? "",
    employeeId: TokenHelper.getCurrentUserId()!,
    employeeFio: TokenHelper.getCurrentUserFio()!,
    organizationId: TokenHelper.getOrganizationId()!,
    organizationName: TokenHelper.getOrganizationName()!,
    addresses: data.addresses ?? [],
  };
};
