import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { useToasts } from "../components/Toast";

export interface UseGqlCreateMutationParams<TData, TVariables> {
  dataKey: string;
  objectKey: string;
  createMutationHook: (
    options?: MutationFunctionOptions<TData, TVariables>
  ) => Promise<FetchResult<TData, Record<string, any>, Record<string, any>>>;
  onSuccess?: (data: TData) => Promise<void>;
  onError?: () => Promise<void>;
  successToast?: string;
  errorToast?: string;
}

export interface UseGqlCreateMutationResult<TVariables> {
  gqlCreateMutation: (variables: TVariables) => Promise<void>;
}

export const useGqlCreateMutation = <TData, TVariables>({
  dataKey,
  objectKey,
  createMutationHook,
  onSuccess = async () => {},
  onError = async () => {},
  successToast = "Объект создан",
  errorToast = "Невозможно создать объект",
}: UseGqlCreateMutationParams<
  TData,
  TVariables
>): UseGqlCreateMutationResult<TVariables> => {
  const { addSuccessToast, addErrorsToast } = useToasts();

  const gqlCreateMutation = async (variables: TVariables) => {
    try {
      const { data } = await createMutationHook({
        variables,
      });

      if ((data as any)?.[dataKey] && (data as any)?.[dataKey].errors) {
        addErrorsToast((data as any)?.[dataKey].errors);
        return;
      }

      if (
        data &&
        (data as any)?.[dataKey] &&
        objectKey in (data as any)?.[dataKey]
      ) {
        addSuccessToast(successToast);
        await onSuccess(data);
      }
    } catch (e) {
      addErrorsToast([{ message: errorToast }]);
      await onError();
      console.error(e);
    }
  };

  return { gqlCreateMutation };
};
