import React from 'react';
import { Drawer as MuiDrawer } from '@mui/material';
export const Drawer = (props) => {
    return (React.createElement(MuiDrawer, { sx: {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiPaper-root': {
                minWidth: '320px',
            },
        }, anchor: "right", ...props }));
};
