import { Sex } from "generated/operations";
import { locale } from "locale";

export const getSexName = (sex: Sex | undefined): string => {
  switch (sex) {
    case Sex.MALE: {
      return locale.user.sexMale;
    }
    case Sex.FEMALE: {
      return locale.user.sexFemale;
    }
    default: {
      return "";
    }
  }
};
