import { useDiagnosesLazyQuery } from "generated/hooks";
import { DiagnosesQuery, SortEnumType } from "generated/operations";
import React from "react";
import {
  GqlAutocomplete,
  GqlAutocompleteOption,
} from "components/GqlAutocomplete";

export interface SelectDiagnosisProps {
  onChangeDiagnosis(value: GqlAutocompleteOption): void;
}

export function SelectDiagnosis({ onChangeDiagnosis }: SelectDiagnosisProps) {
  const [diagnosisQuery] = useDiagnosesLazyQuery();

  const dataTransformer = (data: DiagnosesQuery) => {
    return (
      data?.diagnoses?.items?.map((diag) => ({
        id: diag.id,
        caption: diag.name || "",
      })) ?? []
    );
  };

  const variablesTransformer = (search: string) => {
    return {
      skip: null,
      take: 10,
      order: {
        id: SortEnumType.DESC,
      },
      where: {
        name: {
          contains: search,
        },
      },
    };
  };

  return (
    <GqlAutocomplete
      queryHook={diagnosisQuery}
      dataTransformer={dataTransformer}
      variablesTransformer={variablesTransformer}
      onChange={(event, value) => onChangeDiagnosis(value!)}
    />
  );
}
