import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { OrganizationEditDrawer } from "../Components/OrganizationEditDrawer/OrganizationEditDrawer";
import { useToasts } from "components/Toast";
import { paths } from "router/paths";
import { UpdateOrganizationMutation } from "generated/operations";

export const OrganizationEditDrawerPage: FC = () => {
  const { addSuccessToast } = useToasts();
  const navigate = useNavigate();
  const params = useParams();
  const id = Number(params.id);

  const closeHandler = () => {
    navigate(paths.organizations());
  };

  const successHandler = (payload: UpdateOrganizationMutation) => {
    if (payload) {
      addSuccessToast("Организация изменена");
      navigate(paths.organization(payload.updateOrganization.organization?.id));
    }
  };

  return (
    <OrganizationEditDrawer
      id={id}
      onSuccess={successHandler}
      onCancel={closeHandler}
    />
  );
};
