import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  DefaultOptions,
  from,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { OperationDefinitionNode } from "graphql/index";
import { TokenHelper } from "./TokenHelper";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { handleGraphqlError } from "./errors/handleGraphqlError";

export const ApolloHelper = {
  configureClient(): ApolloClient<NormalizedCacheObject> {
    const handleVariables = (
      value: string | number | Record<string, any> | null
    ) => {
      if (value === null) {
        return null;
      }
      if (typeof value === "string") {
        if (value === "") return null;
        return value.trim();
      }
      if (typeof value === "object") {
        for (let i in value) {
          if (!value.hasOwnProperty(i)) continue;
          value[i] = handleVariables(value[i] as Record<string, unknown>);
        }
      }
      return value;
    };

    const handleVariablesLink = new ApolloLink((operation, forward) => {
      if (
        (operation.query.definitions[0] as OperationDefinitionNode)
          ?.operation === "mutation"
      ) {
        operation.variables = handleVariables(operation.variables) as Record<
          string,
          any
        >;
      }
      return forward(operation);
    });

    const httpLink = createHttpLink({
      uri: "/graphql",
    });

    const getAuthorizationHeader = () => {
      const token = TokenHelper.getCurrentToken();
      if (token) {
        return {
          Authorization: `Bearer ${token}`,
        };
      }
      return {};
    };

    const getOpenedTokenHeader = () => {
      const token = TokenHelper.getOpenedToken();
      if (token) {
        return {
          "X-OpenedToken": token,
        };
      }
      return { "X-OpenedToken": "none" };
    };

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...getAuthorizationHeader(),
        },
      };
    });

    const openedTokenLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...getOpenedTokenHeader(),
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, forward, operation }) => {
      if (graphQLErrors && graphQLErrors.length > 0) {
        let currentErrorIndex = 0;

        const next = async (): Promise<boolean> => {
          if (currentErrorIndex >= graphQLErrors.length) return true;

          const error = graphQLErrors[currentErrorIndex++];
          if (
            !error.extensions ||
            error.extensions.code !== "AUTH_NOT_AUTHENTICATED"
          ) {
            return next();
          }

          return handleGraphqlError({
            code: error.extensions.code,
            operationName: operation.operationName,
            variables: operation.variables,
            next,
          });
        };

        return fromPromise(next())
          .filter((retry) => retry)
          .flatMap(() => forward(operation));
      } else {
        return forward(operation);
      }
    });

    const defaultOptions: DefaultOptions = {
      query: {
        fetchPolicy: "network-only",
      },
    };

    return new ApolloClient({
      link: from([
        errorLink,
        openedTokenLink,
        handleVariablesLink,
        authLink,
        httpLink,
      ]),
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    });
  },

  async onLogout(apolloClient: ApolloClient<any>) {
    await apolloClient.clearStore();
  },
};
