import React, { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { paths } from "router/paths";
import { ClientViewFragment } from "generated/operations";
import { ClientViewDrawer, ViewFrom } from "../components/ClientViewDrawer";

export const EmployingOrganizationClientViewDrawerPage: FC = () => {
  const navigate = useNavigate();

  const params = useParams();

  const id = String(params.id);

  const closeHandler = () => {
    navigate(paths.clientCreate());
  };

  const editHandler = (client: ClientViewFragment) => {
    navigate(paths.clientEdit(client.id));
  };

  return (
    <ClientViewDrawer
      id={id}
      onClose={closeHandler}
      onError={closeHandler}
      onRemoveSuccess={closeHandler}
      onEdit={editHandler}
      viewFrom={ViewFrom.EmployingOrganizationCreateClient}
    />
  );
};
