import { MultipleOpoAppealValues } from "../../types";
import { CreateTemplateForMultipleAppealOposInput } from "generated/operations";
import { getInputDataFromValues as getInputSingle } from "pages/ClientsPage/components/CreateAppealOpo/getInputDataFromValues";

export const getInputDataForTemplateFromValues = (
  data: MultipleOpoAppealValues
): CreateTemplateForMultipleAppealOposInput => {
  return {
    inputs: {
      appealOpos: data.values.map((v) => getInputSingle(v, v.userId)),
      name: data.fileName,
    },
  };
};
