export const ConclusionFileExt = ".pdf";

export const OrganizationStampAcceptableExt = ".jpeg,.jpg,.bmp,.png";
export const OrganizationStampAcceptableMimeTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/bmp",
];

export const OrganizationLicenseAcceptableExt = ".pdf";
export const OrganizationLicenseAcceptableMimeTypes = ["application/pdf"];

export const MultipleAppealOpoFileAcceptableExts = ".xls, .xlsx";
export const MultipleAppealOpoFileAcceptableMimeTypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
