import { FC } from "react";
import { useToasts } from "components/Toast";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { useCreateEmployingOrganizationMutation } from "generated/hooks";
import { EmployingOrganizationValues } from "../../types";
import { Formik } from "formik";
import { DrawerForm } from "components/Form";
import { useConfirm } from "components/Confirm";
import { formCloseHandler } from "components/Form/formCloseHandler";
import { getCreateEmployingOrganizationInputDataFromValues } from "../../utils/getCreateEmployingOrganizationInputDataFromValues";
import { getEmployingOrganizationInitialValues } from "../../utils/getEmployingOrganizationInitialValues";
import { EmployingOrganizationCreateFormValidate } from "../EmployingOrganizationForm/EmployingOrganizationCreateFromValidate";
import { EmployingOrganizationCreateForm } from "../EmployingOrganizationForm/EmployingOrganizationCreateForm";

interface EmployingOrganizationCreateDrawerProps {
  onSuccess: (data?: number | undefined) => void;
  onCancel: () => void;
}

const initialValues: EmployingOrganizationValues =
  getEmployingOrganizationInitialValues();

export const EmployingOrganizationCreateDrawer: FC<
  EmployingOrganizationCreateDrawerProps
> = ({ onSuccess, onCancel }) => {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const { addOkConfirm } = useConfirm();

  const [createEmployingOrganizationMutation, { loading: saving }] =
    useCreateEmployingOrganizationMutation();

  const createEmployingOrganization = async (
    values: EmployingOrganizationValues
  ) => {
    const employingOrganizationInput =
      getCreateEmployingOrganizationInputDataFromValues(values);
    const { data } = await createEmployingOrganizationMutation({
      variables: {
        input: employingOrganizationInput,
      },
    });

    let createEmployingOrganizationResult = data?.createEmployingOrganization;
    if (createEmployingOrganizationResult) {
      if (createEmployingOrganizationResult.errors) {
        addErrorsToast(createEmployingOrganizationResult.errors);
        return;
      }

      if ("employingOrganization" in createEmployingOrganizationResult) {
        addSuccessToast("Организация создана");
        onSuccess(createEmployingOrganizationResult.employingOrganization?.id);
      }
    }
  };

  const submitHandler = (values: EmployingOrganizationValues) => {
    createEmployingOrganization(values);
  };

  const closeHandler = async (values: EmployingOrganizationValues) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: EmployingOrganizationValues;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open
      onClose={() => closeHandler(values)}
    >
      <Formik<EmployingOrganizationValues>
        onSubmit={submitHandler}
        initialValues={initialValues}
        validate={EmployingOrganizationCreateFormValidate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(props.values)}
                title="Новая организация-работодатель"
                confirmButtonProps={{
                  disabled: !(
                    props.isValid &&
                    props.dirty &&
                    Object.keys(EmployingOrganizationCreateFormValidate(values))
                      .length == 0
                  ),
                }}
                cancelButtonProps={{
                  onClick: () => closeHandler(props.values),
                }}
              >
                <EmployingOrganizationCreateForm />
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
