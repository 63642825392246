import { useCreateCspMutation } from "generated/hooks";
import { Dispatch } from "react";
import {
  doFriendlyCustomCertsList,
  DUPLICATE_CSP_CERTIFICATE,
  signBase64string,
} from "utils/certificates";
import { expired } from "types/cspCertificateInfo";
import { UtcDateNow } from "utils/timeExtensions";
import { useToasts } from "components/Toast";

type TSubmitCertificateResult = {
  saving: Boolean;
  submitCertificate: (
    thumbprint: string,
    setSubmitLoading: Dispatch<boolean>
  ) => void;
};

export const useSubmitCertificate = (): TSubmitCertificateResult => {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const [createCspMutation, { loading: saving }] = useCreateCspMutation();

  const submitCertificate = (
    thumbprint: string,
    setSubmitLoading: Dispatch<boolean>
  ): void => {
    setSubmitLoading(true);
    doFriendlyCustomCertsList().then(async (x) => {
      const certificateInfo = x.find((c) => c.thumbprint === thumbprint);
      if (!expired(certificateInfo)) {
        try {
          const cspSignature = await signBase64string(
            certificateInfo!.thumbprint,
            UtcDateNow()
          );
          localStorage.setItem("thumbprint", thumbprint);

          const response = await createCspMutation({
            variables: {
              cspInput: {
                cspSignature: cspSignature,
                expires: certificateInfo!.validTo,
              },
            },
          });
          setSubmitLoading(false);
          if (response.data?.createCsp && response.data?.createCsp.errors) {
            if (
              response.data?.createCsp.errors.find(
                (message: any) => message.message === DUPLICATE_CSP_CERTIFICATE
              )
            ) {
              addErrorsToast([
                { message: "Данный сертификат уже привязан к аккаунту" },
              ]);
            } else {
              addErrorsToast(response.data?.createCsp.errors);
            }
            setSubmitLoading(false);
            return;
          }
          if (
            response.data?.createCsp &&
            response.data?.createCsp.cspCertificate
          ) {
            addSuccessToast("Сертификат привязан к аккаунту");
            setSubmitLoading(false);
          }
        } catch {
          addErrorsToast([{ message: "Ошибка привязки" }]);
          setSubmitLoading(false);
        }
      }
    });
  };

  return {
    saving,
    submitCertificate,
  };
};
