import { GridRenderCellParams } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { ColumnTransformer } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { locale } from "locale";
import { renderCellWithTooltip } from "components/DataGrid/renderCellWithTooltip";
import { ConclusionFileHistoryItemFragment } from "generated/operations";
import { formatDate } from "utils/formatDate";

const fieldsOrder = [
  "dateChanged",
  "clientFio",
  "doctorFio",
  "organizationName",
  "appealType",
  "conclusionName",
  "prevFileName",
  "newFileName",
].reverse();

const getHeaderName = (field: string): string => {
  return (locale.fileChanges as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);

  switch (column.field) {
    case "dateChanged":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.trackedTime,
          tooltipTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.trackedTime,
        }),
        minWidth: 220,
      };
    case "clientFio":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        minWidth: 120,
        renderCell: renderCellWithTooltip(),
      };
    case "doctorFio":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        renderCell: ({
          row,
        }: GridRenderCellParams<string, ConclusionFileHistoryItemFragment>) => {
          return (
            <>
              {row.doctorPosition}
              <br></br>
              {row.doctorFio}
            </>
          );
        },
      };
    case "organizationName":
      return {
        ...column,
        headerName,
        sortable: true,
        renderCell: renderCellWithTooltip(),
      };
    case "appealType":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        minWidth: 120,
        renderCell: renderCellWithTooltip(),
      };
    case "conclusionName":
      return {
        ...column,
        headerName,
        sortable: true,
        renderCell: ({
          row,
        }: GridRenderCellParams<string, ConclusionFileHistoryItemFragment>) => {
          return (
            <>
              {row.conclusionName} <br></br>
              {`№ ${row.conclusionNumber} от ${formatDate(row.conclusionDate)}`}
            </>
          );
        },
      };
    case "prevFileName":
      return {
        ...column,
        headerName,
        sortable: true,
        renderCell: renderCellWithTooltip(),
      };
    case "newFileName":
      return {
        ...column,
        headerName,
        sortable: true,
        renderCell: renderCellWithTooltip(),
      };

    default:
      return {
        ...column,
        headerName,
        renderCell: renderCellWithTooltip(),
      };
  }
};

export const fileChangesColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
