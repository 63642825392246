import { DataTransformer } from "components/DataGrid";
import { PatientItemFragment, PatientsQuery } from "generated/operations";

export const dataTransformer: DataTransformer<
  PatientsQuery,
  PatientItemFragment
> = (data) => {
  return {
    rows: data?.employingOrganizationPatients?.items ?? [],
    rowCount: data?.employingOrganizationPatients?.totalCount ?? 0,
  };
};
