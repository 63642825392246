import { useState } from "react";
import { DateNull } from "types/NullableTypes";
import {
  FinalAppealConclusionTypeNull,
  RestrictionTypeNull,
} from "../utils/types";
import { RadioGroupProps } from "@genome-frontend/uikit/build/lib/uikit/RadioButton";
import { RestrictionType } from "generated/operations";

export const useFinalConclusionHandlers = (
  validateSave: (
    conclusion: FinalAppealConclusionTypeNull,
    restriction: RestrictionTypeNull,
    restrictionDateEnd: DateNull
  ) => boolean
) => {
  const [conclusionTypeCurrent, setConclusionTypeCurrent] =
    useState<FinalAppealConclusionTypeNull>(null);
  const [restrictionTypeCurrent, setRestrictionTypeCurrent] =
    useState<RestrictionTypeNull>(null);
  const [restrictionDateEndCurrent, setRestrictionDateEnd] =
    useState<DateNull>(null);

  const handlerConclusion: RadioGroupProps["onChange"] = (e, value) => {
    if (value !== null) {
      setConclusionTypeCurrent(value as FinalAppealConclusionTypeNull);
    }
  };

  const handlerRestriction: RadioGroupProps["onChange"] = (e, value) => {
    if (value !== null) {
      const restriction = value as RestrictionType;
      setRestrictionTypeCurrent(restriction);
      if (restriction === RestrictionType.NONE) {
        setRestrictionDateEnd(null);
      }
    }
  };

  const handlerRestrictionDateEnd = (date: DateNull) => {
    setRestrictionDateEnd(date);
  };

  const handleClose = () => {
    setConclusionTypeCurrent(null);
    setRestrictionTypeCurrent(null);
    setRestrictionDateEnd(null);
  };

  const saveConclusionStatus = validateSave(
    conclusionTypeCurrent,
    restrictionTypeCurrent,
    restrictionDateEndCurrent
  );

  return {
    conclusionTypeCurrent,
    restrictionTypeCurrent,
    restrictionDateEndCurrent,
    saveConclusionStatus,
    handlerConclusion,
    handlerRestriction,
    handlerRestrictionDateEnd,
    handleClose,
  };
};
