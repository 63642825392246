import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";

import { useMultipleAppealOpoTemplatesQuery } from "generated/hooks";
import {
  MultipleAppealOpoTemplateFragment,
  MultipleAppealOpoTemplatesQuery,
  MultipleAppealOpoTemplatesQueryVariables,
} from "generated/operations";
import { sortingTransformer } from "../../../../utils/sortingTransformer";
import { whereTransformer } from "../../../../utils/whereTransformer";
import { multipleAppealOpoTemplatesColumnTransformer } from "./utils/columnsTransformer";

export type TMultipleAppealOpoTemplateGridProps = Partial<
  UseGqlDataGridParams<
    MultipleAppealOpoTemplatesQuery,
    MultipleAppealOpoTemplateFragment,
    MultipleAppealOpoTemplatesQueryVariables
  >
>;

export type TMultipleAppealOpoTemplateGridResult = UseGqlDataGridResult<
  MultipleAppealOpoTemplatesQuery,
  MultipleAppealOpoTemplateFragment,
  MultipleAppealOpoTemplatesQueryVariables
>;

export const useMultipleAppealOpoTemplateGrid = (
  props?: TMultipleAppealOpoTemplateGridProps
): TMultipleAppealOpoTemplateGridResult => {
  const gridName = "multipleAppealOpoTemplates";
  const gridData = useGqlDataGrid<
    MultipleAppealOpoTemplatesQuery,
    MultipleAppealOpoTemplateFragment,
    MultipleAppealOpoTemplatesQueryVariables
  >({
    type: gridName,
    queryHook: useMultipleAppealOpoTemplatesQuery,
    sortingTransformer: sortingTransformer(gridName),
    columnTransformer: multipleAppealOpoTemplatesColumnTransformer,
    whereTransformer: whereTransformer(gridName),
    ...props,
  });
  return gridData;
};
