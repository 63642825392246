import { ReactNode } from "react";

import {
  ReportsExportExcelButton,
  TReportsExportExcelButton,
} from "pages/ReportsPage/components/ExportExcel/ReportsExportExcelButton";

export const useReportsHeaderButtons = ({
  gridData,
}: TReportsExportExcelButton): ReactNode[] => {
  return [<ReportsExportExcelButton gridData={gridData} />];
};
