import { useNavigate, Outlet } from "react-router";
import {
  DataGrid,
  GridEventListener,
  GridEvents,
} from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useOrganizationAdminUsersDataGrid } from "pages/UsersPage/hooks/useUsersDataGrid/useUsersDataGrid";
import { paths } from "router/paths";

export function OrganizationAdminUsersGrid() {
  const gridData = useOrganizationAdminUsersDataGrid();

  const navigate = useNavigate();

  const handleRowDoubleClick: GridEventListener<GridEvents.rowClick> = ({
    row,
  }) => {
    navigate(paths.userEdit(row.id));
  };

  const { gridProps } = gridData;

  return (
    <>
      <DataGrid
        {...gridProps}
        onRowDoubleClick={handleRowDoubleClick}
        columns={gridProps.columns}
        rowsPerPageOptions={[10, 30, 50]}
        showColumnSettings={false}
        showOrderColumn
        gridKey="organizationAdminUsers"
      />
      <Outlet />
    </>
  );
}
