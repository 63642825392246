import React, { useCallback, KeyboardEvent } from "react";
import { TextInputField, TTextInputFieldProps } from "./TextInputField";
import { TValidationFieldContext } from "./base/ValidationFieldsContext";
import { useField } from "formik";

const isFirefox = navigator.userAgent.indexOf("Firefox") != -1;

export type TValidationTextInputFieldProps = {
  name: string;
  validationFieldContext: TValidationFieldContext;
} & TTextInputFieldProps;

export const ValidationTextInputField = ({
  validationFieldContext,
  name,
  ...props
}: TValidationTextInputFieldProps) => {
  const [field, meta, { setValue }] = useField(name);

  const onChange = useCallback(
    (e: any) => {
      const value = e.target.value;
      setValue(value);

      validationFieldContext.setFieldTouched(name, true);
      validationFieldContext.setFieldValue(name, value);

      const error = validationFieldContext.formValidationFunc({
        [name]: value,
      }) as any;
      validationFieldContext.setFieldError(name, error[name]);
    },
    [validationFieldContext, name, setValue]
  );

  const checkIfDigit = (e: KeyboardEvent<HTMLImageElement>) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    return !e.key.match(regex) && e.preventDefault();
  };

  const isNumberInputAndFirefoxBrowser = props.type === "number" && isFirefox;

  return (
    <TextInputField
      onChange={onChange}
      onBlur={onChange}
      onKeyDown={isNumberInputAndFirefoxBrowser ? checkIfDigit : undefined}
      name={name}
      helperText={meta.touched && meta.error}
      value={field.value}
      error={meta.touched && !!meta.error}
      {...props}
    />
  );
};
