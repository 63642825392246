import { Outlet } from "react-router";
import React, { FC, useMemo } from "react";
import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useAppealsDataGrid } from "pages/AppealPage/hooks/useAppealsDataGrid/useAppealsDataGrid";
import { useAppealsDataGridColumns } from "pages/AppealPage/hooks/useAppealsDataGrid/useAppealsDataGridColumns";

export interface TAppealsGridProps {
  id: string;
}

export const AppealsGrid: FC<TAppealsGridProps> = ({ id }) => {
  const gridData = useAppealsDataGrid({
    variablesTransformer: (variables) => {
      variables.id = id;
      return variables;
    },
  });
  const columns = useAppealsDataGridColumns(gridData);
  const columnsMemo = useMemo(() => columns, [columns]);
  const { gridProps } = gridData;

  return (
    <>
      <DataGrid
        {...gridProps}
        columns={columnsMemo}
        rowsPerPageOptions={[10, 30, 50]}
        showOrderColumn
        disableColumnFilter
        showColumnSettings={false}
        checkboxSelection={false}
        gridKey="appeals"
      />
      <Outlet />
    </>
  );
};
