export const multipleAppealOpo: { [key: string]: string } = {
  fio: "ФИО пользователя",
  department: "Структурное подразделение",
  employeePosition: "Должность",
  okved: "ОКВЭД",
  opoWorkType: "Вид деятельности",
  deleteUserFromMultipleAppeal: "Удалить пользователя из массовой заявки",
  organizationInfo: "Медицинская организация",
  isAbleToCancel: "Отмена заявки",
  appealFile: "Файл таблицы",
};
