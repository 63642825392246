import { useNavigate } from "react-router";
import { UserItemFragment } from "generated/operations";
import { paths } from "router/paths";
import { UserCreateDrawer } from "../components/UserCreateDrawer/UserCreateDrawer";

export const UserCreatePage = () => {
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate(paths.users());
  };

  const successHandler = (user: UserItemFragment) => {
    navigate(paths.user(user?.id));
  };

  return (
    <UserCreateDrawer onCancel={closeHandler} onSuccess={successHandler} />
  );
};
