import { useCreateAppealMutation } from "generated/hooks";
import {
  CreateAppealInput,
  CreateAppealsInput,
  namedOperations,
} from "generated/operations";
import { useGqlCreateMutationWithCsp } from "utils/useGqlCreateMutationWithCsp";

type TCreateAppealWithCspProps = {
  checkedTypes: number[];
  clientId: string;
  employeeId: string;
};

type TCreateAppealWithCspResult = {
  createAppealWithCsp: (values: any) => Promise<void>;
};

export const useCreateAppealWithCsp = ({
  checkedTypes,
  clientId,
  employeeId,
}: TCreateAppealWithCspProps): TCreateAppealWithCspResult => {
  const [createAppealMutation] = useCreateAppealMutation({
    refetchQueries: [namedOperations.Query.AppealsByAdmin],
  });

  const { gqlCreateMutationWithCsp } = useGqlCreateMutationWithCsp({
    dataKey: "createAppeals",
    objectKey: "appealEntity",
    createMutationHook: createAppealMutation,
    successToast: "Заявка создана",
    errorToast: "Невозможно создать заявку",
  });

  const createAppealsFromCheck = () => {
    const appeals: CreateAppealInput[] = checkedTypes.map((typeId) => ({
      appealTypeId: typeId,
      requestDate: new Date(),
      userId: clientId,
      employeeId: employeeId,
    }));

    const appealsInput: CreateAppealsInput = { input: appeals };
    return appealsInput;
  };

  const createAppealWithCsp = async () => {
    await gqlCreateMutationWithCsp({
      input: createAppealsFromCheck(),
    });
  };

  return {
    createAppealWithCsp,
  };
};
