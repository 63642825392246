import { BoolNull } from "types/NullableTypes";

export const conclusionDoctorValidateSave = (
  isNarcology: boolean,
  isNarcologyFit: BoolNull,
  isNarcologyDiagnosis: boolean,
  isPsychiatris: boolean,
  isPsychiatrisFit: BoolNull,
  isPsychiatrisDiagnosis: boolean,
  isClinicalPsychologist: boolean,
  isClinicalPsychologistFit: BoolNull
): boolean => {
  let result = false;

  if (isNarcology) {
    if (isNarcologyFit === null) {
      result ||= false;
    }
    if ((isNarcologyFit === false && isNarcologyDiagnosis) || isNarcologyFit) {
      result ||= true;
    }
  }

  if (isPsychiatris) {
    if (isPsychiatrisFit === null) {
      result ||= false;
    }
    if (
      (isPsychiatrisFit === false && isPsychiatrisDiagnosis) ||
      isPsychiatrisFit
    ) {
      result ||= true;
    }
  }

  if (isClinicalPsychologist) {
    if (isClinicalPsychologistFit === null) {
      result ||= false;
    } else {
      result ||= true;
    }
  }

  return result;
};
