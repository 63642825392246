import { SyntheticEvent, useMemo } from "react";
import { useNavigate, Outlet } from "react-router";
import {
  DataGrid,
  GridEventListener,
  GridEvents,
  GridRenderCellParams,
} from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useUsersDataGrid } from "pages/UsersPage/hooks/useUsersDataGrid/useUsersDataGrid";
import { useUsersHeaderButtons } from "pages/UsersPage/hooks/useUsersDataGrid/useUsersHeaderButtons";
import { paths } from "router/paths";
import { useUsersColumns } from "pages/UsersPage/hooks/useUsersColumns";

export function UsersGrid() {
  const gridData = useUsersDataGrid();
  const columns = useUsersColumns(gridData);
  const headerButtons = useUsersHeaderButtons(gridData);

  const columnsWithSwitch = columns.map((col) => {
    return col;
  });

  const navigate = useNavigate();

  const handleRowDoubleClick: GridEventListener<GridEvents.rowClick> = ({
    row,
  }) => {
    navigate(paths.userEdit(row.id));
  };

  const { gridProps } = gridData;

  const headerButtonsMemo = useMemo(() => headerButtons, [headerButtons]);

  return (
    <>
      <DataGrid
        {...gridProps}
        headerButtons={headerButtonsMemo}
        onRowDoubleClick={handleRowDoubleClick}
        columns={columnsWithSwitch}
        rowsPerPageOptions={[10, 30, 50]}
        showColumnSettings={false}
        showOrderColumn
        gridKey="users"
      />
      <Outlet />
    </>
  );
}
