import { FC, useState } from "react";
import {
  Grid,
  Label,
  RadioGroup,
} from "@genome-frontend/uikit/build/lib/uikit";
import {
  useConclusionAppealQuery,
  useCreateConclusionMutation,
} from "generated/hooks";
import { TokenHelper } from "TokenHelper";
import { CreateConclusionMutation } from "generated/operations";
import { SelectDiagnosis } from "./SelectDiagnosis/SelectDiagnosis";
import { conclusionDoctorValidateSave } from "./ConclusionDoctorValidateSave";
import { conclusionDoctorValidateCreate } from "./ConclusionDoctorValidateCreate";
import { BoolNull } from "types/NullableTypes";
import { locale } from "locale";
import "./ConclusionDoctorDrawer.css";
import { GqlAutocompleteOption } from "components/GqlAutocomplete";
import {
  ConclusionDoctorBase,
  handlerFit,
  radioOptions,
} from "./ConclusionDoctorBase";
import { useCreateConclusionsDoctorWithCsp } from "pages/AppealPage/hooks/useCreateConclusionsDoctorWithCsp";

interface ConclusionDoctorCreateProps {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
  roleName: string;
}

export const ConclusionDoctorCreate: FC<ConclusionDoctorCreateProps> = ({
  appealId,
  organizationId,
  employeeId,
  roleName,
}) => {
  const [selectedDiagnosisNarcologyId, setSelectedDiagnosisNarcologyId] =
    useState<GqlAutocompleteOption>({ id: undefined, caption: "" });
  const [selectedDiagnosisPsychiatristId, setSelectedDiagnosisPsychiatristId] =
    useState<GqlAutocompleteOption>({ id: undefined, caption: "" });

  const [isNarcologyFit, setNarcologyFit] = useState<BoolNull>(null);
  const [isPsychiatristFit, setPsychiatristFit] = useState<BoolNull>(null);

  const isNarcologyProfile: boolean = TokenHelper.checkIsNarcologyProfile();
  const isPsychiatristProfile: boolean =
    TokenHelper.checkIsPsychiatristProfile();

  const conclusionData = useConclusionAppealQuery({
    variables: {
      id: appealId,
      roleName: roleName,
      skip: null,
      take: null,
      order: null,
      where: null,
    },
  });

  const createConclusionStatus = conclusionDoctorValidateCreate(
    isNarcologyProfile,
    isPsychiatristProfile,
    conclusionData.data
  );

  const saveConclusionStatus = conclusionDoctorValidateSave(
    isNarcologyProfile,
    isNarcologyFit,
    selectedDiagnosisNarcologyId !== null,
    isPsychiatristProfile,
    isPsychiatristFit,
    selectedDiagnosisPsychiatristId !== null,
    false,
    null
  );

  const [createConclusionMutation] = useCreateConclusionMutation();
  const { createConclusionsDoctorWithCsp } =
    useCreateConclusionsDoctorWithCsp<CreateConclusionMutation>({
      appealId,
      organizationId,
      employeeId,
      selectedDiagnosisNarcologyId,
      selectedDiagnosisPsychiatristId,
      isNarcologyProfile,
      isPsychiatristProfile,
      isClinicalPsychologistProfile: false,
      isNarcologyFit,
      isPsychiatristFit,
      isClinicalPsychologistFit: null,
      createConclusionStatus,
      async createConclusionMutation(options) {
        const { data } = await createConclusionMutation(options);
        return { errors: data?.createConclusion.errors };
      },
    });

  const handleSelectedDiagnosisNarcology = (value: GqlAutocompleteOption) => {
    setSelectedDiagnosisNarcologyId(value);
  };

  const handleSelectedDiagnosisPsychiatrist = (
    value: GqlAutocompleteOption
  ) => {
    setSelectedDiagnosisPsychiatristId(value);
  };

  const handleClose = () => {
    setNarcologyFit(null);
    setPsychiatristFit(null);
  };

  const submitConclusions = async () => {
    await createConclusionsDoctorWithCsp(null);
    handleClose();
  };

  return (
    <ConclusionDoctorBase
      creationAllowed={createConclusionStatus.isCreateConclusion}
      saveAllowed={saveConclusionStatus}
      onClose={handleClose}
      onSubmit={submitConclusions}
    >
      {isNarcologyProfile && createConclusionStatus.isNarcologyAvailable && (
        <>
          <Label>{locale.conclusion.conclusionNarcology}</Label>
          <form>
            <RadioGroup
              options={radioOptions}
              onChange={handlerFit(setNarcologyFit)}
            />
          </form>

          {!isNarcologyFit && (
            <Grid xs={12} item>
              <Label>{locale.conclusion.diagnosisMKB}</Label>
              <SelectDiagnosis
                onChangeDiagnosis={handleSelectedDiagnosisNarcology}
              />
            </Grid>
          )}
        </>
      )}

      {isPsychiatristProfile && createConclusionStatus.isPsychiatryAvailable && (
        <>
          <Label>{locale.conclusion.conclusionPsychiatrist}</Label>
          <form>
            <RadioGroup
              options={radioOptions}
              onChange={handlerFit(setPsychiatristFit)}
            />
          </form>

          {!isPsychiatristFit && (
            <Grid xs={12} item>
              <Label>{locale.conclusion.diagnosisMKB}</Label>
              <SelectDiagnosis
                onChangeDiagnosis={handleSelectedDiagnosisPsychiatrist}
              />
            </Grid>
          )}
        </>
      )}
    </ConclusionDoctorBase>
  );
};
