import { NavLink as NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { IconButton } from "@genome-frontend/uikit/build/lib/uikit/IconButton/IconButton";
import { SignOutOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/SignOutOutlineIcon";
import { AuthContext, AuthStateEnum } from "auth/AuthContextProvider";
import { PersonOutlineIcon } from "icons/PersonOutlineIcon";
import { useAuth } from "auth/useAuth";
import { AccessPermissions } from "types/AccessPermissions";
import { UserRole } from "types/UserRole";
import { TokenHelper } from "TokenHelper";
import { paths } from "router/paths";
import { HangfireButton } from "components/HangfireButton";
import style from "./style.module.scss";
import { useApolloClient } from "@apollo/client";
import { ApolloHelper } from "ApolloHelper";
import { useNavigate } from "react-router";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const apolloClient = useApolloClient();

  const { state, setState } = useContext(AuthContext);
  const { hasAccessPermission } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (state != AuthStateEnum.AUTHORIZED) {
      navigate(paths.login());
    }
  }, [navigate, state]);

  const handleClickLogout = async () => {
    setState(AuthStateEnum.UNAUTHORIZED);
    handleClose();
    localStorage.removeItem("login");
    TokenHelper.logout();
    await ApolloHelper.onLogout(apolloClient);
  };

  const open = Boolean(anchorEl);

  const userFio = TokenHelper.getCurrentUserFio();
  const roleName = TokenHelper.getCurrentUserRole();
  const isAdministrator = roleName === UserRole.SystemAdmin;

  return (
    <>
      <Box
        sx={{
          p: 2,
          backgroundColor: "#0D424E",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack spacing={3} direction="row">
          {hasAccessPermission(AccessPermissions.EmployeesNavigation) && (
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.normal
              }
              to={paths.users()}
            >
              Сотрудники
            </NavLink>
          )}
          {hasAccessPermission(AccessPermissions.ClientsNavigation) && (
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.normal
              }
              to={paths.clients()}
            >
              Пользователи
            </NavLink>
          )}
          {hasAccessPermission(AccessPermissions.Reports) && (
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.normal
              }
              to={paths.reports()}
            >
              Отчеты
            </NavLink>
          )}
          {hasAccessPermission(AccessPermissions.OrganizationNavigation) && (
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.normal
              }
              to={paths.organizations()}
            >
              Организации
            </NavLink>
          )}
          {hasAccessPermission(AccessPermissions.HistoryChangeNavigation) && (
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.normal
              }
              to={paths.changes(0)}
            >
              Изменения
            </NavLink>
          )}
          <NavLink
            className={({ isActive }) =>
              isActive ? style.active : style.normal
            }
            to={paths.profile()}
          >
            Профиль
          </NavLink>
          {hasAccessPermission(AccessPermissions.AppealsOpoNavigation) &&
            roleName === UserRole.EmployingOrganization && (
              <NavLink
                className={({ isActive }) =>
                  isActive ? style.active : style.normal
                }
                to={paths.multipleAppealOpo()}
              >
                Массовая заявка на ОПО
              </NavLink>
            )}
        </Stack>

        <Box sx={{ display: "flex", ml: "auto" }}>
          <Stack sx={{ ml: 2 }} spacing={1} direction="row">
            {isAdministrator && <HangfireButton />}
          </Stack>

          <Stack sx={{ ml: 15 }} spacing={1} direction="row">
            <Typography noWrap sx={{ marginTop: 1, color: "white" }}>
              {userFio}
            </Typography>
            <IconButton onClick={handleClick}>
              <PersonOutlineIcon />
            </IconButton>
          </Stack>
        </Box>
      </Box>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClickLogout}>
          <ListItemIcon>
            <SignOutOutlineIcon />
          </ListItemIcon>
          <ListItemText>Выйти</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
