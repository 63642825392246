import { MainLayout } from "components/MainLayout";
import { Tab, Tabs } from "@genome-frontend/uikit/build/lib/uikit/Tabs/Tabs";
import { Box } from "@genome-frontend/uikit/build/lib/uikit";
import { TabPanel } from "components/TabPanel/TabPanel";
import { UserChangesGrid } from "./components/UserChangesGrid/UserChangesGrid";
import { FileChangesGrid } from "./components/FileChangesGrid/FileChangesGrid";
import React from "react";
import style from "./style.module.scss";
import { useNavigate, useParams } from "react-router";
import { paths } from "router/paths";

export function ChangesPage() {
  const navigate = useNavigate();
  const params = useParams();

  const [currentTab, setCurrentTab] = React.useState(Number(params.id));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    navigate(paths.changes(newValue));
  };

  return (
    <MainLayout title="Изменения">
      <Tabs value={currentTab} onChange={handleChange}>
        <Tab label="Персональные данные пользователя" />
        <Tab label="Файлы заключений врача и терапевта" />
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <Box className={style.container}>
          <UserChangesGrid />
        </Box>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Box className={style.container}>
          <FileChangesGrid />
        </Box>
      </TabPanel>
    </MainLayout>
  );
}
