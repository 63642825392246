import React from "react";
import { SvgIcon } from "@mui/material";
export const KeyboardArrowUpIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "24", height: "24", viewBox: "0 0 24 24", ...props, sx: {
            width: 24,
            height: 24,
            ...props.sx,
        } },
        React.createElement("path", { d: "M0 0h24v24H0z", fill: "none" }),
        React.createElement("path", { d: "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" })));
};
