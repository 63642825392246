import { useToasts } from "components/Toast";
import {
  AppealOposDocument,
  useDeleteAppealOpoMutation,
} from "generated/hooks";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { useParams } from "react-router";
import { DeleteAppealOpoValues } from "pages/ClientsPage/types";
import { namedOperations } from "generated/operations";

type TDeleteAppealOpoWithCspResult = {
  deleteAppealOpo: (values: DeleteAppealOpoValues) => Promise<void>;
};

export const useDeleteAppealOpoWithCsp = (): TDeleteAppealOpoWithCspResult => {
  const params = useParams();
  const userId = params.id;
  const [deleteAppealOpoMutation] = useDeleteAppealOpoMutation();
  const { addSuccessToast, addErrorsToast } = useToasts();

  const deleteAppealOpo = async ({ id }: DeleteAppealOpoValues) => {
    const data = await deleteAppealOpoMutation({
      variables: {
        input: {
          id: id,
        },
      },
      refetchQueries: [namedOperations.Query.AppealOpos],
      // refetchQueries: [
      //   { query: AppealOposDocument, variables: { userId: userId } },
      // ],
    });

    if (data?.data?.deleteAppealOpo?.int) {
      addSuccessToast("Заявка удалена");
    } else {
      addErrorsToast(data?.data?.deleteAppealOpo?.errors);
    }
  };

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: deleteAppealOpo,
  });

  return {
    deleteAppealOpo: submitWithCspHandler,
  };
};
