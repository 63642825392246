import { ClientUpdateValue } from "../../types";
import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { editValidationSchema } from "./editValidationSchema";

export const ClientEditFormValidate = (
  values: ClientUpdateValue
): FormikErrors<ClientUpdateValue> => {
  let errors: FormikErrors<ClientUpdateValue> = {};

  try {
    validateYupSchema(values, editValidationSchema, true, values);
  } catch (err) {
    errors = yupToFormErrors(err);
  }

  return errors;
};
