import { useNavigate } from "react-router";
import { paths } from "router/paths";
import { ClientCreateDrawer } from "../components/ClientCreateDrawer/ClientCreateDrawer";
import { ClientOutput } from "../types";
import { useCreateClient } from "../hooks/useCreateClient";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import { useCreateClientWithUniqueSnils } from "../hooks/useCreateClientWithUniqueSnils";
import { Outlet } from "react-router";

export const ClientCreatePage = () => {
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate(paths.clients());
  };

  const successHandler = (client: ClientOutput) => {
    navigate(paths.client(client?.id));
  };

  const isEmployingOrganization =
    TokenHelper.getCurrentUserRole() === UserRole.EmployingOrganization;

  const { createClient } = useCreateClient({ onSuccess: successHandler });
  const { createClientWithUniqueSnils } = useCreateClientWithUniqueSnils({
    onSuccess: successHandler,
  });

  return (
    <>
      <ClientCreateDrawer
        onCancel={closeHandler}
        onSuccess={successHandler}
        createClient={
          isEmployingOrganization ? createClientWithUniqueSnils : createClient
        }
      />
      <Outlet />
    </>
  );
};
