import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@genome-frontend/uikit/build/lib/uikit";

export interface ClippedTextProps {
  text: string;
}

export const ClippedText = ({ text }: ClippedTextProps) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const [maxColumnWidth, setMaxColumnWidth] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const updateMaxColumnWidth = () => {
      if (columnRef.current) {
        setMaxColumnWidth(columnRef.current.offsetWidth);
      }
    };

    updateMaxColumnWidth();

    window.addEventListener("resize", updateMaxColumnWidth);
    return () => {
      window.removeEventListener("resize", updateMaxColumnWidth);
    };
  }, []);

  return (
    <Typography
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: maxColumnWidth,
      }}
    >
      {text}
    </Typography>
  );
};
