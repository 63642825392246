import { useCallback, useContext } from "react";
import { ConfirmContext, TConfirmContext } from "./ConfirmContextProvider";

export type ConfirmParams = {
  title?: string;
  text: string;
  onClickOk: () => void;
  okText?: string;
  cancelText?: string;
};

interface ConfirmResult {
  addConfirm: TConfirmContext["addConfirm"];
  addOkConfirm: (params: ConfirmParams) => void;
}

export const useConfirm = (): ConfirmResult => {
  const { addConfirm } = useContext(ConfirmContext);

  const addOkConfirm: ConfirmResult["addOkConfirm"] = useCallback(
    ({
      title = "Предупреждение",
      text,
      onClickOk,
      okText = "Да",
      cancelText = "Отмена",
    }) => {
      addConfirm({
        getConfirmProps: (close) => ({
          title,
          text,
          confirmButtonProps: {
            children: okText,
            onClick: () => {
              onClickOk();
              close();
            },
          },
          cancelButtonProps: {
            children: cancelText,
            onClick: () => {
              close();
            },
          },
        }),
      });
    },
    [addConfirm]
  );

  return {
    addConfirm,
    addOkConfirm,
  };
};
