import React, { useCallback } from "react";
import { useField } from "formik";
import {
  GqlAutocomplete,
  GqlAutocompleteOption,
  GqlAutocompleteProps,
} from "../GqlAutocomplete";
import { TValidationFieldContext } from "./base/ValidationFieldsContext";

export type AutocompleteFieldProps<
  TData,
  TVariables,
  Multiple extends boolean | undefined
> = {
  validationFieldContext: TValidationFieldContext;
  name: string;
  selectedCaptionTransformer?: (value: any) => string;
  optionTransformer: (option: GqlAutocompleteOption) => any;
} & Omit<
  GqlAutocompleteProps<TData, TVariables, Multiple>,
  "onChange" | "value" | "onBlur"
>;

export function AutocompleteField<
  TData,
  TVariables,
  Multiple extends boolean | undefined = false
>({
  name,
  selectedCaptionTransformer,
  validationFieldContext,
  optionTransformer,
  ...props
}: AutocompleteFieldProps<TData, TVariables, Multiple>) {
  const [field, meta] = useField(name);

  const onChange = useCallback(
    (value) => {
      const newValue = optionTransformer(value);
      validationFieldContext.setFieldTouched(name, true);
      validationFieldContext.setFieldValue(name, newValue);

      const error = validationFieldContext.formValidationFunc({
        [name]: newValue,
      }) as any;

      validationFieldContext.setFieldError(name, error[name]);
    },
    [optionTransformer, validationFieldContext, name]
  );

  const onBlur = useCallback(() => {
    validationFieldContext.setFieldTouched(name, true);

    const error = validationFieldContext.formValidationFunc({
      [name]: field.value,
    }) as any;
    validationFieldContext.setFieldError(name, error[name]);
  }, [validationFieldContext, name, field]);

  return (
    <GqlAutocomplete
      defaultValue={
        {
          id: field.value ? field.value.id : null,
          caption: selectedCaptionTransformer
            ? selectedCaptionTransformer(field.value)
            : field.value.name,
        } as any
      }
      errorMessage={meta.error as any}
      onChange={(event, value) => onChange(value)}
      onBlur={onBlur}
      error={!!meta.error}
      {...props}
    />
  );
}
