import { Outlet, useNavigate } from "react-router";
import { useMemo } from "react";
import { DataGrid, Typography } from "@genome-frontend/uikit/build/lib/uikit";
import { GridEventListener } from "@mui/x-data-grid";
import { GridEvents } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { paths } from "../../../../router/paths";
import { useEmployingOrganizationsDataGrid } from "../../hooks/useEmployingOrganizationsDataGrid/useEmployingOrganizationsDataGrid";
import { useEmployingOrganizationsHeaderButtons } from "../../hooks/useEmployingOrganizationsHeaderButtons";
import { useEmployingOrganizationsColumns } from "../../hooks/useEmployingOrganizationsColumns";

export function EmployingOrganizationGrid() {
  const gridData = useEmployingOrganizationsDataGrid();
  const headerButtons = useEmployingOrganizationsHeaderButtons(gridData);
  const columns = useEmployingOrganizationsColumns(gridData);

  const navigate = useNavigate();

  const columnsMemo = useMemo(() => columns, [columns]);

  const headerButtonsMemo = useMemo(() => headerButtons, [headerButtons]);

  const { gridProps } = gridData;

  const handleRowDoubleClick: GridEventListener<GridEvents.rowClick> = ({
    row,
  }) => {
    navigate(paths.employingOrganization(row.id));
  };

  return (
    <>
      <Typography sx={{ mt: 2 }} variant="h6">
        Организации-работодатели
      </Typography>
      <DataGrid
        {...gridProps}
        headerButtons={headerButtonsMemo}
        onRowDoubleClick={handleRowDoubleClick}
        columns={columnsMemo}
        rowsPerPageOptions={[10, 30, 50]}
        showOrderColumn
        showColumnSettings={false}
        checkboxSelection={false}
        gridKey="employingOrganizationsList"
      />
      <Outlet />
    </>
  );
}
