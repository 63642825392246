import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
} from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { ReactElement } from "react";

export type ColumnIconActionParams = {
  getIconFunc: (params: GridRowParams) => ReactElement;
  label: string;
  getDisabledFunc?: (params: GridRowParams) => boolean;
};

export interface GetColumnIconActionParams {
  columnIconActions?: ColumnIconActionParams[];
  fieldName?: string;
  headerName?: string;
}

const getGridActionsCellItem = (
  getIconFunc: (params: GridRowParams) => ReactElement,
  label: string,
  getDisabledFunc: ((params: GridRowParams) => boolean) | undefined,
  params: GridRowParams
) => {
  const icon = getIconFunc(params);
  const disabled = getDisabledFunc ? getDisabledFunc(params) : false;
  return (
    <GridActionsCellItem
      key={label}
      label={label}
      icon={icon}
      showInMenu={false}
      disabled={disabled}
    />
  );
};

export default function getColumnIconActions({
  columnIconActions,
  fieldName,
  headerName,
}: GetColumnIconActionParams = {}): GridColumns {
  if (!columnIconActions) {
    return [];
  }
  return [
    {
      headerName: headerName,
      field: fieldName ? fieldName : "customActions",
      type: "actions",
      getActions: (params) => {
        return columnIconActions?.map(
          ({ getIconFunc, label, getDisabledFunc }) =>
            getGridActionsCellItem(getIconFunc, label, getDisabledFunc, params)
        );
      },
    },
  ];
}
