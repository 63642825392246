import { UserCreateValue } from "../../types";

export const initialValues: UserCreateValue = {
  name: "",
  surname: "",
  fathersName: "",
  sex: "",
  birthdate: "",
  snils: "",
  phoneNumber: "",
  email: "",
  roleName: "",
};
