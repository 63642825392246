import { useToasts } from "components/Toast";
import { useConfirm } from "components/Confirm";
import { useCallback } from "react";
import { namedOperations } from "generated/operations";
import { useDeleteTemplateForMultipleAppealOposMutation } from "generated/hooks";
import { locale } from "locale";

type TProps = {
  onSuccess?: () => void;
};

export const useDeleteMultipleAppealOpoTemplate = ({
  onSuccess,
}: TProps = {}): {
  deleteTemplate: (id: number) => void;
} => {
  const { addOkConfirm } = useConfirm();
  const { addErrorsToast } = useToasts();
  const [deleteTemplateMutation] =
    useDeleteTemplateForMultipleAppealOposMutation({
      refetchQueries: [namedOperations.Query.MultipleAppealOpoTemplates],
    });

  const deleteTemplate = useCallback(
    (id: number) => {
      addOkConfirm({
        text: locale.multipleAppealOpoTemplate.deleteTemplate,
        cancelText: locale.CANCEL,
        onClickOk: async () => {
          const removeData = await deleteTemplateMutation({
            variables: {
              input: { input: { id: id } },
            },
          });
          const errors =
            removeData.data?.deleteMultipleAppealOposTemplate.errors;
          if (errors) {
            addErrorsToast(errors);
            return;
          }
          onSuccess?.();
        },
      });
    },
    [deleteTemplateMutation, addErrorsToast, addOkConfirm, onSuccess]
  );
  return { deleteTemplate };
};
