import { GridSortModel } from "@mui/x-data-grid";
import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";

import { useConclusionAppealQuery } from "generated/hooks";
import {
  ConclusionAppealViewFragment,
  ConclusionAppealQuery,
  ConclusionAppealQueryVariables,
} from "generated/operations";

import { appealViewColumnTransformer } from "./utils/appealViewColumnTransformer";

// Сортировка в обратном направлении из-за того, что на беке происходит умножение id на -1, чтобы работал иерархический грид
const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "asc" }];

export type TConclusionAppealDataGridProps = Partial<
  UseGqlDataGridParams<
    ConclusionAppealQuery,
    ConclusionAppealViewFragment,
    ConclusionAppealQueryVariables
  >
>;

export type TConclusionAppealDataGridResult = UseGqlDataGridResult<
  ConclusionAppealQuery,
  ConclusionAppealViewFragment,
  ConclusionAppealQueryVariables
>;

export const useAppealViewDataGrid = (
  props?: TConclusionAppealDataGridProps
): TConclusionAppealDataGridResult => {
  const gridData = useGqlDataGrid<
    ConclusionAppealQuery,
    ConclusionAppealViewFragment,
    ConclusionAppealQueryVariables
  >({
    type: "conclusionAppeals",
    queryHook: useConclusionAppealQuery,
    defaultSort: DEFAULT_SORT,
    columnTransformer: appealViewColumnTransformer,
    ...props,
  });

  return gridData;
};
