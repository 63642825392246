import { UserViewFragment } from "generated/operations";
import React, { FC } from "react";
import { useUserQuery } from "generated/hooks";
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  Grid,
  Label,
  Typography,
} from "@genome-frontend/uikit/build/lib/uikit";
import { getFio } from "utils/getFio";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { locale } from "locale";
import { FormatDate } from "utils/formatDate";
import { Switch } from "@mui/material";
import { useAuth } from "auth/useAuth";
import { AccessPermissions } from "types/AccessPermissions";
import { PencilOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PencilOutlineIcon";
import "./UserViewDrawer.css";
import { getSexName } from "utils/getSexName";
import { UserRole } from "types/UserRole";
import { TokenHelper } from "TokenHelper";

interface UserViewDrawerProps {
  id: String;
  onClose: () => void;
  onError: () => void;
  onEdit: (user: UserViewFragment) => void;
  onRemoveSuccess: () => void;
}

export const UserViewDrawer: FC<UserViewDrawerProps> = ({
  id,
  onClose,
  onError,
  onEdit,
}) => {
  const { data, loading } = useUserQuery({
    variables: {
      id,
    },
    onError: () => {
      onError();
    },
  });
  const isOrganizationAdmin =
    TokenHelper.getCurrentUserRole() === UserRole.OrganizationAdmin;

  const { hasAccessPermission } = useAuth();

  const closeHandler = () => {
    onClose();
  };

  const editHandler = () => {
    if (data?.user) {
      onEdit(data.user);
    }
  };

  if (!loading && !data) {
    return null;
  }

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 920,
        },
      }}
      open
      onClose={closeHandler}
    >
      <DrawerContent
        onClose={closeHandler}
        title={data?.user ? getFio(data.user, true) : ""}
        isWithActions={false}
      >
        {hasAccessPermission(AccessPermissions.EmployeesUpdate) && (
          <Box className={"box_header"}>
            <Box className={"box_center"}></Box>
            <Box className={"box_center"}>
              <Button
                sx={{ ml: 1 }}
                startIcon={<PencilOutlineIcon />}
                onClick={editHandler}
              >
                {locale.EDIT}
              </Button>
            </Box>
          </Box>
        )}
        {loading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            <Grid spacing={2} container>
              {data?.user && (
                <>
                  <Grid xs={12} item>
                    <h3>Персональные данные</h3>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.surname}</Label>
                    <Typography>{data?.user.surname}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.name}</Label>
                    <Typography>{data?.user.name}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.fathersName}</Label>
                    <Typography>{data?.user.fathersName}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.sex}</Label>
                    <Typography>{getSexName(data?.user.sex)}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.birthdate}</Label>
                    <FormatDate date={data?.user?.birthdate} />
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.snils}</Label>
                    <Typography>{data?.user.snils}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.phoneNumber}</Label>
                    <Typography>{data?.user.phoneNumber}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.email}</Label>
                    <Typography>{data?.user.email}</Typography>
                  </Grid>

                  <Grid xs={12} item>
                    <h3>Роль в системе</h3>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.roleName}</Label>
                    <Typography>{data?.user.roleName}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.position}</Label>
                    <Typography>{data?.user.position}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.organization}</Label>
                    <Typography>{data?.user.organizationName}</Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <Label>{locale.user.profile}</Label>
                    <Typography>{data?.user.profileNames}</Typography>
                  </Grid>

                  <Grid xs={12} item>
                    <h3>Вход в систему</h3>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.login}</Label>
                    <Typography>{data?.user.login}</Typography>
                  </Grid>
                  <Grid xs={8} item>
                    <Label>{locale.user.password}</Label>
                    <Typography>{data?.user.password}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.hasLoginPassAccess}</Label>
                    <Switch
                      disabled={true}
                      checked={data?.user.hasLoginPassAccess}
                    />
                  </Grid>
                  <Grid xs={8} item>
                    <Label>{locale.user.isAccessBlocked}</Label>
                    <Switch
                      disabled={true}
                      checked={data?.user.isAccessBlocked}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};
