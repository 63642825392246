import { UpdateUserInput } from "generated/operations";
import { UserUpdateValue } from "pages/UsersPage/types";
import { UserRole } from "types/UserRole";
import { formatDate } from "utils/formatDate";
import { TokenHelper } from "TokenHelper";

export const getInputDataFromValues = (
  values: UserUpdateValue,
  isUpdateUserPsychiatrist: boolean
): UpdateUserInput => {
  const isUserOrgAdmin =
    TokenHelper.getCurrentUserRole() === UserRole.OrganizationAdmin;
  return {
    id: values.id,
    login: values.login ?? "",
    password: values.password ?? "",
    name: values.name,
    surname: values.surname,
    fathersName: values.fathersName ?? "",
    sex: values.sex,
    birthdate: values.birthdate ? formatDate(values.birthdate, "server") : "",
    snils: values.snils,
    phoneNumber: values.phoneNumber ?? "",
    email: values.email ?? "",
    position: values.position ?? "",
    roleName: values.roleName ?? UserRole.SystemAdmin,
    organizationId: values.organization?.id ?? 0,
    profileIds: values.profileIds ? [...values.profileIds] : [],
    hasLoginPassAccess: values.hasLoginPassAccess ?? false,
    isAccessBlocked: values.isAccessBlocked ?? false,
    isCommissionHead:
      isUserOrgAdmin && isUpdateUserPsychiatrist
        ? values.isCommissionHead
        : null,
  };
};
