import { UserCreateValue } from "../../types";
import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { createValidationSchema } from "./createValidationSchema";

export const UserCreateFromValidate = (
  values: UserCreateValue
): FormikErrors<UserCreateValue> => {
  let errors: FormikErrors<UserCreateValue> = {};

  try {
    validateYupSchema(values, createValidationSchema, true, values);
  } catch (err) {
    errors = yupToFormErrors(err);
  }

  return errors;
};
