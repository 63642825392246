import { CreateAppealOpoValues } from "../../types";
import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { createAppealOpoValidationSchema } from "../../utils/createAppealOpoValidationSchema";

export const CreateAppealOpoFormValidate = (
  values: CreateAppealOpoValues
): FormikErrors<CreateAppealOpoValues> => {
  let errors: FormikErrors<CreateAppealOpoValues> = {};

  try {
    validateYupSchema(values, createAppealOpoValidationSchema, true, values);
  } catch (err) {
    errors = yupToFormErrors(err);
  }

  return errors;
};
