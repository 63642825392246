import { useMemo } from "react";
import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit";
import { useUserChangesHeaderButtons } from "pages/ChangesPage/hooks/useUserChangesDataGrid/useUserChangesHeaderButtons";
import { useUserChangesDataGrid } from "pages/ChangesPage/hooks/useUserChangesDataGrid/useUserChangesDataGrid";

export function UserChangesGrid() {
  const gridData = useUserChangesDataGrid();
  const { gridProps } = gridData;
  const columns = gridData.gridProps.columns.concat([]);
  const columnsMemo = useMemo(() => columns, [columns]);
  const headerButtons = useUserChangesHeaderButtons({ gridData });
  const headerButtonsMemo = useMemo(() => headerButtons, [headerButtons]);

  return (
    <DataGrid
      {...gridProps}
      headerButtons={headerButtonsMemo}
      columns={columnsMemo}
      rowsPerPageOptions={[10, 30, 50]}
      showOrderColumn
      showColumnSettings={false}
      gridKey="clientsHistory"
    />
  );
}
