export const reports: { [key: string]: string } = {
  name: "Названия услуги(обращения)",
  doctorPosition: "Заключение врача",
  doctorFio: "ФИО врача",
  patientFio: "ФИО пользователя",
  patientBirthdate: "Дата рождения",
  patientSnils: "СНИЛС",
  responseDate: "Дата получения",
  number: "Номер",
  isActual: "Действительно",
};
