import { useField } from "formik";
import React from "react";
import { TTextInputFieldProps } from "./SelectField";
import { TValidationFieldContext } from "./base/ValidationFieldsContext";
import { SelectValidation } from "../SelectValidation";

export type TValidationSelectFieldProps = {
  name: string;
  onChangeValue?(value: any): void;
  validationFieldContext: TValidationFieldContext;
} & TTextInputFieldProps;

export const ValidationSelectField = ({
  validationFieldContext,
  name,
  onChangeValue,
  ...props
}: TValidationSelectFieldProps) => {
  const validateSingleField = (value: any) => {
    const error = validationFieldContext.formValidationFunc({
      [name]: value,
    }) as any;

    validationFieldContext.setFieldError(name, error[name]);
  };

  const [field, meta, { setValue }] = useField(name);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setValue(value);
    validationFieldContext.setFieldValue(name, value);
    validationFieldContext.setFieldTouched(name, true);
    validateSingleField(value);
  };

  return (
    <SelectValidation
      {...props}
      name={name}
      value={field.value}
      error={!!meta.error}
      onChange={handleChange}
      onBlur={handleChange}
      helperText={
        validationFieldContext.touched[name] &&
        validationFieldContext.errors[name]
      }
    />
  );
};
