import { locale } from "locale";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ColumnTransformer } from "components/DataGrid";
import { AppealViewFragment } from "generated/operations";
import { paths } from "router/paths";
import { Link } from "@mui/material";

const getHeaderName = (field: string): string => {
  return (locale.appeal as { [key: string]: string })[field] ?? field;
};

const fieldsOrder = ["name", "responseDate", "isActual"].reverse();

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);
  column.sortable = false;
  column.headerName = headerName;
  switch (column.field) {
    case "name":
      return {
        ...column,
        headerName,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, AppealViewFragment>) => {
          const appealId = row.id;
          const clientId = row.patientId;
          const appealName = row.name;
          const appealViewPath = paths.clientAppealView(
            clientId,
            appealId,
            appealName
          );
          return (
            <Link
              href={appealViewPath}
              sx={{
                whiteSpace: "normal",
              }}
            >
              {value}
            </Link>
          );
        },
      };
    case "responseDate":
      return {
        ...column,
        headerName,
        renderCell: ({ value }) => "",
      };
    case "isActual":
      return {
        ...column,
        sortable: false,
        maxWidth: 125,
        align: "center",
        renderCell: ({
          value,
        }: GridRenderCellParams<string, AppealViewFragment>) => {
          if (Boolean(value) == true) return <img src="/onCheckbox.ico" />;
          return <img src="/offCheckbox.ico" />;
        },
      };
    default:
      return {
        ...column,
      };
  }
};

export const appealsGridColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
