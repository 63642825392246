import { DataTransformer } from "components/DataGrid";
import {
  EmployingOrganizationFragment,
  EmployingOrganizationsQuery,
} from "generated/operations";

export const dataTransformer: DataTransformer<
  EmployingOrganizationsQuery,
  EmployingOrganizationFragment
> = (data) => {
  return {
    rows: data?.employingOrganizationsList?.items ?? [],
    rowCount: data?.employingOrganizationsList?.totalCount ?? 0,
  };
};
