export const conclusion: { [key: string]: string } = {
  name: "Названия услуги",
  responseDate: "Дата получения",
  number: "Номер документа",
  isActual: "Действительно",
  diagnosisMKB: "Диагноз по МКБ-10",
  uploadFile: "Загрузить файл",
  changeFile: "Заменить файл",
  successUploadFile: "Файл загружен",
  successChangeFile: "Файл заменён",
  downloadFile: "Скачать форму",
  createdPsychiatrist: "Заключение психиатра создано",
  createdNarcology: "Заключение нарколога создано",
  createdTherapist: "Заключение терапевта создано",
  createdClinicalPsychologistConclusion:
    "Заключение клинического психолога создано",
  createdCommissionHeadConclusion: "Заключение комиссии создано",
  conclusionPsychiatrist: "Заключение по профилю психиатрия",
  conclusionNarcology: "Заключение по профилю наркология",
  conclusionPsychologist: "Заключение по профилю психология",
  conclusionRole: "Для деятельности в должности",
  conclusionFileFormatLoad:
    "Допускается загружать файлы заключений только в формате PDF",
};
