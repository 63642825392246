import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { DataGridHeader } from "../DataGrid";
import { DataGridColumnHeader } from "../DataGrid/DataGridColumnHeader";
import { ExpandableItem } from "./ExpandableItem";
export const TreeDataGrid = (props) => {
    const { columns, hiddenColumns, columnsOrder, orderColumnIndex, showOrderColumn, hideHeader, page, pageSize, childrenListName, rows, disableColumnFilter = false, } = props;
    const [editedRows, setRows] = useState(rows);
    const [triggerForUnToggle, setTriggerForUnToggle] = useState(false);
    useEffect(() => {
        setRows(rows);
        setTriggerForUnToggle(!triggerForUnToggle);
    }, [rows]);
    const onToggleExpandableItem = (id, expanding) => {
        const parentItem = rows.find(x => x.id === id);
        if (parentItem) {
            const childrenToModify = parentItem[childrenListName];
            const amountOfChildren = childrenToModify.length;
            setRows((currentRows) => {
                const indexOfParentRow = currentRows.findIndex(x => x.id === id);
                const copyOfEditedRows = currentRows.concat([]);
                if (expanding) {
                    copyOfEditedRows.splice(indexOfParentRow + 1, 0, ...childrenToModify);
                }
                else {
                    copyOfEditedRows.splice(indexOfParentRow + 1, amountOfChildren);
                }
                const rowsSet = new Set([...copyOfEditedRows]);
                return [...rowsSet];
            });
        }
    };
    const formattedColumns = useMemo(() => {
        const reversedColumnOrder = [...(columnsOrder !== null && columnsOrder !== void 0 ? columnsOrder : [])].reverse();
        const res = props.columns
            // Все колонки, которых нет в columnsOrder идут в конец
            .sort((left, right) => {
            return reversedColumnOrder.indexOf(right.field) - reversedColumnOrder.indexOf(left.field);
        })
            .map((column) => {
            return ({
                ...column,
                filterable: false,
                renderHeader: () => React.createElement(DataGridColumnHeader, { column: column, ...props }),
                sortable: false,
                disableColumnMenu: true,
                hide: hiddenColumns === null || hiddenColumns === void 0 ? void 0 : hiddenColumns.includes(column.field),
            });
        });
        if (showOrderColumn) {
            const field = {
                field: 'orderColumn',
                headerName: '№',
                width: 50,
                type: 'number',
                renderCell: ({ row }) => {
                    return row[childrenListName] !== undefined
                        ? (page !== null && page !== void 0 ? page : 0) * (pageSize !== null && pageSize !== void 0 ? pageSize : 0) + rows.map(x => x.id).indexOf(row.id) + 1
                        : null;
                },
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
            };
            res.splice(orderColumnIndex || 0, 0, field);
        }
        const expandColumn = {
            field: 'expandColumn',
            width: 50,
            type: 'actions',
            filterable: false,
            renderCell: ({ row }) => (React.createElement(ExpandableItem, { triggerUnToggle: triggerForUnToggle, onToggleExpandableItem: onToggleExpandableItem, row: row, ...props })),
            sortable: false,
            disableColumnMenu: true,
        };
        res.splice(0, 0, expandColumn);
        return res;
    }, [columns, hiddenColumns, columnsOrder, props.filterModel, props.sortModel, rows]);
    return (React.createElement(Box, { sx: { height: '100%', display: 'flex', flexDirection: 'column' } },
        !hideHeader && React.createElement(DataGridHeader, { ...props }),
        React.createElement(Box, { sx: { flex: 1 } },
            React.createElement(Box, { sx: { height: '100%' } },
                React.createElement(MuiDataGrid, { ...props, rows: editedRows, columns: formattedColumns, filterModel: undefined, onFilterModelChange: undefined, disableColumnFilter: disableColumnFilter, sx: {
                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        ...props.sx,
                    }, 
                    // Для фикса бага с зависанием скролла при изменении количества строк
                    disableVirtualization: true })))));
};
