import React, { FC, useMemo } from "react";
import { Formik } from "formik";
import {
  Drawer,
  DrawerContent,
  Box,
} from "@genome-frontend/uikit/build/lib/uikit";
import { getInitialValues } from "./getInitialValues";
import { DrawerForm } from "components/Form";
import { ClientUpdateValue } from "pages/ClientsPage/types";
import { ClientEditForm } from "../ClientForm";
import { useClientQuery } from "generated/hooks";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { editValidationSchema } from "./editValidationSchema";
import { Tab, Tabs } from "@genome-frontend/uikit/build/lib/uikit/Tabs/Tabs";
import { AppealsGridByAdmin } from "pages/AppealPage/components/AppealsGridByAdmin/AppealsGridByAdmin";
import { TabPanel } from "components/TabPanel/TabPanel";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import { appealCreateValidate } from "pages/AppealPage/components/AppealCreate/AppealCreateValidate";
import style from "./style.module.scss";
import { useUpdateClientWithCsp } from "../../hooks/useUpdateClientWithCsp";
import { formCloseHandler } from "components/Form/formCloseHandler";
import { useConfirm } from "components/Confirm";

interface ClientEditDrawerProps {
  id: String;
  onSuccess: (data?: number | undefined) => void;
  onCancel: () => void;
}

export const ClientEditDrawer: FC<ClientEditDrawerProps> = ({
  id,
  onSuccess,
  onCancel,
}) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const { addOkConfirm } = useConfirm();

  const { data, loading } = useClientQuery({
    variables: {
      id,
    },
    onError: () => {
      onCancel();
    },
  });

  const isCreateAppeal: boolean = appealCreateValidate(data);

  const roleName = TokenHelper.getCurrentUserRole();
  const isAdministrator = roleName === UserRole.OrganizationAdmin;
  const employeeId = TokenHelper.getCurrentUserId();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const initialValues = useMemo(() => {
    if (!data?.client) {
      return null;
    }
    return getInitialValues(data?.client);
  }, [data]);

  const { updateClientWithCsp, cspLoading, saving } = useUpdateClientWithCsp({
    onSuccess,
    initialValues,
  });

  if (!loading && !data?.client) {
    return null;
  }

  const closeHandler = async (values: ClientUpdateValue) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: ClientUpdateValue;

  if (!initialValues || loading) {
    return (
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 1000,
          },
        }}
        open
        onClose={() => closeHandler(values)}
      >
        <DrawerContent
          onClose={() => closeHandler(values)}
          title="Редактирование"
        >
          <CenteredCircularProgress />
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open
      onClose={() => closeHandler(values)}
    >
      <Formik<ClientUpdateValue>
        onSubmit={updateClientWithCsp}
        initialValues={initialValues}
        validationSchema={editValidationSchema}
      >
        {(props) => {
          values = props.values;
          return (
            <>
              {isAdministrator && (
                <>
                  <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    className={style.tabsTop}
                  >
                    <Tab label="Персональные данные" />
                    <Tab disabled={!isCreateAppeal} label="Заявки на услуги" />
                  </Tabs>
                  <TabPanel value={currentTab} index={0}>
                    <DrawerForm>
                      <DrawerContent
                        onClose={() => closeHandler(values)}
                        title="Редактирование"
                        confirmButtonProps={{
                          disabled: saving || !props.isValid,
                        }}
                        cancelButtonProps={{
                          onClick: () => closeHandler(values),
                        }}
                      >
                        {cspLoading && <CenteredCircularProgress />}
                        {data?.client && !cspLoading && (
                          <>
                            <ClientEditForm user={data?.client} />
                          </>
                        )}
                      </DrawerContent>
                    </DrawerForm>
                  </TabPanel>
                  <TabPanel value={currentTab} index={1}>
                    <DrawerContent
                      onClose={() => closeHandler(values)}
                      title="Создание заявок по услугам"
                      isWithActions={false}
                    >
                      <Box className={style.container}>
                        <AppealsGridByAdmin
                          clientId={data?.client.id}
                          employeeId={employeeId!}
                        />
                      </Box>
                    </DrawerContent>
                  </TabPanel>
                </>
              )}
              {!isAdministrator && (
                <DrawerForm>
                  <DrawerContent
                    onClose={() => closeHandler(values)}
                    title="Редактирование"
                    confirmButtonProps={{ disabled: saving || !props.isValid }}
                    cancelButtonProps={{
                      onClick: () => closeHandler(values),
                    }}
                  >
                    {cspLoading && <CenteredCircularProgress />}
                    {data?.client && !cspLoading && (
                      <>
                        <ClientEditForm user={data?.client} />
                      </>
                    )}
                  </DrawerContent>
                </DrawerForm>
              )}
            </>
          );
        }}
      </Formik>
    </Drawer>
  );
};
