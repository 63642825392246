import { EmployingOrganizationValues } from "../../types";
import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { updateEmployingOrganizationValidationSchema } from "pages/OrganizationsPage/utils/updateEmployingOrganizationValidationSchema";

export const EmployingOrganizationUpdateFormValidate = (
  values: EmployingOrganizationValues
): FormikErrors<EmployingOrganizationValues> => {
  let errors: FormikErrors<EmployingOrganizationValues> = {};

  try {
    validateYupSchema(
      values,
      updateEmployingOrganizationValidationSchema,
      true,
      values
    );
  } catch (err) {
    errors = yupToFormErrors(err);
  }

  return errors;
};
