import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import {
  AppealsQuery,
  AppealsQueryVariables,
  AppealViewFragment,
} from "generated/operations";
import { useAppealsQuery } from "generated/hooks";
import { appealsGridColumnTransformer } from "./utils/appealsGridColumnTransformer";
import { GridSortModel } from "@mui/x-data-grid";

const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "desc" }];

export type TAppealsDataGridProps = Partial<
  UseGqlDataGridParams<AppealsQuery, AppealViewFragment, AppealsQueryVariables>
>;

export type TAppealsDataGridResult = UseGqlDataGridResult<
  AppealsQuery,
  AppealViewFragment,
  AppealsQueryVariables
>;

export const useAppealsDataGrid = (
  props?: TAppealsDataGridProps
): TAppealsDataGridResult => {
  const gridData = useGqlDataGrid<
    AppealsQuery,
    AppealViewFragment,
    AppealsQueryVariables
  >({
    type: "appeals",
    queryHook: useAppealsQuery,
    columnTransformer: appealsGridColumnTransformer,
    selection: true,
    defaultSort: DEFAULT_SORT,
    ...props,
  });

  return gridData;
};
