import {
  CreateCommissionHeadConclusionInput,
  namedOperations,
  RestrictionType,
} from "generated/operations";
import { useToasts } from "components/Toast";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { TokenHelper } from "TokenHelper";
import { DateNull } from "types/NullableTypes";
import { formatDate } from "utils/formatDate";
import {
  RestrictionTypeNull,
  FinalAppealConclusionTypeNull,
} from "../utils/types";
import { MutationBaseOptions } from "@apollo/client/core/watchQueryOptions";

type TCreateFinalConclusionProps<TMutation, TInput> = {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
  conclusionName: string;
  conclusionCreatedMessage: string;
  restrictionTypeCurrent: RestrictionTypeNull;
  conclusionTypeCurrent: FinalAppealConclusionTypeNull;
  restrictionDateEndCurrent: DateNull;
  createFinalConclusionMutation: (
    options: MutationBaseOptions<TMutation, { input: TInput }>
  ) => Promise<{ errors: Array<{ message: string }> | null | undefined }>;
};

type TCreateFinalConclusionResult = {
  createFinalConclusionWithCsp: (values: any) => Promise<void>;
};

export const useCreateFinalConclusionWithCsp = <TMutation, TInput>({
  appealId,
  organizationId,
  employeeId,
  conclusionCreatedMessage,
  conclusionName,
  restrictionTypeCurrent,
  conclusionTypeCurrent,
  restrictionDateEndCurrent,
  createFinalConclusionMutation,
}: TCreateFinalConclusionProps<
  TMutation,
  TInput
>): TCreateFinalConclusionResult => {
  const { addSuccessToast, addErrorsToast } = useToasts();

  const createConclusionFromData = () => {
    return {
      appealId: appealId,
      name: conclusionName,
      restrictionType: restrictionTypeCurrent ?? RestrictionType.NONE,
      conclusionType: conclusionTypeCurrent!,
      employeeId: employeeId,
      regionCode: TokenHelper.getRegionCode(),
      restrictionDateEnd: restrictionDateEndCurrent
        ? formatDate(restrictionDateEndCurrent.toDateString(), "server")
        : null,
      medicalOrganizationCode: TokenHelper.getOrganizationCode(),
      organizationId: organizationId,
    };
  };

  const createConclusions = async () => {
    const { errors } = await createFinalConclusionMutation({
      variables: {
        input: createConclusionFromData() as unknown as TInput,
      },
      refetchQueries: [namedOperations.Query.ConclusionAppeal],
    });

    if (errors) {
      return addErrorsToast(errors);
    }

    addSuccessToast(conclusionCreatedMessage);
  };

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: createConclusions,
  });

  return {
    createFinalConclusionWithCsp: submitWithCspHandler,
  };
};
