import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const PlusOutlineIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "16", height: "16", viewBox: "0 0 16 16", ...props, sx: {
            width: 16,
            height: 16,
            ...props.sx,
        } },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.75 1C7.94891 1 8.13968 1.07902 8.28033 1.21967C8.42098 1.36032 8.5 1.55109 8.5 1.75V7H13.75C13.9489 7 14.1397 7.07902 14.2803 7.21967C14.421 7.36032 14.5 7.55109 14.5 7.75C14.5 7.94891 14.421 8.13968 14.2803 8.28033C14.1397 8.42098 13.9489 8.5 13.75 8.5H8.5V13.75C8.5 13.9489 8.42098 14.1397 8.28033 14.2803C8.13968 14.421 7.94891 14.5 7.75 14.5C7.55109 14.5 7.36032 14.421 7.21967 14.2803C7.07902 14.1397 7 13.9489 7 13.75V8.5H1.75C1.55109 8.5 1.36032 8.42098 1.21967 8.28033C1.07902 8.13968 1 7.94891 1 7.75C1 7.55109 1.07902 7.36032 1.21967 7.21967C1.36032 7.07902 1.55109 7 1.75 7H7V1.75C7 1.55109 7.07902 1.36032 7.21967 1.21967C7.36032 1.07902 7.55109 1 7.75 1Z" })));
};
