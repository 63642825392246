import React, { FC } from "react";
import { Grid } from "@genome-frontend/uikit/build/lib/uikit";
import { UserViewFragment } from "generated/operations";
import { useRoleSelect } from "./RoleSelect/RoleSelect";
import { locale } from "../../../../locale";
import { InputAdornment } from "@mui/material";
import { useFormikContext } from "formik";
import { UserCreateValue } from "pages/UsersPage/types";
import { UserCreateFromValidate } from "../UserCreateDrawer/UserCreateFromValidate";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { ValidationDatePickerField } from "components/formFields/ValidationDatePickerField";
import { ValidationSelectField } from "components/formFields/ValidationSelectedField";
import { MaskValidationTextInput } from "components/formFields/MaskValidationTextInput";
import { MaskExpress } from "utils/maskExpression";
import { sexOptions } from "constants/selectFieldConsts";

interface UserCreateFormProps {
  user?: UserViewFragment;
}

export const UserCreateForm: FC<UserCreateFormProps> = () => {
  const { setFieldValue, touched, errors, setFieldError, setFieldTouched } =
    useFormikContext<UserCreateValue>();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: UserCreateFromValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  const roles = useRoleSelect();

  const rolesOptions = roles.rows.map((rol) => ({
    value: rol.name,
    label: rol.name || "",
  }));

  return (
    <Grid alignItems="flex-start" spacing={2} container>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          fullWidth
          required
          label={locale.user.surname}
          name="surname"
        />
      </Grid>

      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          fullWidth
          required
          label={locale.user.name}
          name="name"
        />
      </Grid>

      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          fullWidth
          required
          label={locale.user.fathersName}
          name="fathersName"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationSelectField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.sex}
          options={sexOptions}
          placeholder="Выберите значение"
          name="sex"
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationDatePickerField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label={locale.user.birthdate}
          name="birthdate"
        />
      </Grid>
      <Grid xs={4} item>
        <MaskValidationTextInput
          mask={MaskExpress.snils}
          required
          fullWidth
          label={locale.user.snils}
          name="snils"
          {...validationFieldContext}
        />
      </Grid>
      <Grid xs={4} item>
        <MaskValidationTextInput
          mask={MaskExpress.phone}
          required
          fullWidth
          label={locale.user.phoneNumber}
          name="phoneNumber"
          {...validationFieldContext}
        />
      </Grid>
      <Grid xs={4} item>
        <ValidationTextInputField
          validationFieldContext={validationFieldContext}
          required
          fullWidth
          label="Email"
          name="email"
        />
      </Grid>

      <Grid xs={4} item>
        <ValidationSelectField
          validationFieldContext={validationFieldContext}
          required
          label={locale.user.roleName}
          name="roleName"
          options={rolesOptions}
          placeholder="Выберите значение"
        />
      </Grid>
    </Grid>
  );
};
