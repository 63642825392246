import { Outlet } from "react-router";
import { FC, useEffect, useMemo, useState } from "react";
import { Box, Button, DataGrid } from "@genome-frontend/uikit/build/lib/uikit";
import { useSelectUsersDataGrid } from "../../hooks/useSelectUsersFormDataGrid/useSelectUsersFormDataGrid";
import { GridInputSelectionModel, GridRowId } from "@mui/x-data-grid";
import { PatientItemFragment } from "generated/operations";

export interface SelectUsersFormProps<T> {
  onSelect: (rows: T[]) => void;
  selectedUsers: Pick<PatientItemFragment, "id" | "fio">[];
}

export const SelectUsersForm: FC<
  SelectUsersFormProps<Pick<PatientItemFragment, "id" | "fio">>
> = ({ onSelect, selectedUsers }) => {
  const gridData = useSelectUsersDataGrid();
  const columns = gridData.gridProps.columns;

  const columnsMemo = useMemo(() => columns, [columns]);

  const { gridProps } = gridData;

  const [selectedRows, setSelectedRows] =
    useState<Pick<PatientItemFragment, "id" | "fio">[]>(selectedUsers);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <DataGrid
          {...gridProps}
          columns={columnsMemo}
          rowsPerPageOptions={[10, 30, 50]}
          showOrderColumn
          showColumnSettings={false}
          keepNonExistentRowsSelected
          checkboxSelection={true}
          selectionModel={selectedRows.map((r) => r.id)}
          onSelectionModelChange={(selectionModel, details) => {
            setSelectedRows((oldSelectedRows) => {
              const oldRowsWithoutUnselected = oldSelectedRows.filter(
                (r) => selectionModel.indexOf(r.id) != -1
              );
              const newSelectedRows = selectionModel.filter(
                (r) =>
                  oldRowsWithoutUnselected.findIndex((e) => e.id == r) == -1
              );
              const newSelectedValues = gridData.rows
                .filter((r) => newSelectedRows.indexOf(r.id) != -1)
                .map((r): Pick<PatientItemFragment, "id" | "fio"> => {
                  return {
                    id: r.id,
                    fio: r.fio,
                  };
                });

              return oldRowsWithoutUnselected.concat(newSelectedValues);
            });
          }}
          gridKey="employingOrganizationPatients"
          autoHeight
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
          <Button
            onClick={() => onSelect(selectedRows)}
            variant={"contained"}
            sx={{ marginY: 3 }}
          >
            Выбрать
          </Button>
        </Box>
      </Box>
      <Outlet />
    </>
  );
};
