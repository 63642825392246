import React, { memo, useContext, useEffect, useState } from "react";
import { TokenHelper } from "./TokenHelper";
import { AuthHelper } from "./AuthHelper";
import { SuccessAuthResponse } from "./types/SuccessAuthResponse";
import { AuthContext, AuthStateEnum } from "./auth/AuthContextProvider";
import {
  resolvePendingRequests,
  subscribeToUnauthenticatedErrorResponse,
  unsubscribeFromUnauthenticatedErrorResponse,
} from "./errors/handleUnauthenticatedError";

import { BadRequest } from "constants/responseCodes";
import { useApolloClient } from "@apollo/client";
import { ApolloHelper } from "ApolloHelper";

const RefreshTokenUpdater = () => {
  const apolloClient = useApolloClient();
  const { state, setState } = useContext(AuthContext);
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);

  const logout = async () => {
    resolvePendingRequests(false);
    TokenHelper.logout();
    await ApolloHelper.onLogout(apolloClient);
    setState(AuthStateEnum.UNAUTHORIZED);
  };

  const tryExecRefreshToken = async () => {
    if (!TokenHelper.getCurrentRefreshToken()) {
      await logout();
      return;
    }

    const response = await AuthHelper.sendFetchRequest(
      new URLSearchParams({
        grant_type: "refresh_token",
        refresh_token: TokenHelper.getCurrentRefreshToken()!,
        scope: "offline_access",
      })
    );

    if (response.status === BadRequest) {
      await logout();
      return;
    }

    const data = (await response.json()) as SuccessAuthResponse;

    if (!data.refresh_token || !data.access_token) {
      await logout();
      return;
    }

    TokenHelper.setAuthorizationToken(data.access_token);
    TokenHelper.setRefreshToken(data.refresh_token);
    setIsRefreshingToken(false);
    resolvePendingRequests(true);
  };

  useEffect(() => {
    const eventDesc = subscribeToUnauthenticatedErrorResponse(() => {
      if (!isRefreshingToken) {
        setIsRefreshingToken(true);
        tryExecRefreshToken();
      }
    });
    return () => unsubscribeFromUnauthenticatedErrorResponse(eventDesc);
    // для корректной работы useEffect`a, необходимо чтобы deps был пустой
    // без disable-строки падает ошибка,
    // что необходимо добавить isRefreshingToken и tryExecRefreshToken в deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default memo(RefreshTokenUpdater);
