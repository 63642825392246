import { MainLayout } from "components/MainLayout";
import { ReportsGrid } from "./components/ReportsGrid/ReportsGrid";

export function ReportsPage() {
  return (
    <MainLayout title="Отчеты">
      <ReportsGrid />
    </MainLayout>
  );
}
