export const employingOrganization: { [key: string]: string } = {
  organizationName: "Наименование",
  address: "Адрес организации",
  delegate: "Уполномоченный представитель",
  surname: "Фамилия",
  name: "Имя",
  fathersName: "Отчество",
  inn: "ИНН",
  ogrn: "ОГРН",
  kpp: "КПП",
  phoneNumber: "Контактный номер телефона",
  email: "Адрес электронной почты",
  okved: "Вид экономической деятельности",
  mainOkved: "Основной ОКВЭД",
  additionalOkveds: "Дополнительные ОКВЭД'ы",
  profile: "Доступ к функционалу",
  delegateInfo: "Данные уполномоченного представителя",
  organizationInfo: "Данные об организации",
};
