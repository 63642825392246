import { useState } from "react";
import {
  Grid,
  Label,
  RadioGroup,
} from "@genome-frontend/uikit/build/lib/uikit";
import { Typography } from "@mui/material";
import { GqlAutocompleteOption } from "components/GqlAutocomplete";
import {
  useConclusionAppealQuery,
  useCreateConclusionForOpoMutation,
} from "generated/hooks";
import { TokenHelper } from "TokenHelper";
import { BoolNull } from "types/NullableTypes";
import { CreateConclusionForOpoMutation } from "generated/operations";
import { conclusionDoctorValidateSave } from "../ConclusionDoctor/ConclusionDoctorValidateSave";
import { locale } from "locale";
import { SelectDiagnosis } from "../ConclusionDoctor/SelectDiagnosis/SelectDiagnosis";
import { AppealAgreementModal } from "../ConclusionDoctor/AppealAgreementModal";
import { SetCommissionSizeModal } from "../ConclusionDoctor/SetCommissionModal";
import {
  ConclusionDoctorBase,
  handlerFit,
  radioOptions,
} from "../ConclusionDoctor/ConclusionDoctorBase";
import { useCreateConclusionsDoctorWithCsp } from "pages/AppealPage/hooks/useCreateConclusionsDoctorWithCsp";
import { opoConclusionDoctorValidateCreate } from "./OpoConclusionDoctorValidateCreate";

interface OpoConclusionDoctorCreateProps {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
  employeePosition: string;
  appealOrganizationId: number;
  roleName: string;
  clinicalPsychologistAppointment: boolean;
  showCommissionSizeModal: boolean;
  commissionSize: number;
}

export const OpoConclusionDoctorDrawer: React.FC<
  OpoConclusionDoctorCreateProps
> = ({
  appealId,
  organizationId,
  employeeId,
  employeePosition,
  appealOrganizationId,
  roleName,
  clinicalPsychologistAppointment,
  showCommissionSizeModal,
  commissionSize,
}) => {
  const [selectedDiagnosisPsychiatristId, setSelectedDiagnosisPsychiatristId] =
    useState<GqlAutocompleteOption>({ id: undefined, caption: "" });

  const [isPsychiatristFit, setPsychiatristFit] = useState<BoolNull>(null);
  const [isClinicalPsychologistFit, setClinicalPsychologistFit] =
    useState<BoolNull>(null);

  const isPsychiatristProfile: boolean =
    TokenHelper.checkIsPsychiatristProfile();
  const isClinicalPsychologistProfile: boolean =
    TokenHelper.checkIsClinicalPsychologist();
  const isCommissionHeadProfile: boolean =
    TokenHelper.checkIsComissionHeadProfile();
  const isCorrectOrganizationDoctor: boolean =
    organizationId === appealOrganizationId;

  const conclusionData = useConclusionAppealQuery({
    variables: {
      id: appealId,
      roleName: roleName,
      skip: null,
      take: null,
      order: null,
      where: null,
    },
  });

  const createConclusionStatus = opoConclusionDoctorValidateCreate(
    employeeId,
    isCorrectOrganizationDoctor,
    isPsychiatristProfile,
    isClinicalPsychologistProfile && clinicalPsychologistAppointment,
    conclusionData.data,
    commissionSize
  );

  const saveConclusionStatus = conclusionDoctorValidateSave(
    false,
    null,
    false,
    isPsychiatristProfile,
    isPsychiatristFit,
    selectedDiagnosisPsychiatristId !== null,
    isClinicalPsychologistProfile,
    isClinicalPsychologistFit
  );

  const [createConclusionForOpoMutation] = useCreateConclusionForOpoMutation();
  const { createConclusionsDoctorWithCsp } =
    useCreateConclusionsDoctorWithCsp<CreateConclusionForOpoMutation>({
      appealId,
      organizationId,
      employeeId,
      selectedDiagnosisNarcologyId: null,
      selectedDiagnosisPsychiatristId,
      isNarcologyProfile: false,
      isPsychiatristProfile,
      isClinicalPsychologistProfile,
      isNarcologyFit: null,
      isPsychiatristFit,
      isClinicalPsychologistFit,
      createConclusionStatus,
      async createConclusionMutation(options) {
        const { data } = await createConclusionForOpoMutation(options);
        return { errors: data?.createConclusionForOpo.errors };
      },
    });

  const handleSelectedDiagnosisPsychiatrist = (
    value: GqlAutocompleteOption
  ) => {
    setSelectedDiagnosisPsychiatristId(value);
  };

  const handleClose = () => {
    setPsychiatristFit(null);
    setClinicalPsychologistFit(null);
  };

  const submitConclusions = async () => {
    await createConclusionsDoctorWithCsp(null);
    handleClose();
  };

  return (
    <>
      <ConclusionDoctorBase
        creationAllowed={createConclusionStatus.isCreateConclusion}
        saveAllowed={saveConclusionStatus}
        onClose={handleClose}
        onSubmit={submitConclusions}
      >
        <Typography mb="0.5rem">
          {locale.conclusion.conclusionRole} "{employeePosition}"
        </Typography>
        {isPsychiatristProfile && createConclusionStatus.isPsychiatryAvailable && (
          <>
            <Label>{locale.conclusion.conclusionPsychiatrist}</Label>
            <form>
              <RadioGroup
                options={radioOptions}
                onChange={handlerFit(setPsychiatristFit)}
              />
            </form>

            {!isPsychiatristFit && (
              <Grid xs={12} item>
                <Label>{locale.conclusion.diagnosisMKB}</Label>
                <SelectDiagnosis
                  onChangeDiagnosis={handleSelectedDiagnosisPsychiatrist}
                />
              </Grid>
            )}
          </>
        )}

        {isClinicalPsychologistProfile &&
          createConclusionStatus.isClinicalPsychologistAvailable && (
            <>
              <Label>{locale.conclusion.conclusionPsychologist}</Label>
              <form>
                <RadioGroup
                  options={radioOptions}
                  onChange={handlerFit(setClinicalPsychologistFit)}
                />
              </form>
            </>
          )}
      </ConclusionDoctorBase>
      {isCorrectOrganizationDoctor && isPsychiatristProfile && (
        <AppealAgreementModal appealId={appealId} />
      )}
      {isCorrectOrganizationDoctor &&
        isPsychiatristProfile &&
        isCommissionHeadProfile &&
        showCommissionSizeModal && (
          <SetCommissionSizeModal appealId={appealId} />
        )}
    </>
  );
};
