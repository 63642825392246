import {
  useCheckCspCertLazyQuery,
  useGetUserCspLazyQuery,
} from "generated/hooks";
import { useState } from "react";
import { checkCspByPassword, fetchToUserCsp } from "utils/certificates";
import { useToasts } from "components/Toast";

type TSubmitWithCspProps<TValues> = {
  next: (values: TValues) => Promise<void>;
};

type TSubmitWithCspResult<TValues> = {
  submitWithCspHandler: (values: TValues) => Promise<void>;
  cspLoading: boolean | undefined;
};

export const useSubmitWithCspHandler = <TValues>({
  next,
}: TSubmitWithCspProps<TValues>): TSubmitWithCspResult<TValues> => {
  const [cspLoading, setCspLoading] = useState<boolean>();
  const [loadUserCsp] = useGetUserCspLazyQuery();
  const [checkCsp] = useCheckCspCertLazyQuery();
  const { addErrorsToast } = useToasts();

  const submitHandler = async (values: TValues) => {
    try {
      setCspLoading(true);
      const thumbprint =
        localStorage.getItem("thumbprint") ??
        (await fetchToUserCsp(loadUserCsp));

      const cspPasswordCheck = await checkCspByPassword(thumbprint, checkCsp);
      if (cspPasswordCheck.result) {
        setCspLoading(false);
        await next(values);
      } else {
        addErrorsToast([{ message: cspPasswordCheck.error }]);
        setCspLoading(false);
      }
    } catch (e: any) {
      addErrorsToast([{ message: e.message }]);
      setCspLoading(false);
    }
  };

  return {
    submitWithCspHandler: submitHandler,
    cspLoading,
  };
};
