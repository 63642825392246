import { OrganizationValues } from "../types";
import { UpdateOrganizationInput } from "generated/operations";
import { formatDate } from "utils/formatDate";

export const getUpdateInputDataFromValues = (
  values: OrganizationValues
): UpdateOrganizationInput => {
  return {
    address: values.address ?? "",
    email: values.email ?? "",
    id: values.id!,
    inn: values.inn ?? "",
    kpp: values.kpp ?? "",
    licenseAuthority: values.licenseAuthority ?? "",
    licenseDate: values.licenseDate
      ? formatDate(values.licenseDate, "server")
      : "",
    licenseFile: {
      file: values.licenseFile ?? "",
      fileName: values.licenseFileText ?? "",
    },
    licenseNumber: values.licenseNumber ?? "",
    name: values.name ?? "",
    ogrn: values.ogrn ?? "",
    organizationCode: values.organizationCode ?? "",
    phone: values.phone ?? "",
    printFile: {
      file: values.printFile ?? "",
      fileName: values.printFileText ?? "",
    },
    republicCode: values.republicCode ?? "",
    mainOkvedId: values.mainOkved?.id ?? 0,
    additionalOkvedIds: (values.additionalOkveds ?? []).map((x) => x.id!),
  };
};
