import { conclusionTherapistValidateSave } from "./ConclusionTherapistValidateSave";
import "date-fns";
import { conclusionTherapistValidateCreate } from "./ConclusionTherapistValidateCreate";
import "./ConclusionTherapist.css";
import {
  FinalConclusion,
  radioOptionsConclusion,
  radioOptionsRestriction,
} from "../FinalConclusion/FinalConclusion";
import { UserRole } from "types/UserRole";
import {
  CreateTherapistConclusionInput,
  CreateTherapistConclusionMutation,
} from "generated/operations";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";
import { locale } from "locale";
import { useCreateTherapistConclusionMutation } from "generated/hooks";
import {
  DatePicker,
  Label,
  RadioGroup,
} from "@genome-frontend/uikit/build/lib/uikit";
import { useCreateFinalConclusionWithCsp } from "pages/AppealPage/hooks/useCreateFinalConclusionWithCsp";
import { useFinalConclusionHandlers } from "pages/AppealPage/hooks/useFinalConclusionHandlers";

interface ConclusionTherapistCreateProps {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
}

export const ConclusionTherapistCreate: React.FC<
  ConclusionTherapistCreateProps
> = ({ appealId, organizationId, employeeId }) => {
  const {
    conclusionTypeCurrent,
    restrictionTypeCurrent,
    restrictionDateEndCurrent,
    saveConclusionStatus,
    handlerConclusion,
    handlerRestriction,
    handlerRestrictionDateEnd,
    handleClose,
  } = useFinalConclusionHandlers(conclusionTherapistValidateSave);

  const [createTherapistConclusionMutation] =
    useCreateTherapistConclusionMutation();
  const { createFinalConclusionWithCsp } = useCreateFinalConclusionWithCsp<
    CreateTherapistConclusionMutation,
    CreateTherapistConclusionInput
  >({
    appealId,
    organizationId,
    employeeId,
    conclusionName: ConclusionsName.Therapist,
    conclusionCreatedMessage: locale.conclusion.createdTherapist,
    restrictionTypeCurrent,
    conclusionTypeCurrent,
    restrictionDateEndCurrent,
    async createFinalConclusionMutation(options) {
      const { data } = await createTherapistConclusionMutation(options);
      return { errors: data?.createTherapistConclusion.errors };
    },
  });

  const submitConclusions = async () => {
    await createFinalConclusionWithCsp(null);
    handleClose();
  };

  return (
    <FinalConclusion
      appealId={appealId}
      roleName={UserRole.Therapist}
      createConclusionButtonText="Выдача заключения"
      saveAllowed={saveConclusionStatus}
      validateCreate={(data) =>
        conclusionTherapistValidateCreate(employeeId, data)
      }
      onClose={handleClose}
      onSubmit={submitConclusions}
    >
      <Label>Выдать разрешение</Label>
      <form>
        <RadioGroup
          options={radioOptionsConclusion}
          onChange={handlerConclusion}
        />
      </form>
      <br></br>
      <Label>Временное ограничение</Label>
      <form>
        <RadioGroup
          options={radioOptionsRestriction}
          onChange={handlerRestriction}
        />
      </form>
      <br></br>
      <Label>Выберите дату окончания ограничения</Label>
      <form>
        <DatePicker
          onChange={handlerRestrictionDateEnd}
          value={restrictionDateEndCurrent}
        />
      </form>
    </FinalConclusion>
  );
};
