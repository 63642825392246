import { ReactNode } from "react";

import {
  UserChangesExportExcelButton,
  TUserChangesExportExcelButton,
} from "pages/ChangesPage/components/ExportExcel/UserChangesExportExcelButton";

export const useUserChangesHeaderButtons = ({
  gridData,
}: TUserChangesExportExcelButton): ReactNode[] => {
  return [<UserChangesExportExcelButton gridData={gridData} />];
};
