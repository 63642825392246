import { CreateUserInput } from "generated/operations";
import { UserCreateValue } from "pages/UsersPage/types";
import { formatDate } from "utils/formatDate";

export const getInputDataFromValues = (
  values: UserCreateValue
): CreateUserInput => {
  return {
    name: values.name,
    surname: values.surname,
    fathersName: values.fathersName ?? "",
    sex: values.sex ?? "",
    birthdate: values.birthdate ? formatDate(values.birthdate, "server") : "",
    snils: values.snils,
    phoneNumber: values.phoneNumber ?? "",
    email: values.email ?? "",
    roleName: values.roleName ?? "",
  };
};
