import { Link } from "@mui/material";

export type TFileDownloadLinkField = {
  fileName: string | null | undefined;
  file: string | null | undefined;
};

export const FileDownloadLinkField = ({
  fileName,
  file,
}: TFileDownloadLinkField) => {
  const fileHref = `data:$application/octet-stream;base64,${file}`;

  return (
    <Link download={fileName} href={fileHref}>
      {fileName}
    </Link>
  );
};
