import { EmployingOrganizationValues } from "pages/OrganizationsPage/types";
import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { createEmployingOrganizationValidationSchema } from "pages/OrganizationsPage/utils/createEmployingOrganizationValidationSchema";

export const EmployingOrganizationEditFormValidate = (
  values: EmployingOrganizationValues
): FormikErrors<EmployingOrganizationValues> => {
  let errors: FormikErrors<EmployingOrganizationValues> = {};

  try {
    validateYupSchema(
      values,
      createEmployingOrganizationValidationSchema,
      true,
      values
    );
  } catch (err) {
    errors = yupToFormErrors(err);
  }

  return errors;
};
