import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { useSubmitWithCspHandler } from "./useSubmitWithCspHandler";
import { useGqlCreateMutation } from "./useGqlCreateMutation";

export interface UseGqlCreateMutationWithCspParams<TData, TVariables> {
  dataKey: string;
  objectKey: string;
  createMutationHook: (
    options?: MutationFunctionOptions<TData, TVariables>
  ) => Promise<FetchResult<TData, Record<string, any>, Record<string, any>>>;
  onSuccess?: (data: TData) => Promise<void>;
  onError?: () => Promise<void>;
  successToast?: string;
  errorToast?: string;
}

export interface UseGqlCreateMutationWithCspResult<TVariables> {
  gqlCreateMutationWithCsp: (variables: TVariables) => Promise<void>;
}

export const useGqlCreateMutationWithCsp = <TData, TVariables>({
  dataKey,
  objectKey,
  createMutationHook,
  onSuccess,
  onError,
  successToast,
  errorToast,
}: UseGqlCreateMutationWithCspParams<
  TData,
  TVariables
>): UseGqlCreateMutationWithCspResult<TVariables> => {
  const { gqlCreateMutation } = useGqlCreateMutation({
    dataKey,
    objectKey,
    createMutationHook,
    onSuccess,
    onError,
    successToast,
    errorToast,
  });

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: gqlCreateMutation,
  });

  return {
    gqlCreateMutationWithCsp: submitWithCspHandler,
  };
};
