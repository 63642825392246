import { Header } from "../../components/Header";
import { Box } from "@mui/material";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import { EmployeeProfileForm } from "./components/EmployeeProfileForm/EmployeeProfileForm";
import { EmployingOrganizationProfileForm } from "./components/EmployingOrganizationProfile/EmployingOrganizationProfileViewDrawer/EmployingOrganizationProfileForm";
import React from "react";

export const ProfilePage = () => {
  const isEmployingOrganization =
    TokenHelper.getCurrentUserRole() == UserRole.EmployingOrganization;
  return (
    <Box>
      <Header />
      {isEmployingOrganization ? (
        <EmployingOrganizationProfileForm />
      ) : (
        <EmployeeProfileForm />
      )}
    </Box>
  );
};
