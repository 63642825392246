import React from 'react';
import { ListDateColumnType, ListBooleanColumnType, FilterListType } from '@genome-frontend/frmo-closed/src/types/filterGraphQLTypes';
import { MaxDate, MinDate } from '@genome-frontend/frmo-closed/src/constants/inputDateConsts';
import { NumberRange } from '../NumberRange';
import { TextInput } from '../TextInput';
import { DatePicker } from '../DatePicker';
import { RadioGroup } from '../RadioButton';
export const DataGridColumnFilterControl = ({ column, formFilterModel, setFormFilterModel, initialValue, }) => {
    var _a, _b, _c, _d, _e;
    const setFieldFilterValue = (field, operatorValue, filter) => {
        const newItems = formFilterModel.items
            .filter((item) => !(item.columnField === field && item.operatorValue === operatorValue))
            .concat(filter);
        setFormFilterModel({
            ...formFilterModel,
            items: newItems,
        });
    };
    const clearFieldFilterValue = (field, operatorValue) => {
        const newItems = formFilterModel.items.filter((item) => !(item.columnField === field && item.operatorValue === operatorValue));
        setFormFilterModel({
            ...formFilterModel,
            items: newItems,
        });
    };
    const getFieldFilter = (field, operatorValue) => {
        var _a;
        return (_a = formFilterModel === null || formFilterModel === void 0 ? void 0 : formFilterModel.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.columnField === field && item.operatorValue === operatorValue);
    };
    const { field, type, filterOperators } = column;
    if (filterOperators) {
        return (React.createElement(React.Fragment, null, filterOperators.map((filterOperator) => {
            const fieldFilter = getFieldFilter(field, filterOperator.value);
            const { InputComponent } = filterOperator;
            if (!InputComponent) {
                return null;
            }
            return (
            // @ts-ignore
            React.createElement(InputComponent, { key: `${filterOperator.value}`, value: initialValue, item: fieldFilter !== null && fieldFilter !== void 0 ? fieldFilter : { columnField: field, operatorValue: filterOperator.value }, applyValue: (value) => {
                    setFieldFilterValue(field, filterOperator.value, value);
                } }));
        })));
    }
    switch (type) {
        case 'number': {
            const fieldFromFilter = getFieldFilter(field, '>=');
            const fieldToFilter = getFieldFilter(field, '<=');
            return (React.createElement(NumberRange, { onChange: (numberRange) => {
                    const [fromValue, toValue] = numberRange;
                    if (fromValue !== null) {
                        setFieldFilterValue(field, '>=', {
                            columnField: field,
                            operatorValue: '>=',
                            value: fromValue,
                        });
                    }
                    else {
                        clearFieldFilterValue(field, '>=');
                    }
                    if (toValue !== null) {
                        setFieldFilterValue(field, '<=', {
                            columnField: field,
                            operatorValue: '<=',
                            value: toValue,
                        });
                    }
                    else {
                        clearFieldFilterValue(field, '<=');
                    }
                }, value: [(_a = fieldFromFilter === null || fieldFromFilter === void 0 ? void 0 : fieldFromFilter.value) !== null && _a !== void 0 ? _a : null, (_b = fieldToFilter === null || fieldToFilter === void 0 ? void 0 : fieldToFilter.value) !== null && _b !== void 0 ? _b : null] }));
        }
        case 'date':
        case 'dateTime': {
            const fieldFilter = getFieldFilter(field, 'is');
            return (React.createElement(DatePicker, { onChange: (date) => {
                    const newDate = date != null ? new Date(date) : null;
                    const newDateString = newDate != null && newDate > MinDate && newDate < MaxDate ? newDate.toDateString() : '';
                    setFieldFilterValue(field, 'is', {
                        columnField: field,
                        operatorValue: 'is',
                        value: type === 'date' && newDateString != '' ? newDateString : date,
                    });
                }, value: (_c = fieldFilter === null || fieldFilter === void 0 ? void 0 : fieldFilter.value) !== null && _c !== void 0 ? _c : null, disableFuture: true, minDate: MinDate, maxDate: MaxDate }));
        }
        case ListDateColumnType: {
            const fieldFilter = getFieldFilter(field, FilterListType.Some);
            return (React.createElement(DatePicker, { onChange: (date) => {
                    setFieldFilterValue(field, FilterListType.Some, {
                        columnField: field,
                        operatorValue: FilterListType.Some,
                        value: date,
                    });
                }, value: (_d = fieldFilter === null || fieldFilter === void 0 ? void 0 : fieldFilter.value) !== null && _d !== void 0 ? _d : null, disableFuture: true, minDate: MinDate, maxDate: MaxDate }));
        }
        case ListBooleanColumnType: {
            const fieldFilter = getFieldFilter(field, FilterListType.Some);
            const radioOptions = [
                { id: 'true', caption: 'Да' },
                { id: 'false', caption: 'Нет' },
            ];
            return (React.createElement(RadioGroup, { options: radioOptions, onChange: (event, value) => {
                    setFieldFilterValue(field, FilterListType.Some, {
                        columnField: field,
                        operatorValue: FilterListType.Some,
                        value: value === 'true',
                    });
                }, value: (fieldFilter === null || fieldFilter === void 0 ? void 0 : fieldFilter.value) === true ? 'true' : (fieldFilter === null || fieldFilter === void 0 ? void 0 : fieldFilter.value) === false ? 'false' : null }));
        }
        case 'boolean': {
            const fieldFilter = getFieldFilter(field, "is");
            const radioOptions = [
                { id: 'true', caption: 'Да' },
                { id: 'false', caption: 'Нет' },
            ];
            return (React.createElement(RadioGroup, { options: radioOptions, onChange: (event, value) => {
                    setFieldFilterValue(field, "is", {
                        columnField: field,
                        operatorValue: "is",
                        value: value === 'true',
                    });
                }, value: (fieldFilter === null || fieldFilter === void 0 ? void 0 : fieldFilter.value) === true ? 'true' : (fieldFilter === null || fieldFilter === void 0 ? void 0 : fieldFilter.value) === false ? 'false' : null }));
        }
        default: {
            const fieldFilter = getFieldFilter(field, 'contains');
            return (React.createElement(TextInput, { value: (_e = initialValue !== null && initialValue !== void 0 ? initialValue : fieldFilter === null || fieldFilter === void 0 ? void 0 : fieldFilter.value) !== null && _e !== void 0 ? _e : '', onChange: (e) => {
                    const { value } = e.target;
                    if (value) {
                        setFieldFilterValue(field, 'contains', {
                            columnField: field,
                            operatorValue: 'contains',
                            value,
                        });
                    }
                    else {
                        clearFieldFilterValue(field, 'contains');
                    }
                }, fullWidth: true }));
        }
    }
};
