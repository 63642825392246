import { OrganizationInfosQuery, SortEnumType } from "generated/operations";
import React from "react";
import { useOrganizationInfosLazyQuery } from "generated/hooks";
import { TValidationFieldContext } from "../formFields/base/ValidationFieldsContext";
import { AutocompleteField } from "../formFields/AutocompleteField";
import { GqlAutocompleteOption } from "../GqlAutocomplete";

export type SelectOrganizationInfoProps = {
  validationFieldContext: TValidationFieldContext;
  name: string;
};

export const getOrganizationInfoCaption = (oi: {
  organizationName: string;
}): string => oi?.organizationName ?? "";

export function SelectOrganizationInfo({
  validationFieldContext,
  name,
}: SelectOrganizationInfoProps) {
  const [OrganizationInfoQuery] = useOrganizationInfosLazyQuery();

  const dataTransformer = (data: OrganizationInfosQuery) => {
    return (
      data?.organizationInfos?.items?.map((oi) => ({
        id: oi.id,
        caption: getOrganizationInfoCaption(oi),
      })) ?? []
    );
  };

  const variablesTransformer = (search: string) => {
    return {
      skip: null,
      take: 10,
      order: {
        id: SortEnumType.DESC,
      },
      where: {
        organizationName: {
          contains: search,
        },
      },
    };
  };

  const optionTransformer = (option: GqlAutocompleteOption | null) => {
    return {
      id: option?.id,
      organizationName: option?.caption,
    };
  };

  return (
    <>
      <AutocompleteField
        queryHook={OrganizationInfoQuery}
        dataTransformer={dataTransformer}
        selectedCaptionTransformer={getOrganizationInfoCaption}
        variablesTransformer={variablesTransformer}
        name={name}
        validationFieldContext={validationFieldContext}
        optionTransformer={optionTransformer}
      />
    </>
  );
}
