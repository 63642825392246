import {
  DatePicker,
  DatePickerProps,
} from "@genome-frontend/uikit/build/lib/uikit/DatePicker";
import { useField } from "formik";
import React, { useCallback } from "react";
import { TValidationFieldContext } from "./base/ValidationFieldsContext";

export type TValidationDatePickerFieldProps = {
  name: string;
  validationFieldContext: TValidationFieldContext;
} & Omit<DatePickerProps, "onChange" | "value" | "onBlur">;

export const ValidationDatePickerField = ({
  validationFieldContext,
  name,
  ...props
}: TValidationDatePickerFieldProps) => {
  const [field, meta] = useField(name);

  const onChange = useCallback(
    (date: Date | null | undefined | unknown) => {
      if (date === null) {
        date = "";
      }

      validationFieldContext.setFieldTouched(name, true);
      validationFieldContext.setFieldValue(name, date);

      const error = validationFieldContext.formValidationFunc({
        [name]: date,
      }) as any;
      validationFieldContext.setFieldError(name, error[name]);
    },
    [validationFieldContext, name]
  );

  const onBlur = useCallback(() => {
    validationFieldContext.setFieldTouched(name, true);

    const error = validationFieldContext.formValidationFunc({
      [name]: field.value,
    }) as any;
    validationFieldContext.setFieldError(name, error[name]);
  }, [validationFieldContext, name, field]);

  return (
    <DatePicker
      {...props}
      value={field.value}
      error={meta.touched && !!meta.error}
      onChange={onChange}
      helperText={meta.touched && meta.error}
      onBlur={onBlur}
      maxDate={props.maxDate}
    />
  );
};
