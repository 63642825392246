import { GridRenderCellParams } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { UserItemFragment } from "generated/operations";
import { ColumnTransformer } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { locale } from "locale";
import { Link } from "@mui/material";
import { paths } from "router/paths";
import { renderCellWithTooltip } from "components/DataGrid/renderCellWithTooltip";

const fieldsOrder = [
  "fio",
  "birthdate",
  "snils",
  "roleName",
  "profileNames",
  "organizationName",
].reverse();

const organizationAdminFieldsOrder = ["fio", "position"].reverse();

const getHeaderName = (field: string): string => {
  return (locale.users as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);
  switch (column.field) {
    case "fio":
      return {
        ...column,
        headerName,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({
            value,
            row,
          }: GridRenderCellParams<string, UserItemFragment>) => {
            let id = row.id;
            let usersPath = paths.userEdit(id);
            return (
              <Link
                href={usersPath}
                sx={{
                  whiteSpace: "normal",
                }}
              >
                {value}
              </Link>
            );
          },
        }),
      };
    case "birthdate":
      return {
        ...column,
        headerName,
        sortable: false,
      };
    case "snils":
      return {
        ...column,
        headerName,
        sortable: false,
        renderCell: renderCellWithTooltip(),
      };
    default:
      return {
        ...column,
        headerName,
        renderCell: renderCellWithTooltip(),
      };
  }
};

export const usersColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};

export const organizationAdminUsersColumnTransformer: ColumnTransformer = (
  columns
) => {
  return columns
    .filter((column) => organizationAdminFieldsOrder.includes(column.field))
    .sort(
      (left, right) =>
        organizationAdminFieldsOrder.indexOf(right.field) -
        fieldsOrder.indexOf(left.field)
    )
    .map((column) => transformColumn(column));
};
