import * as yup from "yup";
import { date, ValidationError } from "yup";
import { RegExpress } from "utils/regularExpression";
import { locale } from "locale";

export const editValidationSchema = yup.object().shape({
  name: yup.string().required(`${locale.REQUIRED}`),
  surname: yup.string().required(`${locale.REQUIRED}`),
  fathersName: yup.string().required(`${locale.REQUIRED}`),
  email: yup.string().required(`${locale.REQUIRED}`).email(`${locale.EMAIL}`),
  birthdate: yup
    .date()
    .required(`${locale.REQUIRED}`)
    .min(new Date(1900, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
    .max(new Date(2100, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
    .typeError(`${locale.INVALID_DATE_FORMAT}`),
  phoneNumber: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .matches(RegExpress.phone, `${locale.PHONE}`),
  snils: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .matches(RegExpress.shils, `${locale.SNILS}`),
  addresses: yup
    .array()
    .of(
      yup.object().shape({
        fullAddress: yup.string().required(`${locale.REQUIRED}`),
        startDate: date()
          .required(`${locale.REQUIRED}`)
          .min(new Date(1900, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
          .max(new Date(2100, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
          .typeError(`${locale.INVALID_DATE_FORMAT}`),
        endDate: date().nullable().typeError(`${locale.INVALID_DATE_FORMAT}`),
      })
    )
    .test((addresses) => {
      const errors: ValidationError[] = [];
      if (addresses?.length! > 1) {
        for (let index = 0; index <= addresses?.length! - 1; index++) {
          const element = addresses![index];
          const previousElement =
            index - 1 >= 0 ? addresses![index - 1] : undefined;

          if (index != addresses?.length! - 1) {
            if (element && element.endDate == null) {
              errors.push(
                new ValidationError(
                  locale.validation.DEREGISTRATION_DATE_MUST_BE_SET,
                  element.endDate,
                  `addresses.${index}.endDate`,
                  locale.validation.DEREGISTRATION_DATE_MUST_BE_SET
                )
              );
            }
          }

          if (previousElement && previousElement.endDate && element.startDate) {
            if (
              previousElement.endDate.setHours(0) >=
              element.startDate.setHours(0)
            ) {
              errors.push(
                new ValidationError(
                  locale.validation.WITHDRAWAL_DATE_MUST_EARLIER_NEW_REGISTRATION,
                  element.startDate,
                  `addresses.${index}.startDate`,
                  locale.validation.WITHDRAWAL_DATE_MUST_EARLIER_NEW_REGISTRATION
                )
              );
            }
          }

          if (element.endDate !== null && element.endDate !== undefined) {
            if (element.endDate.setHours(0) < element.startDate!.setHours(0)) {
              errors.push(
                new ValidationError(
                  locale.validation.WITHDRAWAL_DATE_CANNOT_EARLIER_REGISTRATION,
                  element.endDate,
                  `addresses.${index}.endDate`,
                  locale.validation.WITHDRAWAL_DATE_CANNOT_EARLIER_REGISTRATION
                )
              );
            }
          }
        }
      } else {
        if (addresses != undefined) {
          const element = addresses[0];
          if (element && element.endDate && element.startDate) {
            if (element.endDate.setHours(0) <= element.startDate.setHours(0)) {
              errors.push(
                new ValidationError(
                  locale.validation.WITHDRAWAL_DATE_MUST_EARLIER_NEW_REGISTRATION,
                  element.endDate,
                  `addresses.${0}.endDate`,
                  locale.validation.WITHDRAWAL_DATE_MUST_EARLIER_NEW_REGISTRATION
                )
              );
            }
          }
        }
      }
      return errors.length ? new ValidationError(errors) : true;
    }),
});
