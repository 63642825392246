import { ValidationFileField } from "components/formFields/ValidationFileField";
import { locale } from "locale";
import React from "react";
import {
  MultipleAppealOpoFileAcceptableExts,
  MultipleAppealOpoFileAcceptableMimeTypes,
} from "constants/fileExtension";
import { useFormikContext } from "formik";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { MultipleOpoAppealFromFileValues } from "../../types";
import { CreateMultipleOpoAppealFileUploadFormValidate } from "./createMultipleOpoAppealFileUploadFormValidate";

export const MultipleOpoAppealFileUploadForm = () => {
  const { setFieldValue, touched, errors, setFieldError, setFieldTouched } =
    useFormikContext<MultipleOpoAppealFromFileValues>();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: CreateMultipleOpoAppealFileUploadFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <>
      <ValidationFileField
        acceptableMimeTypes={MultipleAppealOpoFileAcceptableMimeTypes}
        acceptableExtensions={MultipleAppealOpoFileAcceptableExts}
        label={locale.multipleAppealOpo.appealFile}
        name="appealOpo"
        fileFieldName="appealOpoFile"
        textFieldName="appealOpoFileText"
        validationFieldContext={validationFieldContext}
        uploadButtonText="Загрузить файл таблицы"
        required
        maxSize={5}
      />
    </>
  );
};
