import { EmployingOrganizationValues } from "../types";
import { CreateEmployingOrganizationInput } from "generated/operations";

export const getCreateEmployingOrganizationInputDataFromValues = (
  values: EmployingOrganizationValues
): CreateEmployingOrganizationInput => {
  return {
    organizationName: values.organizationName ?? "",
    address: values.address ?? "",
    delagateName: values.delagateName ?? "",
    delagateSurname: values.delagateSurname ?? "",
    delagateFathersName: values.delagateFathersName ?? "",
    inn: values.inn ?? "",
    ogrn: values.ogrn ?? "",
    kpp: values.kpp ?? "",
    phoneNumber: values.phoneNumber ?? "",
    email: values.email ?? "",
    mainOkvedId: values.mainOkved?.id ?? 0,
    additionalOkvedsIds: (values.additionalOkveds ?? []).map((x) => x.id!),
  };
};
