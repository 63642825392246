import { useToasts } from "components/Toast";
import { useDeleteUserMutation } from "generated/hooks";
import { locale } from "locale";
import { useConfirm } from "components/Confirm";
import { useCallback } from "react";
import { namedOperations } from "generated/operations";

type TProps = {
  onSuccess?: () => void;
};

export const useUserRemove = ({ onSuccess }: TProps = {}): {
  removeUser: (id: String) => void;
  removing: boolean;
} => {
  const { addOkConfirm } = useConfirm();
  const { addSuccessToast, addErrorsToast } = useToasts();
  const [deleteUserMutation, { loading: removing }] = useDeleteUserMutation({
    refetchQueries: [namedOperations.Query.User],
  });

  const removeUser = useCallback(
    (id: String) => {
      addOkConfirm({
        text: "Удалить",
        cancelText: "Отменить",
        onClickOk: async () => {
          const removeData = await deleteUserMutation({
            variables: {
              id: { id },
            },
          });
          if (
            removeData.data?.deleteUser &&
            removeData.data?.deleteUser.errors
          ) {
            addErrorsToast(removeData.data?.deleteUser.errors);
            return;
          }
          addSuccessToast("Удаление прошло успешно");
          onSuccess?.();
        },
      });
    },
    [
      deleteUserMutation,
      addErrorsToast,
      addSuccessToast,
      addOkConfirm,
      onSuccess,
    ]
  );
  return { removeUser, removing };
};
