import { EmployingOrganizationValues } from "pages/OrganizationsPage/types";
import { UpdateEmployingOrganizationProfileInput } from "generated/operations";

export const getUpdateEmployingOrganizationInputDataFromValues = (
  values: EmployingOrganizationValues
): UpdateEmployingOrganizationProfileInput => {
  return {
    id: values.id,
    organizationName: values.organizationName ?? "",
    address: values.address ?? "",
    delagateName: values.delagateName ?? "",
    delagateSurname: values.delagateSurname ?? "",
    delagateFathersName: values.delagateFathersName ?? "",
    phoneNumber: values.phoneNumber ?? "",
    email: values.email ?? "",
    mainOkvedId: values.mainOkved?.id ?? 0,
    additionalOkvedsIds: (values.additionalOkveds ?? []).map((x) => x.id!),
  };
};
