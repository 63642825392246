import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useAppealViewColumns } from "pages/AppealPage/hooks/useAppealViewDataGrid/useAppealViewColumns";
import { GridRowClassNameParams } from "@mui/x-data-grid";
import { UserRole } from "types/UserRole";
import { TConclusionAppealDataGridResult } from "../../hooks/useAppealViewDataGrid/useAppealViewDataGrid";
import { TokenHelper } from "TokenHelper";
import { ConclusionAppealType, RestrictionType } from "generated/operations";
import "./AppealViewGrid.css";
import { darken } from "@mui/material";

interface AppealViewGridProps {
  gridData: TConclusionAppealDataGridResult;
}

export function AppealViewGrid({ gridData }: AppealViewGridProps) {
  const { gridProps } = gridData;
  const columns = useAppealViewColumns(gridData);

  const roleName = TokenHelper.getCurrentUserRole();
  const isColorMarked =
    roleName === UserRole.Therapist || roleName === UserRole.LawOfficial;

  const rowClassGreen = "row-green";
  const rowClassRed = "row-red";
  const rowClassOrange = "row-orange";

  const conclusionGood: number = 1;
  const conclusionUnfit: number = 2;

  const getHoverBackgroundColor = (color: string) => {
    return darken(color, 0.05);
  };

  const getRowClassName = (params: GridRowClassNameParams<any>): string => {
    if (isColorMarked) {
      if (
        params.row.type === ConclusionAppealType.CONCLUSION_DOCTOR &&
        params.row.conclusionType === conclusionGood
      ) {
        return rowClassGreen;
      }

      if (
        params.row.type === ConclusionAppealType.CONCLUSION_DOCTOR &&
        params.row.conclusionType === conclusionUnfit
      ) {
        return rowClassRed;
      }

      if (
        params.row.type === ConclusionAppealType.CONCLUSION_THERAPIST &&
        params.row.conclusionType === conclusionUnfit
      ) {
        return rowClassRed;
      }

      if (
        params.row.type === ConclusionAppealType.CONCLUSION_THERAPIST &&
        params.row.conclusionType === conclusionGood
      ) {
        if (params.row.restrictionType === RestrictionType.NONE) {
          return rowClassGreen;
        } else {
          return rowClassOrange;
        }
      }
    }

    return "";
  };

  return (
    <>
      <DataGrid
        {...gridProps}
        columns={columns}
        showColumnSettings={false}
        checkboxSelection={false}
        disableColumnFilter
        getRowClassName={getRowClassName}
        rowsPerPageOptions={[10, 30, 50]}
        gridKey="appealsViews"
        sx={{
          "& .row-green": {
            "&:hover": {
              bgcolor: () => getHoverBackgroundColor("#B4E197"),
            },
          },
          "& .row-red": {
            "&:hover": {
              bgcolor: () => getHoverBackgroundColor("#F47C7C"),
            },
          },
          "& .row-orange": {
            "&:hover": {
              bgcolor: () => getHoverBackgroundColor("#F5DF99"),
            },
          },
        }}
      />
    </>
  );
}
