import { MainLayout } from "components/MainLayout";
import { ClientsGrid } from "./components/ClientsGrid/ClientsGrid";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import { EmployingOrganizationClientsGrid } from "./components/ClientsGrid/EmployingOrganizationClientsGrid";

export function ClientsPage() {
  const isEmployingOrganization =
    TokenHelper.getCurrentUserRole() === UserRole.EmployingOrganization;

  return (
    <MainLayout title="Пользователи">
      {isEmployingOrganization ? (
        <EmployingOrganizationClientsGrid />
      ) : (
        <ClientsGrid />
      )}
    </MainLayout>
  );
}
