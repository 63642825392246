import { OkvedsQuery, SortEnumType } from "../../generated/operations";
import React from "react";
import { useOkvedsLazyQuery } from "../../generated/hooks";
import { TValidationFieldContext } from "../formFields/base/ValidationFieldsContext";
import { AutocompleteField } from "../formFields/AutocompleteField";
import { GqlAutocompleteOption } from "../GqlAutocomplete";

export type SelectOkvedProps = {
  validationFieldContext: TValidationFieldContext;
  name: string;
};

const separatorBetweenOkvedCodeAndName = " - ";

export const getOkvedCaption = (okv: {
  code: string;
  name: string;
}): string => {
  return okv && okv.code && okv.name
    ? `${okv.code}${separatorBetweenOkvedCodeAndName}${okv.name}`
    : "";
};

export const getOkvedCodeAndNameFromCaption = (
  caption: string
): [string, string] => {
  const [code, name] = caption.split(separatorBetweenOkvedCodeAndName);
  return [code, name];
};

export function SelectOkved({
  validationFieldContext,
  name,
}: SelectOkvedProps) {
  const [OkvedQuery] = useOkvedsLazyQuery();

  const dataTransformer = (data: OkvedsQuery) => {
    return (
      data?.okveds?.items?.map((okv) => ({
        id: okv.id,
        caption: getOkvedCaption(okv),
      })) ?? []
    );
  };

  const variablesTransformer = (search: string) => {
    return {
      skip: null,
      take: 10,
      order: {
        id: SortEnumType.DESC,
      },
      where: {
        name: {
          contains: search,
        },
      },
    };
  };

  const optionTransformer = (option: GqlAutocompleteOption | null) => {
    const [okvedCode, okvedName] =
      option && option.caption
        ? getOkvedCodeAndNameFromCaption(option?.caption)
        : ["", ""];

    return {
      id: option?.id,
      name: okvedName,
      code: okvedCode,
    };
  };

  return (
    <>
      <AutocompleteField
        queryHook={OkvedQuery}
        dataTransformer={dataTransformer}
        selectedCaptionTransformer={getOkvedCaption}
        variablesTransformer={variablesTransformer}
        name={name}
        validationFieldContext={validationFieldContext}
        optionTransformer={optionTransformer}
      />
    </>
  );
}
