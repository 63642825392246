import { Outlet } from "react-router";
import { FC, useMemo } from "react";
import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useAppealOposDataGrid } from "pages/AppealPage/hooks/useAppealOposDataGrid/useAppealOposDataGrid";
import { useAppealOposDataGridColumns } from "pages/AppealPage/hooks/useAppealOposDataGrid/useAppealOposDataGridColumns";
import { useDeleteAppealOpoWithCsp } from "pages/AppealPage/hooks/useDeleteAppealOpoWithCsp";

export interface TAppealOposGridProps {
  userId: string;
}

export const AppealOposGrid: FC<TAppealOposGridProps> = ({ userId }) => {
  const { deleteAppealOpo } = useDeleteAppealOpoWithCsp();
  const gridData = useAppealOposDataGrid({
    variablesTransformer: (variables) => {
      variables.userId = userId;
      return variables;
    },
    deleteAppealOpo,
  });
  const columns = useAppealOposDataGridColumns(gridData);
  const columnsMemo = useMemo(() => columns, [columns]);
  const { gridProps } = gridData;

  return (
    <>
      <DataGrid
        {...gridProps}
        columns={columnsMemo}
        rowsPerPageOptions={[10, 30, 50]}
        showOrderColumn
        disableColumnFilter
        showColumnSettings={false}
        checkboxSelection={false}
        gridKey="appealOpos"
      />
      <Outlet />
    </>
  );
};
