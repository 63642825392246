import React, { Dispatch, SetStateAction } from "react";
import {
  Grid,
  Modal,
  ModalContent,
} from "@genome-frontend/uikit/build/lib/uikit";
import { Button } from "@mui/material";
import { ConclusionType } from "generated/operations";
import {
  TRadioGroupOption,
  RadioGroupProps,
} from "@genome-frontend/uikit/build/lib/uikit/RadioButton";
import { BoolNull } from "types/NullableTypes";

interface ConclusionDoctorBaseProps {
  creationAllowed: boolean;
  saveAllowed: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}

export const radioOptions: TRadioGroupOption[] = [
  { id: ConclusionType.GOODIN, caption: "ГОДЕН" },
  { id: ConclusionType.UNFIT, caption: "НЕ ГОДЕН" },
];

export const handlerFit =
  (
    updateState: Dispatch<SetStateAction<BoolNull>>
  ): RadioGroupProps["onChange"] =>
  (e, value) => {
    if (value === ConclusionType.GOODIN) {
      updateState(true);
    } else {
      updateState(false);
    }
  };

export const ConclusionDoctorBase: React.FC<ConclusionDoctorBaseProps> = ({
  children,
  creationAllowed,
  saveAllowed,
  onSubmit,
  onClose,
}) => {
  const [isOpen, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const handleSubmit = async () => {
    await onSubmit();
    handleClose();
  };

  return (
    <>
      <Grid container sx={{ mt: 1 }}>
        <Grid xs={4} item>
          <Button
            variant="contained"
            onClick={handleOpen}
            disabled={!creationAllowed}
          >
            {"Выдача заключения"}
          </Button>
        </Grid>
      </Grid>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalContent
          title="Выберите тип заключения"
          confirmButtonProps={{
            children: "Создать",
            onClick: handleSubmit,
            disabled: !saveAllowed,
          }}
          cancelButtonProps={{ onClick: handleClose }}
        >
          {children}
        </ModalContent>
      </Modal>
    </>
  );
};
