import { namedOperations } from "generated/operations";
import { getInputDataFromValues } from "../components/ClientCreateDrawer/getInputDataFromValues";
import { useSendPostRequestToClosedServer } from "../../../utils/sendPostRequestToClosedServer";
import { ClientCreateValue, ClientOutput } from "../types";

type TCreateClientProps = {
  onSuccess: (user: ClientOutput) => void;
};

export interface UseCreateClient {
  createClient: (clientCreateValue: ClientCreateValue) => Promise<void>;
}

export function useCreateClient({
  onSuccess,
}: TCreateClientProps): UseCreateClient {
  const { sendPostRequestToClosedServer } = useSendPostRequestToClosedServer<
    ClientCreateValue,
    ClientOutput
  >({
    endPointPath: "/api/Patient/CreatePatient",
    successToast: "Пользователь успешно создан!",
    failureToast: "Не удалось создать пользователя!",
    onSuccess: onSuccess,
    refetchQueryName: namedOperations.Query.Clients,
  });

  const handleSubmit = async (input: ClientCreateValue) => {
    const clientInput = getInputDataFromValues(input);
    await sendPostRequestToClosedServer(clientInput);
  };

  return {
    createClient: handleSubmit,
  };
}
