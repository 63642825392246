type Id = string | number;

export const paths = {
  login: () => "/login",
  homePage: () => "/",
  profile: () => "/profile",
  editProfile: () => `edit`,

  users: () => "/users",
  userCreate: () => `/users/create`,
  userCreateSubPath: () => "create",
  user: (id: Id = ":id") => `/users/${id}`,
  userSubPath: (id: Id = ":id") => `${id}`,
  userEdit: (id: Id = ":id") => `/users/${id}/edit`,
  userEditSubPath: () => `edit`,

  clients: () => "/clients",
  clientCreate: () => `/clients/create`,
  clientCreateSubPath: () => "create",
  client: (id: Id = ":id") => `/clients/${id}`,
  clientFromEmployingOrganizationCreate: (id: Id = ":id") =>
    `employingOrganization/${id}`,
  clientSubPath: (id: Id = ":id") => `${id}`,
  clientEdit: (id: Id = ":id") => `/clients/${id}/edit`,
  clientEditSubPath: () => `edit`,

  clientAppealView: (
    id: Id = ":id",
    appealId: Id = ":appealId",
    appealName: string = ":appealName"
  ) => `/clients/${id}/appealView/${appealId}/appealName/${appealName}`,

  clientAppealOpoView: (id: Id = ":id", appealId: Id = ":appealId") =>
    `/clients/${id}/appealView/${appealId}/opo`,

  reports: () => "/reports",

  organizations: () => "/organizations",
  organization: (id: Id = ":id") => `/organizations/medOrganizations/${id}`,
  organizationNewSubPath: () => `newMedOrganization`,
  organizationNew: () => `/organizations/newMedOrganization`,
  organizationEdit: (id: Id = ":id") =>
    `/organizations/medOrganizations/${id}/edit`,

  employingOrganization: (id: Id = ":id") =>
    `/organizations/employingOrganizations/${id}`,
  employingOrganizationNewSubPath: () =>
    `/organizations/newEmployingOrganization`,
  employingOrganizationEdit: (id: Id = ":id") =>
    `/organizations/employingOrganizations/${id}/edit`,

  multipleAppealOpo: () => "/multiAppealOpo",

  changes: (id: Id = ":id") => `/changes/${id}`,
};
