import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";

import { useUsersQuery, useOrganizationAdminUsersQuery } from "generated/hooks";
import {
  OrganizationAdminUsersItemViewFragment,
  OrganizationAdminUsersQuery,
  OrganizationAdminUsersQueryVariables,
  UserItemFragment,
  UsersQuery,
  UsersQueryVariables,
} from "generated/operations";
import { sortingTransformer } from "../../../../utils/sortingTransformer";
import { whereTransformer } from "../../../../utils/whereTransformer";

import {
  organizationAdminUsersColumnTransformer,
  usersColumnTransformer,
} from "./utils/usersColumnTransformer";

export type TUsersDataGridProps = Partial<
  UseGqlDataGridParams<UsersQuery, UserItemFragment, UsersQueryVariables>
>;

export type TOrganizationAdminUsersDataGridProps = Partial<
  UseGqlDataGridParams<
    OrganizationAdminUsersQuery,
    OrganizationAdminUsersItemViewFragment,
    OrganizationAdminUsersQueryVariables
  >
>;

export type TUsersDataGridResult = UseGqlDataGridResult<
  UsersQuery,
  UserItemFragment,
  UsersQueryVariables
>;

export type TOrganizationAdminUsersDataGridResult = UseGqlDataGridResult<
  OrganizationAdminUsersQuery,
  OrganizationAdminUsersItemViewFragment,
  OrganizationAdminUsersQueryVariables
>;

export const useUsersDataGrid = (
  props?: TUsersDataGridProps
): TUsersDataGridResult => {
  const gridName = "users";
  const gridData = useGqlDataGrid<
    UsersQuery,
    UserItemFragment,
    UsersQueryVariables
  >({
    type: gridName,
    queryHook: useUsersQuery,
    sortingTransformer: sortingTransformer(gridName),
    columnTransformer: usersColumnTransformer,
    whereTransformer: whereTransformer(gridName),
    ...props,
  });
  return gridData;
};

export const useOrganizationAdminUsersDataGrid = (
  props?: TOrganizationAdminUsersDataGridProps
): TOrganizationAdminUsersDataGridResult => {
  const gridName = "usersInOrganization";
  const gridData = useGqlDataGrid<
    OrganizationAdminUsersQuery,
    OrganizationAdminUsersItemViewFragment,
    OrganizationAdminUsersQueryVariables
  >({
    type: gridName,
    queryHook: useOrganizationAdminUsersQuery,
    sortingTransformer: sortingTransformer(gridName),
    columnTransformer: organizationAdminUsersColumnTransformer,
    whereTransformer: whereTransformer(gridName),
    ...props,
  });
  return gridData;
};
