import {
  ConclusionAppealQuery,
  ConclusionAppealType,
} from "generated/operations";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";

export const conclusionCommissionValidateCreate = (
  employeeId: string,
  data: ConclusionAppealQuery | undefined,
  commissionSize: number | null | undefined,
  clinicalPsychologistAppointment: boolean,
  isCorrectOrganizationDoctor: boolean
): boolean => {
  if (
    data === undefined ||
    !commissionSize ||
    data.conclusionAppeals?.totalCount === 0 ||
    (data.conclusionAppeals?.totalCount ?? 0) < commissionSize ||
    !isCorrectOrganizationDoctor
  ) {
    return false;
  }

  let psychiatristsCount: number = 0;
  let clinicalPsychologistExists: boolean = false;
  let commissionConclusionExists: boolean = false;

  const conclusions = data.conclusionAppeals?.items!;
  for (let conclusion of conclusions) {
    if (
      conclusion.name === ConclusionsName.CommissionHead &&
      conclusion.type === ConclusionAppealType.CONCLUSION_COMMISSION_HEAD &&
      conclusion.isActual &&
      (conclusion.doctorId === employeeId || conclusion.conclusionFile?.file)
    ) {
      commissionConclusionExists = true;
      continue;
    }

    if (
      conclusion.name === ConclusionsName.Psychiatrist &&
      conclusion.type === ConclusionAppealType.CONCLUSION_DOCTOR &&
      conclusion.isActual &&
      conclusion.conclusionFile?.file
    ) {
      psychiatristsCount++;
      continue;
    }

    if (
      clinicalPsychologistAppointment &&
      conclusion.name === ConclusionsName.ClinicalPsychologist &&
      conclusion.type === ConclusionAppealType.CONCLUSION_DOCTOR &&
      conclusion.isActual &&
      conclusion.conclusionFile?.file
    ) {
      clinicalPsychologistExists = true;
    }
  }

  return (
    psychiatristsCount === commissionSize &&
    (clinicalPsychologistExists || !clinicalPsychologistAppointment) &&
    !commissionConclusionExists
  );
};
