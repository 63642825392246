export const fileChanges: { [key: string]: string } = {
  organizationName: "Организация",
  clientFio: "ФИО пользователя",
  appealType: "Название услуги (обращения)",
  conclusionName: "Тип, номер и дата заключения",
  conclusionNumber: "Номер заключения",
  conclusionDate: "Дата выдачи заключения",
  doctorFio: "ФИО врача",
  doctorPosition: "Должность доктора",
  prevFileName: "Предыдущее имя файла",
  newFileName: "Новое имя файла",
  dateChanged: "Время и дата загрузки по МСК",
};
