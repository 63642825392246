import { FormikProps } from "formik";

export const isAllFormValuesWasTouched = <T>(
  props: FormikProps<T>,
  getInputValues: Function,
  nDisabledFields: number = 0
): boolean => {
  return (
    Object.keys(props.touched).length >=
    Object.keys(getInputValues(props.values)).length - nDisabledFields
  );
};
