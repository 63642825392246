import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
} from "@genome-frontend/uikit/build/lib/uikit/DataGrid";

export type ColumnActionParams = {
  label: string;
  onClick: (params: GridRowParams) => void;
  getDisabledFunc?: (params: GridRowParams) => boolean;
};

export interface GetColumnActionParams {
  columnActions?: ColumnActionParams[];
}

export default function getColumnActions({
  columnActions,
}: GetColumnActionParams = {}): GridColumns {
  if (!columnActions) {
    return [];
  }
  return [
    {
      field: "actions",
      type: "actions",
      getActions: (params) => {
        return columnActions?.map(({ label, onClick, getDisabledFunc }) => (
          <GridActionsCellItem
            key={label}
            label={label}
            onClick={() => onClick(params)}
            showInMenu
            disabled={getDisabledFunc?.(params) ?? false}
          />
        ));
      },
    },
  ];
}
