import { useNavigate } from "react-router";
import { FC } from "react";
import { paths } from "router/paths";
import { EmployingOrganizationCreateDrawer } from "../Components/EmployingOrganizationCreateDrawer/EmployingOrganizationCreateDrawer";

export const EmployingOrganizationCreateDrawerPage: FC = () => {
  const navigate = useNavigate();
  const closeHandler = () => {
    navigate(paths.organizations());
  };

  const onSuccess = (data?: number) => {
    navigate(paths.organizations());
  };

  return (
    <EmployingOrganizationCreateDrawer
      onSuccess={onSuccess}
      onCancel={closeHandler}
    />
  );
};
