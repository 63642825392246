import { Alert, Box, Snackbar, Stack } from "@mui/material";
import { Typography } from "@genome-frontend/uikit/build/lib/uikit/Typography/Typography";
import { TextInput } from "@genome-frontend/uikit/build/lib/uikit/TextInput/TextInput";
import { Button } from "@genome-frontend/uikit/build/lib/uikit/Button/Button";
import { AuthContext, AuthStateEnum } from "auth/AuthContextProvider";
import { TokenHelper } from "TokenHelper";
import { AuthHelper } from "AuthHelper";
import { SuccessAuthResponse } from "types/SuccessAuthResponse";
import { ChangeEvent, useContext, useState } from "react";
import { ErrorAuthResponse } from "types/ErrorAuthResponse";
import * as React from "react";
import "./LoginPage.css";
import { paths } from "router/paths";
import { useNavigate } from "react-router";
import { Modal } from "@genome-frontend/uikit/build/lib/uikit";
import CspCertificate from "components/CspCertificate/CspCertificate";
import { AuthorizationOpened } from "AuthorizationOpened";
import { useSubmitWithCsp } from "./hooks/useSubmitWithCsp";
import { useDisplayCspErrorModal } from "./utils/useDisplayCspErrorModal";

export function LoginPage() {
  const navigate = useNavigate();
  const { setState } = useContext(AuthContext);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const [snackbarValue, setSnackbarValue] = useState(false);
  const [errorAuth, setErrorAuth] = useState("Неверный логин или пароль!");

  const { displayCspErrorModal, cspErrorModal, setCspErrorModalModal } =
    useDisplayCspErrorModal();

  const { submitWithCsp } = useSubmitWithCsp({ displayCspErrorModal });

  const setAuthorized = async () => {
    if (login === null || login === "") {
      setErrorAuth("Логин обязательно для ввода");
      setSnackbarValue(true);
      return;
    }

    if (password === null || password === "") {
      setErrorAuth("Пароль обязателен для ввода");
      setSnackbarValue(true);
      return;
    }

    const response = await AuthHelper.sendFetchRequest(
      new URLSearchParams({
        username: login,
        password: password,
        grant_type: "password",
        scope: "offline_access",
      })
    );

    const body = await response.json();

    if (response.status == 200) {
      const data = body as SuccessAuthResponse;
      if (data.access_token && data.refresh_token) {
        TokenHelper.setTokenExpirationOffset(Math.floor(data.expires_in / 3));
        TokenHelper.setAuthorizationToken(data.access_token);
        TokenHelper.setRefreshToken(data.refresh_token);
        setState(AuthStateEnum.AUTHORIZED);
        AuthorizationOpened.setAuthorizationOpened();
        navigate(paths.profile());
      }
    } else {
      const data = body as ErrorAuthResponse;

      if (data.error_description!) {
        setErrorAuth(data.error_description);
      }

      setState(AuthStateEnum.UNAUTHORIZED);
      setSnackbarValue(true);
    }
  };

  const onLoginChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLogin(event.target.value);
  };

  const onPasswordChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPassword(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      setAuthorized();
    }
  };

  return (
    <Box marginTop={20}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={15}
        m="auto"
      >
        <Box position={"relative"} minHeight={250} minWidth={300}>
          <Typography sx={{ mb: 2 }} variant="h6">
            Авторизация
          </Typography>
          <Stack sx={{ mb: 4 }} spacing={2}>
            <TextInput
              name="login"
              fullWidth
              label="Логин"
              placeholder="Введите логин"
              value={login}
              onChange={onLoginChange}
              onKeyPress={handleKeyPress}
            />
            <TextInput
              name="password"
              fullWidth
              label="Пароль"
              type="password"
              placeholder="Введите пароль"
              value={password}
              onChange={onPasswordChange}
              onKeyPress={handleKeyPress}
            />
          </Stack>

          <Stack justifyContent="space-between">
            <Button variant="contained" color="primary" onClick={setAuthorized}>
              Войти
            </Button>
          </Stack>
        </Box>
        <Box position={"relative"} minHeight={250} minWidth={300}>
          <Typography sx={{ mb: 2 }} variant="h6">
            Авторизация c ЭЦП
          </Typography>
          <CspCertificate
            onSubmit={submitWithCsp}
            buttonText={"Войти"}
            details={true}
          />
          {cspErrorModal && (
            <Modal
              open={cspErrorModal.show}
              title={cspErrorModal.title}
              onClose={() =>
                setCspErrorModalModal({ ...cspErrorModal!, show: false })
              }
            >
              {cspErrorModal.description}
            </Modal>
          )}
        </Box>
      </Stack>
      <Snackbar
        open={snackbarValue}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorAuth}
        </Alert>
      </Snackbar>
    </Box>
  );
}
