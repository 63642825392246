import { GridRenderCellParams } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { ConclusionAppealViewFragment } from "generated/operations";
import { ColumnTransformer } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { locale } from "locale";
import { Checkbox, Label } from "@genome-frontend/uikit/build/lib/uikit";
import { FormatDate } from "utils/formatDate";
import { LinkDownloadFile } from "components/LinkDownloadFile/LinkDownloadFile";

const fieldsOrder = ["name", "responseDate", "number", "isActual"].reverse();

const getHeaderName = (field: string): string => {
  return (locale.conclusion as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);

  switch (column.field) {
    case "name":
      return {
        ...column,
        headerName,
        sortable: false,
        filterable: false,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, ConclusionAppealViewFragment>) => {
          if (row.conclusionFile !== null) {
            return (
              <LinkDownloadFile
                fileData={row.conclusionFile?.file!}
                fileName={row.conclusionFile?.fileName!}
                title={value!}
              />
            );
          } else {
            return value;
          }
        },
      };
    case "responseDate":
      return {
        ...column,
        headerName,
        sortable: false,
        filterable: false,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, ConclusionAppealViewFragment>) => {
          return <FormatDate date={value!} />;
        },
      };
    case "number":
      return {
        ...column,
        headerName,
        sortable: false,
        filterable: false,
      };
    case "isActual":
      return {
        ...column,
        headerName,
        sortable: false,
        align: "center",
        minWidth: 125,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, ConclusionAppealViewFragment>) => {
          if (Boolean(value) == true) return <img src="/onCheckbox.ico" />;
          return <img src="/offCheckbox.ico" />;
        },
      };
    default:
      return {
        ...column,
        minWidth: 175,
        headerName,
      };
  }
};

export const appealViewColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
