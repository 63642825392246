import {
  CERTIFICATE_ACCESS_DENIED,
  CERTIFICATE_ACCESS_DENIED_DESCRIPTION,
  CERTIFICATE_FAILED_VERIFICATION,
  CERTIFICATE_FAILED_VERIFICATION_DESCRIPTION,
  CERTIFICATE_USER_NOT_FOUND,
  CERTIFICATE_USER_NOT_FOUND_DESCRIPTION,
  EXPIRED_CSP_CERTIFICATE,
  EXPIRED_CSP_CERTIFICATE_DESCRIPTION,
  NULL_CSP_CERTIFICATE,
  NULL_CSP_CERTIFICATE_DESCRIPTION,
} from "utils/certificates";
import * as React from "react";
import { Dispatch, useState } from "react";

type IModal = {
  show: boolean;
  title: string;
  description: JSX.Element;
};

type TDisplayCspErrorModalResult = {
  displayCspErrorModal: (type: string) => void;
  cspErrorModal: IModal | undefined;
  setCspErrorModalModal: Dispatch<React.SetStateAction<IModal | undefined>>;
};

export const useDisplayCspErrorModal = (): TDisplayCspErrorModalResult => {
  const [modal, setModal] = useState<IModal>();

  const displayCspErrorModal = (type: string): void => {
    switch (type) {
      case EXPIRED_CSP_CERTIFICATE: {
        setModal({
          show: true,
          title: "Срок действия данного сертификата истёк.",
          description: EXPIRED_CSP_CERTIFICATE_DESCRIPTION,
        });
        break;
      }
      case NULL_CSP_CERTIFICATE: {
        setModal({
          show: true,
          title: "Сертификат не привязан к аккаунту",
          description: NULL_CSP_CERTIFICATE_DESCRIPTION,
        });
        break;
      }
      case CERTIFICATE_FAILED_VERIFICATION: {
        setModal({
          show: true,
          title: "",
          description: CERTIFICATE_FAILED_VERIFICATION_DESCRIPTION,
        });
        break;
      }
      case CERTIFICATE_USER_NOT_FOUND: {
        setModal({
          show: true,
          title: "",
          description: CERTIFICATE_USER_NOT_FOUND_DESCRIPTION,
        });
        break;
      }
      case CERTIFICATE_ACCESS_DENIED: {
        setModal({
          show: true,
          title: "",
          description: CERTIFICATE_ACCESS_DENIED_DESCRIPTION,
        });
        break;
      }
      default: {
        setModal({
          show: true,
          title: type,
          description: <div className={"CspModal"}>{"Произошла ошибка"}</div>,
        });
      }
    }
  };

  return {
    displayCspErrorModal,
    cspErrorModal: modal,
    setCspErrorModalModal: setModal,
  };
};
