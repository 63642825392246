import { locale } from "locale";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { AppealViewFragment } from "generated/operations";
import { paths } from "router/paths";
import { Link } from "@mui/material";
import { Button } from "@genome-frontend/uikit/build/lib/uikit";
import { DeleteAppealOpoValues } from "pages/ClientsPage/types";

const getHeaderName = (field: string): string => {
  return (locale.appealOpo as { [key: string]: string })[field] ?? field;
};

const fieldsOrder = [
  "name",
  "responseDate",
  "isActual",
  "isAbleToCancel",
].reverse();

export const transformColumn = (
  column: GridColDef,
  deleteAppealOpo: (values: DeleteAppealOpoValues) => Promise<void>
): GridColDef => {
  const handleClickCancel = async (appealId: number) => {
    console.log("appealId", appealId);
    await deleteAppealOpo({ id: appealId });
  };

  const headerName = getHeaderName(column.field);
  column.sortable = false;
  column.headerName = headerName;
  switch (column.field) {
    case "name":
      return {
        ...column,
        headerName,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, AppealViewFragment>) => {
          const appealOpoId = row.id;
          const clientId = row.patientId;
          const appealOpoViewPath = paths.clientAppealOpoView(
            clientId,
            appealOpoId
          );
          return (
            <Link
              href={appealOpoViewPath}
              sx={{
                whiteSpace: "normal",
              }}
            >
              {value}
            </Link>
          );
        },
      };
    case "responseDate":
      return {
        ...column,
        headerName,
        renderCell: ({ value }) => "",
      };
    case "isActual":
      return {
        ...column,
        sortable: false,
        maxWidth: 125,
        align: "center",
        renderCell: ({
          value,
        }: GridRenderCellParams<string, AppealViewFragment>) => {
          if (Boolean(value)) return <img src="/onCheckbox.ico" />;
          return <img src="/offCheckbox.ico" />;
        },
      };
    case "isAbleToCancel":
      return {
        ...column,
        sortable: false,
        maxWidth: 125,
        align: "center",
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, AppealViewFragment>) => {
          return (
            <Button
              color="error"
              variant="contained"
              disabled={!Boolean(value)}
              onClick={() => {
                handleClickCancel(row.id);
              }}
            >
              {"Отменить"}
            </Button>
          );
        },
      };
    default:
      return {
        ...column,
      };
  }
};

export const appealOposGridColumnTransformer = (
  columns: GridColDef[],
  deleteAppealOpo: (values: DeleteAppealOpoValues) => Promise<void>
): GridColDef[] => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column, deleteAppealOpo));
};
