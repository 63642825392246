import React, { FC, useMemo } from "react";
import { useToasts } from "components/Toast";
import { useConfirm } from "components/Confirm";
import {
  useEmployingOrganizationProfileQuery,
  useUpdateEmployingOrganizationProfileMutation,
} from "generated/hooks";
import {
  namedOperations,
  UpdateEmployingOrganizationProfileMutation,
} from "generated/operations";
import { EmployingOrganizationValues } from "pages/OrganizationsPage/types";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { Formik } from "formik";
import { DrawerForm } from "components/Form";
import { getUpdateEmployingOrganizationInputDataFromValues } from "pages/ProfilePage/utils/getUpdateEmployingOrganizationInputDataFromValues";
import { getInitialValuesFromData } from "pages/ProfilePage/utils/getInitialValuesFromData";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { EmployingOrganizationProfileEditForm } from "../EmployingOrganizationProfileForm/EmployingOrganizationProfileEditForm";
import { formCloseHandler } from "components/Form/formCloseHandler";
import { EmployingOrganizationEditFormValidate } from "../EmployingOrganizationProfileForm/EmployingOrganizationProfileEditFormValidate";

interface EmployingOrganizationEditDrawerProps {
  onSuccess: (payload: UpdateEmployingOrganizationProfileMutation) => void;
  onCancel: () => void;
}

export const EmployingOrganizationProfileEditDrawer: FC<
  EmployingOrganizationEditDrawerProps
> = ({ onSuccess, onCancel }) => {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const { addOkConfirm } = useConfirm();

  const { data, loading } = useEmployingOrganizationProfileQuery();

  const [updateEmployingOrganizationProfileMutation, { loading: saving }] =
    useUpdateEmployingOrganizationProfileMutation({
      refetchQueries: [namedOperations.Query.EmployingOrganizationProfile],
    });

  const updateOrganization = async (values: EmployingOrganizationValues) => {
    const input = getUpdateEmployingOrganizationInputDataFromValues(values);
    const updateResult = await updateEmployingOrganizationProfileMutation({
      variables: {
        input,
      },
    });

    let updateEmployingOrganizationResult =
      updateResult.data?.updateEmployingOrganizationProfile;
    if (updateEmployingOrganizationResult) {
      if (updateEmployingOrganizationResult.errors) {
        addErrorsToast(updateEmployingOrganizationResult.errors);
        return;
      }

      if (updateEmployingOrganizationResult.employingOrganizationProfile) {
        addSuccessToast("Данные успешно обновлены");
        onSuccess(
          updateEmployingOrganizationResult.employingOrganizationProfile?.id
        );
      }
    }
  };

  const initialValues = useMemo(() => {
    if (!data?.employingOrganizationProfile) {
      return null;
    }
    return getInitialValuesFromData(data?.employingOrganizationProfile);
  }, [data]);

  if (!loading && !data?.employingOrganizationProfile) {
    return null;
  }

  if (!initialValues || loading) {
    return (
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 1000,
          },
        }}
        open
        onClose={onCancel}
      >
        <DrawerContent onClose={onCancel} title="Редактирование">
          <CenteredCircularProgress />
        </DrawerContent>
      </Drawer>
    );
  }

  const closeHandler = async (values: EmployingOrganizationValues) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: EmployingOrganizationValues;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open
      onClose={() => closeHandler(values)}
    >
      <Formik<EmployingOrganizationValues>
        validate={EmployingOrganizationEditFormValidate}
        onSubmit={updateOrganization}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(props.values)}
                title="Редактирование"
                confirmButtonProps={{
                  disabled: saving || !props.isValid,
                }}
                cancelButtonProps={{
                  onClick: () => closeHandler(props.values),
                }}
              >
                {data?.employingOrganizationProfile && (
                  <EmployingOrganizationProfileEditForm isSubmitting={false} />
                )}
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
