import React, { FC } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  Typography,
  Label,
  Grid,
} from "@genome-frontend/uikit/build/lib/uikit";
import { PencilOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PencilOutlineIcon";
import { locale } from "locale";
import { useEmployingOrganizationQuery } from "generated/hooks";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { Switch } from "@mui/material";
import { TokenHelper } from "TokenHelper";
import { AccessPermissions } from "types/AccessPermissions";
import { MaskExpress } from "utils/maskExpression";
import { ClippedText } from "components/ClippedText/ClippedText";

interface EmployingOrganizationViewDrawerProps {
  id: string;
  onClose: () => void;
  onError: () => void;
  onEdit: (organization: any) => void;
}

export const EmployingOrganizationViewDrawer: FC<
  EmployingOrganizationViewDrawerProps
> = ({ id, onClose, onError, onEdit }) => {
  const { data, loading } = useEmployingOrganizationQuery({
    variables: {
      id,
    },
    onError: () => {
      onError();
    },
  });
  const closeHandler = () => {
    onClose();
  };

  const editHandler = () => {
    if (data?.employingOrganizationById) {
      onEdit(data.employingOrganizationById);
    }
  };

  const formatOkved = (code: string, name: string) => code + " - " + name;

  if (!loading && !data) {
    return null;
  }

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 920,
        },
      }}
      open
      onClose={closeHandler}
    >
      <DrawerContent
        onClose={closeHandler}
        title="Просмотр организации-работодателя"
        isWithActions={false}
      >
        {TokenHelper.checkAccessPermission(
          AccessPermissions.EmployingOrganizationsUpdate
        ) && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 1,
                borderBottom: "1px solid #DFE3E6",
                marginBottom: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ ml: 1 }}
                  startIcon={<PencilOutlineIcon />}
                  onClick={editHandler}
                >
                  {locale.EDIT}
                </Button>
              </Box>
            </Box>
          </>
        )}
        {loading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            <Grid
              container
              spacing={2}
              whiteSpace={"pre-wrap"}
              columnSpacing={0.5}
            >
              <Grid xs={12} item>
                <h3>Данные уполномоченного представителя</h3>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>{locale.employingOrganizationUpdate.surname}:</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.delagateSurname}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>
                    {locale.employingOrganizationUpdate.phoneNumber}:
                  </Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {MaskExpress.FromPhoneNumber(
                      data?.employingOrganizationById?.phoneNumber ?? null
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>{locale.employingOrganizationUpdate.name}:</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.delagateName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>{locale.employingOrganizationUpdate.email}:</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.email}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>
                    {locale.employingOrganizationUpdate.fathersName}:
                  </Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.delagateFathersName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={12} item>
                <h3>Данные об организации</h3>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>
                    {locale.employingOrganizationUpdate.organizationName}:
                  </Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.organizationName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>{locale.employingOrganizationUpdate.address}:</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.address}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>{locale.employingOrganizationUpdate.mainOkved}:</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.mainOkved!.code} -{" "}
                    {data?.employingOrganizationById.mainOkved!.name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>{locale.employingOrganizationUpdate.inn}:</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>{data?.employingOrganizationById.inn}</Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label
                    sx={{
                      "& .MuiBox-root": {
                        whiteSpace: "wrap",
                      },
                    }}
                  >
                    {locale.organization.additionalOkveds}:
                  </Label>
                </Grid>
                {!data?.employingOrganizationById.additionalOkveds?.length && (
                  <Grid xs={7} item>
                    <Typography>Отсутствует</Typography>
                  </Grid>
                )}

                <Grid xs={7} container item>
                  {data?.employingOrganizationById.additionalOkveds.map(
                    (okv) => (
                      <ClippedText
                        text={formatOkved(okv.code ?? "", okv.name ?? "")}
                      />
                    )
                  )}
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>{locale.employingOrganizationUpdate.kpp}:</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>{data?.employingOrganizationById.kpp}</Typography>
                </Grid>
              </Grid>

              <Grid xs={6} container item>
                <Grid xs={5} item>
                  <Label>{locale.employingOrganizationUpdate.ogrn}:</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.ogrn}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={12} item>
                <h3>Вход в систему</h3>
              </Grid>

              <Grid xs={12} container item>
                <Grid xs={2.5} item>
                  <Label>{locale.employingOrganizationUpdate.login}</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.login}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={12} container item>
                <Grid xs={2.5} item>
                  <Label>{locale.employingOrganizationUpdate.password}</Label>
                </Grid>
                <Grid xs={7} item>
                  <Typography>
                    {data?.employingOrganizationById.password}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={12} container item>
                <Grid xs={1} item>
                  <Switch
                    disabled={true}
                    checked={data?.employingOrganizationById.hasLoginPassAccess}
                  />
                </Grid>
                <Grid xs={7} item>
                  <Label>
                    {locale.employingOrganizationUpdate.hasLoginPassAccess}
                  </Label>
                </Grid>
              </Grid>

              <Grid xs={12} container item>
                <Grid xs={1} item>
                  <Switch
                    disabled={true}
                    checked={data?.employingOrganizationById.isAccessBlocked}
                  />
                </Grid>
                <Grid xs={7} item>
                  <Label>
                    {locale.employingOrganizationUpdate.isAccessBlocked}
                  </Label>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};
