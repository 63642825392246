import { OpoWorkTypesQuery, SortEnumType } from "../../generated/operations";
import React from "react";
import { useOpoWorkTypesLazyQuery } from "../../generated/hooks";
import { TValidationFieldContext } from "../formFields/base/ValidationFieldsContext";
import { AutocompleteField } from "../formFields/AutocompleteField";
import { GqlAutocompleteOption } from "../GqlAutocomplete";

export type SelectOpoWorkTypeProps = {
  validationFieldContext: TValidationFieldContext;
  name: string;
};

const separatorBetweenOpoWorkTypeCodeAndName = " - ";

export const getOpoWorkTypeCaption = (wt: {
  code: string;
  name: string;
}): string => {
  return wt && wt.code && wt.name
    ? `${wt.code}${separatorBetweenOpoWorkTypeCodeAndName}${wt.name}`
    : "";
};

export const getOpoWorkTypeCodeAndNameFromCaption = (
  caption: string
): [string, string] => {
  const [code, name] = caption.split(separatorBetweenOpoWorkTypeCodeAndName);
  return [code, name];
};

export function SelectOpoWorkType({
  validationFieldContext,
  name,
}: SelectOpoWorkTypeProps) {
  const [OpoWorkTypeQuery] = useOpoWorkTypesLazyQuery();

  const dataTransformer = (data: OpoWorkTypesQuery) => {
    return (
      data?.opoWorkTypes?.items?.map((wt) => ({
        id: wt.id,
        caption: getOpoWorkTypeCaption(wt),
      })) ?? []
    );
  };

  const variablesTransformer = (search: string) => {
    return {
      skip: null,
      take: 10,
      order: {
        id: SortEnumType.DESC,
      },
      where: {
        name: {
          contains: search,
        },
      },
    };
  };

  const optionTransformer = (option: GqlAutocompleteOption | null) => {
    const [opoWorkTypeCode, opoWorkTypeName] =
      option && option.caption
        ? getOpoWorkTypeCodeAndNameFromCaption(option?.caption)
        : ["", ""];

    return {
      id: option?.id,
      name: opoWorkTypeName,
      code: opoWorkTypeCode,
    };
  };

  return (
    <>
      <AutocompleteField
        queryHook={OpoWorkTypeQuery}
        dataTransformer={dataTransformer}
        selectedCaptionTransformer={getOpoWorkTypeCaption}
        variablesTransformer={variablesTransformer}
        name={name}
        validationFieldContext={validationFieldContext}
        optionTransformer={optionTransformer}
      />
    </>
  );
}
