import { ClientCreateValue } from "../../types";
import { TokenHelper } from "TokenHelper";

export const initialValues: ClientCreateValue = {
  name: "",
  surname: "",
  fathersName: "",
  sex: "",
  birthdate: "",
  snils: "",
  phoneNumber: "",
  email: "",
  employingOrganizationId:
    TokenHelper.getCurrentEmployingOrganizationId() ?? undefined,
};
