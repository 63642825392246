import { OrganizationValues } from "../../types";
import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { createOrganizationValidationSchema } from "../../utils/createOrganizationValidationSchema";

export const OrganizationCreateFormValidate = (
  values: OrganizationValues
): FormikErrors<OrganizationValues> => {
  let errors: FormikErrors<OrganizationValues> = {};

  try {
    validateYupSchema(values, createOrganizationValidationSchema, true, values);
  } catch (err) {
    errors = yupToFormErrors(err);
  }

  return errors;
};
