import React, { Dispatch, useEffect, useState } from "react";
import {
  CSP_DOWNLOAD_LINK,
  doFriendlyCustomCertsList,
  getCspVersion,
  getPluginVersion,
  PLUGIN_DOWNLOAD_LINK,
} from "utils/certificates";
import { Row } from "react-bootstrap";
import { Input, Stack } from "@mui/material";
import { Button } from "@genome-frontend/uikit/build/lib/uikit/Button/Button";
import {
  Select,
  TSelectOption,
} from "@genome-frontend/uikit/build/lib/uikit/Select";
import "./CspCertificate.css";
import { CircularProgress } from "@genome-frontend/uikit/build/lib/uikit";
import { Certificate } from "crypto-pro";

interface IProps {
  onSubmit: (data: string, setSubmitLoading: Dispatch<boolean>) => void;
  details: boolean;
  buttonText: string;
}

const CspCertificate: React.FC<IProps> = ({
  onSubmit,
  details,
  buttonText,
}) => {
  const [cspVersion, setCspVersion] = useState<string | null>();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [pluginVersion, setPluginVersion] = useState<string | null>();
  const [certsList, setCertsList] = useState<Certificate[]>();
  const [thumbprint, setThumbprint] = useState<string | null | undefined>();
  const options: TSelectOption[] | undefined =
    certsList &&
    certsList.map((x) => {
      return {
        value: x.thumbprint,
        label:
          x.name +
          "." +
          " Действителен до: " +
          new Date(x.validTo).toLocaleString("ru-RU"),
      };
    });

  useEffect(() => {
    getCspVersion().then((x) => {
      setCspVersion(x);
    });
    getPluginVersion().then((x) => {
      setPluginVersion(x);
    });
    doFriendlyCustomCertsList().then((x) => {
      setCertsList(x);
    });
  }, []);

  useEffect(() => {
    const defaultCert = () => {
      if (certsList && certsList.length > 0) {
        const value = localStorage.getItem("thumbprint");
        if (value && certsList.find((x) => x.thumbprint === value))
          setThumbprint(value);
        else {
          localStorage.removeItem("thumbprint");
          setThumbprint(certsList[0].thumbprint);
        }
      } else setThumbprint(null);
    };
    defaultCert();
  }, [certsList]);

  return (
    <>
      <Stack sx={{ mb: 4 }} spacing={2}>
        {!pluginVersion && (
          <Row>
            <ul>
              <li className={"csp-plugin-error"}>Плагин не загружен</li>
              <li>
                <a href={PLUGIN_DOWNLOAD_LINK}>Ссылка на скачивание</a>
              </li>
            </ul>
          </Row>
        )}

        {pluginVersion && !cspVersion && (
          <Row>
            <ul>
              <li className={"csp-plugin-error"}>
                Криптопровайдер не загружен
              </li>
              <li>
                <a href={CSP_DOWNLOAD_LINK}>Ссылка на скачивание</a>
              </li>
            </ul>
          </Row>
        )}

        {cspVersion && pluginVersion && (
          <React.Fragment>
            <Row>
              <ul>
                <li className={"csp-plugin-success"}>Плагин загружен</li>
                {details && (
                  <React.Fragment>
                    <li>Версия плагина: {pluginVersion}</li>
                    <li>Версия криптопровайдера: {cspVersion}</li>
                  </React.Fragment>
                )}
              </ul>
            </Row>

            <div style={{ margin: 0 }}>
              {certsList && certsList.length > 0 && options ? (
                <Select
                  value={thumbprint}
                  options={options}
                  label={"Сертификат подписи"}
                  onChange={(e) => {
                    setThumbprint(e.target.value as string);
                  }}
                />
              ) : (
                !(certsList && certsList.length === 0) && <CircularProgress />
              )}
              {certsList && certsList.length === 0 && (
                <Input
                  className={"csp-no-contains"}
                  placeholder={"Сертификаты отсутствуют"}
                  disabled={true}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </Stack>
      <Stack>
        {buttonText && !submitLoading && (
          <Button
            className={"Button-CspCert"}
            variant="contained"
            color="primary"
            disabled={!cspVersion || !thumbprint}
            onClick={() => onSubmit(thumbprint!, setSubmitLoading)}
          >
            {buttonText}
          </Button>
        )}
        {submitLoading && <CircularProgress />}
      </Stack>
    </>
  );
};

export default CspCertificate;
