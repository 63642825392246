import { OrganizationValues } from "../types";

export const getInitialValuesFromData = (values: any): OrganizationValues => {
  return {
    address: values.address ?? "",
    email: values.email ?? "",
    id: values.id!,
    inn: values.inn ?? "",
    kpp: values.kpp ?? "",
    licenseAuthority: values.licenseAuthority ?? "",
    licenseDate: values.licenseDate ?? "",
    licenseFileText: values.licenseFile.fileName ?? "",
    licenseNumber: values.licenseNumber ?? "",
    name: values.name ?? "",
    ogrn: values.ogrn ?? "",
    organizationCode: values.organizationCode ?? "",
    phone: values.phone?.substring(2) ?? "",
    printFileText: values.printFile.fileName ?? "",
    republicCode: values.republicCode ?? "",
    mainOkved: values.mainOkved,
    additionalOkveds: values.additionalOkveds ?? [],
  };
};
