import { TMultipleAppealOpoTemplateGridResult } from "./useMultipleAppealOpoTemplateGrid";
import getColumnActions from "../../../../components/DataGrid/getColumnActions";
import { locale } from "../../../../locale";
import {
  ConclusionAppealType,
  MultipleAppealOpoTemplateFragment,
} from "../../../../generated/operations";
import { GridRowParams } from "@mui/x-data-grid";

interface TMultipleAppealOpoTemplateGridActionsColumn {
  onTemplateRemoveClicked: (templateId: number) => void;
  onTemplateRenameClicked: (templateId: number) => void;
}

export const useMultipleAppealOpoTemplateGridColumns = (
  gridData: TMultipleAppealOpoTemplateGridResult,
  actionsColumnProps: TMultipleAppealOpoTemplateGridActionsColumn
) => {
  const actionsColumn = getColumnActions({
    columnActions: [
      {
        label: locale.RENAME,
        onClick: (params: GridRowParams<MultipleAppealOpoTemplateFragment>) => {
          const template = params.row;
          actionsColumnProps.onTemplateRenameClicked(template.id);
        },
      },
      {
        label: locale.REMOVE,
        onClick: (params: GridRowParams<MultipleAppealOpoTemplateFragment>) => {
          const template = params.row;
          actionsColumnProps.onTemplateRemoveClicked(template.id);
        },
      },
    ],
  });

  return gridData.gridProps.columns.concat(actionsColumn);
};
