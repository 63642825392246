import { ColumnTransformer } from "components/DataGrid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { locale } from "locale";
import { ReportViewFragment } from "generated/operations";
import moment from "moment";
import { FormatDateMoment } from "constants/inputDateConsts";
import { LinkDownloadFile } from "components/LinkDownloadFile/LinkDownloadFile";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import {
  ListBooleanColumnType,
  ListDateColumnType,
} from "types/filterGraphQLTypes";
import { renderCellWithTooltip } from "components/DataGrid/renderCellWithTooltip";

const fieldsOrder = [
  "name",
  "doctorPosition",
  "doctorFio",
  "patientFio",
  "patientBirthdate",
  "patientSnils",
  "responseDate",
  "number",
  "isActual",
].reverse();

const getHeaderName = (field: string): string => {
  return (locale.reports as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  column.headerName = getHeaderName(column.field);

  switch (column.field) {
    case "name":
      return {
        ...column,
        filterable: true,
        sortable: true,
        flex: 2,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ value, row }: GridRenderCellParams<string>) => {
            if (row.id < 0) {
              if (
                TokenHelper.getCurrentUserRole() ==
                  UserRole.OrganizationAdmin &&
                row.conclusionFile !== null
              ) {
                return (
                  <LinkDownloadFile
                    fileData={row.conclusionFile?.file!}
                    fileName={row.conclusionFile?.fileName!}
                    title={value!}
                  />
                );
              }
            }
            return value;
          },
        }),
      };

    case "doctorPosition":
      return {
        ...column,
        filterable: true,
        sortable: true,
        flex: 2,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ value, row }: GridRenderCellParams<string>) =>
            row.id < 0 ? value : "",
          tooltipTransformer: ({ value, row }: GridRenderCellParams<string>) =>
            row.id < 0 ? value : "",
        }),
      };
    case "doctorFio":
      return {
        ...column,
        filterable: true,
        sortable: true,
        flex: 2,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ value, row }: GridRenderCellParams<string>) =>
            row.id < 0 ? value : "",
          tooltipTransformer: ({ value, row }: GridRenderCellParams<string>) =>
            row.id < 0 ? value : "",
        }),
      };
    case "patientFio":
      return {
        ...column,
        filterable: true,
        sortable: true,
        flex: 2,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.id < 0 ? row.patientFio.fio : row.patientFio,
          tooltipTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.id < 0 ? row.patientFio.fio : row.patientFio,
        }),
      };
    case "patientSnils":
      return {
        ...column,
        filterable: true,
        sortable: true,
        flex: 2,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.id < 0 ? row.patientSnils.snils : row.patientSnils,
          tooltipTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.id < 0 ? row.patientSnils.snils : row.patientSnils,
        }),
      };
    case "patientBirthdate":
      return {
        ...column,
        filterable: true,
        sortable: true,
        flex: 2,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.id < 0
              ? moment(row.patientBirthdate.birthdate).format(FormatDateMoment)
              : moment(row.patientBirthdate).format(FormatDateMoment),
          tooltipTransformer: ({ row }: GridRenderCellParams<string>) =>
            row.id < 0
              ? moment(row.patientBirthdate.birthdate).format(FormatDateMoment)
              : moment(row.patientBirthdate).format(FormatDateMoment),
        }),
      };
    case "isActual":
      return {
        ...column,
        type: ListBooleanColumnType,
        sortable: false,
        renderCell: ({
          row,
          value,
        }: GridRenderCellParams<boolean, ReportViewFragment>) => {
          if (row.id < 0) {
            if (Boolean(value)) return <img src="/onCheckbox.ico" />;
            return <img src="/offCheckbox.ico" />;
          } else {
            return "";
          }
        },
      };
    case "number":
      return {
        ...column,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ value, row }: GridRenderCellParams<string>) =>
            row.id < 0 ? value : "",
          tooltipTransformer: ({ value, row }: GridRenderCellParams<string>) =>
            row.id < 0 ? value : "",
        }),
      };
    case "responseDate":
      return {
        ...column,
        type: ListDateColumnType,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({ value, row }: GridRenderCellParams<string>) =>
            row.id < 0
              ? value !== null
                ? moment(value).format(FormatDateMoment)
                : ""
              : "",
          tooltipTransformer: ({ value, row }: GridRenderCellParams<string>) =>
            row.id < 0
              ? value !== null
                ? moment(value).format(FormatDateMoment)
                : ""
              : "",
        }),
      };
    default:
      return {
        ...column,
        renderCell: renderCellWithTooltip(),
      };
  }
};

export const reportsColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
