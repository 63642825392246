import { TokenHelper } from "../TokenHelper";
import { ApiResult } from "../types/ApiResut";

export async function sendGetRequestToClosedServer<TDataOutput>(
  endPointPath: string
): Promise<ApiResult<TDataOutput> | null> {
  const baseApi = process.env.REACT_APP_CLOSED_LOCATION;
  const query = `/api/ClosedServer/SendGetRequestToClosedServer?closedEndPointPath=${endPointPath}`;
  const endApi = baseApi + query;
  try {
    let response = await fetch(endApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TokenHelper.getCurrentToken()}`,
        "X-OpenedToken": TokenHelper.getOpenedToken(),
      },
    });
    if (!response.ok) {
      return null;
    }
    let responseData: null | Promise<ApiResult<TDataOutput>> =
      (await response.json()) as Promise<ApiResult<TDataOutput>>;
    return typeof responseData === "string"
      ? (JSON.parse(responseData) as ApiResult<TDataOutput>)
      : await responseData;
  } catch (e) {
    return null;
  }
}
