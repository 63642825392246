import {
  getIterationsCount,
  XlsxExportButton,
} from "components/XlsxExportButton/XlsxExportButton";
import {
  useGetConclusionFileHistoriesLazyQuery,
  useGetConclusionFileHistoriesQuery,
} from "generated/hooks";
import { ExcelTakeCount } from "constants/excelFetchExport";
import { TFileHistoriesDataGridResult } from "pages/ChangesPage/hooks/useFileChangesDateGrid/useFileChangesDataGrid";

export type TFileChangesExportExcelButton = {
  gridData: TFileHistoriesDataGridResult;
};

export const FileChangesExportExcelButton = ({
  gridData,
}: TFileChangesExportExcelButton) => {
  const [execQuery] = useGetConclusionFileHistoriesLazyQuery();
  const { gridProps } = gridData;
  const columnParams = [
    { wch: 20 },
    { wch: 20 },
    { wch: 30 },
    { wch: 30 },
    { wch: 20 },
    { wch: 30 },
    { wch: 20 },
    { wch: 35 },
  ];

  const convertRowDataToAoA = (rows: any): string[][] => {
    const dataAoA = [];

    dataAoA.push(gridProps.columns.map((c) => c.headerName));

    for (let row of rows) {
      dataAoA.push([
        row.organizationName,
        row.clientFio,
        row.appealType,
        row.conclusionName,
        row.doctorFio,
        row.prevFileName,
        row.newFileName,
        row.trackedTime,
      ]);
    }
    return dataAoA;
  };

  const fetchForExcelData = async () => {
    const takeCount = ExcelTakeCount;
    const basicGridLength = gridData.rows.length;
    const totalItems = gridProps.rowCount ?? 0;
    const historyArray = gridData.rows.slice(0);
    const iterationsCount = getIterationsCount(
      totalItems,
      basicGridLength,
      takeCount
    );
    for (let i = 0; i < iterationsCount; i++) {
      const response = await execQuery({
        variables: {
          skip: i === 0 ? basicGridLength : takeCount * i + basicGridLength,
          take: takeCount,
          order: gridData.variables.order,
          where: gridData.variables.where,
        },
      });
      if (historyArray && response && response.data) {
        response.data.conclusionFileHistories?.items?.forEach((item) => {
          historyArray.push(item);
        });
      }
    }
    return convertRowDataToAoA(historyArray);
  };

  return (
    <XlsxExportButton
      sheetName={"Отчет"}
      fileName={"Отчет"}
      columnParams={columnParams}
      fetchData={fetchForExcelData}
    />
  );
};
