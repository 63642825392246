import {
  RussianPhoneDigitsCount,
  RussianRegionalCode,
} from "constants/russianPhoneNumber";

export class MaskExpress {
  static phone: string = "+7 (999) 999-99-99";

  static snils: string = "999-999-999-99";

  public static ToPhoneNumber(phone: string): string {
    const numberPhone = phone
      .replaceAll("+7", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "");
    return numberPhone;
  }

  public static isValidPhoneNumber(phone: string): boolean {
    const pattern = new RegExp(
      `^\\${RussianRegionalCode} \\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$`
    );

    return pattern.test(phone);
  }

  public static FromPhoneNumber(digits: string | null): string | null {
    if (digits == null || MaskExpress.isValidPhoneNumber(digits)) {
      return digits;
    }
    const cleaned = digits
      .replace(/\D/g, "")
      .padEnd(RussianPhoneDigitsCount, " ");

    const part1 = cleaned.substring(0, 3);
    const part2 = cleaned.substring(3, 6);
    const part3 = cleaned.substring(6, 8);
    const part4 = cleaned.substring(8, 10);

    return `${RussianRegionalCode} (${part1}) ${part2}-${part3}-${part4}`;
  }
}
