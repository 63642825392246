import * as yup from "yup";
import { RegExpress } from "utils/regularExpression";
import { locale } from "locale";

export const createValidationSchema = yup.object().shape({
  name: yup.string().required(`${locale.REQUIRED}`),
  surname: yup.string().required(`${locale.REQUIRED}`),
  fathersName: yup.string().required(`${locale.REQUIRED}`),
  email: yup.string().required(`${locale.REQUIRED}`).email(`${locale.EMAIL}`),
  birthdate: yup
    .date()
    .required(`${locale.REQUIRED}`)
    .min(new Date(1900, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
    .max(new Date(2100, 1, 1), `${locale.INVALID_DATE_FORMAT}`)
    .typeError(`${locale.INVALID_DATE_FORMAT}`),
  phoneNumber: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .matches(RegExpress.phone, `${locale.PHONE}`),
  snils: yup
    .string()
    .required(`${locale.REQUIRED}`)
    .matches(RegExpress.shils, `${locale.SNILS}`),
  roleName: yup.string().required(`${locale.REQUIRED}`),
});
