import {
  Box,
  Button,
  Drawer,
  DrawerContent,
} from "@genome-frontend/uikit/build/lib/uikit";
import React, { FC, useState } from "react";
import { useCreateAppealOpoMutation } from "generated/hooks";
import { CreateAppealOpoValues } from "../../types";
import { CreateAppealOpoForm } from "./CreateAppealOpoForm";
import { Formik } from "formik";
import { DrawerForm } from "components/Form";
import { CreateAppealOpoFormValidate } from "./CreateAppealOpoFormValidate";
import { useConfirm } from "components/Confirm";
import { formCloseHandler } from "components/Form/formCloseHandler";
import { getInitialValues } from "./initialValues";
import { getInputDataFromValues } from "./getInputDataFromValues";
import { namedOperations } from "generated/operations";
import { useToasts } from "components/Toast";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";

interface CreateAppealOpoDrawerProps {
  clientId: string;
}

export const CreateAppealOpoDrawer: FC<CreateAppealOpoDrawerProps> = ({
  clientId,
}) => {
  const [createAppealOpoMutation] = useCreateAppealOpoMutation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClickClose = () => {
    setIsOpen(false);
  };

  const initialValues = getInitialValues();
  const { addOkConfirm } = useConfirm();
  const { addSuccessToast, addErrorsToast } = useToasts();

  const createAppealOpo = async (values: CreateAppealOpoValues) => {
    const input = getInputDataFromValues(values, clientId);
    const data = await createAppealOpoMutation({
      variables: {
        input: input,
      },
      refetchQueries: [namedOperations.Query.AppealOpos],
    });

    if (data?.data?.createAppealOpo?.appealOpo?.id) {
      addSuccessToast("Заявка создана");
      handleClickClose();
    } else {
      addErrorsToast(data?.data?.createAppealOpo?.errors);
    }
  };

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: createAppealOpo,
  });

  const closeHandler = async (values: CreateAppealOpoValues) =>
    formCloseHandler({
      values,
      initialValues,
      onCancel: handleClickClose,
      addOkConfirm,
    });

  let values: CreateAppealOpoValues = initialValues;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={handleClickOpen} variant={"contained"}>
          {"Заявка на услугу"}
        </Button>
      </Box>
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 1000,
          },
        }}
        open={isOpen}
        onClose={() => closeHandler(values)}
      >
        <Formik<CreateAppealOpoValues>
          onSubmit={submitWithCspHandler}
          initialValues={initialValues}
          validate={CreateAppealOpoFormValidate}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(props) => {
            values = props.values;
            return (
              <DrawerForm>
                <DrawerContent
                  onClose={() => closeHandler(props.values)}
                  title="Заявка ОПО"
                  confirmButtonProps={{
                    disabled: !(
                      props.isValid &&
                      props.dirty &&
                      Object.keys(CreateAppealOpoFormValidate(values)).length ==
                        0
                    ),
                  }}
                  cancelButtonProps={{
                    onClick: () => closeHandler(props.values),
                  }}
                >
                  <CreateAppealOpoForm />
                </DrawerContent>
              </DrawerForm>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};
