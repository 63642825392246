import * as XLSX from "xlsx-js-style";
import { Button } from "@genome-frontend/uikit/build/lib/uikit/Button/Button";
import React, { useState } from "react";
import { CircularProgress } from "@genome-frontend/uikit/build/lib/uikit";

type TXlsxExportButtonProps = {
  columnParams?: XlsxColumnParams[];
  sheetName: string;
  fileName: string;
  fetchData: any;
};

export type XlsxColumnParams = {
  wch: number;
};

export const getIterationsCount = (
  totalItems: number,
  basicGridLength: number,
  takeCount: number
) => {
  // Округление до наименьшего целого, т.к если останется даже одна запись - нужно будет делать запрос
  return Math.ceil((totalItems - basicGridLength) / takeCount);
};

export const XlsxExportButton = ({
  columnParams,
  sheetName,
  fileName,
  fetchData,
}: TXlsxExportButtonProps) => {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>();
  const handleButtonClick = async () => {
    setDataIsLoading(true);
    const data = await fetchData();
    const sheet = XLSX.utils.aoa_to_sheet(data);
    sheet["!cols"] = columnParams;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
    XLSX.writeFile(workbook, `${fileName}.xls`);
    setDataIsLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleButtonClick}
        sx={{
          marginTop: "0.5%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "-1%",
        }}
        disabled={dataIsLoading}
      >
        {"Выгрузить в Excel"}
        {dataIsLoading && <CircularProgress />}
      </Button>
    </>
  );
};
