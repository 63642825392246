import {
  ConclusionType,
  CreateConclusionInput,
  namedOperations,
} from "generated/operations";
import { useToasts } from "components/Toast";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { ConclusionsName } from "../utils/conclusionsName";
import { TokenHelper } from "TokenHelper";
import { BoolNull } from "types/NullableTypes";
import { GqlAutocompleteOption } from "../../../components/GqlAutocomplete";
import { MutationFunctionOptions } from "@apollo/client";
import { TConclusionDoctorCreateResult } from "../utils/types";

type TCreateConclusionsDoctorProps<TMutation> = {
  appealId: number;
  organizationId: number | null;
  employeeId: string;
  selectedDiagnosisNarcologyId: GqlAutocompleteOption | null;
  selectedDiagnosisPsychiatristId: GqlAutocompleteOption | null;
  isNarcologyProfile: boolean;
  isPsychiatristProfile: boolean;
  isClinicalPsychologistProfile: boolean;
  isNarcologyFit: BoolNull;
  isPsychiatristFit: BoolNull;
  isClinicalPsychologistFit: BoolNull;
  createConclusionStatus: TConclusionDoctorCreateResult;
  createConclusionMutation: (
    options: MutationFunctionOptions<
      TMutation,
      { input: CreateConclusionInput }
    >
  ) => Promise<{ errors: Array<{ message: string }> | null | undefined }>;
};

type TCreateConclusionsDoctorResult = {
  createConclusionsDoctorWithCsp: (values: any) => Promise<void>;
};

export const useCreateConclusionsDoctorWithCsp = <TMutation>({
  appealId,
  organizationId,
  employeeId,
  selectedDiagnosisNarcologyId,
  selectedDiagnosisPsychiatristId,
  isNarcologyProfile,
  isPsychiatristProfile,
  isClinicalPsychologistProfile,
  isNarcologyFit,
  isPsychiatristFit,
  isClinicalPsychologistFit,
  createConclusionStatus,
  createConclusionMutation,
}: TCreateConclusionsDoctorProps<TMutation>): TCreateConclusionsDoctorResult => {
  const { addSuccessToast, addErrorsToast } = useToasts();

  const createConclusionFromData = (
    name: string,
    fit: boolean,
    diagnosisId: number | null
  ): CreateConclusionInput => {
    return {
      appealId: appealId,
      name: name,
      diagnosisId: diagnosisId,
      conclusionType: fit ? ConclusionType.GOODIN : ConclusionType.UNFIT,
      employeeId: employeeId,
      regionCode: TokenHelper.getRegionCode(),
      medicalOrganizationCode: TokenHelper.getOrganizationCode(),
      organizationId: organizationId,
    };
  };

  const createConclusion = async (conclusionName: string, fit: boolean) => {
    const { errors } = await createConclusionMutation({
      variables: {
        input: createConclusionFromData(
          conclusionName,
          fit,
          (conclusionName === ConclusionsName.Narcology
            ? selectedDiagnosisNarcologyId?.id
            : selectedDiagnosisPsychiatristId?.id) ?? null
        ),
      },

      refetchQueries: [namedOperations.Query.ConclusionAppeal],
    });

    if (errors) {
      addErrorsToast(errors);
      return;
    }

    addSuccessToast(`${conclusionName} создано`);
  };

  const createConclusions = async () => {
    if (
      isNarcologyProfile &&
      createConclusionStatus.isNarcologyAvailable &&
      isNarcologyFit !== null
    ) {
      await createConclusion(ConclusionsName.Narcology, isNarcologyFit);
    }

    if (
      isPsychiatristProfile &&
      createConclusionStatus.isPsychiatryAvailable &&
      isPsychiatristFit !== null
    ) {
      await createConclusion(ConclusionsName.Psychiatrist, isPsychiatristFit);
    }

    if (
      isClinicalPsychologistProfile &&
      createConclusionStatus.isClinicalPsychologistAvailable &&
      isClinicalPsychologistFit !== null
    ) {
      await createConclusion(
        ConclusionsName.ClinicalPsychologist,
        isClinicalPsychologistFit
      );
    }
  };

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: createConclusions,
  });

  return {
    createConclusionsDoctorWithCsp: submitWithCspHandler,
  };
};
