import * as yup from "yup";
import { locale } from "locale";

export const createAppealOpoValidationSchema = yup.object().shape({
  employeePosition: yup.string().required(`${locale.REQUIRED}`),
  department: yup.string().optional(),
  okved: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(`${locale.REQUIRED}`),
  }),
  opoWorkType: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(`${locale.REQUIRED}`),
  }),
  organizationInfo: yup.object().shape({
    id: yup.number().required(),
    organizationName: yup.string().required(`${locale.REQUIRED}`),
  }),
  userId: yup.string().uuid(`${locale.REQUIRED}`),
  requestDate: yup.date().required(`${locale.REQUIRED}`),
});
