import { GridSortModel } from "@mui/x-data-grid";
import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import { useReportsQuery } from "generated/hooks";
import {
  ReportsQuery,
  ReportsQueryVariables,
  ReportViewFragment,
} from "generated/operations";
import { reportsColumnTransformer } from "./utils/reportsColumnTransformer";
import { sortingTransformer } from "../../../../utils/sortingTransformer";
import { whereTransformer } from "../../../../utils/whereTransformer";

const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "desc" }];

export type TReportsDataGridProps = Partial<
  UseGqlDataGridParams<ReportsQuery, ReportViewFragment, ReportsQueryVariables>
>;

export type TReportsDataGridResult = UseGqlDataGridResult<
  ReportsQuery,
  ReportViewFragment,
  ReportsQueryVariables
>;

export const useReportsDataGrid = (
  props?: TReportsDataGridProps
): TReportsDataGridResult => {
  const gridName = "reports";
  return useGqlDataGrid<
    ReportsQuery,
    ReportViewFragment,
    ReportsQueryVariables
  >({
    type: gridName,
    queryHook: useReportsQuery,
    defaultSort: DEFAULT_SORT,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    columnTransformer: reportsColumnTransformer,
    ...props,
  });
};
