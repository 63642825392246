import {
  getIterationsCount,
  XlsxExportButton,
} from "components/XlsxExportButton/XlsxExportButton";
import { useReportsLazyQuery } from "generated/hooks";
import { ExcelTakeCount } from "constants/excelFetchExport";
import { TReportsDataGridResult } from "pages/ReportsPage/hooks/useReportsDataGrid/useReportsDataGrid";
import { UserRole } from "types/UserRole";

export type TReportsExportExcelButton = {
  gridData: TReportsDataGridResult;
};

export const ReportsExportExcelButton = ({
  gridData,
}: TReportsExportExcelButton) => {
  const [execQuery] = useReportsLazyQuery();
  const { gridProps } = gridData;
  const columnParams = [
    { wch: 35 },
    { wch: 20 },
    { wch: 30 },
    { wch: 30 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ];

  const convertRowDataToAoA = (rows: any): string[][] => {
    const dataAoA = [];

    dataAoA.push(gridProps.columns.map((c) => c.headerName));

    for (let row of rows) {
      dataAoA.push([
        row.name,
        "",
        "",
        row.patientFio,
        row.patientBirthdate,
        row.patientSnils,
        "",
        "",
        "",
      ]);

      for (let document of row.documents) {
        dataAoA.push([
          document.name,
          document.doctorPosition,
          document.doctorFio,
          row.patientFio,
          row.patientBirthdate,
          row.patientSnils,
          document.responseDate,
          document.number,
          document.isActual,
        ]);
      }
    }
    return dataAoA;
  };

  async function fetchForExcelData() {
    const takeCount = ExcelTakeCount;
    const basicGridLength = gridData.rows.length;
    const totalItems = gridProps.rowCount ?? 0;
    const historyArray = gridData.rows.slice(0);
    const iterationsCount = getIterationsCount(
      totalItems,
      basicGridLength,
      takeCount
    );
    for (let i = 0; i < iterationsCount; i++) {
      const response = await execQuery({
        variables: {
          roleName: UserRole.SystemAdmin,
          skip: i === 0 ? basicGridLength : takeCount * i + basicGridLength,
          take: takeCount,
          order: gridData.variables.order,
          where: gridData.variables.where,
        },
      });
      if (historyArray && response && response.data) {
        response.data.reports?.items?.forEach((item) => {
          historyArray.push(item);
        });
      }
    }
    return convertRowDataToAoA(historyArray);
  }

  return (
    <XlsxExportButton
      sheetName={"Отчет"}
      fileName={"Отчет"}
      columnParams={columnParams}
      fetchData={fetchForExcelData}
    />
  );
};
