import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const PencilOutlineIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "16", height: "16", viewBox: "0 0 16 16", ...props, sx: {
            width: 16,
            height: 16,
            ...props.sx,
        } },
        React.createElement("g", { clipPath: "url(#clip0_1614_972)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.4259 0.451773C12.8401 -0.134013 11.8904 -0.134012 11.3046 0.451774L2.06206 9.69429C1.89669 9.85966 1.77228 10.0614 1.69876 10.2834L0.0228441 15.3443C-0.106396 15.7346 0.2644 16.1054 0.654675 15.9761L5.71558 14.3002C5.93759 14.2267 6.13933 14.1023 6.3047 13.9369L15.5472 4.69441C16.133 4.10863 16.133 3.15888 15.5472 2.57309L13.4259 0.451773ZM12.727 1.85664C12.5317 1.66135 12.2151 1.66138 12.0198 1.8567L9.96269 3.91461L12.084 6.03593L14.1413 3.97789C14.3365 3.78262 14.3364 3.46609 14.1412 3.27085L12.727 1.85664ZM11.0233 7.0966L8.90203 4.97527L2.89162 10.9857L1.72019 14.2712L5.01294 13.107L11.0233 7.0966Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_1614_972" },
                React.createElement("rect", { width: "16", height: "16", fill: "white" })))));
};
