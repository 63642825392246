import React, { FC, useState } from "react";
import {
  Box,
  Modal,
  ModalContent,
} from "@genome-frontend/uikit/build/lib/uikit";
import { BoxProps, Button } from "@mui/material";
import { locale } from "locale";
import { MultipleAppealOpoTemplateGrid } from "./MultipleAppealOpoTemplateGrid";
import { MultipleAppealOpoTemplateFragment } from "../../../../generated/operations";
import { useDeleteMultipleAppealOpoTemplate } from "../../hooks/useMultipleAppealOpoTemplateGrid/useDeleteMultipleAppealOpoTemplate";
import { TextInputField } from "../../../../components/formFields/TextInputField";
import { Formik } from "formik";
import { useRenameMultipleAppealOpoTemplate } from "../../hooks/useMultipleAppealOpoTemplateGrid/useRenameMultipleAppealOpoTemplate";

interface MultipleAppealOpoTemplateDrawerProps {
  isModalOpen: boolean;
  onModalOpened: () => void;
  onModalClosed: () => void;
  onModalConfirmed: (template: MultipleAppealOpoTemplateFragment) => void;
}

interface TemplateRenameFormFields {
  newTemplateName: string;
}

export const MultipleAppealOpoTemplateDrawer: FC<
  MultipleAppealOpoTemplateDrawerProps & BoxProps
> = ({
  isModalOpen,
  onModalOpened,
  onModalClosed,
  onModalConfirmed,
  ...props
}) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<MultipleAppealOpoTemplateFragment>();

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [templateForRename, setTemplateForRename] =
    useState<MultipleAppealOpoTemplateFragment | null>(null);
  const { deleteTemplate } = useDeleteMultipleAppealOpoTemplate();
  const { renameTemplate } = useRenameMultipleAppealOpoTemplate();

  const handleTemplateSelected = (
    template: MultipleAppealOpoTemplateFragment
  ) => {
    setSelectedTemplate(template);
  };

  const handleModalConfirmed = () => {
    onModalConfirmed(selectedTemplate!);
    onModalClosed();
  };

  const handleTemplateDeleteClicked = (templateId: number) => {
    deleteTemplate(templateId);
  };

  const handleTemplateRenameClicked = (
    template: MultipleAppealOpoTemplateFragment
  ) => {
    setTemplateForRename(template);
    setIsRenameModalOpen(true);
  };

  const handleRenameTemplateModalClosed = () => {
    setTemplateForRename(null);
    setIsRenameModalOpen(false);
  };

  const handleRenameTemplateModalConfirmed = (newTemplateName: string) => {
    renameTemplate(templateForRename?.id!, newTemplateName);
    handleRenameTemplateModalClosed();
  };

  const getTemplateRenameFormFields = (): TemplateRenameFormFields => {
    return {
      newTemplateName: templateForRename?.name ?? "",
    };
  };

  return (
    <Box {...props}>
      <Button variant="contained" onClick={onModalOpened}>
        {locale.multipleAppealOpoTemplate.showSavedTemplates}
      </Button>
      <Modal open={isModalOpen} onClose={onModalClosed}>
        <ModalContent
          title={locale.multipleAppealOpoTemplate.savedTemplatesList}
          confirmButtonProps={{
            children: locale.SELECT,
            onClick: handleModalConfirmed,
          }}
          cancelButtonProps={{
            onClick: onModalClosed,
          }}
        >
          <MultipleAppealOpoTemplateGrid
            onTemplateSelected={handleTemplateSelected}
            onTemplateDeleteClicked={handleTemplateDeleteClicked}
            onTemplateRenameClicked={handleTemplateRenameClicked}
          />
        </ModalContent>
      </Modal>
      <Modal open={isRenameModalOpen} onClose={onModalClosed}>
        <Formik
          initialValues={getTemplateRenameFormFields()}
          onSubmit={() => {}}
          sx={{
            width: "100%",
          }}
        >
          {(formikProps) => {
            return (
              <ModalContent
                title={locale.multipleAppealOpoTemplate.enterTemplateName}
                confirmButtonProps={{
                  children: locale.SAVE,
                  disabled:
                    !formikProps.dirty ||
                    formikProps.values.newTemplateName.length == 0,
                  onClick: () => {
                    handleRenameTemplateModalConfirmed(
                      formikProps.values.newTemplateName
                    );
                  },
                }}
                cancelButtonProps={{
                  onClick: handleRenameTemplateModalClosed,
                }}
              >
                <TextInputField name={"newTemplateName"} required />
              </ModalContent>
            );
          }}
        </Formik>
      </Modal>
    </Box>
  );
};
