import { Button, Link, Stack, Tooltip } from "@mui/material";
import { ReactElement } from "react";
import "./IconLinkDownloadFile.css";
import { grey } from "../../constants/colors";
import { TokenHelper } from "TokenHelper";
import download from "downloadjs";

export interface IconLinkDownloadFormProps {
  clientId: string;
  conclusionId: number;
  popUpMessage: string;
  conclusionName: string;
  disabled?: boolean;
}

type DownloadFormParams = {
  clientId: string;
  conclusionId: number;
  formatExport: number;
  conclusionName: string;
};

const fetchDownloadForm = (params: DownloadFormParams) => {
  const baseApi = process.env.REACT_APP_CLOSED_LOCATION;
  const query = `/api/Conclusion/DownloadForm`;
  const endApi = baseApi + query;

  const token = TokenHelper.getCurrentToken();
  const tokenOpened = TokenHelper.getOpenedToken();

  return fetch(endApi!, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "X-OpenedToken": tokenOpened,
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.blob())
    .then((blob) =>
      download(
        blob,
        params.formatExport === 0 ? "Печатная форма.pdf" : "Печатная форма.docx"
      )
    );
};

export function IconLinkDownloadForm({
  clientId,
  conclusionId,
  popUpMessage,
  disabled,
  conclusionName,
}: IconLinkDownloadFormProps) {
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={popUpMessage} className={"tool"}>
        <Button
          style={{
            maxWidth: "35px",
            maxHeight: "30px",
            minWidth: "35px",
            minHeight: "30px",
          }}
          size="small"
          variant="contained"
          disabled={disabled}
          onClick={() => {
            fetchDownloadForm({
              clientId: clientId,
              conclusionId: conclusionId,
              formatExport: 0,
              conclusionName: conclusionName,
            });
          }}
        >
          PDF
        </Button>
      </Tooltip>
      <Tooltip title={popUpMessage} className={"tool"}>
        <Button
          style={{
            maxWidth: "45px",
            maxHeight: "30px",
            minWidth: "45px",
            minHeight: "30px",
          }}
          size="small"
          variant="contained"
          disabled={disabled}
          onClick={() => {
            fetchDownloadForm({
              clientId: clientId,
              conclusionId: conclusionId,
              formatExport: 1,
              conclusionName: conclusionName,
            });
          }}
        >
          DOCX
        </Button>
      </Tooltip>
    </Stack>
  );
}
