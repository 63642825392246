export class ConclusionsName {
  static Narcology: string = "Заключение нарколога";

  static Psychiatrist: string = "Заключение психиатра";

  static Therapist: string = "Заключение терапевта";

  static ClinicalPsychologist: string = "Заключение клинического психолога";

  static CommissionHead: string = "Заключение комиссии";
}
