import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { paths } from "router/paths";
import { OrganizationViewDrawer } from "../Components/OrganizationViewDrawer/OrganizationViewDrawer";

export const OrganizationViewDrawerPage: FC = () => {
  const navigate = useNavigate();

  const params = useParams();

  const id = Number(params.id);

  const closeHandler = () => {
    navigate(paths.organizations());
  };

  const editHandler = (organization: any) => {
    navigate(paths.organizationEdit(organization.id));
  };

  return (
    <OrganizationViewDrawer
      id={id}
      onClose={closeHandler}
      onError={closeHandler}
      onEdit={editHandler}
    />
  );
};
