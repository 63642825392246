import { TokenHelper } from "../TokenHelper";
import { useToasts } from "../components/Toast";
import { ApiResult } from "../types/ApiResut";
import { useSubmitWithCspHandler } from "./useSubmitWithCspHandler";
import { useApolloClient } from "@apollo/client";

export interface ClosedServerCspPostRequestParams<TDataInput, TDataOutput> {
  endPointPath: string;
  successToast: string;
  failureToast: string;
  onSuccess: (data: TDataOutput) => {};
  refetchQueryNames: Array<string> | undefined;
  isInitialValues?: (values: TDataInput) => boolean;
}
export interface UseSendPostRequestToClosedServer<TDataInput, TDataOutput> {
  sendPostRequestToClosedServerWithCsp: (values: TDataInput) => Promise<void>;
  cspLoading: boolean | undefined;
  saving: boolean;
}

export function useSendPostRequestToClosedServerWithCsp<
  TDataInput,
  TDataOutput
>(
  params: ClosedServerCspPostRequestParams<TDataInput, TDataOutput>
): UseSendPostRequestToClosedServer<TDataInput, TDataOutput> {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const apolloClient = useApolloClient();
  const baseApi = process.env.REACT_APP_CLOSED_LOCATION;
  const query = `/api/ClosedServer/SendPostRequestToClosedServer?closedEndPointPath=${params.endPointPath}`;
  const endApi = baseApi + query;
  let saving = false;

  const sendRequest = (data: TDataInput) => {
    saving = true;
    return fetch(endApi!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TokenHelper.getCurrentToken()}`,
        "X-OpenedToken": TokenHelper.getOpenedToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json() as Promise<ApiResult<TDataOutput>>;
      })
      .then(async (responseData) => {
        responseData =
          typeof responseData === "string"
            ? (JSON.parse(responseData) as ApiResult<TDataOutput>)
            : responseData;
        if (responseData.successful) {
          if (!!params.refetchQueryNames) {
            await apolloClient.refetchQueries({
              include: params.refetchQueryNames,
            });
          }
          addSuccessToast(params.successToast);
          params.onSuccess(responseData.data);
        } else {
          addErrorsToast([{ message: responseData.errorMessage }]);
        }
        return responseData.data;
      })
      .catch((error) => {
        addErrorsToast([{ message: params.failureToast }]);
        return error;
      });
  };

  const { submitWithCspHandler, cspLoading } = useSubmitWithCspHandler({
    next: sendRequest,
  });

  const sendRequestWithCsp = async (values: TDataInput) => {
    if (params.isInitialValues && params.isInitialValues(values)) {
      return;
    }

    await submitWithCspHandler(values);
  };

  return {
    sendPostRequestToClosedServerWithCsp: sendRequestWithCsp,
    cspLoading,
    saving,
  };
}
