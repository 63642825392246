import { getInputDataFromValues } from "../components/ClientCreateDrawer/getInputDataFromValues";
import { ClientCreateValue, ClientOutput } from "../types";
import { useConfirm } from "components/Confirm";
import { useCreateClient } from "./useCreateClient";
import { useToasts } from "components/Toast";
import { sendGetRequestToClosedServer } from "utils/sendGetRequestToClosedServer";
import { useNavigate } from "react-router";
import { paths } from "../../../router/paths";

type TCreateClientWithUniqueSnilsProps = {
  onSuccess: (user: ClientOutput) => void;
};

export interface UseCreateClientWithUniqueSnils {
  createClientWithUniqueSnils: (
    clientCreateValue: ClientCreateValue
  ) => Promise<void>;
}

export function useCreateClientWithUniqueSnils({
  onSuccess,
}: TCreateClientWithUniqueSnilsProps): UseCreateClientWithUniqueSnils {
  const { createClient } = useCreateClient({ onSuccess });
  const { addErrorsToast } = useToasts();
  const { addConfirm } = useConfirm();
  const defaultOnFailureText =
    "Ошибка обращения к серверу!\nПопробуйте перезайти в аккаунт и попробовать снова.";
  const navigate = useNavigate();

  const handleSubmit = async (input: ClientCreateValue) => {
    const clientInput = getInputDataFromValues(input);
    const query = `/api/Patient/GetPatientBySnils?snils=${clientInput.snils}`;
    const res = await sendGetRequestToClosedServer<ClientOutput>(query);
    if (res === null || !res.successful) {
      addErrorsToast([
        {
          message: res?.errorMessage ?? defaultOnFailureText,
        },
      ]);
    } else if (res.data === null) {
      await createClient(clientInput);
    } else if (res.data.id) {
      addConfirm({
        getConfirmProps: (close) => ({
          title: "Внимание",
          text: "Пользователь с указанным СНИЛС уже существует в системе, проверьте правильность введенных данных и исправьте их или откройте профиль пользователя",
          confirmButtonProps: {
            children: "Открыть профиль пользователя",
            onClick: () => {
              close();
              navigate(
                paths.clientFromEmployingOrganizationCreate(res.data.id)
              );
            },
          },
          cancelButtonProps: {
            children: "Исправить данные",
            onClick: close,
          },
        }),
      });
    }
  };

  return {
    createClientWithUniqueSnils: handleSubmit,
  };
}
