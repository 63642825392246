import { UserUpdateValue } from "../../types";
import { FormikErrors } from "formik/dist/types";
import { validateYupSchema, yupToFormErrors } from "formik";
import { editValidationSchema } from "./editValidationSchema";

export const UserEditFromValidate =
  (isOrganizationAdmin: boolean) =>
  (values: UserUpdateValue): FormikErrors<UserUpdateValue> => {
    let errors: FormikErrors<UserUpdateValue> = {};

    try {
      validateYupSchema(
        values,
        editValidationSchema(isOrganizationAdmin),
        true,
        values
      );
    } catch (err) {
      errors = yupToFormErrors(err);
    }

    return errors;
  };
