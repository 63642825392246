export enum UserProfile {
  SystemAdmin = "Главный администратор",
  OrganizationAdmin = "Администратор организации",
  Therapist = "Терапевт",
  Narcology = "Нарколог",
  Psychiatrist = "Психиатр",
  CommissionHead = "Председатель комиссии",
  Gibdd = "Сотрудник ГИБДД",
  Rosgvardia = "Сотрудник Росгвардии",
  User = "Пользователь",
  EmployingOrganization = "Организация-работодатель",
  ClinicalPsychologist = "Клинический психолог",
}
