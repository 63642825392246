import { GridRenderCellParams } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { ClientItemFragment } from "generated/operations";
import { ColumnTransformer } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { Link } from "@mui/material";
import { paths } from "router/paths";
import { locale } from "locale";
import { AccessPermissions } from "types/AccessPermissions";
import { TokenHelper } from "TokenHelper";

const fieldsOrder = ["fio", "birthdate", "snils", "phoneNumber"].reverse();

const getHeaderName = (field: string): string => {
  return (locale.users as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);

  switch (column.field) {
    case "fio":
      return {
        ...column,
        headerName,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, ClientItemFragment>) => {
          const id = row.id;
          let clientsPath = paths.client(id);
          if (
            TokenHelper.checkAccessPermission(AccessPermissions.ClientsUpdate)
          ) {
            clientsPath = paths.clientEdit(id);
          }
          return <Link href={clientsPath}>{value}</Link>;
        },
      };
    case "birthdate":
      return {
        ...column,
        headerName,
        sortable: false,
      };
    case "snils":
      return {
        ...column,
        headerName,
        sortable: false,
      };
    case "phoneNumber":
      return {
        ...column,
        headerName,
        sortable: false,
      };
    default:
      return {
        ...column,
        headerName,
      };
  }
};

export const clientsColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
