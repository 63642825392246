import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import {
  AppealsByAdminQuery,
  AppealsByAdminQueryVariables,
  AppealByAdminViewFragment,
} from "generated/operations";
import { useAppealsByAdminQuery } from "generated/hooks";
import { GridSortModel } from "@mui/x-data-grid";
import { appealsGridByAdminColumnTransformer } from "./utils/appealsGridByAdminColumnTransformer";

const DEFAULT_SORT: GridSortModel = [{ field: "id", sort: "desc" }];

export type TAppealsByAdminDataGridProps = Partial<
  UseGqlDataGridParams<
    AppealsByAdminQuery,
    AppealByAdminViewFragment,
    AppealsByAdminQueryVariables
  >
>;

export type TAppealsByAdminDataGridResult = UseGqlDataGridResult<
  AppealsByAdminQuery,
  AppealByAdminViewFragment,
  AppealsByAdminQueryVariables
>;

export const useAppealsByAdminDataGrid = (
  props?: TAppealsByAdminDataGridProps
): TAppealsByAdminDataGridResult => {
  return useGqlDataGrid<
    AppealsByAdminQuery,
    AppealByAdminViewFragment,
    AppealsByAdminQueryVariables
  >({
    type: "appealsByAdmin",
    queryHook: useAppealsByAdminQuery,
    defaultSort: DEFAULT_SORT,
    columnTransformer: appealsGridByAdminColumnTransformer,
    ...props,
  });
};
