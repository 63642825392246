import React, { FC, useMemo } from "react";
import { useToasts } from "components/Toast";
import { useConfirm } from "components/Confirm";
import {
  useOrganizationQuery,
  useUpdateOrganizationMutation,
} from "generated/hooks";
import {
  namedOperations,
  UpdateOrganizationMutation,
} from "generated/operations";
import { OrganizationValues } from "../../types";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { Formik } from "formik";
import { DrawerForm } from "components/Form";
import { getUpdateInputDataFromValues } from "../../utils/getUpdateInputDataFromValues";
import { getInitialValuesFromData } from "../../utils/getInitialValuesFromData";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { OrganizationEditForm } from "../OrganizationForm/OrganizationEditForm";
import { OrganizationEditFormValidate } from "../OrganizationForm/OrganizationEditFormValidate";
import { formCloseHandler } from "components/Form/formCloseHandler";

interface OrganizationEditDrawerProps {
  id: number;
  onSuccess: (payload: UpdateOrganizationMutation) => void;
  onCancel: () => void;
}

export const OrganizationEditDrawer: FC<OrganizationEditDrawerProps> = ({
  id,
  onSuccess,
  onCancel,
}) => {
  const { addErrorsToast } = useToasts();
  const { addOkConfirm } = useConfirm();

  const { data, loading } = useOrganizationQuery({
    variables: {
      id,
    },
    onError: () => {
      onCancel();
    },
  });

  const [updateOrganizationMutation, { loading: saving }] =
    useUpdateOrganizationMutation({
      onCompleted: (payload) => {
        onSuccess(payload);
      },
      onError: () => {
        addErrorsToast([{ message: "Ошибка при обновлении организации" }]);
      },
      refetchQueries: [
        namedOperations.Query.Organizations,
        namedOperations.Query.Organization,
      ],
    });

  const updateOrganization = async (values: OrganizationValues) => {
    const input = getUpdateInputDataFromValues(values);
    await updateOrganizationMutation({
      variables: {
        input,
      },
    });
  };

  const initialValues = useMemo(() => {
    if (!data?.organization) {
      return null;
    }
    return getInitialValuesFromData(data?.organization);
  }, [data]);

  const { submitWithCspHandler, cspLoading } = useSubmitWithCspHandler({
    next: updateOrganization,
  });

  if (!loading && !data?.organization) {
    return null;
  }

  if (!initialValues || loading) {
    return (
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 1000,
          },
        }}
        open
        onClose={onCancel}
      >
        <DrawerContent onClose={onCancel} title="Редактирование">
          <CenteredCircularProgress />
        </DrawerContent>
      </Drawer>
    );
  }

  const closeHandler = async (values: OrganizationValues) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: OrganizationValues;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open
      onClose={() => closeHandler(values)}
    >
      <Formik<OrganizationValues>
        validate={OrganizationEditFormValidate}
        onSubmit={submitWithCspHandler}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(props.values)}
                title="Редактирование"
                confirmButtonProps={{
                  disabled: saving || !props.isValid,
                }}
                cancelButtonProps={{
                  onClick: () => closeHandler(props.values),
                }}
              >
                {cspLoading && <CenteredCircularProgress />}
                {data?.organization && !cspLoading && (
                  <OrganizationEditForm isSubmitting={cspLoading} />
                )}
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
