export enum AccessPermissions {
  ClientsNavigation = "Administration.Clients.Navigation",
  ClientsRead = "Administration.Clients.Read",
  ClientsCreate = "Administration.Clients.Create",
  ClientsUpdate = "Administration.Clients.Update",
  ClientsDelete = "Administration.Clients.Delete",
  ClientsAssign = "Administration.Clients.AssignToEmployingOrganization",
  HistoryChangeNavigation = "Administration.HistoryChange.Navigation",
  HistoryChangeRead = "Administration.HistoryChange.Read",
  EmployeesNavigation = "Administration.Employees.Navigation",
  EmployeesRead = "Administration.Employees.Read",
  EmployeesCreate = "Administration.Employees.Create",
  EmployeesUpdate = "Administration.Employees.Update",
  EmployeesDelete = "Administration.Employees.Delete",
  OrganizationNavigation = "Administration.Organizations.Navigation",
  OrganizationsRead = "Administration.Organizations.Read",
  OrganizationsCreate = "Administration.Organizations.Create",
  OrganizationsUpdate = "Administration.Organizations.Update",
  OrganizationsDelete = "Administration.Organizations.Delete",
  EmployingOrganizationsUpdate = "Administration.EmployingOrganizations.Update",
  EmployingOrganizationsCreate = "Administration.EmployingOrganizations.Create",
  EmployingOrganizationsRead = "Administration.EmployingOrganizations.Read",
  EmployingOrganizationsProfileUpdate = "Administration.EmployingOrganizations.ProfileUpdate",
  EmployingOrganizationsDelete = "Administration.EmployingOrganizations.Delete",
  AppealsOpoNavigation = "Administration.AppealsOpo.Navigation",
  AppealsOpoCreate = "Administration.AppealsOpo.Create",
  AppealsOpoRead = "Administration.AppealsOpo.Read",
  AppealsOpoUpdate = "Administration.AppealsOpo.Update",
  AppealsOpoDelete = "Administration.AppealsOpo.Delete",
  Permissions = "Administration.Permissions",
  Reports = "Administration.Reports",
}
