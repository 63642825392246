import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { FC } from "react";
import { CreateMultipleOpoAppealDataGridFormValidate } from "../CreateMultipleAppealOpo/createMultipleOpoAppealDataGridFormValidate";
import { Box } from "@genome-frontend/uikit/build/lib/uikit";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";

export interface EditDepartmentProps {
  validationFieldContext: TValidationFieldContext;
  editCellParams: GridRenderEditCellParams;
  onChanged: (rowIndex: number, fieldName: string, fieldValue: any) => void;
}

export const EditDepartment: FC<EditDepartmentProps> = ({
  validationFieldContext,
  editCellParams,
  onChanged,
}) => {
  const apiRef = useGridApiContext();
  const { id, field } = editCellParams;
  const handleChange = (name: any, newValue: any) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };
  const index = apiRef.current.getRowIndex(id);

  const oldSet = validationFieldContext.setFieldValue;
  const newSet = (name: any, v: any) => {
    handleChange(name, v);
    oldSet(name, v);
    onChanged(index, name, v);
  };
  let newValidationFieldContext: TValidationFieldContext = {
    setFieldValue: newSet,
    formValidationFunc: CreateMultipleOpoAppealDataGridFormValidate as any,
    setFieldError: validationFieldContext.setFieldError,
    setFieldTouched: validationFieldContext.setFieldTouched,
    touched: validationFieldContext.touched,
    errors: validationFieldContext.errors,
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <ValidationTextInputField
        style={{ width: "100%" }}
        name={`values[${index}].department`}
        validationFieldContext={newValidationFieldContext}
      />
    </Box>
  );
};
