import { Outlet } from "react-router";
import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit/DataGrid/DataGrid";
import React, { FC, useEffect, useState } from "react";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { useFormikContext } from "formik";
import { MultipleOpoAppealGridRow, MultipleOpoAppealValues } from "../../types";
import { CreateMultipleOpoAppealDataGridFormValidate } from "./createMultipleOpoAppealDataGridFormValidate";
import { GridColumns } from "@mui/x-data-grid";
import { EditOkved } from "../EditOkvedColumn/EditOkved";
import { EditOpoWorkType } from "../EditOpoWorkTypeColumn/EditOpoWorkType";
import { EditDepartment } from "../EditDepartmentColumn/EditDepartment";
import { EditEmployeePosition } from "../EditEmployeePositionColumn/EditEmployeePosition";
import { locale } from "locale";
import { getOpoWorkTypeCaption } from "components/Selectors/SelectOpoWorkType";
import { DeleteUserFromMultipleAppealButton } from "../DeleteUserFromMultipleAppealButtonColumn/DeleteUserFromMultipleAppealButton";

export interface MultipleOpoAppealDataGridFormProps {
  rows: MultipleOpoAppealGridRow[];
  onRowFieldChanged: (
    rowIndex: number,
    fieldName: string,
    fieldValue: any
  ) => void;
  onRowDeleted: (rowId: string) => void;
}

export const MultipleOpoAppealDataGridForm: FC<
  MultipleOpoAppealDataGridFormProps
> = ({ rows, onRowFieldChanged, onRowDeleted }) => {
  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
  } = useFormikContext<MultipleOpoAppealValues>();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: CreateMultipleOpoAppealDataGridFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  useEffect(() => {
    rows.forEach(async (r, i) => {
      await setFieldValue(`values[${i}].userId`, r.userId);
      await setFieldValue(`values[${i}].requestDate`, r.requestDate);
    });
  }, [rows, setFieldValue]);

  const [columns] = useState<GridColumns>([
    { field: "fio", headerName: locale.multipleAppealOpo.fio, width: 300 },
    {
      field: "department",
      headerName: locale.multipleAppealOpo.department,
      editable: true,
      flex: 3,
      renderEditCell: (params) => (
        <EditDepartment
          validationFieldContext={validationFieldContext}
          editCellParams={params}
          onChanged={onRowFieldChanged}
        />
      ),
      renderCell: (params) =>
        params?.value?.length > 0 ? (
          params.value
        ) : (
          <EditDepartment
            validationFieldContext={validationFieldContext}
            editCellParams={params}
            onChanged={onRowFieldChanged}
          />
        ),
    },
    {
      field: "employeePosition",
      headerName: locale.multipleAppealOpo.employeePosition,
      editable: true,
      flex: 3,
      renderEditCell: (params) => (
        <EditEmployeePosition
          validationFieldContext={validationFieldContext}
          editCellParams={params}
          onChanged={onRowFieldChanged}
        />
      ),
      renderCell: (params) =>
        params?.value?.length > 0 ? (
          params.value
        ) : (
          <EditEmployeePosition
            validationFieldContext={validationFieldContext}
            editCellParams={params}
            onChanged={onRowFieldChanged}
          />
        ),
    },
    {
      field: "okved",
      headerName: locale.multipleAppealOpo.okved,
      editable: true,
      flex: 2,
      renderEditCell: (params) => (
        <EditOkved
          validationFieldContext={validationFieldContext}
          editCellParams={params}
          onChanged={onRowFieldChanged}
        />
      ),
      renderCell: (params) =>
        params?.value?.code?.toString() || (
          <EditOkved
            validationFieldContext={validationFieldContext}
            editCellParams={params}
            onChanged={onRowFieldChanged}
          />
        ),
    },
    {
      field: "opoWorkType",
      headerName: locale.multipleAppealOpo.opoWorkType,
      flex: 2,
      editable: true,
      renderEditCell: (params) => (
        <EditOpoWorkType
          validationFieldContext={validationFieldContext}
          editCellParams={params}
          onChanged={onRowFieldChanged}
        />
      ),
      renderCell: (params) =>
        params?.value?.code ? (
          getOpoWorkTypeCaption(params.value)
        ) : (
          <EditOpoWorkType
            validationFieldContext={validationFieldContext}
            editCellParams={params}
            onChanged={onRowFieldChanged}
          />
        ),
    },
    {
      field: "deleteRow",
      width: 50,
      renderCell: (params) => (
        <DeleteUserFromMultipleAppealButton
          editCellParams={params}
          validationFieldContext={validationFieldContext}
          onDeleted={onRowDeleted}
        />
      ),
    },
  ]);

  return (
    <>
      <DataGrid
        hideHeader={true}
        rows={rows}
        getRowId={(r) => r.userId}
        columns={columns}
        rowsPerPageOptions={[10, 30, 50]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
      />
      <Outlet />
    </>
  );
};
