import { useEmployingOrganizationProfileQuery } from "generated/hooks";
import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  CircularProgress,
  Label,
} from "@genome-frontend/uikit/build/lib/uikit";
import { locale } from "locale";
import CspCertificate from "components/CspCertificate/CspCertificate";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { TokenHelper } from "TokenHelper";
import { paths } from "router/paths";
import { useNavigate, Outlet } from "react-router";
import { useSubmitCertificate } from "pages/ProfilePage/utils/useSubmitCertificate";
import { MaskExpress } from "utils/maskExpression";
import { PencilOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PencilOutlineIcon";
import { ClippedText } from "components/ClippedText";

export const EmployingOrganizationProfileForm = () => {
  const { data, loading } = useEmployingOrganizationProfileQuery();

  const { saving, submitCertificate } = useSubmitCertificate();
  const navigate = useNavigate();

  if (!loading && !data) {
    return null;
  }

  const formatOkved = (code: string, name: string) => code + " - " + name;

  return (
    <>
      <Box>
        {loading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            <Grid margin={10}>
              <Grid container item>
                <Grid xs={4} item />
                <Grid xs={4} item>
                  <Button
                    sx={{ ml: 1 }}
                    startIcon={<PencilOutlineIcon />}
                    onClick={() => navigate(paths.editProfile())}
                  >
                    {"Редактировать информацию"}
                  </Button>
                </Grid>
              </Grid>

              <Typography sx={{ mb: 2 }} variant="h6">
                Данные уполномоченного представителя
              </Typography>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization?.surname}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.delagateSurname ??
                      "Иванов"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.name}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.delagateName ?? "Иван"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.fathersName}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.delagateFathersName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization?.phoneNumber}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {MaskExpress.FromPhoneNumber(
                      data?.employingOrganizationProfile?.phoneNumber ?? null
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.email}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.email}
                  </Typography>
                </Grid>
              </Grid>

              <Typography sx={{ mb: 2, mt: 5 }} variant="h6">
                Данные об организации
              </Typography>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.organizationName}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.organizationName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.address}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.address}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.profile}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>{TokenHelper.getCurrentUserRole()}</Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.okved}</Label>
                </Grid>
                <Grid xs={4} item>
                  <ClippedText
                    text={formatOkved(
                      data?.employingOrganizationProfile.mainOkved?.code ?? "",
                      data?.employingOrganizationProfile.mainOkved?.name ?? ""
                    )}
                  />
                </Grid>
              </Grid>

              {data?.employingOrganizationProfile.additionalOkveds?.length ??
              0 > 0 ? (
                <Grid container item>
                  <Grid xs={4} item>
                    <Label>
                      {locale.employingOrganization.additionalOkveds}
                    </Label>
                  </Grid>
                  <Grid xs={4} container item>
                    {data?.employingOrganizationProfile.additionalOkveds.map(
                      (okv) => (
                        <ClippedText
                          text={formatOkved(okv.code ?? "", okv.name ?? "")}
                        />
                      )
                    )}
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              <Grid mt={4} container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.ogrn}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.ogrn}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.inn}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.inn}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid xs={4} item>
                  <Label>{locale.employingOrganization.kpp}</Label>
                </Grid>
                <Grid xs={4} item>
                  <Typography>
                    {data?.employingOrganizationProfile?.kpp}
                  </Typography>
                </Grid>
              </Grid>

              <Typography sx={{ mb: 2, mt: 5 }} variant="h6">
                Привязка ЭЦП
              </Typography>
              {saving ? (
                <CircularProgress />
              ) : (
                <Grid container item>
                  <Grid xs={3} item>
                    <CspCertificate
                      onSubmit={submitCertificate}
                      buttonText={"Привязать сертификат ЭЦП к профилю"}
                      details={false}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Box>
      <Outlet />
    </>
  );
};
